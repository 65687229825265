import * as ACTION from "../actiontypes/actionTypes";

const initialState: INNOVATION_STATE = {
  INNOVATION: {
    INNOVATION_LIST: [],
    INNOVATION_COUNT: 0,
    USER_LIST: [],
  },
};

export const innovationreducer = (
  state: INNOVATION_STATE = initialState,
  action: INNOVATION_ACTION
): INNOVATION_STATE => {
  switch (action.type) {
    case ACTION.INNOVATION_GET:
      let innovaion_list: INNOVATION = action.INNOVATION;
      return { ...state, INNOVATION: innovaion_list };
    case ACTION.INNOVATION_GET_ID:
      let innovation: INNOVATION = action.INNOVATION;
      return { ...state, INNOVATION: innovation };

    case ACTION.INNOVATION_REGISTERED_USERS:
      let users: INNOVATION = action.INNOVATION;
      return { ...state, INNOVATION: users };

    default:
      return { ...state };
  }
  // return state;
};
