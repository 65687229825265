import axios from "axios";
import { store } from "../../store";
import * as EVENT from "../../Components/constants";
import { EVENT_GET, EVENT_GET_ID } from "../actiontypes/actionTypes";
import { RequestMethods } from "../../shared/RequestMethods";

export const getEvents =
  (skip: string, limit: string, eventType: string = "All Events") =>
  (dispatch: GET_EVENT_DISPATCH) => {
    let eventTypeData = "";

    if (eventType !== "All Events") {
      eventTypeData = eventType.toUpperCase();
    }

    let config = {
      method: "get",
      url: `${process.env.REACT_APP_BASE_API}/events?skip=${skip}&limit=${limit}&eventType=${eventTypeData}`,
      headers: {
        "Content-Type": "application/json",
      },
    };

    axios(config)
      .then(function (response) {
        const list: EVENT = {
          EVENT_LIST: response.data,
          USER_LIST: [],
        };
        const action: EVENT_ACTION = {
          type: EVENT_GET,
          EVENT: list,
        };
        return dispatch(action);
      })
      .catch(function (error) {
        // console.log("Events not fetched ", error);
      });
  };

export const getEventId = (id: string) => (dispatch: GET_EVENT_ID_DISPATCH) => {
  var config = {
    method: "put",
    url: `${process.env.REACT_APP_BASE_API}/events/${id}`,
    headers: {
      "Content-Type": "application/json",
    },
  };
  axios(config)
    .then(function (response) {
      const list: EVENT = {
        EVENT_LIST: response.data,
        USER_LIST: [],
      };
      const action: EVENT_ACTION = {
        type: EVENT_GET_ID,
        EVENT: list,
      };
      // console.log("Event id fetched ");
      return dispatch(action);
    })
    .catch(function (error) {
      // console.log("Event id not fetched ", error);
    });
};

export const registerEvent = async (id: string) => {
  let data = {
    eventId: id,
    registrationDetails: {},
  };
  let config = {
    method: RequestMethods.POST,
    url: `${process.env.REACT_APP_BASE_API}/events/register`,
    headers: {
      Authorization: `Bearer ${store.getState().USER.USER.token}`,
      "Content-Type": "application/json",
    },
    data,
  };
  axios(config)
    .then(function (response) {})
    .catch(function (error) {});
};

export const registerEventPaymentLink = async (
  id: string
): Promise<{
  order_id: string;
  currency: string;
  amount: number;
  promotionId: string;
}> => {
  let data = {
    eventId: id,
    registrationDetails: {},
  };
  let config = {
    method: RequestMethods.POST,
    url: `${process.env.REACT_APP_BASE_API}/events/registerpayment`,
    headers: {
      Authorization: `Bearer ${store.getState().USER.USER.token}`,
      "Content-Type": "application/json",
    },
    data,
  };

  const response = await axios(config);
  const { order_id, currency, amount, promotionId } = response.data;

  return { order_id, currency, amount, promotionId };
};
