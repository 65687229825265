export const SET_USER = "SET_USER";
export const RESET_USER = "RESET_USER";
export const LOADER_SHOW = "LOADER_SHOW";
export const LOADER_HIDE = "LOADER_HIDE";

// user management
export const GET_NEWUSERS = "GET_NEWUSERS";
export const GET_EXISTUSERS = "GET_EXISTUSERS";
export const GET_REJECTUSER = "GET_REJECTUSER";
export const GET_UPDATEUSER = "UPDATEUSER";
export const ACCEPT_USERPROFILE = "ACCEPT_USERPROFILE";
export const REJECT_USERPROFILE = "REJECT_USERPROFILE";
export const UPDATE_USERPROFILE = "UPDATE_USERPROFILE";
export const UPDATE_COMPANYPROFILE = "UPDATE_COMPANYPROFILE";

//products

export const GET_PRODUCTS = "GET_PRODUCTS";

// publications
export const PUBLICATIONS_CREATE = "PUBLICATIONS_CREATE";
export const PUBLICATIONS_UPDATE = "PUBLICATIONS_UPDATE";
export const PUBLICATIONS_GET = "PUBLICATIONS_GET";
export const PUBLICATIONS_GET_ID = "PUBLICATIONS_GET_ID";
export const PUBLICATIONS_DELETE = "PUBLICATIONS_DELETE";

// news
export const NEWS_CREATE = "NEWS_CREATE";
export const NEWS_UPDATE = "NEWS_UPDATE";
export const NEWS_GET = "NEWS_GET";
export const NEWS_GET_ID = "NEWS_GET_ID";
export const NEWS_DELETE = "NEWS_DELETE";

//opportunity

export const GET_OPPORTUNITY = "GET_OPPORTUNITY";
export const REJECTED_OPPORTUNITY = "REJECTED_OPPORTUNITY";

// article
export const ARTICLE_CREATE = "ARTICLE_CREATE";
export const ARTICLE_UPDATE = "ARTICLE_UPDATE";
export const ARTICLE_GET = "ARTICLE_GET";
export const ARTICLE_GET_ID = "ARTICLE_GET_ID";
export const ARTICLE_DELETE = "ARTICLE_DELETE";

// blogs
export const BLOG_CREATE = "BLOG_CREATE";
export const BLOG_UPDATE = "BLOG_UPDATE";
export const BLOG_GET = "BLOG_GET";
export const BLOG_GET_ID = "BLOG_GET_ID";
export const BLOG_DELETE = "BLOG_DELETE";

// events
export const EVENT_CREATE = "EVENT_CREATE";
export const EVENT_UPDATE = "EVENT_UPDATE";
export const EVENT_GET = "EVENT_GET";
export const EVENT_GET_ID = "EVENT_GET_ID";
export const EVENT_DELETE = "EVENT_DELETE";
export const EVENT_REGISTERED_USERS = "EVENT_REGISTERED_USERS";

// innovation
export const INNOVATION_CREATE = "INNOVATION_CREATE";
export const INNOVATION_UPDATE = "INNOVATION_UPDATE";
export const INNOVATION_GET = "INNOVATION_GET";
export const INNOVATION_GET_ID = "INNOVATION_GET_ID";
export const INNOVATION_DELETE = "INNOVATION_DELETE";
export const INNOVATION_REGISTERED_USERS = "INNOVATION_REGISTERED_USERS";

// contact
export const CONTACT_CREATE = "CONTACT_CREATE";
export const CONTACT_GET = "CONTACT_GET";
export const CONTACT_DELETE = "CONTACT_DELETE";
// notification
export const NOTIFICATION_PATCH = "NOTIFICATION_PATCH";
export const NOTIFICATION_GET = "NOTIFICATION_GET";
export const NOTIFICATION_GET_UNVIEWD = "NOTIFICATION_GET_UNVIEWD";
export const NOTIFICATION_GET_COUNT = "NOTIFICATION_GET_COUNT";
// chat
export const CHAT_GET = "CHAT_GET";
export const CHAT_DELETE = "CHAT_DELETE";
// subscribed
export const SUBSCRIBED_GET = "SUBSCRIBED_GET";
export const SUBSCRIBED_DELETE = "SUBSCRIBED_DELETE";
// ongoin
export const ONGOING_GET = "ONGOING_GET";

// technology partners
export const PARTNERS_CREATE = "PARTNERS_CREATE";
export const PARTNERS_GET = "PARTNERS_GET";
export const PARTNERS_UPDATE = "PARTNERS_UPDATE";
export const PARTNERS_DELETE = "PARTNERS_DELETE";

// sector
export const SECTOR_CREATE = "SECTOR_CREATE";
export const SECTOR_GET = "SECTOR_GET";
export const SECTOR_GET_ID = "SECTOR_GET_ID";
export const SECTOR_SLUG = "SECTOR_SLUG";
//sub-sector
export const SUB_SECTOR_CREATE = "SUB_SECTOR_CREATE";
export const SUB_SECTOR_GET = "SUB_SECTOR_GET";
export const SUB_SECTOR_GET_ID = "SUB_SECTOR_GET_ID";
export const SUB_SECTOR_SLUG = "SUB_SECTOR_SLUG";

// toast
export const TOAST_SUCCESS = "TOAST_SUCCESS";
export const TOAST_FAIL = "TOAST_FAIL";
export const TOAST_CLOSE = "TOAST_CLOSE";

// follow
export const FOLLOW_USER = "FOLLOW_USER";
export const UNFOLLOW_USER = "UNFOLLOW_USER";
// connection
export const CONNECT_USER = "CONNECT_USER";
export const UNCONNECT_USER = "UNCONNECT_USER";
export const CONNECTION_LIST = "CONNECTION_LIST";
// loader
export const BACKDROP_LOADER_START = "BACKDROP_LOADER_START";
export const BACKDROP_LOADER_STOP = "BACKDROP_LOADER_STOP";
export const SPINNER_LOADER_START = "SPINNER_LOADER_START";
export const SPINNER_LOADER_STOP = "SPINNER_LOADER_STOP";

export const TECHNOLOGY_PARTNER_GET = "TECHNOLOGY_PARTNER_GET";

export const PROMOTIONS_GET = "PROMOTIONS_GET";
export const PROMOTIONS_CREATE = "PROMOTIONS_CREATED";
export const PROMOTIONS_HOMEPAGE_GET = "PRROMOTIONS_HOMEPAGE_GET";
