import React, { Dispatch, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Helmet } from "react-helmet";

import {
  LIMIT,
  productItemPartialFetched,
  sectorItem,
  SKIP,
  subsectorItem,
  TECHNOLOGY,
  title,
  metaData,
} from "../constants";
import { getSector } from "../../store/actioncreators/sectoractions";
import { getSubSectorBySector } from "../../store/actioncreators/sub-sectoractions";
import ProductList from "./ProductList";
import { useDetectClickOutside } from "react-detect-click-outside";
import product_logo from "../../assests/images/products_logo.png";
import { getQueryParams } from "../../utils";
import globe from "../../assests/home/globe.png";
import "./style.css";

const Products = ({ handleLoginModal }: { handleLoginModal: () => void }) => {
  const sectorlist: SECTOR = useSelector((state: STATE) => state.SECTOR.SECTOR);
  const subsectorlist: SUB_SECTOR = useSelector(
    (state: STATE) => state.SUB_SECTOR.SUB_SECTOR
  );
  const dispatch: Dispatch<any> = useDispatch();
  const navigator = useNavigate();

  const [page, setPage] = useState({
    skip: getQueryParams("skip") ? getQueryParams("skip") : SKIP,
    limit: LIMIT,
  });
  const [maxSkip, setMaxSkip] = useState(0);

  const [sector, setSector] = useState({
    drop: false,
    selected: "",
    id: "",
  });

  const [subSector, setSubSector] = useState({
    drop: false,
    selected: "",
    id: "",
    count: 0,
  });

  const ref1 = useDetectClickOutside({
    onTriggered: () => {
      setSector({ ...sector, drop: false });
    },
  });

  const ref2 = useDetectClickOutside({
    onTriggered: () =>
      setSubSector({
        drop: false,
        selected: "Select sub sector",
        id: "",
        count: 0,
      }),
  });

  const products: PRODUCTS = useSelector(
    (state: STATE) => state.PRODUCTS.PRODUCTS
  );

  const fetchData = (value: number) => {
    let final =
      parseInt(page.skip) + value < 0
        ? parseInt(page.skip)
        : parseInt(page.skip) + value;
    setPage({ skip: final.toString(), limit: page.limit });
    navigator(TECHNOLOGY + `?skip=${final}`);
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    setMaxSkip(
      Math.ceil(products.PRODUCTS_LIST.productsCount / parseInt(LIMIT))
    );
  }, [page, products]);

  useEffect(() => {
    dispatch(getSector());
    setSector({
      drop: false,
      selected: "All Sectors",
      id: "",
    });
    setSubSector({
      ...subSector,
      selected: "All Sub-Sectors",
      id: "",
      count: 0,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSectorClick = (id: string, name: string) => {
    if (id !== sector.id || sector.id === "") {
      dispatch(getSubSectorBySector(id));
      setSector({ ...sector, id: id, selected: name });
      setSubSector({ ...subSector, drop: true });
    } else {
      setSubSector({ ...subSector, drop: !subSector.drop });
    }
  };

  const handleSubSectorClick = () => {};

  const getCountbyFilter = () => {
    let count =
      products.PRODUCTS_LIST?.products &&
      products.PRODUCTS_LIST?.products?.reduce(
        (count: number, item: productItemPartialFetched) =>
          count + Number(item.subSectorId === subSector.id),
        0
      );
    return count;
  };

  let [search, setSearch] = useState("");

  const handleSearch = (search: string) => {
    setPage({ skip: "0", limit: LIMIT });
    setSearch(search);
  };

  return (
    <div className="flex flex-col min-h-screen relative  justify-between py-10 items-center w-full">
      <Helmet>
        <title>{title.TECHNOLOGY}</title>
        <meta
          name="description"
          key="description"
          content={metaData.TECHNOLOGY}
        />
        <meta name="title" key="title" content={title?.TECHNOLOGY} />
        <meta property="og:title" content={title.TECHNOLOGY} />
        <meta property="og:description" content={metaData.TECHNOLOGY} />
        <meta property="og:image" content={globe} />
        <meta
          property="og:url"
          content={`${process.env.REACT_APP_BASE_URL}/technology`}
        />
        <meta property="og:type" content="website" />
        <meta name="twitter:title" content={title?.TECHNOLOGY} />
        <meta name="twitter:description" content={metaData.TECHNOLOGY} />
        <meta name="twitter:image" content={globe} />
        <meta name="twitter:card" content={title?.TECHNOLOGY} />
      </Helmet>
      <div className="flex flex-col w-full items-center">
        <div className="flex flex-row items-center">
          <img
            src={product_logo}
            alt="Technologies"
            className="w-14 h-14 mr-2"
          />
          <h1 className="text-4xl font-roboto ">Technologies</h1>
        </div>
        <div className="flex w-full justify-between  mx-10">
          <div className="md:flex w-full justify-start">
            <div
              className="flex flex-col justify-end duration-200 z-10 px-5"
              ref={ref1}
            >
              <button
                id="dropdownDefault"
                data-dropdown-toggle="dropdown"
                className="w-48 text-GTI-BLUE-default border bg-white focus:outline-none font-medium font-roboto rounded-lg m-1 text-sm px-4 py-2.5  text-center inline-flex justify-between items-center border-slate-300  flex-shrink"
                type="button"
                onClick={() => {
                  setSector({ ...sector, drop: !sector.drop });
                  setSubSector({ ...subSector, drop: false });
                }}
              >
                {sector.id === "" ? "All Sectors" : sector.selected}
                <svg
                  className="ml-2 w-4 h-4"
                  aria-hidden="true"
                  fill="grey"
                  stroke="grey"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M19 9l-7 7-7-7"
                  ></path>
                </svg>
              </button>
              <div
                id="dropdown"
                className={
                  "relative z-10 px-2  rounded " +
                  (!sector.drop ? "hidden" : "")
                }
                data-popper-placement="bottom"
              >
                <ul
                  className="absolute flex flex-col text-sm font-roboto bg-white border border-gray-100 shadow rounded-md"
                  aria-labelledby="dropdownDefault"
                >
                  <li
                    className="block z-10 py-2 px-4 rounded  text-GTI-BLUE-default  whitespace-nowrap hover:bg-blue-50 "
                    onClick={() => {
                      setSector({
                        id: "",
                        drop: false,
                        selected: "All Sectors",
                      });
                      setSubSector({
                        ...subSector,
                        id: "",
                        drop: false,
                        count: 0,
                      });
                    }}
                  >
                    All Sectors
                  </li>
                  {sectorlist &&
                    sectorlist.SECTOR_LIST.map((item: sectorItem, id) => {
                      return (
                        <li
                          className="block z-10 py-2 px-4 rounded  text-GTI-BLUE-default  whitespace-nowrap hover:bg-blue-50 "
                          onClick={() => {
                            handleSectorClick(item._id, item.name);
                          }}
                        >
                          {item.name}
                        </li>
                      );
                    })}
                </ul>
              </div>
            </div>
            {subsectorlist.SUB_SECTOR_LIST.length > 0 &&
            sector.selected !== "All Sectors" &&
            sector.id !== "" ? (
              <div
                className="flex flex-col justify-end duration-200 z-10 px-5"
                ref={ref2}
              >
                <button
                  id="dropdownDefault"
                  data-dropdown-toggle="dropdown"
                  className="w-48 text-GTI-BLUE-default border bg-white focus:outline-none font-medium font-roboto rounded-lg m-1 text-sm px-4 py-2.5  text-center inline-flex justify-between items-center border-slate-300  flex-shrink"
                  type="button"
                  onClick={() => {
                    setSector({ ...sector, drop: false });
                    setSubSector((prev) => ({ ...prev, drop: !prev.drop }));
                  }}
                >
                  {sector.id === "" ? "Select Subsector" : subSector.selected}
                  <svg
                    className="ml-2 w-4 h-4"
                    aria-hidden="true"
                    fill="grey"
                    stroke="grey"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M19 9l-7 7-7-7"
                    ></path>
                  </svg>
                </button>
                <div
                  id="dropdown"
                  className={
                    "relative z-10 px-2  rounded " +
                    (!subSector.drop ? "hidden" : "")
                  }
                  data-popper-placement="bottom"
                >
                  <ul
                    className="absolute flex flex-col text-sm font-roboto bg-white border border-gray-100 shadow rounded-md"
                    aria-labelledby="dropdownDefault"
                  >
                    {subSector.drop &&
                      subsectorlist.SUB_SECTOR_LIST.length > 0 &&
                      subsectorlist.SUB_SECTOR_LIST.map(
                        (item: subsectorItem, id) => {
                          return (
                            <li
                              className="block z-10 py-2 px-4 rounded  text-GTI-BLUE-default  whitespace-nowrap hover:text-slate-500 "
                              onClick={() => {
                                setSubSector({
                                  id: item._id,
                                  drop: false,
                                  selected: item.name,
                                  count: getCountbyFilter(),
                                });
                                handleSubSectorClick();
                              }}
                            >
                              {item.name}
                            </li>
                          );
                        }
                      )}
                  </ul>
                </div>
              </div>
            ) : null}
          </div>
          <div className="flex pr-10 items-center">
            <label htmlFor="simple-search" className="sr-only">
              Search
            </label>
            <div className="relative w-full">
              <div className="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                <svg
                  aria-hidden="true"
                  className="w-5 h-5 text-gray-500 hover:text-GTI-BLUE-default"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                    clipRule="evenodd"
                  ></path>
                </svg>
              </div>
              <input
                type="text"
                id="simple-search"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5"
                placeholder="Search"
                required
                value={search}
                onChange={(e) => {
                  handleSearch(e.target.value);
                }}
              />
            </div>
          </div>
        </div>
        <ProductList
          skip={page.skip}
          limit={LIMIT}
          secId={sector.id}
          subSecId={subSector.id}
          search={search}
        />
      </div>

      <div className="flex mb-5 py-5">
        <button
          disabled={page.skip === "0"}
          onClick={() => {
            fetchData(-1);
          }}
          className="inline-flex items-center py-2 px-4 text-sm font-medium text-GTI-BLUE-default disabled:text-gray-500 bg-white  rounded-lg border border-gray-300 hover:bg-gray-100 hover:text-gray-700 "
        >
          <svg
            aria-hidden="true"
            className="mr-2 w-5 h-5"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              d="M7.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l2.293 2.293a1 1 0 010 1.414z"
              clipRule="evenodd"
            ></path>
          </svg>
          Previous
        </button>
        {parseInt(page.skip) + 1 <= maxSkip && (
          <button
            onClick={() => {
              fetchData(0);
            }}
            className="inline-flex items-center mx-2 py-2 px-4 text-sm font-medium text-gray-500 bg-white rounded-lg border border-gray-300 hover:bg-gray-100 hover:text-gray-700 "
          >
            {parseInt(page.skip) + 1}
          </button>
        )}
        {parseInt(page.skip) + 2 <= maxSkip && (
          <button
            onClick={() => {
              fetchData(1);
            }}
            className="inline-flex items-center mx-2 py-2 px-4 text-sm font-medium text-gray-500 bg-white rounded-lg border border-gray-300 hover:bg-gray-100 hover:text-gray-700 "
          >
            {parseInt(page.skip) + 2}
          </button>
        )}
        {parseInt(page.skip) + 3 <= maxSkip && (
          <button
            onClick={() => {
              fetchData(2);
            }}
            className="inline-flex items-center mx-2 py-2 px-4 text-sm font-medium text-gray-500 bg-white rounded-lg border border-gray-300 hover:bg-gray-100 hover:text-gray-700 "
          >
            {parseInt(page.skip) + 3}
          </button>
        )}
        {parseInt(page.skip) + 4 <= maxSkip && (
          <button
            disabled
            className="inline-flex items-center mx-2 py-2 px-4 text-sm font-medium text-gray-500 bg-white rounded-lg border border-gray-300 hover:bg-gray-100 hover:text-gray-700 "
          >
            -
          </button>
        )}
        {parseInt(page.skip) + 6 <= maxSkip && (
          <button
            onClick={() => {
              fetchData(maxSkip - parseInt(page.skip) - 3);
            }}
            className="inline-flex items-center mx-2 py-2 px-4 text-sm font-medium text-gray-500 bg-white rounded-lg border border-gray-300 hover:bg-gray-100 hover:text-gray-700 "
          >
            {maxSkip - 2}
          </button>
        )}
        {parseInt(page.skip) + 5 <= maxSkip && (
          <button
            onClick={() => {
              fetchData(maxSkip - parseInt(page.skip) - 2);
            }}
            className="inline-flex items-center mx-2 py-2 px-4 text-sm font-medium text-gray-500 bg-white rounded-lg border border-gray-300 hover:bg-gray-100 hover:text-gray-700 "
          >
            {maxSkip - 1}
          </button>
        )}
        {parseInt(page.skip) + 4 <= maxSkip && (
          <button
            onClick={() => {
              fetchData(maxSkip - parseInt(page.skip) - 1);
            }}
            className="inline-flex items-center mx-2 py-2 px-4 text-sm font-medium text-gray-500 bg-white rounded-lg border border-gray-300 hover:bg-gray-100 hover:text-gray-700 "
          >
            {maxSkip}
          </button>
        )}
        <button
          disabled={
            (parseInt(page.skip) + 1) * parseInt(page.limit) >= products.TOTAL
          }
          onClick={() => {
            fetchData(1);
          }}
          className="inline-flex items-center py-2 px-4 text-sm font-medium text-GTI-BLUE-default disabled:text-gray-500 bg-white rounded-lg border border-gray-300 hover:bg-gray-100 hover:text-gray-700 "
        >
          Next
          <svg
            aria-hidden="true"
            className="ml-2 w-5 h-5"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z"
              clipRule="evenodd"
            ></path>
          </svg>
        </button>
      </div>
    </div>
  );
};

export default Products;
