import React from "react";
import { BsFacebook } from "react-icons/bs";
import { GrLinkedin } from "react-icons/gr";
import { AiFillTwitterCircle } from "react-icons/ai";
import {
  TwitterShareButton,
  FacebookShareButton,
  LinkedinShareButton,
} from "react-share";

const SocialShare = ({ topic, url }: { topic: string; url: string }) => {
  return (
    <div className="absolute  flex flex-col -left-40  top-[400px]">
      <a
        href="https://twitter.com/GTI_Techglobal"
        target="_blank"
        rel="noopener noreferrer"
      >
        <AiFillTwitterCircle className="w-10 h-10  text-GTI-BLUE-default my-2" />
      </a>
      <a
        href="https://www.facebook.com/people/Global-Tech-Interface/100063974445081/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <BsFacebook className="w-8 h-8 text-GTI-BLUE-default my-2" />
      </a>
      <a
        href="https://www.linkedin.com/company/globaltech-interface/about/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <GrLinkedin className="w-8 h-8 text-GTI-BLUE-default my-2 rounded-full" />
      </a>
    </div>
  );
};

export default SocialShare;
