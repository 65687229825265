import React, { useState } from "react";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { MdArrowRightAlt } from "react-icons/md";
import ReactFlagsSelect from "react-flags-select";

import {
  generalSubscriberMessage,
  displayerMessage,
  scouterMessage,
} from "../../shared/constants";
import { formatUserType } from "../../utils";
import { PROFILE_TYPES, SIGNUP_PROFILE_TYPES } from "../../shared/enum";
import scouter from "../../assests/images/signup/scouter.png";
import displayer from "../../assests/images/signup/displayer.png";
import generalSubscriber from "../../assests/images/signup/general-subscriber.png";

const PersonalInformation = ({
  profileType,
  country,
  personalFormik,
  handleCountry,
  handleProfileTypes,
}: {
  profileType: PROFILE_TYPES;
  country: string;
  personalFormik: any;
  handleCountry: (e: string) => void;
  handleProfileTypes: (e: PROFILE_TYPES) => void;
}) => {
  const [showPassword, setShowPassword] = useState(false);

  const [signUpImage, setSignUpImage] = useState(displayer);
  const [signUpMessage, setSignUpMessage] = useState(displayerMessage);

  const handleType = (key: any) => {
    handleProfileTypes(key);
    if (key === PROFILE_TYPES.DISPLAYER) {
      setSignUpImage(displayer);
      setSignUpMessage(displayerMessage);
    } else if (key === PROFILE_TYPES.SCOUTER) {
      setSignUpImage(scouter);
      setSignUpMessage(scouterMessage);
    } else if (key === PROFILE_TYPES.GENERAL_SUBSCRIBER) {
      setSignUpImage(generalSubscriber);
      setSignUpMessage(generalSubscriberMessage);
    }
  };

  return (
    <form onSubmit={personalFormik.handleSubmit} className="flex w-full">
      <div className="flex flex-col items-center w-full duration-200">
        <div className="flex w-10/12 md:mt-12 items-center">
          <div className="hidden md:flex flex-col items-center justify-center w-6/12">
            <p className="text-base p-2 font-roboto border rounded-xl w-4/5">
              {signUpMessage}
            </p>
            <img src={signUpImage} className="w-3/5 h-3/5" alt="Sign Up" />
          </div>
          <div className="md:w-6/12 flex flex-col md:mb-10 w-full">
            <div>
              <p className="text-white bg-GTI-BLUE-default p-2 text-sm md:text-lg rounded-lg">
                Are you a tech displayer / scouter or general subscriber? Choose
                One
              </p>
              <div className="flex flex-col md:flex-row justify-between my-2">
                {Object.values(SIGNUP_PROFILE_TYPES).map(
                  (value: string, index) => (
                    <button
                      key={index}
                      className={`w-full rounded-lg px-4 py-2 mx-1 text-sm md:text-lg mb-2 ${
                        value === profileType
                          ? "bg-GTI-BLUE-default text-white"
                          : "bg-white border-2 text-black"
                      }`}
                      type="button"
                      onClick={() => handleType(value)}
                    >
                      {formatUserType(value)}
                    </button>
                  )
                )}
              </div>
            </div>
            <div className="flex flex-row items-stretch justify-evenly m-3 mb-5">
              <div className="relative w-full">
                <input
                  type="text"
                  id="name"
                  name="name"
                  value={personalFormik.values.name}
                  onChange={personalFormik.handleChange}
                  className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                  placeholder=" "
                />
                <label
                  htmlFor="name"
                  id="fname_label"
                  className={
                    "absolute text-sm text-gray-500  duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white  px-2 peer-focus:px-2  peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1 " +
                    (personalFormik.errors.name
                      ? "text-red-500 peer-focus:text-red-500"
                      : "text-gray-500 peer-focus:text-blue-600")
                  }
                >
                  {personalFormik.errors.name
                    ? personalFormik.errors.name
                    : "*Full Name"}
                </label>
              </div>
            </div>
            <div className="flex flex-row items-stretch justify-evenly m-3 mb-5">
              <div className="relative w-full">
                <input
                  type="text"
                  id="email"
                  name="email"
                  value={personalFormik.values.email}
                  onChange={personalFormik.handleChange}
                  className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                  placeholder=" "
                />
                <label
                  htmlFor="email"
                  id="email"
                  className={
                    "absolute text-sm text-gray-500  duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white  px-2 peer-focus:px-2  peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1 " +
                    (personalFormik.errors.email
                      ? "text-red-500 peer-focus:text-red-500"
                      : "text-slate-500 peer-focus:text-blue-600")
                  }
                >
                  {!personalFormik.errors.email
                    ? "*Email id"
                    : personalFormik.errors.email}
                </label>
              </div>
            </div>
            <div className="flex flex-row items-stretch justify-evenly m-3 mb-5">
              <div className="relative w-full">
                <input
                  type={showPassword ? "text" : "password"}
                  id="password"
                  name="password"
                  value={personalFormik.values.password}
                  onChange={personalFormik.handleChange}
                  className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                  placeholder=" "
                />
                <button
                  type="button"
                  onClick={() => setShowPassword((prev) => !prev)}
                  className="border-none absolute top-[4px] right-[10px] h-[40px]"
                >
                  {showPassword ? (
                    <AiFillEyeInvisible size={24} />
                  ) : (
                    <AiFillEye size={24} />
                  )}
                </button>
                <label
                  htmlFor="password"
                  id="label_pass"
                  className={
                    "absolute text-sm text-gray-500  duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white  px-2 peer-focus:px-2  peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1 " +
                    (personalFormik.errors.password
                      ? "text-red-500 peer-focus:text-red-500"
                      : "text-slate-500 peer-focus:text-blue-600")
                  }
                >
                  {!personalFormik.errors.password
                    ? " *Set Password"
                    : personalFormik.errors.password}
                </label>
              </div>
            </div>
            <div className="flex flex-row items-stretch justify-evenly m-3 mb-5">
              <span className="inline-flex w-full">
                <ReactFlagsSelect
                  selected={country}
                  onSelect={(code) => {
                    personalFormik.setFieldValue("country", code);
                    handleCountry(code);
                  }}
                  id="country"
                  fullWidth={false}
                  selectedSize={18}
                  showSelectedLabel={false}
                  showSecondarySelectedLabel={false}
                  showSecondaryOptionLabel={false}
                  searchable
                  className="block  px-1 py-1 text-sm text-gray-900 bg-transparent rounded-lg border-1 appearance-none  focus:outline-none"
                />
                <div className="relative w-full">
                  <input
                    type="number"
                    id="phone"
                    maxLength={10}
                    name="phone"
                    value={personalFormik.values.phone}
                    onChange={personalFormik.handleChange}
                    className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                    placeholder=" "
                  />
                  <label
                    id="phone_label"
                    htmlFor="phone"
                    className={
                      "absolute text-sm text-gray-500  duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white  px-2 peer-focus:px-2  peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1 " +
                      (personalFormik.errors.phone
                        ? "text-red-500 peer-focus:text-red-500"
                        : "text-slate-500 peer-focus:text-blue-600")
                    }
                  >
                    {!personalFormik.errors.phone
                      ? " *Phone Number"
                      : personalFormik.errors.phone}
                  </label>
                </div>
              </span>
            </div>
            <div className="flex flex-row items-stretch justify-evenly m-3 mb-5">
              <div className="relative w-full">
                <input
                  type="text"
                  id="ref"
                  name="ref"
                  value={personalFormik.values.ref}
                  onChange={personalFormik.handleChange}
                  className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                  placeholder=" "
                />
                <label
                  htmlFor="ref"
                  id="ref"
                  className="absolute text-sm text-gray-500 peer-focus:text-blue-600  duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white  px-2 peer-focus:px-2  peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1"
                >
                  Reference Code
                </label>
              </div>
            </div>
            <button
              className={` mt-6 flex gap-2 items-center justify-center w-full rounded bg-white hover:bg-GTI-BLUE-default px-5 py-3 border border-GTI-BLUE-default font-medium text-GTI-BLUE-default hover:text-white shadow-md shadow-indigo-500/20`}
            >
              {profileType === PROFILE_TYPES.GENERAL_SUBSCRIBER
                ? "Submit"
                : "Next"}
              <MdArrowRightAlt />
            </button>
          </div>
        </div>
      </div>
    </form>
  );
};

export default PersonalInformation;
