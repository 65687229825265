import * as ACTION from "../actiontypes/actionTypes";

const initialState: SECTOR_STATE = {
  SECTOR: {
    SECTOR_LIST: [],
  },
};

export const sectorReducer = (
  state: SECTOR_STATE = initialState,
  action: SECTOR_ACTION
): SECTOR_STATE => {
  switch (action.type) {
    case ACTION.SECTOR_GET:
      let sectors: SECTOR = action.SECTOR;
      return { ...state, SECTOR: sectors };
    case ACTION.SECTOR_GET_ID:
      let sector: SECTOR = action.SECTOR;
      return { ...state, SECTOR: sector };
    default:
      return { ...state };
  }
  // return state;
};
