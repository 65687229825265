import { store } from "../../store";
import { BACKDROP_LOADER_START } from "../actiontypes/actionTypes";

export const backdropLoaderStart = () => (dispatch: LOADER_DISPATCH) => {
  const loader: LOADER_ACTION = {
    type: BACKDROP_LOADER_START,
    LOADER: {
      BACKDROP: true,
      SPINNER: store.getState().LOADER.LOADER.SPINNER,
    },
  };
  return dispatch(loader);
};
export const backdropLoaderStop = () => (dispatch: LOADER_DISPATCH) => {
  const loader: LOADER_ACTION = {
    type: BACKDROP_LOADER_START,
    LOADER: {
      BACKDROP: false,
      SPINNER: store.getState().LOADER.LOADER.SPINNER,
    },
  };
  return dispatch(loader);
};
export const spinnerLoaderStart = () => (dispatch: LOADER_DISPATCH) => {
  const loader: LOADER_ACTION = {
    type: BACKDROP_LOADER_START,
    LOADER: {
      BACKDROP: store.getState().LOADER.LOADER.BACKDROP,
      SPINNER: true,
    },
  };
  return dispatch(loader);
};
export const spinnerLoaderStop = () => (dispatch: LOADER_DISPATCH) => {
  const loader: LOADER_ACTION = {
    type: BACKDROP_LOADER_START,
    LOADER: {
      BACKDROP: store.getState().LOADER.LOADER.BACKDROP,
      SPINNER: false,
    },
  };
  return dispatch(loader);
};
