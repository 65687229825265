import React from "react";
import { Form, Formik } from "formik";
import { Helmet } from "react-helmet";

import helpdesk1Img from "../../assests/helpdesk/helpdesk-1.png";
import helpdesk2Img from "../../assests/helpdesk/helpdesk-2.png";
import helpdesk3Img from "../../assests/helpdesk/helpdesk-3.png";
import helpdesk4Img from "../../assests/helpdesk/helpdesk-4.png";
import helpdeskHeroImg from "../../assests/helpdesk/helpdesk-header.png";
import supportImg from "../../assests/helpdesk/helpdesk-support.png";
import helpdeskConnectImg from "../../assests/helpdesk/lets-connect.png";
import CustomModal from "../../shared/CustomModal";
import { helpdeskSchema } from "../validations/contactValidations";
import { notify } from "../../utils";
import { postHelpdesk } from "../../api/helpdesk";
import { title, metaData } from "../constants";

interface IConnectCard {
  title: string;
  description: string;
  image: any;
}

const supportData = [
  "Market insight reports.",
  "Local business model development and mentoring.",
  "Market and technology validation – secondary and primary research.",
  "Identification and facilitation of meetings with potential partners/customers/ investors.",
  "Eco-system development and stakeholder identification – mentors, universities, networks, investors, clients, partners, governmental and non-governmental organizations.",
  "Market entry commercialization strategies",
  "Mentorship and support to develop a roadmap for a sustainable market entry.",
];

const connectSectionData: Array<IConnectCard> = [
  {
    title: "Access to the information",
    description:
      "365 days' information services on global technologies, market opportunities, various start-up programs, events/trade-fairs/exhibitions/workshops, funding opportunities, investor information, etc.",
    image: helpdesk1Img,
  },
  {
    title: "Create Connections",
    description:
      "Provide a marketplace for connecting businesses with partners for collaborations, deployment, access to new markets, B2B opportunities with key stakeholders.",
    image: helpdesk2Img,
  },
  {
    title: "Connect with Global Experts",
    description:
      "Provide access to a global network of subject matter experts and mentors who can provide information and mentorship support on tech deployment, business setup, IPR, taxation, regulatory and policies, accounting, etc.  ",
    image: helpdesk3Img,
  },
  {
    title: "One Stop Shop",
    description:
      "From information to deployment – get access to GTI Premium Services and Hands on Support  for internalization.",
    image: helpdesk4Img,
  },
];

const CheckSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
  >
    <path
      d="M11 0C4.9225 0 0 4.9225 0 11C0 17.0775 4.9225 22 11 22C17.0775 22 22 17.0775 22 11C22 4.9225 17.0775 0 11 0ZM16.5 4.895L18.48 6.875L9.625 15.73L4.895 11L6.875 9.02L9.625 11.77L16.5 4.895Z"
      fill="#151E70"
    />
  </svg>
);

const ConnectCard: React.FC<{ data: IConnectCard }> = ({ data }) => {
  return (
    <div className="flex gap-2 w-full max-w-[500px] flex-col items-center px-10 md:px-0 md:items-start md:flex-row">
      <img src={data.image} className="w-full max-w-[260px]" alt={data.title} />
      <div className="relative flex md:h-[240px] flex-col">
        <div className="md:absolute bg-white shadow-md md:left-[-80px] top-4 px-4 py-2 rounded-full font-popins">
          <h5>{data.title}</h5>
        </div>
        <p className="mt-[20px] md:mt-[60px] font-zilla-slab">
          {data.description}
        </p>
      </div>
    </div>
  );
};

const Helpdesk = () => {
  const [openContactModal, setOpenContactModal] = React.useState(false);

  const onContactClose = () => setOpenContactModal(false);

  const initialValues = {
    firstName: "",
    lastName: "",
    phoneNumber: "",
    email: "",
    message: "",
  };

  const handleSubmit = async (values: typeof initialValues) => {
    try {
      await postHelpdesk(values);
      setOpenContactModal(false);
      notify("Message sent successfully!", "success");
    } catch (err) {
      notify("Something went wrong!", "error");
    }
  };

  return (
    <div className="flex flex-col  w-full items-center mb-20 py-2">
      <Helmet>
        <title>{title.HELPDESK}</title>
        <meta
          name="description"
          key="description"
          content={metaData.HELPDESK}
        />
        <meta name="title" key="title" content="Helpdesk" />
        <meta property="og:title" content="Helpdesk" />
        <meta property="og:description" content={metaData.HELPDESK} />
        <meta property="og:image" content={helpdeskHeroImg} />
        <meta
          property="og:url"
          content={`${process.env.REACT_APP_BASE_URL}/helpdesk`}
        />
        <meta property="og:type" content="website" />
        <meta name="twitter:title" content="Helpdesk" />
        <meta name="twitter:description" content={metaData.HELPDESK} />
        <meta name="twitter:image" content={helpdeskHeroImg} />
        <meta name="twitter:card" content="Helpdesk" />
      </Helmet>
      <div className="flex sm:flex-row w-full flex-wrap flex-col">
        <div className="flex flex-col w-full md:w-1/2 p-10 md:px-20 items-center justify-center">
          <p className="text-black text-2xl md:text-5xl font-normal leading-[48px] font-popins ">
            GTI® Helpdesk for Internationalisation
          </p>
          <p className="text-black font-zilla-slab text-base md:text-lg font-normal leading-7 mt-6">
            The objective of the GTI Helpdesk is to help companies with
            international market access in India, EU, UK, USA, Africa, and
            Southeast Asia. Through the helpdesk, companies looking at
            internationalisation can avail continuous and consistent support
            with their queries related to international market access and
            associated services.
          </p>
          <p className="text-black font-zilla-slab text-base md:text-lg font-normal leading-7 mt-4">
            GTI provides online marketplace and virtual connect with potential
            partners / stakeholders.
          </p>
        </div>
        <div className="flex  flex-shrink flex-col w-full p-10 md:w-1/2 md:pb-40 md:pt-20  justify-center items-center ">
          <img src={helpdeskHeroImg} alt={title.HELPDESK} />
        </div>
      </div>

      <div>
        <h4 className="w-full text-center text-black text-2xl md:text-5xl font-normal leading-[50px] mb-14 font-popins">
          Why connect with GTI Helpdesk?
        </h4>
        <div className="flex flex-col md:flex-row gap-6 w-full justify-between flex-wrap max-w-[1200px]">
          {connectSectionData.map((item, index) => (
            <ConnectCard data={item} key={index} />
          ))}
        </div>
      </div>

      <div className="w-full flex flex-col items-center mt-40 px-4">
        <div className="w-full flex flex-col md:flex-row justify-between max-w-[1200px]">
          <div className="w-full max-w-[640px] text-start text-black text-[20px] font-zilla-slab">
            <p>
              Through the helpdesk the companies can connect to potential
              partners for collaborations and new business opportunities. The
              Helpdesk aims to become a one stop centre for all queries in the
              context of expanding business to India, EU, UK, USA, Africa, and
              Southeast Asia.
            </p>
            <p className="mt-4 font-zilla-slab">
              Our experts will guide you with the valuable information and
              relevant networking opportunities. We aim to make your dream to
              scale-up and access new markets a reality with our support.
            </p>
          </div>
          <img
            src={helpdeskConnectImg}
            alt="Why connect with GTI Helpdesk?"
            className="w-full my-6 md:my-0 max-w-[390px]"
          />
        </div>
        <div className="w-full flex justify-start gap-10 max-w-[1200px]">
          <button
            onClick={() => setOpenContactModal(true)}
            className=" bg-GTI-BLUE-default rounded-full px-4 py-2 text-white font-popins "
          >
            Let’s Connect to make an inquiry. It’s FREE!
          </button>
        </div>
      </div>

      <div className="mt-[100px] bg-GTI-BLUE-default w-full md:px-[80px] md:py-[42px] px-10 py-6 flex items-center">
        <h4 className="text-white font-popins text-[28px] md:text-[40px] mx-w-[1200px] text-center">
          For more advanced internationalisation services and support, you can
          leverage our premium services
        </h4>
      </div>

      <div className="mt-[100px]">
        <h4 className="w-full text-center text-black text-2xl md:text-5xl font-normal font-popins leading-[40px] mb-14">
          GTI Premium Services and Hands on Support
        </h4>
        <div className="w-full max-w-[1200px] flex flex-col md:flex-row justify-between items-center gap-10">
          <ul className="list-none w-full max-w-[600px] px-4 md:px-0 flex flex-col gap-8">
            {supportData.map((item, index) => (
              <li
                key={index}
                className="flex items-start text-[20px] font-zilla-slab"
              >
                <span className="mr-2 mt-1">
                  <CheckSvg />
                </span>
                {item}
              </li>
            ))}
          </ul>
          <img
            src={supportImg}
            alt="GTI Premium Services and Hands on Support"
          />
        </div>
      </div>

      <div className="w-full flex flex-col md:flex-row px-4 justify-center gap-10 max-w-[1200px] my-10">
        <button
          onClick={() => setOpenContactModal(true)}
          className=" bg-GTI-BLUE-default rounded-full px-8 py-2 text-white font-popins text-[30px]"
        >
          Contact Us
        </button>
      </div>

      <CustomModal
        className="p-0"
        onClose={onContactClose}
        open={openContactModal}
      >
        <div className="flex flex-row w-full rounded-lg">
          <div className="flex rounded-l-lg flex-col p-10 bg-GTI-BLUE-default justify-center items-center w-1/2">
            <p className="text-white font-popins text-4xl -translate-y-5 ">
              Contact Us
            </p>
            <p className="font-poppins text-md text-white">
              GTI Helpdesk Manager
            </p>
            <p className="font-poppins text-md text-white">Harsha Lingam</p>
            <p className="font-poppins text-md text-white">
              hlingam@globalbusinessinroads.com
            </p>
          </div>
          <div className="flex flex-col w-1/2 ">
            <Formik
              initialValues={initialValues}
              validationSchema={helpdeskSchema}
              onSubmit={(values: any, { resetForm }) => {
                handleSubmit(values);
                resetForm();
              }}
            >
              {({
                handleChange,
                setFieldValue,
                handleSubmit,
                errors,
                values,
              }) => (
                <>
                  <Form className="flex flex-col rounded-tr-lg rounded-br-lg justify-center items-center p-10 border-r border-b border-t  border-r-gray-200 border-t-gray-200 border-b-gray-200">
                    <div className="flex flex-row w-full px-5 justify-between mb-4">
                      <div className="flex flex-col w-full space-x-2 mr-2 relative">
                        <div className="relative w-full">
                          <input
                            onChange={(e) =>
                              setFieldValue("firstName", e.target.value)
                            }
                            type="text"
                            id="floating_outlined"
                            className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                            placeholder=" "
                          />
                          <label
                            htmlFor="floating_outlined"
                            className="absolute text-sm text-gray-500  duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white  px-2 peer-focus:px-2 peer-focus:text-blue-600  peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1"
                          >
                            First Name
                          </label>
                        </div>
                        {errors.firstName && (
                          <p
                            id="filled_error_help"
                            className="mt-2 text-xs text-red-600 dark:text-red-400"
                          >
                            {/* @ts-ignore */}
                            {errors.firstName}
                          </p>
                        )}
                      </div>
                      <div className="flex flex-col w-full space-x-2 mr-2 relative">
                        <div className="relative w-full">
                          <input
                            onChange={(e) =>
                              setFieldValue("lastName", e.target.value)
                            }
                            type="text"
                            id="floating_outlined"
                            className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                            placeholder=" "
                          />
                          <label
                            htmlFor="floating_outlined"
                            className="absolute text-sm text-gray-500  duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white  px-2 peer-focus:px-2 peer-focus:text-blue-600  peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1"
                          >
                            Last Name
                          </label>
                        </div>
                        {errors.lastName && (
                          <p
                            id="filled_error_help"
                            className="mt-2 text-xs text-red-600 dark:text-red-400"
                          >
                            {typeof errors.lastName === "object"
                              ? // @ts-ignore
                                errors.lastName[0]
                              : errors.lastName}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="flex flex-col w-full px-5 space-x-2 relative mb-4">
                      <div className="relative">
                        <input
                          onChange={(e) =>
                            setFieldValue("phoneNumber", e.target.value)
                          }
                          type="number"
                          id="floating_outlined"
                          className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                          placeholder=" "
                        />
                        <label
                          htmlFor="floating_outlined"
                          className="absolute text-sm text-gray-500  duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white  px-2 peer-focus:px-2 peer-focus:text-blue-600  peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1"
                        >
                          Phone Number
                        </label>
                      </div>
                      {errors.phoneNumber && (
                        <p
                          id="filled_error_help"
                          className="mt-2 text-xs text-red-600 dark:text-red-400"
                        >
                          {typeof errors.phoneNumber === "object"
                            ? // @ts-ignore
                              errors.phoneNumber[0]
                            : errors.phoneNumber}
                        </p>
                      )}
                    </div>
                    <div className="flex flex-col w-full px-5 space-x-2 relative mb-4">
                      <div className="relative">
                        <input
                          onChange={(e) =>
                            setFieldValue("email", e.target.value)
                          }
                          type="text"
                          id="floating_outlined"
                          className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                          placeholder=" "
                        />
                        <label
                          htmlFor="floating_outlined"
                          className="absolute text-sm text-gray-500  duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white  px-2 peer-focus:px-2 peer-focus:text-blue-600  peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1"
                        >
                          Email id
                        </label>
                      </div>
                      {errors.email && (
                        <p
                          id="filled_error_help"
                          className="mt-2 text-xs text-red-600 dark:text-red-400"
                        >
                          {/* {errors.email} */}
                        </p>
                      )}
                    </div>
                    <div className="flex flex-col w-full px-5 space-x-2 relative mb-4">
                      <div className="relative">
                        <input
                          onChange={(e) =>
                            setFieldValue("message", e.target.value)
                          }
                          type="text"
                          id="floating_outlined"
                          className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                          placeholder=" "
                        />
                        <label
                          htmlFor="floating_outlined"
                          className="absolute text-sm text-gray-500  duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white  px-2 peer-focus:px-2 peer-focus:text-blue-600  peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1"
                        >
                          Helpdesk Query{" "}
                        </label>
                      </div>
                      {errors.message && (
                        <p
                          id="filled_error_help"
                          className="mt-2 text-xs text-red-600 dark:text-red-400"
                        >
                          {typeof errors.message === "object"
                            ? // @ts-ignore
                              errors.message[0]
                            : errors.message}
                        </p>
                      )}
                    </div>

                    <button
                      type="submit"
                      className="px-4 py-2 rounded-lg bg-GTI-BLUE-default text-white font-popins "
                    >
                      Send Message
                    </button>
                  </Form>
                </>
              )}
            </Formik>
          </div>
        </div>
      </CustomModal>
    </div>
  );
};

export default Helpdesk;
