import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { articlereducer } from "./store/reducer/articlereducer";
import { blogreducer } from "./store/reducer/blogreducer";
import { chatreducer } from "./store/reducer/chatreducer";
import { connectionreducer } from "./store/reducer/connectionreducer";
import { eventreducer } from "./store/reducer/eventreducer";
import { followreducer } from "./store/reducer/followreducer";
import { innovationreducer } from "./store/reducer/innovationreducer";
import { loaderreducer } from "./store/reducer/loaderreducer";
import { notificationreducer } from "./store/reducer/notificationreducer";
import { opportunityreducer } from "./store/reducer/opportunityreducer";
import { partnerreducer } from "./store/reducer/partnersreducer";
import { productsreducer } from "./store/reducer/productsreducer";
import { sectorReducer } from "./store/reducer/sectorreducer";
import { subsectorReducer } from "./store/reducer/subsectorreducer";
import { toastreducer } from "./store/reducer/toastreducer";
import { userreducer } from "./store/reducer/userreducer";
import { publicationsreducer } from "./store/reducer/publicationReducer";
import { newsreducer } from "./store/reducer/newsReducer";
import { technologypartnerreducer } from "./store/reducer/technologypartnerreducer";
import { promotionsreducer } from "./store/reducer/promotionsreducer";

const reducer = combineReducers({
  USER: userreducer,
  ARTICLE: articlereducer,
  BLOG: blogreducer,
  PUBLICATION: publicationsreducer,
  NEWS: newsreducer,
  SECTOR: sectorReducer,
  SUB_SECTOR: subsectorReducer,
  TOAST: toastreducer,
  EVENT: eventreducer,
  PRODUCTS: productsreducer,
  OPP: opportunityreducer,
  INNOVATION: innovationreducer,
  FOLLOW: followreducer,
  CONNECTION: connectionreducer,
  NOTIFICATION: notificationreducer,
  CHAT: chatreducer,
  PARTNER: partnerreducer,
  LOADER: loaderreducer,
  TECHNOLOGY_PARTNER: technologypartnerreducer,
  PROMOTIONS: promotionsreducer,
});

const middleware = [thunk];

export const store = createStore(reducer, applyMiddleware(...middleware));
