import { useRef, useEffect } from "react";

export function useDebouncedCallback(callback: () => void, delay: number) {
  const timerRef = useRef<any>(undefined);

  useEffect(() => {
    return () => {
      if (timerRef.current !== undefined) {
        clearTimeout(timerRef.current);
      }
    };
  }, []);

  return function debouncedCallback() {
    if (timerRef.current !== undefined) {
      clearTimeout(timerRef.current);
    }

    timerRef.current = setTimeout(() => {
      callback();
    }, delay);
  };
}
