import * as ACTION from "../actiontypes/actionTypes";

const initialState: any = {
  technologyPartner: [],
};

export const technologypartnerreducer = (
  state: TECHNOLOGY_PARTNER_STATE = initialState,
  action: TECHNOLOGY_PARTNER_ACTION
): TECHNOLOGY_PARTNER_STATE => {
  switch (action.type) {
    case ACTION.TECHNOLOGY_PARTNER_GET:
      return { ...state, technologyPartner: action.technologyPartner };
    default:
      return { ...state };
  }
};
