import * as ACTION from "../actiontypes/actionTypes";

const initialState: any = {
  publications: [],
  publicationsCount: 0,
};

export const publicationsreducer = (
  state: PUBLICATION_STATE = initialState,
  action: PUBLICATIONS_ACTION
): PUBLICATION_STATE => {
  switch (action.type) {
    case ACTION.PUBLICATIONS_GET:
      return { ...state, ...action.PUBLICATION };
    case ACTION.PUBLICATIONS_GET_ID:
      return { ...state, ...action.PUBLICATION };
    default:
      return { ...state };
  }
  // return state;
};
