import * as ACTION from "../actiontypes/actionTypes";

const initialState: EVENT_STATE = {
  EVENT: {
    EVENT_LIST: {
      events: [],
      eventsCount: 0,
    },
    USER_LIST: [],
  },
};

export const eventreducer = (
  state: EVENT_STATE = initialState,
  action: EVENT_ACTION
): EVENT_STATE => {
  switch (action.type) {
    case ACTION.EVENT_GET:
      let events: EVENT = action.EVENT;
      return { ...state, EVENT: events };
    case ACTION.EVENT_GET_ID:
      let event: EVENT = action.EVENT.EVENT;
      return { ...state, EVENT: event };

    case ACTION.EVENT_REGISTERED_USERS:
      let users: EVENT = action.EVENT;
      return { ...state, EVENT: users };

    default:
      return { ...state };
  }
  // return state;
};
