import React, { Dispatch, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams, useLocation, useNavigate, Link } from "react-router-dom";
import axios from "axios";
import { useSelector } from "react-redux";
import ReactCountryFlag from "react-country-flag";
import Slider from "react-slick";
import { Helmet } from "react-helmet";

import companyLogo from "../../assests/banners/company_logo.png";
import {
  followUser,
  unfollowUser,
} from "../../store/actioncreators/followactions";
import { sendConnection } from "../../store/actioncreators/connectionactions";
import { ScreenSpinner } from "../utils/loader";
import { spinnerLoaderStop } from "../../store/actioncreators/loaderactions";
import RenderHTML from "../utils/RenderHTML";
import { BiLinkExternal } from "react-icons/bi";

const Featured = ({ handleLoginModal }: { handleLoginModal: () => void }) => {
  const dispatch: Dispatch<any> = useDispatch();
  const spinner: LOADER = useSelector((state: STATE) => state.LOADER.LOADER);

  let [promotion, setPromotion] = useState({
    _id: "",
    title: "",
    description: "",
    contentTitle: "",
    contentDescription: "",
    images: [],
    userId: "",
    productId: "",
    opportunityId: "",
    paymentStatus: "",
    verificationStatus: "",
    createdAt: "",
    __v: -1,
    users: {
      _id: "",
      fullName: "",
      email: "",
      phoneNumber: "",
      countryCode: "",
      referenceCode: "",
      isEmailVerified: false,
      isUserVerified: false,
      isRejected: false,
      password: "",
      userRole: -1,
      userType: "",
      companyId: "",
      follower: [],
      following: [],
      connections: [
        {
          connectionStatus: "",
          userId: "",
        },
      ],
      createdAt: "",
      __v: -1,
    },
    company: {
      _id: "",
      name: "",
      logo: "",
      description: "",
      address: "",
      website: "",
      country: "",
      companyTurnover: -1,
      companyId: "",
      typeAndSizeOfPartnersRequired: [],
      typesOfPartnershipConsidered: [],
      createdAt: "",
      developmentStage: "",
      iprStatus: [],
      __v: -1,
    },
  });

  const sidebar_carousal_settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    swipeToSlide: true,
    autoplay: true,
  };

  const user: USER = useSelector((state: STATE) => state.USER.USER);
  const location = useLocation();
  const navigate = useNavigate();

  const [following, setFollowing] = useState(false);
  const [connected, setConnection] = useState("Connect");

  let { id } = useParams();
  let promotionId: string = id ? id : "";

  const loadPromotion = async (id: string) => {
    let config = {
      method: "get",
      url: `${process.env.REACT_APP_BASE_API}/promotions/details/${id}`,
      headers: {
        "Content-Type": "application/json",
      },
    };

    await axios(config)
      .then(function (response) {
        setPromotion(response.data.promotion);
        dispatch(spinnerLoaderStop());
      })
      .catch(function (error) {
        dispatch(spinnerLoaderStop());
      });
  };

  const isFollowing = async (userid: string) => {
    const extoken: string =
      localStorage.getItem("GTI_data")?.split(" ")[0] ?? "";

    let config = {
      method: "get",
      url: `${process.env.REACT_APP_BASE_API}/users/checkisfollowing/${userid}`,
      headers: {
        Authorization: `Bearer ${extoken}`,
        "Content-Type": "application/json",
      },
    };

    await axios(config)
      .then(function (response) {
        setFollowing(response.data);
      })
      .catch(function (error) {});
  };

  const isConnected = async (userid: string) => {
    const extoken: string =
      localStorage.getItem("GTI_data")?.split(" ")[0] ?? "";

    let config = {
      method: "get",
      url: `${process.env.REACT_APP_BASE_API}/users/checkisconnected/${userid}`,
      headers: {
        Authorization: `Bearer ${extoken}`,
        "Content-Type": "application/json",
      },
    };

    await axios(config)
      .then(function (response) {
        if (typeof response.data == "string") {
          setConnection(response.data);
        }
      })
      .catch(function (error) {});
  };

  useEffect(() => {
    loadPromotion(promotionId);
    isFollowing(promotion.userId);
    isConnected(promotion.userId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const DOC = new Date(promotion.createdAt);

  const handleFollow = () => {
    if (user?.admin !== -1) {
      if (!following) {
        dispatch(followUser(promotion.userId));
        setFollowing(true);
      }
      return;
    }
    handleLoginModal();
  };
  const handleUnfollow = () => {
    if (user?.admin !== -1) {
      if (following) {
        dispatch(unfollowUser(promotion.userId));
        setFollowing(false);
      }
      return;
    }
    handleLoginModal();
  };
  const handleConnect = () => {
    if (user?.admin !== -1) {
      if (connected === "Connect") {
        dispatch(sendConnection(promotion.userId));
        setConnection("Connection Requested");
      }
      return;
    }
    handleLoginModal();
  };

  return (
    <React.Fragment>
      <Helmet>
        <title>{promotion?.company?.name}</title>
        <meta
          name="description"
          key="description"
          content={
            promotion?.contentDescription
              ? promotion?.contentDescription
              : promotion?.company?.description
          }
        />
        <meta name="title" key="title" content={promotion?.company?.name} />
        <meta property="og:title" content={promotion?.company?.name} />
        <meta
          property="og:description"
          content={
            promotion.contentDescription
              ? promotion.contentDescription
              : promotion.company.description
          }
        />
        {/* <meta
          property="og:image"
          content={product.image === NONE ? productbanner : product.image}
        /> */}
        <meta
          property="og:url"
          content={`${process.env.REACT_APP_BASE_URL}/featuredview/${promotion._id}`}
        />
        <meta property="og:type" content="website" />
        <meta name="twitter:title" content={promotion?.company?.name} />
        <meta
          name="twitter:description"
          content={
            promotion?.contentDescription
              ? promotion?.contentDescription
              : promotion?.company?.description
          }
        />
        {/* <meta
          name="twitter:image"
          content={product?.image === NONE ? productbanner : product?.image}
        /> */}
        <meta name="twitter:card" content={promotion?.company?.name} />
      </Helmet>
      <div className="flex flex-col p-2 md:p-10 w-full h-full">
        {spinner.SPINNER ? (
          <ScreenSpinner />
        ) : (
          <>
            <div className="flex flex-col md:flex-row w-full justify-between md:space-x-10 ">
              <div className="w-full md:w-3/5 flex flex-col md:px-10 md:m-5 md:space-y-5">
                <p className="font-roboto text-xl md:text-3xl font-bold">
                  {promotion.contentTitle}
                </p>
                <p className="md:hidden text-gray-400 mb-2 md:mb-0 text-xxs font-roboto">
                  Posted on:&nbsp;
                  {DOC.toLocaleString("default", {
                    month: "long",
                    day: "2-digit",
                    year: "numeric",
                  })}
                </p>
                <p className="hidden md:block font-sans md:font-roboto text-xxs md:text-sm">
                  <RenderHTML html={promotion.contentDescription} />
                </p>
              </div>

              <div className="md:w-5/12 flex flex-col md:px-10 md:m-5 space-y-3 md:space-y-5">
                <Slider
                  {...sidebar_carousal_settings}
                  className="hidden md:flex md:flex-row w-full md:max-h-[330px] text-black border-2 p-2 border-blue-800"
                >
                  {promotion?.images?.length > 0
                    ? promotion.images.map((image) => (
                        <img
                          className="hidden md:block w-full h-full max-h-[200px] md:max-h-[300px] object-contain"
                          src={image}
                          alt={promotion.contentTitle}
                        />
                      ))
                    : null}
                </Slider>

                <p className="md:hidden font-sans md:font-roboto text-xxs md:text-sm">
                  <RenderHTML html={promotion.contentDescription} />
                </p>

                <p className="hidden md:block text-gray-400 text-xs font-roboto">
                  Posted on:&nbsp;
                  {DOC.toLocaleString("default", {
                    month: "long",
                    day: "2-digit",
                    year: "numeric",
                  })}
                </p>

                <div className="flex flex-row gap-4 items-center">
                  <Link
                    className="flex flex-row items-center gap-2 font-roboto text-sm justify-center"
                    to={`${
                      promotion?.productId
                        ? `/product/${promotion?.productId}`
                        : `/opportunity/${promotion?.opportunityId}`
                    } `}
                  >
                    <p className="font-roboto text-sm ">
                      {promotion?.productId
                        ? "Product"
                        : promotion?.opportunityId
                        ? "Opportunity"
                        : null}
                    </p>
                    {(promotion?.productId || promotion?.opportunityId) && (
                      <BiLinkExternal />
                    )}
                  </Link>
                </div>
              </div>
            </div>
            <br className="md:hidden" />
            <hr />

            <div className="flex flex-col md:flex-row w-full md:space-x-8 py-5 md:py-10">
              <span className="block md:hidden font-roboto text-sm md:text-lg text-black-500 font-bold text-justify pb-5 md:pb-0">
                {promotion?.company?.name}
                <span className="font-roboto text-sm md:text-lg text-black-500">
                  ({promotion.company?.country})
                </span>
              </span>

              <div className="md:w-64 md:h-64 flex flex-col px-5 md:px-10 md:mx-5 space-y-5">
                <img
                  className="self-center w-1/2 md:w-full h-1/2 md:h-3/5 object-contain shadow-xl"
                  src={
                    !promotion?.company?.logo
                      ? companyLogo
                      : promotion?.company?.logo
                  }
                  alt={promotion?.company?.name}
                />

                <span className="hidden md:block font-roboto text-sm text-black-500 font-bold">
                  {promotion?.company?.name}
                  <br />
                  <span className="font-roboto text-sm text-black-500">
                    {promotion?.company?.country ? (
                      <ReactCountryFlag
                        countryCode={promotion.company?.country}
                        svg
                        style={{ width: "2em", height: "2em" }}
                        className="mr-2"
                      />
                    ) : null}
                  </span>
                </span>

                {promotion?.userId !== user?.id && (
                  <div className="flex flex-row self-center ">
                    <button
                      type="button"
                      className={
                        "button active whitespace-nowrap disabled:cursor-not-allowed disabled:hover:bg-GTI-BLUE-default disabled:bg-opacity-50 disabled:hover:bg-opacity-50"
                      }
                      disabled={connected !== "Connect"}
                      onClick={() => {
                        handleConnect();
                      }}
                    >
                      {connected}
                    </button>

                    <button
                      type="button"
                      className={"button active"}
                      onClick={() => {
                        !following ? handleFollow() : handleUnfollow();
                      }}
                    >
                      {following ? "Unfollow" : "Follow"}
                    </button>
                  </div>
                )}
              </div>
              <p className="font-sans text-xxs md:text-base text-gray-500">
                {promotion.company?.description}
              </p>
            </div>
          </>
        )}
      </div>
    </React.Fragment>
  );
};

export default Featured;
