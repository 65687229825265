import React, { Dispatch, useEffect, useState, useRef } from "react";
import ReactDOM from "react-dom";
import { Editor } from "@tinymce/tinymce-react";
import axios from "axios";
import { useDispatch } from "react-redux";
import { Helmet } from "react-helmet";

import article from "../../assests/images/articles.png";
import {
  CONTENT_TYPE,
  presignedData,
  publicationCreate,
  title,
  metaData,
} from "../constants";
import PublicationsList from "./PublicationsList";
import {
  failToast,
  successToast,
} from "../../store/actioncreators/toastactions";
import { createPublication } from "../../store/actioncreators/publicationActions";
import "./style.css";
import globe from "../../assests/home/globe.png";

type publicationModalItem = {
  topic: string;
  description: string;
  documentUrl: File | null;
  imageUrl: File | null;
};

const PublicationModal = ({ handleModel }: { handleModel: () => void }) => {
  const editorRef2 = useRef<any>(null);
  const dispatch: Dispatch<any> = useDispatch();

  const [publicationModal, setModalData] = useState<publicationModalItem>({
    topic: "",
    description: "",
    imageUrl: null,
    documentUrl: null,
  });

  const handleImageChange = function (e: React.ChangeEvent<HTMLInputElement>) {
    const fileList = e.target.files;

    if (!fileList) return;

    setModalData({ ...publicationModal, imageUrl: fileList[0] });
  };
  const handleDocChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const fileList = e.target.files;

    if (!fileList) return;

    setModalData({ ...publicationModal, documentUrl: fileList[0] });
  };
  const getPresigned = async (content: presignedData) => {
    const data = JSON.stringify(content);
    let result: string = "";
    const config = {
      method: "post",
      url: `${process.env.REACT_APP_BASE_API}/users/getPresignedUrl`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    await axios(config)
      .then(function (response) {
        result = response.data;
      })
      .catch(function (error) {
        result = "error";
        dispatch(failToast());
      });

    return result;
  };

  const postFile = async (signed: string) => {
    var config = {
      method: "put",
      url: signed,
      headers: {
        "Content-Type": CONTENT_TYPE,
        "Access-Control-Allow-Origin": true,
      },
      data: publicationModal.imageUrl,
    };

    await axios(config)
      .then(function (response) {
        dispatch(successToast());
      })
      .catch(function (error) {});
  };

  const handleCreate = async () => {
    let signedURL: string = "",
      signedDocUrl = "";

    if (
      !publicationModal.imageUrl?.size ||
      !publicationModal?.documentUrl?.size
    )
      return;

    const signedData: presignedData = {
      fileName: publicationModal.imageUrl?.name || publicationModal.topic,
      filePath: "publication_image",
      fileType: publicationModal.imageUrl?.type ?? "png",
    };
    const signedDocData: presignedData = {
      fileName: publicationModal.documentUrl?.name || publicationModal.topic,
      filePath: "publication_doc",
      fileType: publicationModal.documentUrl?.type ?? "*",
    };
    if (publicationModal.imageUrl?.size) {
      signedURL = await getPresigned(signedData);
      postFile(signedURL);
    }
    if (publicationModal.documentUrl?.size) {
      signedDocUrl = await getPresigned(signedDocData);
      postFile(signedDocUrl);
    }

    const data: publicationCreate = {
      description: publicationModal?.description,
      documentUrl: signedDocUrl.split("?")[0] ?? "",
      imageUrl: signedURL.split("?")[0] ?? "",
      topic: publicationModal?.topic,
    };

    await dispatch(createPublication(data));
  };
  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "auto";
    };
  }, []);
  const content = (
    <div className="z-10 pb-[200px] pt-4 fixed w-full h-screen bg-slate-700 bg-opacity-70 top-0 left-0 flex justify-center overflow-auto">
      <div
        className={
          "duration-200 ease-in-out w-2/5 h-fit flex flex-col space-y-1 justify-evenly text-center bg-white shadow-lg p-10  shadow-GTI-BLUE-default rounded "
        }
      >
        <div className="flex flex-row justify-center relative">
          <h4 className="font-roboto text-xl pt-5 text-GTI-BLUE-default">
            New Publication
          </h4>
          <button
            onClick={() => {
              handleModel();
            }}
            className="absolute right-0 -top-5 font-bold hover:text-red-500 duration-300 border border-slate-100 px-3 py-1 rounded"
          >
            X
          </button>
        </div>
        <div className="flex flex-col w-full ease-in-out duration-200">
          <form
            className="flex flex-col space-y-3"
            onSubmit={(e) => {
              e.preventDefault();
              handleCreate();
            }}
          >
            <div className="ease-in-out duration-200 flex flex-col justify-center space-y-3">
              <input
                type="text"
                onChange={(e) => {
                  setModalData({
                    ...publicationModal,
                    topic: e.target.value,
                  });
                }}
                required
                aria-label="Title"
                className="mb-6 bg-gray-100 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
                placeholder="Title"
              />
              <Editor
                onInit={(evt, editor) => (editorRef2.current = editor)}
                onChange={(e) => {
                  setModalData({
                    ...publicationModal,
                    description: editorRef2.current.getContent(),
                  });
                }}
                initialValue="<p>Description for Blog</p>"
                init={{
                  height: 200,
                  menubar: false,
                  plugins: [
                    "advlist",
                    "autolink",
                    "lists",
                    "link",
                    "image",
                    "charmap",
                    "preview",
                    "anchor",
                    "searchreplace",
                    "visualblocks",
                    "code",
                    "fullscreen",
                    "insertdatetime",
                    "media",
                    "table",
                    "code",
                    "help",
                    "wordcount",
                  ],
                  toolbar:
                    "undo redo | blocks | " +
                    "bold italic forecolor | alignleft aligncenter " +
                    "alignright alignjustify | bullist numlist outdent indent | " +
                    "removeformat ",
                  content_style:
                    "body { font-family:Helvetica,Arial,sans-serif,roboto; font-size:14px }",
                }}
              />
              <input
                onChange={(e) => {
                  handleImageChange(e);
                }}
                type="file"
                accept={CONTENT_TYPE}
                required
                aria-label="Click to uplod photos(*png only)"
                className="mb-6 bg-gray-100 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
                placeholder="Click to uplod photos(*png only)"
              />
              <input
                onChange={(e) => {
                  handleDocChange(e);
                }}
                type="file"
                required
                aria-label="Click to document"
                className="mb-6 bg-gray-100 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
                placeholder="Click to document"
              />
            </div>
            <div className="ease-in-out duration-200 flex justify-center mx-10">
              <button type="submit" className="button active">
                Create
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
  return ReactDOM.createPortal(content, document.body);
};

const Publications = () => {
  return (
    <div className="flex flex-col relative md:justify-center items-center w-full">
      <Helmet>
        <title>{title.PUBLICATIONS}</title>
        <meta
          name="description"
          key="description"
          content={metaData.PUBLICATIONS}
        />
        <meta name="title" key="title" content={title.PUBLICATIONS} />
        <meta property="og:title" content={title.PUBLICATIONS} />
        <meta property="og:description" content={metaData.PUBLICATIONS} />
        <meta property="og:image" content={globe} />
        <meta
          property="og:url"
          content={`${process.env.REACT_APP_BASE_URL}/publications`}
        />
        <meta property="og:type" content="website" />
        <meta name="twitter:title" content={title?.PUBLICATIONS} />
        <meta name="twitter:description" content={metaData?.PUBLICATIONS} />
        <meta name="twitter:image" content={globe} />
        <meta name="twitter:card" content={title?.PUBLICATIONS} />
      </Helmet>
      <div className="flex flex-row items-center mb-10">
        <img src={article} alt="Publications" className="w-5 md:w-10 mr-2" />
        <h1 className="text-xl  md:text-4xl font-roboto ">Publications</h1>
      </div>

      <PublicationsList />
    </div>
  );
};
export default Publications;
