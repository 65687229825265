import * as ACTION from "../actiontypes/actionTypes";

const initialState: OPP_STATE = {
  OPP: {
    OPP_LIST: {
      opportunities: [],
      opportunitiesCount: 0,
    },
    YOUR_OPP_LIST: [],
    COMPANY_OPP_LIST: [],
    COMPANY_PENDING_OPP_LIST: [],
    COMPANY_APPROVED_OPP_LIST: [],
    COMPANY_UNAPPROVED_OPP_LIST: [],
    COMPANY_REJECTED_OPP_LIST: [],
    TOTAL: 0,
    TOTAL_PENDING: 0,
    TOTAL_APPROVED: 0,
    TOTAL_UNAPPROVED: 0,
    TOTAL_REJECTED: 0,
  },
};

export const opportunityreducer = (
  state: OPP_STATE = initialState,
  action: GET_OPP_ACTION
): OPP_STATE => {
  switch (action.type) {
    case ACTION.GET_OPPORTUNITY:
      let pending_opportunity: OPP = action.OPP;
      return { ...state, OPP: pending_opportunity };
    default:
      return { ...state };
  }
  // return state;
};
