import React, { Dispatch, useEffect, useState } from "react";
import { Helmet } from "react-helmet";

import article_icon from "../../assests/images/article/article_icon.png";
import { sectorItem, SKIP, title, metaData, LIMIT } from "../constants";
import ArticlesList from "./ArticlesList";
import { useDispatch, useSelector } from "react-redux";
import { getSector } from "../../store/actioncreators/sectoractions";
import { getSubSector } from "../../store/actioncreators/sub-sectoractions";
import { useDetectClickOutside } from "react-detect-click-outside";
import "./style.css";

const Articles = () => {
  const sectorlist: SECTOR = useSelector((state: STATE) => state.SECTOR.SECTOR);

  const dispatch: Dispatch<any> = useDispatch();
  const [page, setPage] = useState({
    skip: SKIP,
    limit: LIMIT,
  });
  const [sector, setSector] = useState({
    drop: false,
    selected: "",
    id: "",
  });

  useEffect(() => {
    dispatch(getSector());
    dispatch(getSubSector());
    setSector({
      ...sector,
      selected: "All Sectors",
      id: "",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const ref1 = useDetectClickOutside({
    onTriggered: () => {
      setSector({ ...sector, drop: false });
    },
  });

  return (
    <div className="flex flex-col relative min-h-screen md:py-10 items-center w-full">
      <Helmet>
        <title>{title.ARTICLES}</title>
        <meta
          name="description"
          key="description"
          content={metaData.ARTICLES}
        />
        <meta name="title" key="title" content="Articles" />
        <meta property="og:title" content="Articles" />
        <meta property="og:description" content={metaData.ARTICLES} />
        <meta property="og:image" content={article_icon} />
        <meta
          property="og:url"
          content={`${process.env.REACT_APP_BASE_URL}/articles`}
        />
        <meta property="og:type" content="website" />
        <meta name="twitter:title" content="Articles" />
        <meta name="twitter:description" content={metaData.ARTICLES} />
        <meta name="twitter:image" content={article_icon} />
        <meta name="twitter:card" content="Articles" />
      </Helmet>
      <div className="flex flex-col items-center max-w-[1000px] w-full md:p-10 ">
        <h1 className="text-xl md:text-4xl font-roboto flex flex-row items-center mb-4">
          <img src={article_icon} alt="Article" className="w-4 md:w-8 mr-2" />
          Articles
        </h1>
        <p className="hidden md:block">
          GTI® functions as an educational and knowledge sharing platform by
          regularly sharing information and news from the industry through the
          platform. It is also an open innovation platform where entities can
          identify customers, investors and partners for innovations in
          cleantech, biotech and ICT, across geographic boundaries.
        </p>
      </div>

      <div className="flex w-full justify-between mx-10 py-5">
        <div
          className="flex flex-col justify-end duration-200 z-10 px-5"
          ref={ref1}
        >
          <button
            id="dropdownDefault"
            data-dropdown-toggle="dropdown"
            className="w-48 text-GTI-BLUE-default border bg-white focus:outline-none font-medium font-roboto rounded-lg m-1 text-sm px-4 py-2.5 text-center inline-flex items-center border-slate-300 justify-center flex-shrink"
            type="button"
            onClick={() => {
              setSector({ ...sector, drop: !sector.drop });
            }}
          >
            {sectorlist.SECTOR_LIST && sector.id === ""
              ? "All Sectors"
              : sector.selected}
            <svg
              className="ml-2 w-4 h-4"
              aria-hidden="true"
              fill="grey"
              stroke="grey"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M19 9l-7 7-7-7"
              ></path>
            </svg>
          </button>
          <div
            id="dropdown"
            className={
              "relative z-10 rounded px-2 " + (!sector.drop ? "hidden" : "")
            }
            data-popper-placement="bottom"
          >
            <ul
              className="absolute flex flex-col text-sm font-roboto bg-white rounded-md shadow-md px-5 border "
              aria-labelledby="dropdownDefault"
            >
              <li
                className="block z-10 py-2 px-4 rounded text-GTI-BLUE-default whitespace-nowrap hover:text-slate-500 "
                onClick={() => {
                  setSector({ id: "", drop: false, selected: "All Sectors" });
                }}
              >
                All Sectors
              </li>
              {sectorlist &&
                sectorlist.SECTOR_LIST.map((item: sectorItem, id) => {
                  return (
                    <li
                      key={id}
                      className="block z-10 py-2 px-4 rounded text-GTI-BLUE-default  whitespace-nowrap hover:text-slate-500 "
                      onClick={() => {
                        setSector({
                          id: item._id,
                          drop: false,
                          selected: item.name,
                        });
                      }}
                    >
                      {item.name}
                    </li>
                  );
                })}
            </ul>
          </div>
        </div>
      </div>
      <div className="w-full">
        <ArticlesList skip={page.skip} limit={page.limit} secId={sector.id} />
      </div>
    </div>
  );
};

export default Articles;
