import axios from "axios";
import { store } from "../../store";
import * as CONTACT from "../../Components/constants";

export const createContact =
  (item: CONTACT.contactItem) => (dispatch: CREATE_CONTACT_DISPATCH) => {
    var data = JSON.stringify({
      name: item.name,
      phoneNumber: item.phoneNumber,
      email: item.email,
      message: item.message,
    });

    var config = {
      method: "post",
      url: `${process.env.REACT_APP_BASE_API}/contact-us`,
      headers: {
        Authorization: `Bearer ${store.getState().USER.USER.token}`,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        // console.log("Contact  created ");
      })
      .catch(function (error) {
        // console.log("Contact not created ", error);
      });
  };
