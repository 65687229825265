import React, { Dispatch, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { IoMdDownload } from "react-icons/io";
import { Helmet } from "react-helmet";

import { InnovationItemFetched, NONE } from "../constants";
import { registerCall } from "../../store/actioncreators/innovationactions";
import {
  spinnerLoaderStart,
  spinnerLoaderStop,
} from "../../store/actioncreators/loaderactions";
import { ScreenSpinner } from "../utils/loader";
import PdfDownloader from "../../shared/PdfDownloader";
import { RequestMethods } from "../../shared/RequestMethods";
import TechnologyOpportunityRequiredPopUp from "../../shared/TechnologyOpportunityRequiredPopUp";
import { getYourProductCount } from "../../store/actioncreators/productactions";
import { getYourOppportunityCount } from "../../store/actioncreators/opportunityactions";

const CallView = ({ handleLoginModal }: { handleLoginModal: () => void }) => {
  const dispatch: Dispatch<any> = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const { id } = useParams();
  const [registered, setRegister] = useState(false);
  const [
    technologyOpportunityRequiredPopUp,
    setTechnologyOpporunityRequiredPopUp,
  ] = useState(false);
  const user: USER = useSelector((state: STATE) => state.USER.USER);
  const spinner: LOADER = useSelector((state: STATE) => state.LOADER.LOADER);
  const [innovation, setInnovation] = useState<InnovationItemFetched>({
    _id: NONE,
    title: NONE,
    description: NONE,
    companyName: NONE,
    companyLogo: NONE,
    companyDocument: NONE,
    imageUrl: NONE,
    startDate: NONE,
    endDate: NONE,
    displayOnHomePage: false,
    isDeleted: false,
    createdAt: NONE,
    formLink: "",
    referenceCode: NONE,
    __v: -1,
  });

  const getRegistration = () => {
    const token = localStorage.getItem("GTI_data")?.split(" ")[0] ?? "";

    const config = {
      method: RequestMethods.GET,
      url: `${process.env.REACT_APP_BASE_API}/innovation-calls/isUserRegistered/${id}`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };

    axios(config)
      .then(function (response) {
        setRegister(response.data);
      })
      .catch(function (error) {});
  };

  const loadCall = async () => {
    var config = {
      method: RequestMethods.PUT,
      url: `${process.env.REACT_APP_BASE_API}/innovation-calls/${id}`,
      headers: {
        "Content-Type": "application/json",
      },
    };

    await axios(config)
      .then(function (response) {
        setInnovation(response.data);
        dispatch(spinnerLoaderStop());
      })
      .catch(function (error) {
        dispatch(spinnerLoaderStop());
      });
  };

  const addQuery = async (innovationCallCompanyName: string) => {
    const regex = /[!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]/g;

    const queryParams = new URLSearchParams(location.search);
    queryParams.set(
      "company",
      innovationCallCompanyName
        .toLocaleLowerCase()
        .replaceAll(regex, "")
        .replaceAll(" ", "_")
    );
    const newSearchString = queryParams.toString();
    navigate({
      pathname: location.pathname,
      search: newSearchString,
    });
  };

  useEffect(() => {
    dispatch(spinnerLoaderStart());
    loadCall();
    getRegistration();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [registered]);

  useEffect(() => {
    if (innovation?.title) addQuery(innovation.companyName);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [innovation]);

  const DOS = new Date(innovation.startDate);
  const DOE = new Date(innovation.endDate);

  const handleRegister = async () => {
    const { formLink } = innovation;
    if (user.admin !== -1 && (!registered || (registered && formLink))) {
      const productsCount = await getYourProductCount();
      const opportunitiesCount = await getYourOppportunityCount();
      if (productsCount === 0 && opportunitiesCount === 0) {
        setTechnologyOpporunityRequiredPopUp(true);
      } else if (formLink) {
        dispatch(registerCall(innovation._id));
        window.open(formLink, "_blank");
      } else {
        dispatch(registerCall(innovation._id));
        setRegister(true);
      }
    } else if (user.admin === -1) {
      handleLoginModal();
    }
  };

  return (
    <React.Fragment>
      <div
        id="main"
        className="flex flex-col items-center w-full bg-slate-100 pb-[100px]"
      >
        <Helmet>
          <title>{innovation.title}</title>
          <meta
            name="description"
            key="description"
            content={innovation.description}
          />
          <meta name="title" key="title" content={innovation.title} />
          <meta property="og:title" content={innovation.title} />
          <meta
            property="og:description"
            content={`${innovation.description}`}
          />
          <meta property="og:image" content={innovation.companyLogo} />
          <meta
            property="og:url"
            content={`${process.env.REACT_APP_BASE_URL}/innovation-call-view/${innovation._id}`}
          />
          <meta property="og:type" content="website" />
          <meta name="twitter:title" content={innovation.title} />
          <meta name="twitter:description" content={innovation.description} />
          <meta name="twitter:image" content={innovation.description} />
          <meta name="twitter:card" content={innovation.title} />
        </Helmet>
        {spinner.SPINNER ? (
          <ScreenSpinner />
        ) : (
          <>
            <div className="max-w-[95%] md:max-w-[1000px] z-10 w-full">
              <div className="flex flex-col rounded-2xl border-2  gap-7 mt-10 items-center bg-white w-full px-10 py-5">
                <div className="w-full z-10 text-black flex flex-col justify-between items-start max-w-[1200px]">
                  <h1 className="text-xl font-sans w-full text-center border-b-2 pb-4">
                    Apply to the Innovation Call
                  </h1>
                  <div className="flex w-full items-center justify-start">
                    <img
                      src={innovation.companyLogo}
                      alt={innovation.companyName}
                      className="h-full w-full max-h-[250px] my-7 self-start object-contain"
                    />
                  </div>
                  <div>
                    <h1 className="text-sm md:text-lg font-medium font-sans">
                      {innovation.title.charAt(0).toLocaleUpperCase() +
                        innovation.title.slice(1)}
                    </h1>
                    <div className="flex gap-5 ">
                      <span className="font-sans text-sm md:text-lg font-regular">
                        {innovation.companyName}
                      </span>
                    </div>
                    {innovation.referenceCode && (
                      <div className="flex ">
                        <span className="font-sans text-sm font-bold font-regular">
                          Reference Code:&nbsp;
                        </span>
                        <span className="font-sans text-sm font-regular">
                          {innovation.referenceCode}
                        </span>
                      </div>
                    )}
                  </div>
                </div>
                <div className="w-full flex justify-between items-center">
                  <div>
                    <p
                      className={` flex gap-2 items-center text-sm md:text-lg font-bold`}
                    >
                      Posted on
                    </p>
                    <p
                      className={` flex gap-2 items-center text-sm md:text-lg `}
                    >
                      {DOS.toLocaleDateString("default", {
                        month: "short",
                        day: "2-digit",
                        year: "numeric",
                      })}
                    </p>
                  </div>
                  <div>
                    <p
                      className={` flex gap-2 items-center text-sm md:text-lg  font-bold`}
                    >
                      Deadline of submission
                    </p>
                    <p
                      className={` flex gap-2 items-center text-sm md:text-lg  ${
                        DOE.getTime() < new Date().getTime()
                          ? "text-red-700"
                          : ""
                      }`}
                    >
                      {DOE.toLocaleString("default", {
                        month: "short",
                        day: "2-digit",
                        year: "numeric",
                      })}
                    </p>
                  </div>
                </div>
                <div className="w-full flex flex-col items-start text-sm md:text-lg ">
                  <p
                    dangerouslySetInnerHTML={{ __html: innovation.description }}
                  ></p>
                </div>
                <div className="md:flex md:justify-center w-full transform">
                  <button
                    type="button"
                    className={"button active "}
                    onClick={() => {
                      handleRegister();
                    }}
                  >
                    {user.admin !== -1
                      ? registered && !innovation?.formLink
                        ? "Already registered"
                        : "Register"
                      : "Create Profile / Register"}
                  </button>

                  {innovation?.companyDocument ? (
                    <PdfDownloader
                      className="button active flex gap-2 items-center justify-center"
                      url={innovation.companyDocument}
                    >
                      <IoMdDownload />
                      Download
                    </PdfDownloader>
                  ) : null}
                </div>
              </div>
            </div>
          </>
        )}
        {technologyOpportunityRequiredPopUp && (
          <TechnologyOpportunityRequiredPopUp
            userType={user?.userType}
            setTechnologyOpporunityRequiredPopUp={
              setTechnologyOpporunityRequiredPopUp
            }
          />
        )}
      </div>
    </React.Fragment>
  );
};

export default CallView;
