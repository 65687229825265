import React from "react";
import { AiOutlineAlignLeft } from "react-icons/ai";
import { AiOutlineAlignRight } from "react-icons/ai";
import { useNavigate, useLocation } from "react-router-dom";
import gbi_logo from "../../assests/images/gti_logo.svg";
import gbi_home_logo from "../../assests/home/logo_home.svg";
import { HOME } from "../constants";

const H_left = ({ handleShow, show }: { handleShow: any; show: boolean }) => {
  const location = useLocation();

  let navigate = useNavigate();
  return (
    <div className="flex max-w-full xl:max-w-[160px] md:mx-5 xl:justify-center xl:items-center justify-between cursor-pointer">
      <img
        src={location.pathname === HOME ? gbi_home_logo : gbi_logo}
        onClick={() => {
          navigate(HOME);
        }}
        alt={"GTI® logo"}
        className="h-[55px] max-w-[140px]"
      />
      <div
        className="block xl:hidden absolute right-4 top-2"
        onClick={() => {
          handleShow();
        }}
      >
        {show ? (
          <AiOutlineAlignLeft
            color="black"
            className="bg-white p-2 lg:m-2 w-10 h-10 rounded-md "
          />
        ) : (
          <AiOutlineAlignRight
            color="black"
            className="bg-white p-2 lg:m-2 w-10 h-10 rounded-md "
          />
        )}
      </div>
    </div>
  );
};

export default H_left;
