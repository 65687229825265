import React from "react";
import { Helmet } from "react-helmet";

import pageNotFound from "../../assests/images/page_not_found.svg";
import { title, metaData } from "../constants";
import globe from "../../assests/home/globe.png";

const PageNotFound = () => {
  return (
    <div className="flex flex-col relative items-center h-screen w-full p-5">
      <Helmet>
        <title>{title.PAGE_NOT_FOUND}</title>
        <meta
          name="description"
          key="description"
          content={metaData.PAGE_NOT_FOUND}
        />
        <meta name="title" key="title" content="Page Not Found" />
        <meta property="og:title" content="Page Not Found" />
        <meta property="og:description" content={metaData.PAGE_NOT_FOUND} />
        <meta property="og:image" content={globe} />
        <meta property="og:type" content="website" />
        <meta name="twitter:title" content="Page Not Found" />
        <meta name="twitter:description" content={metaData.PAGE_NOT_FOUND} />
        <meta name="twitter:image" content={globe} />
        <meta name="twitter:card" content="Page Not Found" />
      </Helmet>
      <h2 className="text-xl md:text-2xl mb-4">404 - Not Found</h2>
      <p className="text-gray-600 mb-8 text-xs md:text-lg">
        Sorry, the page you are looking for does not exist.
      </p>
      <img src={pageNotFound} alt="404 Not Found" className="h-96" />
    </div>
  );
};

export default PageNotFound;
