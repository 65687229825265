import React, { useEffect } from "react";

interface PdfDownloaderProps {
  url: string;
  children: React.ReactNode;
  className?: string;
}

function PdfDownloader({ url, className, children }: PdfDownloaderProps) {
  useEffect(() => {
    const downloadPdf = async () => {
      const response = await fetch(url);
      const blob = await response.blob();
      const downloadUrl = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = downloadUrl;
      a.download = "download.pdf";
      a.click();
      URL.revokeObjectURL(downloadUrl);
    };

    const handleClick = () => {
      downloadPdf();
    };

    const button = document.getElementById("pdf-downloader-button");
    button!.addEventListener("click", handleClick);

    return () => {
      button!.removeEventListener("click", handleClick);
    };
  }, [url]);

  return (
    <button id="pdf-downloader-button" type="button" className={className}>
      {children}
    </button>
  );
}

export default PdfDownloader;
