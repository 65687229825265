import React, { useEffect, useState } from "react";
import { MdArrowRightAlt } from "react-icons/md";
import { NavLink } from "react-router-dom";

import completeGif from "../../assests/complete.gif";
import failedGif from "../../assests/failed.gif";
import { verifyEmail } from "../../api/user";
import { getQueryParams, isSuccess } from "../../utils";

const EmailVerifySuccess = () => {
  const token = getQueryParams("token").split(" ").join("+") ?? "";
  const [status, setStatus] = useState("");

  useEffect(() => {
    if (token) verifyToken(token ?? "");
    else {
      setStatus("error");
    }
  }, [token]);

  const verifyToken = async (token: string) => {
    const response = await verifyEmail(token);
    if (isSuccess(response)) {
      setStatus("success");
    } else {
      setStatus("error");
    }
  };

  return (
    <div className="relative w-full flex min-h-screen flex-col items-center justify-center overflow-hidden py-6 sm:py-12 bg-white">
      <div className="max-w-xl px-5 text-center">
        {status && (
          <img
            src={status === "success" ? completeGif : failedGif}
            alt="Email Verification"
            className="h-28 w-28 mx-auto"
          />
        )}
        <h2 className="mb-4 text-[42px] font-bold text-zinc-800">
          Verification{" "}
          {!status
            ? "Pending..."
            : status === "success"
            ? `Success 🎉`
            : `Failed 😞`}
        </h2>
        <p className="mb-2 text-lg text-zinc-500">
          {!status
            ? ""
            : status === "success"
            ? `Congratulations! Your email has been verified successfully. You will
            have access to all the features of our platform once admin approves your account!`
            : `It seems either the link expired or you are unauthorized to verify this email. 
            Please try again with verified link!`}
        </p>
        <NavLink
          to="/"
          className="mt-6 flex gap-2 items-center justify-center w-full rounded bg-GTI-BLUE-default px-5 py-3 font-medium text-white shadow-md shadow-indigo-500/20 hover:bg-indigo-700"
        >
          Home{"  "} <MdArrowRightAlt />
        </NavLink>
      </div>
    </div>
  );
};

export default EmailVerifySuccess;
