import axios from "axios";
import { store } from "../../store";
import { NOTIFICATION_GET } from "../actiontypes/actionTypes";
import { getAllNotificationApi } from "../../api/user";

export const getNotifications = () => (dispatch: NOTIFICATION_DISPATCH) => {
  var config = {
    method: "get",
    url: `${process.env.REACT_APP_BASE_API}/notifications`,
    headers: {
      Authorization: `Bearer ${store.getState().USER.USER.token}`,
      "Content-Type": "application/json",
    },
  };

  axios(config)
    .then(function (response) {
      // console.log("now total ", response);
      const list: NOTIFICATION = {
        NOTIFICATION: response.data,
        NOTIFICATION_NOT_VIEWED:
          store.getState().NOTIFICATION.NOTIFICATION_LIST
            .NOTIFICATION_NOT_VIEWED,
        COUNT: store.getState().NOTIFICATION.NOTIFICATION_LIST.COUNT,
      };
      const action: NOTIFICATION_ACTION = {
        type: NOTIFICATION_GET,
        NOTIFICATION: {
          NOTIFICATION_LIST: list,
        },
      };
      return dispatch(action);
    })
    .catch(function (error) {});
};

export const viewAllNotification = () => (dispatch: NOTIFICATION_DISPATCH) => {
  var config = {
    method: "patch",
    url: `${process.env.REACT_APP_BASE_API}/notifications`,
    headers: {
      Authorization: `Bearer ${store.getState().USER.USER.token}`,
      "Content-Type": "application/json",
    },
  };

  axios(config)
    .then(function (response) {
      // console.log("notifications viewed");
    })
    .catch(function (error) {
      // console.log("notifications viewing failed");
    });
};
export const getUnviewNotification =
  (skip: string, limit: string) => (dispatch: NOTIFICATION_DISPATCH) => {
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_BASE_API}/notifications/unviewed`,
      headers: {
        Authorization: `Bearer ${store.getState().USER.USER.token}`,
        "Content-Type": "application/json",
      },
    };

    axios(config)
      .then(function (response) {
        // console.log("now total ", );
        const list: NOTIFICATION = {
          NOTIFICATION:
            store.getState().NOTIFICATION.NOTIFICATION_LIST.NOTIFICATION,
          NOTIFICATION_NOT_VIEWED: response.data,
          COUNT: store.getState().NOTIFICATION.NOTIFICATION_LIST.COUNT,
        };
        const action: NOTIFICATION_ACTION = {
          type: NOTIFICATION_GET,
          NOTIFICATION: {
            NOTIFICATION_LIST: list,
          },
        };
        return dispatch(action);
      })
      .catch(function (error) {
        // console.log("unable to view notification ");
      });
  };
export const getAllNotification =
  (skip: string, limit: string) => (dispatch: NOTIFICATION_DISPATCH) => {
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_BASE_API}/notifications?skip=${skip}&limit=${limit}`,
      headers: {
        Authorization: `Bearer ${store.getState().USER.USER.token}`,
        "Content-Type": "application/json",
      },
    };

    getAllNotificationApi(skip, limit)
      .then(function (response) {
        // console.log({ response });
        const list: NOTIFICATION = {
          NOTIFICATION: response.data.notifications,
          NOTIFICATION_NOT_VIEWED: response.data.notifications,
          COUNT: response?.data?.notificationsCount,
        };
        const action: NOTIFICATION_ACTION = {
          type: NOTIFICATION_GET,
          NOTIFICATION: {
            NOTIFICATION_LIST: list,
          },
        };
        return dispatch(action);
      })
      .catch(function (error) {
        // console.log("unable to view notification ");
      });
  };
export const getNotificationsCount =
  () => (dispatch: NOTIFICATION_DISPATCH) => {
    const extoken: string =
      localStorage.getItem("GTI_data")?.split(" ")[0] ?? "";

    if (!extoken) return;

    let config = {
      method: "get",
      url: `${process.env.REACT_APP_BASE_API}/notifications/getcount`,
      headers: {
        Authorization: `Bearer ${extoken}`,
        "Content-Type": "application/json",
      },
    };

    axios(config)
      .then(function (response) {
        const list: NOTIFICATION = {
          NOTIFICATION:
            store.getState().NOTIFICATION.NOTIFICATION_LIST.NOTIFICATION,
          NOTIFICATION_NOT_VIEWED:
            store.getState().NOTIFICATION.NOTIFICATION_LIST
              .NOTIFICATION_NOT_VIEWED,
          COUNT: +response.data,
        };
        const action: NOTIFICATION_ACTION = {
          type: NOTIFICATION_GET,
          NOTIFICATION: {
            NOTIFICATION_LIST: list,
          },
        };
        return dispatch(action);
      })
      .catch(function (error) {});
  };
