import React, { Dispatch, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useDetectClickOutside } from "react-detect-click-outside";
import { Helmet } from "react-helmet";

import { getSector } from "../../store/actioncreators/sectoractions";
import { getSubSector } from "../../store/actioncreators/sub-sectoractions";
import CompanyProductList from "./CompanyProductList";
import { LIMIT, SKIP, metaData, title } from "../constants";
import globe from "../../assests/home/globe.png";
import "./style.css";

const CompanyProducts = () => {
  const sectorlist: SECTOR = useSelector((state: STATE) => state.SECTOR.SECTOR);
  const subsectorlist: SUB_SECTOR = useSelector(
    (state: STATE) => state.SUB_SECTOR.SUB_SECTOR
  );
  const products: PRODUCTS = useSelector(
    (state: STATE) => state.PRODUCTS.PRODUCTS
  );
  const dispatch: Dispatch<any> = useDispatch();
  const [productModal, setModel] = useState(false);

  const [page, setPage] = useState({
    skip: SKIP,
    limit: LIMIT,
  });

  const [sector, setSector] = useState({
    drop: false,
    selected: "",
    id: "",
  });
  const [subSector, setSubSector] = useState({
    drop: false,
    selected: "",
    id: "",
  });
  const [type, setType] = useState({
    drop: false,
    selected: "Pending",
    id: 2,
    total: products.TOTAL_PENDING,
  });

  const ref1 = useDetectClickOutside({
    onTriggered: () => setType({ ...type, drop: false }),
  });

  const fetchData = (value: number) => {
    let final = +page.skip + value < 0 ? +page.skip : +page.skip + value;
    setPage({ skip: final.toString(), limit: page.limit });
  };

  useEffect(() => {
    dispatch(getSector());
    dispatch(getSubSector());
    setSector({
      ...sector,
      selected: "All Sectors",
      id: "",
    });
    setSubSector({
      ...subSector,
      selected: "All Sub-Sectors",
      id: "",
    });
  }, []);

  const handleType = (index: number) => {
    if (index === 0) {
      setType({
        ...type,
        selected: "Approved",
        id: 0,
        total: products.TOTAL_APPROVED,
        drop: false,
      });
    } else if (index === 1) {
      setType({
        ...type,
        selected: "Unapproved",
        id: 1,
        total: products.TOTAL_UNAPPROVED,
        drop: false,
      });
    } else if (index === 2) {
      setType({
        ...type,
        selected: "Pending",
        id: 2,
        total: products.TOTAL_PENDING,
        drop: false,
      });
    } else {
      setType({
        ...type,
        selected: "Rejected",
        id: 3,
        total: products.TOTAL_REJECTED,
        drop: false,
      });
    }
  };

  const handleModel = async () => {
    setModel(!productModal);
  };

  return (
    <div className="flex flex-col relative justify-center py-10 items-center w-full">
      <Helmet>
        <title>{title.COMPANY_TECHNOLOGIES}</title>
        <meta
          name="description"
          key="description"
          content={metaData.COMPANY_TECHNOLOGIES}
        />
        <meta name="title" key="title" content={title.COMPANY_TECHNOLOGIES} />
        <meta property="og:title" content={title.COMPANY_TECHNOLOGIES} />
        <meta
          property="og:description"
          content={metaData.COMPANY_TECHNOLOGIES}
        />
        <meta property="og:image" content={globe} />
        <meta
          property="og:url"
          content={`${process.env.REACT_APP_BASE_URL}/company-technology`}
        />
        <meta property="og:type" content="website" />
        <meta name="twitter:title" content={title.COMPANY_TECHNOLOGIES} />
        <meta
          name="twitter:description"
          content={metaData.COMPANY_OPPORTUNITIES}
        />
        <meta name="twitter:image" content={globe} />
        <meta name="twitter:card" content={metaData.COMPANY_TECHNOLOGIES} />
      </Helmet>
      <div className="flex flex-row items-center">
        <h1 className="text-2xl font-roboto ">Company Products</h1>
      </div>
      <div className="flex w-full justify-end  mx-10 py-5">
        <div
          ref={ref1}
          className="flex flex-row justify-end duration-200 z-10 px-5"
        >
          <button
            id="dropdownDefault"
            data-dropdown-toggle="dropdown"
            className="w-fit text-white border-2 bg-GTI-BLUE-default focus:outline-none font-medium font-roboto rounded-lg m-1 text-sm px-4 py-2.5  text-center inline-flex items-center border-slate-300 justify-center flex-shrink"
            type="button"
            onClick={() => {
              setType({ ...type, drop: !type.drop });
            }}
          >
            {type.selected}
            <svg
              className="ml-2 w-4 h-4"
              aria-hidden="true"
              fill="grey"
              stroke="grey"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M19 9l-7 7-7-7"
              ></path>
            </svg>
          </button>
          <div
            id="dropdown"
            className={"relative z-10 rounded " + (!type.drop ? "hidden" : "")}
            data-popper-placement="bottom"
          >
            <ul
              className="absolute flex flex-col  -left-32  top-14 text-sm font-roboto bg-slate-100"
              aria-labelledby="dropdownDefault"
            >
              <li
                className="block z-10 py-2 px-4 rounded  text-GTI-BLUE-default  whitespace-nowrap hover:text-slate-500 "
                onClick={() => {
                  handleType(0);
                }}
              >
                Approved
              </li>
              <li
                className="block z-10 py-2 px-4 rounded  text-GTI-BLUE-default  whitespace-nowrap hover:text-slate-500 "
                onClick={() => {
                  handleType(1);
                }}
              >
                Unapproved
              </li>
              <li
                className="block z-10 py-2 px-4 rounded  text-GTI-BLUE-default  whitespace-nowrap hover:text-slate-500 "
                onClick={() => {
                  handleType(2);
                }}
              >
                Pending
              </li>
              <li
                className="block z-10 py-2 px-4 rounded  text-GTI-BLUE-default  whitespace-nowrap hover:text-slate-500 "
                onClick={() => {
                  handleType(3);
                }}
              >
                Rejected
              </li>
            </ul>
          </div>
        </div>
      </div>
      <CompanyProductList
        skip={page.skip}
        limit={LIMIT}
        secId={sector.id}
        subsecId={subSector.id}
        type={type.id}
      />
      <div className="flex mb-5 py-5">
        <button
          disabled={page.skip === "0"}
          onClick={() => {
            fetchData(-1);
          }}
          className="inline-flex items-center py-2 px-4 text-sm font-medium text-GTI-BLUE-default disabled:text-gray-500 bg-white  rounded-lg border border-gray-300 hover:bg-gray-100 hover:text-gray-700 "
        >
          <svg
            aria-hidden="true"
            className="mr-2 w-5 h-5"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              d="M7.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l2.293 2.293a1 1 0 010 1.414z"
              clipRule="evenodd"
            ></path>
          </svg>
          Previous
        </button>
        <button
          disabled
          className="inline-flex items-center mx-2 py-2 px-4 text-sm font-medium text-gray-500 bg-white rounded-lg border border-gray-300 hover:bg-gray-100 hover:text-gray-700 "
        >
          {+page.skip + 1}
        </button>
        <button
          disabled={(+page.skip + 1) * +page.limit >= type.total}
          onClick={() => {
            fetchData(1);
          }}
          className="inline-flex items-center py-2 px-4 text-sm font-medium text-GTI-BLUE-default disabled:text-gray-500 bg-white rounded-lg border border-gray-300 hover:bg-gray-100 hover:text-gray-700 "
        >
          Next
          <svg
            aria-hidden="true"
            className="ml-2 w-5 h-5"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z"
              clipRule="evenodd"
            ></path>
          </svg>
        </button>
      </div>
    </div>
  );
};

export default CompanyProducts;
