import React, { Dispatch, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import { useSelector } from "react-redux";
import ReactCountryFlag from "react-country-flag";
import Slider from "react-slick";

import SuccessModal from "./SuccessModal";
import companyLogo from "../../assests/banners/company_logo.png";
import gbi_home_logo from "../../assests/home/logo_home.svg";
import { ScreenSpinner } from "../utils/loader";
import { notify } from "../../utils";
import { spinnerLoaderStop } from "../../store/actioncreators/loaderactions";
import RenderHTML from "../utils/RenderHTML";
import { RequestMethods } from "../../shared/RequestMethods";
import { PaymentStatus } from "../../store/reducer/userreducer";
import { VerificationStatus } from "../../shared/enum";
import { BiLinkExternal } from "react-icons/bi";
import { Company } from "../../shared/constants";
import { retryPromotionPayment } from "../../store/actioncreators/promotionsaction";

const Promotion = ({ handleLoginModal }: { handleLoginModal: () => void }) => {
  const dispatch: Dispatch<any> = useDispatch();
  const spinner: LOADER = useSelector((state: STATE) => state.LOADER.LOADER);
  const user: USER = useSelector((state: STATE) => state.USER.USER);
  const navigate = useNavigate();

  let [promotion, setPromotion] = useState({
    _id: "",
    title: "",
    description: "",
    images: [],
    userId: "",
    productId: "",
    opportunityId: "",
    paymentStatus: "",
    verificationStatus: "",
    createdAt: "",
    __v: -1,
    users: {
      _id: "",
      fullName: "",
      email: "",
      phoneNumber: "",
      countryCode: "",
      referenceCode: "",
      isEmailVerified: false,
      isUserVerified: false,
      isRejected: false,
      password: "",
      userRole: -1,
      userType: "",
      companyId: "",
      follower: [],
      following: [],
      connections: [
        {
          connectionStatus: "",
          userId: "",
        },
      ],
      createdAt: "",
      __v: -1,
    },
    company: {
      _id: "",
      name: "",
      logo: "",
      description: "",
      address: "",
      website: "",
      country: "",
      companyTurnover: -1,
      companyId: "",
      typeAndSizeOfPartnersRequired: [],
      typesOfPartnershipConsidered: [],
      createdAt: "",
      developmentStage: "",
      iprStatus: [],
      __v: -1,
    },
  });

  let [successModal, setSuccessModal] = useState<boolean>(false);
  const [state, setState] = useState("LOADING");
  const [message, setMessage] = useState("");

  let { id } = useParams();
  let promotionId: string = id ? id : "";

  const handleSuccessModal = (
    isOpen: boolean,
    state: string,
    message: string
  ) => {
    setSuccessModal(isOpen);
    setState(state);
    setMessage(message);
  };

  const sidebar_carousal_settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    swipeToSlide: true,
    autoplay: true,
  };

  const loadPromotion = async (promotionId: string) => {
    const extoken: string =
      localStorage.getItem("GTI_data")?.split(" ")[0] ?? "";
    let config = {
      method: RequestMethods.GET,
      url: `${process.env.REACT_APP_BASE_API}/promotions/${promotionId}`,
      headers: {
        Authorization: `Bearer ${extoken}`,
        "Content-Type": "application/json",
      },
    };

    axios(config)
      .then(function (response) {
        setPromotion(response?.data?.promotion);

        dispatch(spinnerLoaderStop());
      })
      .catch(function (error) {
        dispatch(spinnerLoaderStop());
      });
  };

  const paymentHandler = async (
    amount: number,
    currency: string,
    order_id: string,
    promotionId: string
  ) => {
    let options = {
      key: process.env.REACT_APP_RAZORPAY_KEY_ID,
      amount,
      currency,
      name: Company.NAME,
      description: Company.DESCRIPTION,
      image: gbi_home_logo,
      order_id,
      callback_url: `${process.env.REACT_APP_BASE_API}/payments/validate`,
      prefill: {
        name: user?.user?.name,
        email: user?.user?.email,
      },
      notes: {
        promotionId,
      },
      theme: {
        color: "#3399cc",
      },
    };

    let rzp1 = new (window as any).Razorpay(options);

    rzp1.on("payment.failed", function (response: any) {
      alert("Payment Failed. Please Retry again.");
      navigate("/featured/failed");
    });

    rzp1.open();
  };

  const handleRetryPromotionPayment = async () => {
    try {
      handleSuccessModal(true, "LOADING", "");

      const { order_id, currency, amount } = await retryPromotionPayment({
        promotionId,
      });

      handleSuccessModal(false, "LOADING", "");

      paymentHandler(amount, currency, order_id, promotionId);
    } catch (err) {
      notify("Failed to create Featured!", "error");
    }
  };

  useEffect(() => {
    loadPromotion(promotionId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const DOC = new Date(promotion.createdAt);

  return (
    <React.Fragment>
      <div className="flex flex-col p-2 md:p-10 w-full h-full">
        {spinner.SPINNER ? (
          <ScreenSpinner />
        ) : (
          <>
            <div className="flex flex-col md:flex-row w-full justify-between md:space-x-10 ">
              <div className="w-full md:w-3/5 flex flex-col md:px-10 md:m-5 md:space-y-5">
                <p className="font-roboto text-xl md:text-3xl font-bold">
                  {promotion.title}
                </p>
                <p className="md:hidden text-gray-400 mb-2 md:mb-0 text-xxs font-roboto">
                  Posted on:&nbsp;
                  {DOC.toLocaleString("default", {
                    month: "long",
                    day: "2-digit",
                    year: "numeric",
                  })}
                </p>
                {promotion.paymentStatus === PaymentStatus.SUCCESS ? (
                  <div className="hidden md:flex flex-row mb-1 md:mb-0">
                    <p className="font-roboto text-xs md:text-sm font-bold">
                      Payment Status:&nbsp;
                    </p>
                    <button
                      className={` py-1 px-1 rounded inline-flex text-xxs md:text-xs ${
                        promotion.paymentStatus === PaymentStatus.SUCCESS
                          ? "bg-green-200 text-green-800"
                          : "bg-red-200 text-red-700"
                      }`}
                    >
                      {promotion.paymentStatus}
                    </button>
                  </div>
                ) : null}
                {promotion.paymentStatus === PaymentStatus.PENDING ||
                promotion.paymentStatus === PaymentStatus.FAILED ? (
                  <div
                    className="w-full flex gap-2 cursor-pointer"
                    onClick={() => handleRetryPromotionPayment()}
                  >
                    <div
                      className={`inline-flex items-center px-3 py-2 text-sm font-medium text-center rounded-lg bg-blue-200 text-blue-700`}
                    >
                      {`Payment Status ${promotion.paymentStatus}, Click here to Retry`}
                    </div>
                  </div>
                ) : null}
                <div className="hidden md:flex flex-row mb-1 md:mb-0">
                  <p className="font-roboto text-xs md:text-sm font-bold">
                    Approval Status:&nbsp;
                  </p>
                  <button
                    className={` py-1 px-1 rounded inline-flex text-xxs md:text-xs ${
                      promotion.verificationStatus ===
                      VerificationStatus.PENDING
                        ? "bg-gray-300 text-black"
                        : promotion.verificationStatus ===
                          VerificationStatus.ACCEPTED
                        ? "bg-green-200 text-green-800"
                        : "bg-red-200 text-red-700"
                    }`}
                  >
                    {promotion.verificationStatus}
                  </button>
                </div>
                <p className="hidden md:block font-sans md:font-roboto text-xxs md:text-sm">
                  <RenderHTML html={promotion.description} />
                </p>
              </div>

              <div className="md:w-5/12 flex flex-col md:px-10 md:m-5 space-y-3 md:space-y-5">
                <Slider
                  {...sidebar_carousal_settings}
                  className="hidden md:flex md:flex-row w-full md:max-h-[330px] text-black border-2 p-2 border-blue-800"
                >
                  {promotion?.images?.length > 0
                    ? promotion.images.map((image) => (
                        <img
                          className="hidden md:block w-full h-full max-h-[200px] md:max-h-[300px] object-contain"
                          src={image}
                          alt={promotion.title}
                        />
                      ))
                    : null}
                </Slider>

                <p className="md:hidden font-sans md:font-roboto text-xxs md:text-sm">
                  <RenderHTML html={promotion.description} />
                </p>

                <p className="hidden md:block text-gray-400 text-xs font-roboto">
                  Posted on:&nbsp;
                  {DOC.toLocaleString("default", {
                    month: "long",
                    day: "2-digit",
                    year: "numeric",
                  })}
                </p>
                <div className="flex flex-row gap-4 items-center">
                  <Link
                    className="flex flex-row items-center gap-2 font-roboto text-sm justify-center"
                    to={`${
                      promotion?.productId
                        ? `/product/${promotion?.productId}`
                        : `/opportunity/${promotion?.opportunityId}`
                    } `}
                  >
                    <p className="font-roboto text-sm ">
                      {promotion?.productId
                        ? "Product"
                        : promotion?.opportunityId
                        ? "Opportunity"
                        : null}
                    </p>
                    {(promotion?.productId || promotion?.opportunityId) && (
                      <BiLinkExternal />
                    )}
                  </Link>
                </div>
              </div>
            </div>
            <br className="md:hidden" />
            <hr />

            <div className="flex flex-col md:flex-row w-full md:space-x-8 py-5 md:py-10">
              <span className="block md:hidden font-roboto text-sm md:text-lg text-black-500 font-bold text-justify pb-5 md:pb-0">
                {promotion?.company?.name}
                <span className="font-roboto text-sm md:text-lg text-black-500">
                  ({promotion.company?.country})
                </span>
              </span>

              <div className="md:w-64 md:h-64 flex flex-col px-5 md:px-10 md:mx-5 space-y-5">
                <img
                  className="self-center w-1/2 md:w-full h-1/2 md:h-3/5 object-contain shadow-xl"
                  src={
                    !promotion.company?.logo
                      ? companyLogo
                      : promotion.company.logo
                  }
                  alt={promotion?.company?.name}
                />

                <span className="hidden md:block font-roboto text-sm text-black-500 font-bold">
                  {promotion?.company?.name}
                  <br />
                  <span className="font-roboto text-sm text-black-500">
                    {promotion?.company?.country ? (
                      <ReactCountryFlag
                        countryCode={promotion.company?.country}
                        svg
                        style={{ width: "2em", height: "2em" }}
                        className="mr-2"
                      />
                    ) : null}
                  </span>
                </span>
              </div>
              <p className="font-sans text-xxs md:text-base text-gray-500">
                {promotion.company?.description}
              </p>
            </div>
          </>
        )}
      </div>
      {successModal && (
        <SuccessModal
          state={state}
          message={message}
          show={successModal}
          toggle={handleSuccessModal}
        />
      )}
    </React.Fragment>
  );
};

export default Promotion;
