import { useEffect, ComponentType } from "react";
import { NavigateFunction, useLocation } from "react-router-dom";

type WithScrollToTopProps = NavigateFunction & {
  location: ReturnType<typeof useLocation>;
};

export const withScrollToTop = (WrappedComponent: ComponentType<any>) => {
  return (props?: any) => {
    const location = useLocation();

    useEffect(() => {
      window.scrollTo(0, 0);
    }, [location.pathname]);

    return <WrappedComponent {...props} location={location} />;
  };
};
