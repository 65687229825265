import React, { useEffect, useState } from "react";

import technologyOpportunityRequired from "../assests/images/technology_opportunity_required.svg";
import { Link } from "react-router-dom";
import { PROFILE_TYPES } from "./enum";

interface TechnologyOpportunityRequiredPopUpInterface {
  userType: string;
  setTechnologyOpporunityRequiredPopUp: (e: boolean) => void;
}

const TechnologyOpportunityRequiredPopUp: React.FC<
  TechnologyOpportunityRequiredPopUpInterface
> = ({ userType, setTechnologyOpporunityRequiredPopUp }) => {
  const [content, setContent] = useState({
    title: "",
    description: "",
    buttonText: "",
    redirectUrl: "",
  });

  useEffect(() => {
    if (userType === PROFILE_TYPES.DISPLAYER) {
      setContent({
        title: "Please Upload Your Technology to Access!",
        description:
          "To access, you must complete your profile by uploading your Technology",
        buttonText: "Create Technology",
        redirectUrl: "/your-technology",
      });
    } else if (userType === PROFILE_TYPES.SCOUTER) {
      setContent({
        title: "Please Upload Your Market Opportunity to Access!",
        description:
          "To access, you must complete your profile by uploading your Market Opportunity",
        buttonText: "Create Market Opportunity",
        redirectUrl: "/your-opportunities",
      });
    } else if (userType === PROFILE_TYPES.GENERAL_SUBSCRIBER) {
      setContent({
        title: "General Subscriber Don't have Access",
        description: "It can be accessed by Displayer or Scouter",
        buttonText: "Okay",
        redirectUrl: "/",
      });
    }
  }, [userType]);

  return (
    <div className="fixed inset-0 flex items-center justify-center z-40 w-1/2 mx-auto">
      <div
        className="fixed inset-0 bg-black opacity-50"
        onClick={() => setTechnologyOpporunityRequiredPopUp(false)}
      ></div>
      <div className="bg-white p-6 rounded-lg shadow-md text-center z-50">
        <h2 className="text-xl font-semibold text-GTI-BLUE-default mb-2 underline underline-offset-2">
          {content?.title}
        </h2>
        <img
          src={technologyOpportunityRequired}
          alt="Announcement"
          className="w-96 h-60 mx-auto mb-4"
        />
        <p className="text-gray-900 text-lg">{content?.description}</p>
        <button className="button relative mt-4 bg-GTI-BLUE-default text-white hover:bg-blue-900 hover:text-grey">
          <Link to={content?.redirectUrl}>{content?.buttonText}</Link>
        </button>
      </div>
    </div>
  );
};

export default TechnologyOpportunityRequiredPopUp;
