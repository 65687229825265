import React, { Dispatch, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Helmet } from "react-helmet";
import { useDetectClickOutside } from "react-detect-click-outside";

import events from "../../assests/images/events.png";
import { LIMIT, SKIP, title, metaData } from "../constants";
import EventList from "./EventsList";
import { getSector } from "../../store/actioncreators/sectoractions";
import { getSubSector } from "../../store/actioncreators/sub-sectoractions";

const Events = () => {
  const dispatch: Dispatch<any> = useDispatch();

  useEffect(() => {
    dispatch(getSector());
    dispatch(getSubSector());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const eventTypeList = ["Online", "Offline", "Videos"];

  const [page, setPage] = useState({
    skip: SKIP,
    limit: LIMIT,
  });

  const [eventType, setEventType] = useState({
    drop: false,
    selected: "All Events",
    id: "",
  });

  const ref1 = useDetectClickOutside({
    onTriggered: () => {
      setEventType({ ...eventType, drop: false });
    },
  });

  useEffect(() => {
    setEventType({
      ...eventType,
      selected: "All Events",
      id: "",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="flex flex-col relative justify-center py-10 items-center w-full">
      <Helmet>
        <title>{title.EVENTS}</title>
        <meta name="description" key="description" content={metaData.EVENTS} />
        <meta name="title" key="title" content="Events" />
        <meta property="og:title" content="Events" />
        <meta property="og:description" content={metaData.EVENTS} />
        <meta property="og:image" content={events} />
        <meta
          property="og:url"
          content={`${process.env.REACT_APP_BASE_URL}/events`}
        />
        <meta property="og:type" content="website" />
        <meta name="twitter:title" content="Events" />
        <meta name="twitter:description" content={metaData.EVENTS} />
        <meta name="twitter:image" content={events} />
        <meta name="twitter:card" content="Events" />
      </Helmet>
      <div className="flex flex-col items-center max-w-[1000px] w-full p-10 ">
        <h1 className="text-4xl font-roboto flex flex-row items-center mb-4">
          <img
            src={events}
            alt="Events"
            className="w-10 mr-2 text-lg md:text-xl"
          />
          Events
        </h1>
        <p className="text-sm md:text-lg">
          GTI® team regularly hosts curated events to help governments,
          corporates and enterprises gain access to new markets, technologies,
          facilitate innovation partnerships and participate in research
          collaborations. To facilitate technology partnerships, knowledge
          exchange, education and capacity building, GTI® team hosts these
          events with hands-on support and assistance from our team to
          seamlessly and efficiently take forward your business needs. See
          details of upcoming events below!
        </p>
      </div>
      <div className="flex w-full justify-between mx-10 py-5">
        <div
          className="flex flex-col justify-end duration-200 z-10 px-5"
          ref={ref1}
        >
          <button
            id="dropdownDefault"
            data-dropdown-toggle="dropdown"
            className="w-48 text-GTI-BLUE-default border bg-white focus:outline-none font-medium font-roboto rounded-lg m-1 text-sm px-4 py-2.5 text-center inline-flex items-center border-slate-300 justify-center flex-shrink"
            type="button"
            onClick={() => {
              setEventType({ ...eventType, drop: !eventType.drop });
            }}
          >
            {eventType.selected}
            <svg
              className="ml-2 w-4 h-4"
              aria-hidden="true"
              fill="grey"
              stroke="grey"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M19 9l-7 7-7-7"
              ></path>
            </svg>
          </button>
          <div
            id="dropdown"
            className={
              "relative z-10 rounded px-2 " + (!eventType.drop ? "hidden" : "")
            }
            data-popper-placement="bottom"
          >
            <ul
              className="absolute flex flex-col text-sm font-roboto bg-white rounded-md shadow-md px-5 border "
              aria-labelledby="dropdownDefault"
            >
              <li
                className="block z-10 py-2 px-4 rounded  text-GTI-BLUE-default  whitespace-nowrap hover:text-slate-500 "
                onClick={() => {
                  setEventType({
                    drop: false,
                    selected: "All Events",
                    id: "",
                  });
                }}
              >
                All Events
              </li>
              {eventTypeList &&
                eventTypeList.map((item, id) => {
                  return (
                    <li
                      key={id}
                      className="block z-10 py-2 px-4 rounded text-GTI-BLUE-default whitespace-nowrap hover:text-slate-500 "
                      onClick={() => {
                        setEventType({
                          id: id.toString(),
                          drop: false,
                          selected: item,
                        });
                      }}
                    >
                      {item}
                    </li>
                  );
                })}
            </ul>
          </div>
        </div>
      </div>
      <div className="w-full">
        <EventList
          skip={page.skip}
          limit={page.limit}
          eventType={eventType.selected}
        />
      </div>
    </div>
  );
};
export default Events;
