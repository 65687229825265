import React, { Dispatch, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Helmet } from "react-helmet";

import { articleItemFetched, NONE } from "../constants";
import articlebanner from "../../assests/banners/articlebanner.png";
import gtiLogo from "../../assests/images/gti_logo.svg";
import ReactPlayer from "react-player";
import axios from "axios";
import SocialShare from "../Shareable/SocialShare";
import { getArticles } from "../../store/actioncreators/articleactions";

const Card = ({ item }: { item: articleItemFetched }) => {
  const DOC = new Date(item.createdAt);
  const navigate = useNavigate();

  const handleView = () => {
    navigate(`/articles/${item._id}`, { state: { id: item._id } });
  };

  return (
    <div
      className="w-full max-w-[350px] cursor-pointer overflow-hidden rounded-lg bg-white shadow h-full max-h-[320px]"
      onClick={handleView}
    >
      {!item.youtubeLink || item.youtubeLink === "none" ? (
        <img
          src={item?.imageUrl ?? ""}
          className="aspect-video w-full object-cover"
          alt={item.topic}
        />
      ) : (
        <ReactPlayer
          url={item.youtubeLink === NONE ? articlebanner : item.youtubeLink}
          className="flex rounded w-full justify-center"
          height="200px"
          width="auto"
          alt={item.topic}
          light
        />
      )}
      <div className="p-4">
        <p className="mb-1 text-sm text-primary-500">
          <time>
            {DOC.toLocaleString("default", {
              month: "short",
              day: "2-digit",
              year: "numeric",
            })}
          </time>
        </p>
        <h3 className="text-sm md:text-xl font-medium text-gray-900 truncate">
          {item.topic?.replace(/(<([^>]+)>)/gi, "")}
        </h3>
        <p className="mt-1 text-gray-500 truncate">{item.shortDescription}</p>
      </div>
    </div>
  );
};

const Article = () => {
  let [article, setArticle] = useState<articleItemFetched>({
    _id: "",
    topic: "",
    description: "",
    shortDescription: "",
    displayOnHomePage: false,
    isDeleted: false,
    sectorId: "",
    subSectorId: "",
    articleType: "",
    imageUrl: "",
    externalLink: "",
    youtubeLink: "",
    webinarId: "",
    createdAt: "",
    __v: -1,
  });
  const location = useLocation();
  const navigate = useNavigate();

  const articles: ARTICLE = useSelector(
    (state: STATE) => state.ARTICLE.ARTICLE
  );

  const dispatch: Dispatch<any> = useDispatch();
  const { id } = useParams();

  useEffect(() => {
    if (article) dispatch(getArticles(article.sectorId, "0", "4"));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [article]);

  const loadArticle = (id: string) => {
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_BASE_API}/article/${id}`,
      headers: {
        "Content-Type": "application/json",
      },
    };

    axios(config)
      .then(function (response) {
        setArticle(response.data);
      })
      .catch(function (error) {});
  };

  const addQuery = async (articleTitle: string) => {
    const regex = /[!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]/g;

    const queryParams = new URLSearchParams(location.search);
    queryParams.set(
      "title",
      articleTitle
        .toLocaleLowerCase()
        .replaceAll(regex, "")
        .replaceAll(" ", "_")
    );
    const newSearchString = queryParams.toString();
    navigate({
      pathname: location.pathname,
      search: newSearchString,
    });
  };

  useEffect(() => {
    id && loadArticle(id);
  }, [id]);

  useEffect(() => {
    if (article?.topic) addQuery(article.topic);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [article]);

  const DOC = new Date(article.createdAt);

  return (
    <React.Fragment>
      <div className="flex flex-col mx-auto w-full md:space-y-5 h-full items-center ">
        <Helmet>
          <title>{article.topic}</title>
          <meta
            name="description"
            key="description"
            content={article.description}
          />
          <meta name="title" key="title" content={article.topic} />
          <meta property="og:title" content={article.topic} />
          <meta property="og:description" content={article.description} />
          <meta
            property="og:image"
            content={article.imageUrl ? article.imageUrl : gtiLogo}
          />
          <meta
            property="og:url"
            content={`${process.env.REACT_APP_BASE_URL}/article/${id}`}
          />
          <meta property="og:type" content="website" />
          <meta name="twitter:title" content={article.topic} />
          <meta name="twitter:description" content={article.description} />
          <meta
            name="twitter:image"
            content={article.imageUrl ? article.imageUrl : gtiLogo}
          />
          <meta name="twitter:card" content={article.topic} />
        </Helmet>
        <div className="flex flex-col w-5/6 md:w-2/3 space-y-5 items-start relative">
          <SocialShare
            url={`${process.env.REACT_APP_BASE_API}/article/${id}`}
            topic={article.topic}
          />
          <h1
            dangerouslySetInnerHTML={{ __html: article.topic }}
            className="text-xl md:text-3xl w-full md:py-10 font-roboto text-left font-bold"
          ></h1>
          <div className="flex flex-col w-full h-1/2 rounded  shadow-lg shadow-slate-500 justify-items-start">
            {!article.youtubeLink ? (
              <img
                src={
                  article.imageUrl === "none" || !article.imageUrl
                    ? articlebanner
                    : article.imageUrl
                }
                className="flex w-full h-96 rounded object-contain"
                alt={article.topic}
                loading="lazy"
              />
            ) : (
              <div className="relative bg-black flex justify-center h-auto w-full aspect-w-16 aspect-h-9">
                <ReactPlayer
                  url={
                    article.youtubeLink === NONE
                      ? articlebanner
                      : article.youtubeLink
                  }
                  className=" w-full h-full min-h-[400px] bg-gray-700 bg-opacity-60"
                  alt={article.topic}
                  light
                />
              </div>
            )}
          </div>
          <div className="flex w-full flex-row-end text-gray-500 ">
            <h4>
              {DOC.toLocaleString("default", {
                month: "short",
                day: "2-digit",
                year: "numeric",
              })}
            </h4>
          </div>
          <div
            className="!mb-20 text-sm md:text-lg"
            dangerouslySetInnerHTML={{ __html: article.description }}
          />
        </div>
        <div className="flex flex-col h-full pb-32 w-5/6">
          <h3 className="font-roboto font-bold text-lg md:text-2xl">
            Related Articles
          </h3>
          <br />
          <div className="grid grid-rows-3 gap-y-6 md:gap-y-0 md:gap-x-5 md:grid-cols-3 md:grid-rows-1">
            {articles.articles
              .filter((article) => article?._id !== id)
              .slice(0, 3).length === 0 ? (
              <h4>No related articles</h4>
            ) : (
              articles.articles
                .filter((article) => article?._id !== id)
                .slice(0, 3)
                .map((item, id) => {
                  return <Card item={item} key={id} />;
                })
            )}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Article;
