import * as ACTION from "../actiontypes/actionTypes";

const initialState: PRODUCT_STATE = {
  PRODUCTS: {
    TOTAL: 0,
    PRODUCTS_LIST: {
      products: [],
      productsCount: 0,
    },
    YOUR_PRODUCT_LIST: {
      products: [],
      productsCount: 0,
    },
    COMPANY_PRODUCT_LIST: [],
    COMPANY_PENDING_PRODUCT_LIST: [],
    COMPANY_APPROVED_PRODUCT_LIST: [],
    COMPANY_UNAPPROVED_PRODUCT_LIST: [],
    COMPANY_REJECTED_PRODUCT_LIST: [],
    TOTAL_PENDING: 0,
    TOTAL_APPROVED: 0,
    TOTAL_UNAPPROVED: 0,
    TOTAL_REJECTED: 0,
  },
};

export const productsreducer = (
  state: PRODUCT_STATE = initialState,
  action: GET_PRODUCT_ACTION
): PRODUCT_STATE => {
  // console.log(`action.type ${action.type}`);

  switch (action.type) {
    case ACTION.GET_PRODUCTS:
      let pending_products: PRODUCTS = action.PRODUCTS;
      return { ...state, PRODUCTS: pending_products };
    default:
      return { ...state };
  }
  // return state;
};
