import { Helmet } from "react-helmet";

import article from "../../assests/images/articles.png";
import NewsList from "./NewsList";
import { title, metaData } from "../constants";
import "./style.css";

const News = () => {
  return (
    <div className="flex flex-col relative justify-center py-10 items-center w-full">
      <Helmet>
        <title>{title.NEWS}</title>
        <meta name="description" key="description" content={metaData.NEWS} />
        <meta name="title" key="title" content="News" />
        <meta property="og:title" content="News" />
        <meta property="og:description" content="News" />
        <meta property="og:image" content={article} />
        <meta
          property="og:url"
          content={`${process.env.REACT_APP_BASE_URL}/news`}
        />
        <meta property="og:type" content="website" />
        <meta name="twitter:title" content="News" />
        <meta name="twitter:description" content={metaData.NEWS} />
        <meta name="twitter:image" content={article} />
        <meta name="twitter:card" content="News" />
      </Helmet>
      <div className="flex flex-row items-center mb-10">
        <img src={article} alt="News" className="w-10 mr-2" />
        <h1 className="text-4xl font-roboto ">News</h1>
      </div>
      <NewsList />
    </div>
  );
};

export default News;
