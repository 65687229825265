import React, { useState } from "react";
import axios from "axios";
import { Spinner } from "../utils/loader";
import { notify } from "../../utils";
const NewsLetter = () => {
  const [email, setEmail] = useState({
    mail: "",
    loader: false,
  });
  const check = () => {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email.mail)) {
      return true;
    }
    return false;
  };

  const subscribeNews = () => {
    if (check()) {
      setEmail({ ...email, loader: true });
      let config = {
        method: "post",
        url: `${process.env.REACT_APP_BASE_API}/users/newsletter/${email.mail}`,
      };

      axios
        .request(config)
        .then((response) => {
          // console.log("mail send ");
          setEmail({ mail: "", loader: false });
          notify("Subscribed successfully!", "success");
        })
        .catch((error) => {
          // console.log("error in sending mail");
          setEmail({ mail: "", loader: false });
          notify("Failed to subscribe!", "error");
        });
    }
  };

  return (
    <div className="flex flex-col w-full py-10 justify-center items-center">
      <p className="text-base xl:text-2xl font-pj-sans font-semibold py-10">
        Subscribe to our newsletter for latest updates
      </p>
      <div className="flex flex-row xl:w-1/2 px-10 lg:px-20 space-x-2 items-center focus:shadow-lg focus:shadow-GTI-BLUE-default justify-center">
        <input
          onChange={(e) => {
            setEmail((prev) => ({ ...prev, mail: e.target.value }));
          }}
          value={email.mail}
          type="text"
          aria-label="Email Address"
          className="w-full font-dm-sans border border-gray-300 text-gray-900 text-xs lg:text-lg rounded-lg focus:ring-blue-500 focus:border-blue-500 block px-6 py-2 lg:py-4 shadow-lg shadow-GTI-BLUE-default"
          placeholder="Enter your E-mail id"
        />
        <button
          onClick={() => subscribeNews()}
          className="inline-flex font-pj-sans text-sm lg:text-lg bg-GTI-BLUE-default justify-center items-center text-white w-1/5 rounded-lg py-2 lg:py-4 px-10 lg:px-16 shadow-lg shadow-GTI-BLUE-default"
        >
          Subscribe
          {email.loader && <Spinner />}
        </button>
      </div>
    </div>
  );
};

export default NewsLetter;
