import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

//redux for getting user details
import { createStore, applyMiddleware, Store } from "redux";
import { Provider } from "react-redux";
import thunk from "redux-thunk";
import { store } from "./store";
// import reducer from "./store/reducer";

import ReactGA from "react-ga4";

const trackingId = process.env.REACT_APP_STREAM_ID ?? "";

ReactGA.initialize(trackingId);

// const store: Store<UserState, UserAction> & {
//   dispatch: DispatchType;
// } = createStore(reducer, applyMiddleware(thunk));

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <Provider store={store}>
    <App />
  </Provider>
);

reportWebVitals();
