import * as actionTypes from "../actiontypes/actionTypes";

export enum PROFILE_TYPES {
  DISPLAYER = "DISPLAYER",
  SCOUTER = "SCOUTER",
  GENERAL_SUBSCRIBER = "GENERAL_SUBSCRIBER",
}

export enum PaymentStatus {
  PENDING = "PENDING",
  SUCCESS = "SUCCESS",
  FAILED = "FAILED",
}

export enum VerificationStatus {
  PENDING = "PENDING",
  ACCEPTED = "ACCEPTED",
  REJECTED = "REJECTED",
}

const initialState: USER_STATE = {
  USER: {
    id: "-1",
    admin: -1,
    token: "",
    loader: false,
    profileImage: "",
    userType: PROFILE_TYPES.GENERAL_SUBSCRIBER,
    productCount: 0,
    opportunityCount: 0,
    user: {
      name: "",
      email: "",
      phone: -1,
      country: "",
      ref: "",
      pwd: "",
      emailVerified: false,
      coverImg: "",
      rejected: false,
      userVerified: false,
      profileImg: "",
    },
    company: [],
  },
};

export const userreducer = (
  state: USER_STATE = initialState,
  action: USER_ACTION
): USER_STATE => {
  switch (action.type) {
    case actionTypes.SET_USER:
      const newuser: USER = {
        ...action.USER,
        id: action.USER.id,
        admin: action.USER.admin,
        token: action.USER.token,
        loader: false,
        user: {
          name: action.USER.user.name,
          email: action.USER.user.email,
          phone: action.USER.user.phone,
          country: action.USER.user.country,
          ref: action.USER.user.ref,
          pwd: action.USER.user.pwd,
          emailVerified: action.USER.user.emailVerified,
          coverImg: action.USER.user.coverImg,
          rejected: action.USER.user.rejected,
          userVerified: action.USER.user.userVerified,
          profileImg: action.USER.user.profileImg,
        },
        company: action.USER.company ? action.USER.company : [],
      };
      return {
        ...state,
        USER: newuser,
      };
    case actionTypes.RESET_USER:
      const currentuser: USER = {
        id: "-1",
        admin: -1,
        token: "",
        loader: false,
        profileImage: "",
        userType: PROFILE_TYPES.GENERAL_SUBSCRIBER,
        productCount: 0,
        opportunityCount: 0,
        user: {
          name: "",
          email: "",
          phone: -1,
          country: "",
          ref: "",
          pwd: "",
          emailVerified: false,
          coverImg: "",
          rejected: false,
          userVerified: false,
          profileImg: "",
        },
        company: [],
      };
      return {
        ...state,
        USER: currentuser,
      };
    default:
      return { ...state };
  }
};
