import axios from "axios";
import { ConnectionStatus, STORAGE_KEY } from "../../Components/constants";
import { store } from "../../store";
import {
  CONNECTION_LIST,
  CONNECT_USER,
  UNCONNECT_USER,
} from "../actiontypes/actionTypes";
import { notify } from "../../utils";

export const getConnections =
  (dropdownConnectionStatus?: string) => async (dispatch: any) => {
    if (!dropdownConnectionStatus)
      dropdownConnectionStatus = ConnectionStatus.CONNECTED;

    const token = localStorage.getItem(STORAGE_KEY)?.split(" ")[0] ?? "";

    var config = {
      method: "get",
      url: `${process.env.REACT_APP_BASE_API}/users/getconnections?connectionStatus=${dropdownConnectionStatus}`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };

    axios(config)
      .then(function (response) {
        if (response.data) {
          const list: CONNECTION = {
            CONNECTION_LIST: response.data,
          };

          const action1: CONNECTION_ACTION = {
            type: CONNECTION_LIST,
            CONNECTION: {
              CONNECTION: list,
            },
          };
          const action = async (dispatch: any) => {
            dispatch(action1);
          };

          return dispatch(action);
        } else {
          const list: CONNECTION = {
            CONNECTION_LIST: {
              connections: [],
              connectionsCount: 0,
            },
          };

          const action1: CONNECTION_ACTION = {
            type: CONNECTION_LIST,
            CONNECTION: {
              CONNECTION: list,
            },
          };

          const action = async (dispatch: any) => {
            dispatch(action1);
          };

          return dispatch(action);
        }
      })
      .catch(function (error) {
        // console.log("Unable to connect", error);
      });
  };
export const sendConnection = (id: string) => async (dispatch: any) => {
  var config = {
    method: "patch",
    url: `${process.env.REACT_APP_BASE_API}/users/sendconnectionrequest/${id}`,
    headers: {
      Authorization: `Bearer ${store.getState().USER.USER.token}`,
      "Content-Type": "application/json",
    },
  };

  await axios(config)
    .then(function (response) {
      const list: CONNECTION = {
        CONNECTION_LIST: {
          connections: [],
          connectionsCount: 0,
        },
      };
      const action1: CONNECTION_ACTION = {
        type: CONNECT_USER,
        CONNECTION: {
          CONNECTION: list,
        },
      };
      const action = async (dispatch: any) => {
        dispatch(action1);
      };
      notify("Sent connection request!", "success");
      return dispatch(action);
    })
    .catch(function (error) {
      notify("Failed to send connection request!", "error");
      // console.log("Unable to connect", error);
    });
};
export const acceptConnection = (id: string) => async (dispatch: any) => {
  var config = {
    method: "patch",
    url: `${process.env.REACT_APP_BASE_API}/users/acceptconnectionrequest/${id}`,
    headers: {
      Authorization: `Bearer ${store.getState().USER.USER.token}`,
      "Content-Type": "application/json",
    },
  };

  await axios(config)
    .then(function (response) {
      // console.log("request accepted");

      const list: CONNECTION = {
        CONNECTION_LIST: {
          connections: [],
          connectionsCount: 0,
        },
      };
      const action1: CONNECTION_ACTION = {
        type: UNCONNECT_USER,
        CONNECTION: {
          CONNECTION: list,
        },
      };
      const action = async (dispatch: any) => {
        dispatch(action1);
      };

      notify("Connection accepted!", "success");
      return dispatch(action);
    })
    .catch(function (error) {
      notify("Failed to accept!", "error");
      // console.log("Unable to accept", error);
    });
};
// export const acceptConnection = (id: string) => async (dispatch: any) => {
//   var config = {
//     method: "patch",
//     url: `${process.env.REACT_APP_BASE_API}/users/acceptconnectionrequest/${id}`,
//     headers: {
//       Authorization: `Bearer ${store.getState().USER.USER.token}`,
//       "Content-Type": "application/json",
//     },
//   };

//   await axios(config)
//     .then(function (response) {
//       console.log("request accepted");

//       const list: CONNECTION = [];
//       const action1: CONNECTION_ACTION = {
//         type: UNCONNECT_USER,
//         CONNECTION: {
//           CONNECTION_LIST: list,
//         },
//       };
//       const action = async (dispatch: any) => {
//         dispatch(action1);
//       };

//       return dispatch(action);
//     })
//     .catch(function (error) {
//       console.log("Unable to accept", error);
//     });
// };
