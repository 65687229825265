import * as ACTION from "../actiontypes/actionTypes";

const initialState: SUB_SECTOR_STATE = {
  SUB_SECTOR: {
    SUB_SECTOR_LIST: [],
  },
};

export const subsectorReducer = (
  state: SUB_SECTOR_STATE = initialState,
  action: SUB_SECTOR_ACTION
): SUB_SECTOR_STATE => {
  switch (action.type) {
    case ACTION.SUB_SECTOR_GET:
      let sub_sectors: SUB_SECTOR = action.SUB_SECTOR;
      return { ...state, SUB_SECTOR: sub_sectors };
    case ACTION.SUB_SECTOR_GET_ID:
      let sub_sector: SUB_SECTOR = action.SUB_SECTOR;
      return { ...state, SUB_SECTOR: sub_sector };
    default:
      return { ...state };
  }
  // return state;
};
