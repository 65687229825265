import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { Helmet } from "react-helmet";

import { publicationFetched } from "../constants";
import articlebanner from "../../assests/banners/articlebanner.png";
import PdfDownloader from "../../shared/PdfDownloader";

const Publication = ({
  handleLoginModal,
}: {
  handleLoginModal: () => void;
}) => {
  let [publication, setPublications] = useState<publicationFetched>({
    _id: "",
    topic: "",
    imageUrl: "",
    description: "",
    documentUrl: "",
    displayOnHomePage: false,
    isDeleted: false,
    createdAt: "",
    updatedAt: "",
  });
  const { id } = useParams();

  const loadPublication = (id: string) => {
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_BASE_API}/publications/${id}`,
      headers: {
        "Content-Type": "application/json",
      },
    };

    axios(config)
      .then(function (response) {
        setPublications(response.data);
      })
      .catch(function (error) {});
  };

  useEffect(() => {
    loadPublication(id ?? "");
  }, [id]);
  const DOC = new Date(publication.createdAt);

  return (
    <React.Fragment>
      <div
        className="flex flex-col w-full items-center bg-slate-100"
        style={{ msOverflowStyle: "none", scrollbarWidth: "none" }}
      >
        <Helmet>
          <title>{publication.topic}</title>
          <meta
            name="description"
            key="description"
            content={publication.description}
          />
          <meta name="title" key="title" content={publication?.topic} />
          <meta property="og:title" content={publication.topic} />
          <meta property="og:description" content={publication.description} />
          <meta
            property="og:image"
            content={
              publication.imageUrl ? publication.imageUrl : articlebanner
            }
          />
          <meta
            property="og:url"
            content={`${process.env.REACT_APP_BASE_URL}/publications/${publication._id}`}
          />
          <meta property="og:type" content="website" />
          <meta name="twitter:title" content={publication?.topic} />
          <meta name="twitter:description" content={publication?.description} />
          <meta
            name="twitter:image"
            content={
              publication.imageUrl ? publication.imageUrl : articlebanner
            }
          />
          <meta name="twitter:card" content={publication?.topic} />
        </Helmet>
        <div className="flex flex-col w-4/5 space-y-5 mb-10 bg-white rounded-2xl p-6 mt-6 border-2 ">
          <h1 className="text-xl md:text-3xl w-full md:pb-6 font-roboto text-center font-bold">
            {publication.topic?.replace(/(<([^>]+)>)/gi, "")}
          </h1>
          <div className="flex flex-col w-full h-fit rounded-2xl justify-items-start">
            {publication.imageUrl ? (
              <img
                src={
                  publication.imageUrl ? publication.imageUrl : articlebanner
                }
                className="flex w-full h-full md:h-96 rounded object-contain"
                alt={publication.topic}
                loading="lazy"
              />
            ) : null}
          </div>
          <div className="flex w-full flex-row-end text-gray-500 text-xs">
            <h4>
              {DOC.toLocaleString("default", {
                month: "short",
                day: "2-digit",
                year: "numeric",
              })}
            </h4>
          </div>
          <div
            className="text-sm md:text-lg"
            dangerouslySetInnerHTML={{ __html: publication.description }}
          ></div>
          <div className="w-full flex justify-center">
            <PdfDownloader
              url={publication?.documentUrl}
              className="button active flex gap-2 items-center justify-center"
            >
              Download Document
            </PdfDownloader>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Publication;
