import axios from "axios";
import { store } from "../../store";
import {
  TOAST_CLOSE,
  TOAST_FAIL,
  TOAST_SUCCESS,
} from "../actiontypes/actionTypes";

export const successToast = () => (dispatch: TOAST_DISPATCH) => {
  const toast: TOAST_ACTION = {
    type: TOAST_SUCCESS,
  };
  return dispatch(toast);
};
export const failToast = () => (dispatch: TOAST_DISPATCH) => {
  const toast: TOAST_ACTION = {
    type: TOAST_FAIL,
  };
  return dispatch(toast);
};
export const closeToast = () => (dispatch: TOAST_DISPATCH) => {
  const toast: TOAST_ACTION = {
    type: TOAST_CLOSE,
  };
  return dispatch(toast);
};
