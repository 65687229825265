import React from "react";
import { useNavigate } from "react-router-dom";

import error from "../../assests/error.gif";

const PromotionsPaymentFailure = ({
  handleLoginModal,
}: {
  handleLoginModal: () => void;
}) => {
  let navigate = useNavigate();

  return (
    <div className="flex flex-col mt-10 relative items-center h-screen w-full p-5">
      <img src={error} alt="Payment Failed" className="h-48" />
      <h2 className="text-xl md:text-2xl mb-4">Feature Created</h2>
      <p className="text-gray-600 mb-8 text-xs md:text-lg text-center">
        Payment Failed. Please Retry again.
      </p>
      <button
        data-modal-toggle="error-modal"
        onClick={() => {
          navigate("/");
        }}
        type="button"
        className="text-white w-fit bg-GTI-BLUE-default hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center mr-2"
      >
        Home
      </button>
    </div>
  );
};

export default PromotionsPaymentFailure;
