import axios from "axios";
import { store } from "../../store";
import * as ARTICLE from "../../Components/constants";
import {
  ARTICLE_GET,
  ARTICLE_GET_ID,
  TOAST_FAIL,
  TOAST_SUCCESS,
} from "../actiontypes/actionTypes";

export const getArticles =
  (secId: string, skip: string, limit: string) =>
  (dispatch: GET_ARTICLE_DISPATCH) => {
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_BASE_API}/article?sectorId=${secId}&skip=${skip}&limit=${limit}`,
      headers: {
        Authorization: `Bearer ${store.getState().USER.USER.token}`,
        "Content-Type": "application/json",
      },
    };

    axios(config)
      .then(function (response) {
        const list: ARTICLE = {
          ...response.data,
        };
        const action: ARTICLE_ACTION = {
          type: ARTICLE_GET,
          ARTICLE: list,
        };

        return dispatch(action);
      })
      .catch(function (error) {});
  };

export const getArticleId =
  (id: string) => (dispatch: GET_ARTICLE_ID_DISPATCH) => {
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_BASE_API}/article/${id}`,
      headers: {
        Authorization: `Bearer ${store.getState().USER.USER.token}`,
        "Content-Type": "application/json",
      },
    };

    axios(config)
      .then(function (response) {
        const list: ARTICLE = {
          ...response.data,
        };
        const action: ARTICLE_ACTION = {
          type: ARTICLE_GET_ID,
          ARTICLE: list,
        };
        // console.log("Article id fetched ");
        return dispatch(action);
      })
      .catch(function (error) {
        // console.log("Article id not fetched ", error);
      });
  };
