import * as ACTION from "../actiontypes/actionTypes";

const initialState: any = {
  news: [],
  newsCount: 0,
};

export const newsreducer = (
  state: NEWS_STATE = initialState,
  action: NEWS_ACTION
): NEWS_STATE => {
  switch (action.type) {
    case ACTION.NEWS_GET:
      return { ...state, ...action.NEWS };
    case ACTION.NEWS_GET_ID:
      return { ...state, ...action.NEWS };
    default:
      return { ...state };
  }
  // return state;
};
