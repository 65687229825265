import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import Slider from "react-slick";
import { useSelector } from "react-redux";
import { BiLinkExternal } from "react-icons/bi";
import { Helmet } from "react-helmet";

import { NONE, productItemFullFetched } from "../constants";
import productbanner from "../../assests/banners/product_banner_alt.png";
import RenderHTML from "../utils/RenderHTML";
import PdfDownloader from "../../shared/PdfDownloader";
import { notify } from "../../utils";
import { PROFILE_TYPES } from "../../shared/enum";

const YourProduct = ({
  handleLoginModal,
}: {
  handleLoginModal: () => void;
}) => {
  const navigate = useNavigate();
  let [product, setProduct] = useState<productItemFullFetched>({
    _id: "",
    name: "",
    description: "",
    image: "",
    images: [],
    video: "",
    videos: [],
    documents: [],
    displayOnHomePage: false,
    isApprovedBySubAdmin: false,
    isApprovedByAdmin: false,
    isRejected: false,
    document: "",
    sectorId: "",
    subSectorId: "",
    userId: "",
    createdAt: "",
    developmentStage: "",
    iprStatus: [],
    __v: -1,
    users: {
      _id: "",
      fullName: "",
      email: "",
      phoneNumber: "",
      countryCode: "",
      referenceCode: "",
      isEmailVerified: false,
      isUserVerified: false,
      isRejected: false,
      password: "",
      userRole: -1,
      companyId: "",
      follower: [],
      following: [],
      connections: [
        {
          connectionStatus: "",
          userId: "",
        },
      ],
      createdAt: "",
      __v: -1,
    },
    company: {
      _id: "",
      name: "",
      logo: "",
      description: "",
      address: "",
      website: "",
      country: "",
      companyTurnover: -1,
      companyId: "",
      typeAndSizeOfPartnersRequired: [],
      typesOfPartnershipConsidered: [],
      createdAt: "",
      __v: -1,
    },
    sectors: {
      _id: "",
      name: "",
      slug: "",
      image: "",
      createdAt: "",
      __v: -1,
    },
    subsectors: {
      _id: "",
      name: "",
      slug: "",
      sectorId: "",
      createdAt: "",
      __v: -1,
    },
  });

  const sidebar_carousal_settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    swipeToSlide: true,
    autoplay: false,
  };

  const user: USER = useSelector((state: STATE) => state.USER.USER);
  useEffect(() => {
    if (user.id && user.userType === PROFILE_TYPES.GENERAL_SUBSCRIBER) {
      notify("Unauthorized", "error");
      navigate("/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const loadProduct = (id: string) => {
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_BASE_API}/products/${id}`,
      headers: {
        "Content-Type": "application/json",
      },
    };

    axios(config)
      .then(function (response) {
        setProduct(response.data);
      })
      .catch(function (error) {});
  };

  const { state } = useLocation();
  useEffect(() => {
    loadProduct(state.product._id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const DOC = new Date(product.createdAt);

  return (
    <React.Fragment>
      <div className="flex flex-col p-10 w-full">
        <Helmet>
          <title>{product.name}</title>
          <meta
            name="description"
            key="description"
            content={product.description}
          />
          <meta name="title" key="title" content={product?.name} />
          <meta property="og:title" content={product.name} />
          <meta property="og:description" content={product.description} />
          <meta
            property="og:image"
            content={
              product?.images?.length > 0 ? product?.images[0] : product?.image
            }
          />
          <meta
            property="og:url"
            content={`${process.env.REACT_APP_BASE_URL}/your-product`}
          />
          <meta property="og:type" content="website" />
          <meta name="twitter:title" content={product?.name} />
          <meta name="twitter:description" content={product?.description} />
          <meta
            name="twitter:image"
            content={
              product?.images?.length > 0 ? product?.images[0] : product?.image
            }
          />
          <meta name="twitter:card" content={product?.name} />
        </Helmet>
        <div className="flex flex-col md:flex-row w-full justify-between space-x-10 ">
          <div className="w-full md:w-3/5 flex flex-col px-10 m-5 space-y-5">
            <p className="font-roboto text-3xl font-bold">{product.name}</p>
            <div className="flex flex-row">
              <p className="font-roboto text-sm font-bold">Sector -</p>
              <p className="font-roboto text-sm px-2 text-gray-500">
                {product.sectors.name}
              </p>
            </div>
            <div className="flex flex-row">
              <p className="font-roboto text-sm font-bold">Sub Sector -</p>
              <p className="font-roboto text-sm px-2 text-gray-500">
                {product.subsectors.name}
              </p>
            </div>
            <div className="flex flex-row">
              <p className="font-roboto text-sm font-bold">
                Development Stage -
              </p>
              <p className="font-roboto text-sm px-2 text-gray-500">
                {product.developmentStage}
              </p>
            </div>
            <p className="font-roboto text-sm">
              <RenderHTML html={product.description} />
            </p>
          </div>

          <div className="w-full md:w-2/5 flex flex-col px-10 m-5 space-y-5">
            <Slider
              {...sidebar_carousal_settings}
              className="flex flex-row w-full md:max-h-[330px] text-black border-4 p-2"
            >
              {product?.images?.length > 0 ? (
                product.images.map((image) => (
                  <img
                    className="w-full h-full object-contain max-h-[250px] "
                    src={image}
                    alt={product.name}
                  />
                ))
              ) : (
                <img
                  className="w-full h-full object-contain max-h-[250px] "
                  src={product.image === NONE ? productbanner : product.image}
                  alt={product.name}
                />
              )}
              {product?.video ? (
                <video controls>
                  <source src={product.video} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              ) : null}
              {product?.videos?.length
                ? product?.videos.map((video) => (
                    <video controls>
                      <source src={video} type="video/mp4" />
                      Your browser does not support the video tag.
                    </video>
                  ))
                : null}
            </Slider>
            <p className="text-gray-400 text-xs font-roboto">
              Posted on:&nbsp;
              {DOC.toLocaleString("default", {
                month: "long",
                day: "2-digit",
                year: "numeric",
              })}
            </p>

            {!!product?.document ? (
              <div className="flex flex-row gap-4 items-center">
                <PdfDownloader
                  className="flex flex-row items-center gap-2 font-roboto text-sm justify-center"
                  url={product?.document}
                >
                  <p className="font-roboto text-sm ">Document</p>
                  <BiLinkExternal />
                </PdfDownloader>
              </div>
            ) : null}
            {product?.documents?.length ? (
              <div className="flex flex-row gap-4 items-center">
                {product.documents?.map((document) => (
                  <PdfDownloader
                    className="flex flex-row items-center gap-2 font-roboto text-sm justify-center"
                    url={document}
                  >
                    <p className="font-roboto text-sm ">Document</p>
                    <BiLinkExternal />
                  </PdfDownloader>
                ))}
              </div>
            ) : null}

            <div className="flex flex-col gap-2">
              <p className="font-roboto text-sm whitespace-nowrap">
                IPR Status
              </p>
              <div className="flex flex-wrap gap-2">
                {product?.iprStatus?.map((status, index) => (
                  <button
                    key={index}
                    className="bg-gray-300  text-black py-2 px-2 rounded inline-flex text-xs"
                  >
                    {status}
                  </button>
                ))}
              </div>
            </div>
          </div>
        </div>
        <hr />
        <div className="flex flex-col md:flex-row w-full space-x-8 py-10">
          <div className="w-3/5 flex flex-col px-10 space-y-5">
            <img
              className="w-full h-3/5 object-contain shadow-xl"
              src={
                !product.company?.logo ? productbanner : product.company.logo
              }
              alt={product.company?.description}
            />

            <span className="font-roboto text-lg text-black-500 font-bold text-justify">
              {product.company?.name}
              <span className="font-roboto text-lg text-black-500">
                ({product.company?.country})
              </span>
            </span>
          </div>
          <p className="font-roboto text-md text-gray-500">
            {product.company?.description}
          </p>
        </div>
      </div>
    </React.Fragment>
  );
};
export default YourProduct;
