import * as ACTION from "../actiontypes/actionTypes";

const initialState: PARTNERS_STATE = {
  PARTNERS: {
    PARTNERS_LIST: [],
  },
};

export const partnerreducer = (
  state: PARTNERS_STATE = initialState,
  action: PARTNERS_ACTION
): PARTNERS_STATE => {
  switch (action.type) {
    case ACTION.PARTNERS_GET:
      let list: PARTNERS = action.PARTNERS;
      return { ...state, PARTNERS: list };
    default:
      return { ...state };
  }
  // return state;
};
