import * as ACTION from "../actiontypes/actionTypes";

const initialState: BLOG_STATE = {
  BLOG: {
    BLOG_LIST: {
      blogs: [],
      blogsCount: 0,
    },
  },
};

export const blogreducer = (
  state: BLOG_STATE = initialState,
  action: BLOG_ACTION
): BLOG_STATE => {
  switch (action.type) {
    case ACTION.BLOG_GET:
      let blogs: BLOG = action.BLOG;
      return { ...state, BLOG: blogs };
    case ACTION.BLOG_GET_ID:
      let blog: BLOG = action.BLOG;
      return { ...state, BLOG: blog };

    default:
      return { ...state };
  }
  // return state;
};
