import React, { Dispatch, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { newsFetched } from "../constants";
import { useNavigate } from "react-router-dom";
import { ScreenSpinner } from "../utils/loader";
import { getQueryParams } from "../../utils";
import { getNews } from "../../store/actioncreators/newsAction";

const Card = ({ item }: { item: newsFetched }) => {
  const DOC = new Date(item.createdAt);
  const navigate = useNavigate();
  const handleView = () => {
    navigate(`/news/${item._id}`, { state: { id: item._id } });
  };
  return (
    <div
      className="w-full max-w-[350px] cursor-pointer overflow-hidden rounded-lg bg-white shadow h-full max-h-[330px]"
      onClick={handleView}
    >
      {item.imageUrl ? (
        <img
          src={item?.imageUrl ?? ""}
          className="aspect-video w-full object-contain"
          alt={item.title}
        />
      ) : null}
      <div className="p-2">
        <p className="mb-1 text-xs text-gray-500">
          <time>
            {DOC.toLocaleString("default", {
              month: "short",
              day: "2-digit",
              year: "numeric",
            })}
          </time>
        </p>
        <h3 className="text-base font-medium text-gray-900 truncate">
          {item.title}
        </h3>
        <div
          className="mt-1 text-gray-500 line-clamp-1 md:line-clamp-none"
          dangerouslySetInnerHTML={{
            __html: item.shortDescription?.split("<br>")?.join(""),
          }}
        ></div>
      </div>
    </div>
  );
};

const NewsList = () => {
  const dispatch: Dispatch<any> = useDispatch();
  const { news, newsCount }: any = useSelector((state: STATE) => state.NEWS);
  const spinner: LOADER = useSelector((state: STATE) => state.LOADER.LOADER);

  const navigate = useNavigate();
  const skip = getQueryParams("skip") ?? "0";

  const [page, setPage] = useState({
    skip: skip ? skip : "0",
    limit: "9",
  });
  const [maxSkip, setMaxSkip] = useState(0);

  useEffect(() => {
    setMaxSkip(Math.ceil(newsCount / 9));
  }, [page, newsCount]);

  useEffect(() => {
    dispatch(getNews(page.skip, page.limit));
  }, [page]);

  const fetchData = (val: number) => {
    let newSkip = parseInt(page.skip) + val;
    if (newSkip >= 0) {
      setPage({
        skip: newSkip + "",
        limit: page.limit,
      });
      navigate(`/news?skip=${newSkip}`);
      window.scrollTo(0, 0);
    }
  };

  return (
    <div className="flex flex-col w-full items-center justify-center">
      {spinner.SPINNER ? (
        <ScreenSpinner />
      ) : (
        <div className="w-full flex flex-row flex-wrap gap-10 p-4 items-center justify-center">
          {news.map((item: newsFetched, id: number) => {
            return (
              <>
                <Card item={item} key={id} />
              </>
            );
          })}
        </div>
      )}
      <div className="flex justify-center mb-5 py-5">
        <div className="flex mb-5 py-5">
          <button
            disabled={page.skip === "0"}
            onClick={() => {
              fetchData(-1);
            }}
            className="inline-flex items-center py-2 px-4 text-sm font-medium text-GTI-BLUE-default disabled:text-gray-500 bg-white  rounded-lg border border-gray-300 hover:bg-gray-100 hover:text-gray-700 "
          >
            <svg
              aria-hidden="true"
              className="mr-2 w-5 h-5"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M7.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l2.293 2.293a1 1 0 010 1.414z"
                clipRule="evenodd"
              ></path>
            </svg>
            Previous
          </button>
          {parseInt(page.skip) + 1 <= maxSkip && (
            <button
              onClick={() => {
                fetchData(0);
              }}
              className="inline-flex items-center mx-2 py-2 px-4 text-sm font-medium text-gray-500 bg-white rounded-lg border border-gray-300 hover:bg-gray-100 hover:text-gray-700 "
            >
              {parseInt(page.skip) + 1}
            </button>
          )}
          {parseInt(page.skip) + 2 <= maxSkip && (
            <button
              onClick={() => {
                fetchData(1);
              }}
              className="inline-flex items-center mx-2 py-2 px-4 text-sm font-medium text-gray-500 bg-white rounded-lg border border-gray-300 hover:bg-gray-100 hover:text-gray-700 "
            >
              {parseInt(page.skip) + 2}
            </button>
          )}
          {parseInt(page.skip) + 3 <= maxSkip && (
            <button
              onClick={() => {
                fetchData(2);
              }}
              className="inline-flex items-center mx-2 py-2 px-4 text-sm font-medium text-gray-500 bg-white rounded-lg border border-gray-300 hover:bg-gray-100 hover:text-gray-700 "
            >
              {parseInt(page.skip) + 3}
            </button>
          )}
          {parseInt(page.skip) + 4 <= maxSkip && (
            <button
              disabled
              className="inline-flex items-center mx-2 py-2 px-4 text-sm font-medium text-gray-500 bg-white rounded-lg border border-gray-300 hover:bg-gray-100 hover:text-gray-700 "
            >
              -
            </button>
          )}
          {parseInt(page.skip) + 6 <= maxSkip && (
            <button
              onClick={() => {
                fetchData(maxSkip - parseInt(page.skip) - 3);
              }}
              className="inline-flex items-center mx-2 py-2 px-4 text-sm font-medium text-gray-500 bg-white rounded-lg border border-gray-300 hover:bg-gray-100 hover:text-gray-700 "
            >
              {maxSkip - 2}
            </button>
          )}
          {parseInt(page.skip) + 5 <= maxSkip && (
            <button
              onClick={() => {
                fetchData(maxSkip - parseInt(page.skip) - 2);
              }}
              className="inline-flex items-center mx-2 py-2 px-4 text-sm font-medium text-gray-500 bg-white rounded-lg border border-gray-300 hover:bg-gray-100 hover:text-gray-700 "
            >
              {maxSkip - 1}
            </button>
          )}
          {parseInt(page.skip) + 4 <= maxSkip && (
            <button
              onClick={() => {
                fetchData(maxSkip - parseInt(page.skip) - 1);
              }}
              className="inline-flex items-center mx-2 py-2 px-4 text-sm font-medium text-gray-500 bg-white rounded-lg border border-gray-300 hover:bg-gray-100 hover:text-gray-700 "
            >
              {maxSkip}
            </button>
          )}
          <button
            disabled={
              parseInt(page.limit) * (parseInt(page.skip) + 1) >= newsCount
            }
            onClick={() => {
              fetchData(1);
            }}
            className="inline-flex items-center py-2 px-4 text-sm font-medium text-GTI-BLUE-default disabled:text-gray-500 bg-white rounded-lg border border-gray-300 hover:bg-gray-100 hover:text-gray-700 "
          >
            Next
            <svg
              aria-hidden="true"
              className="ml-2 w-5 h-5"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z"
                clipRule="evenodd"
              ></path>
            </svg>
          </button>
        </div>
      </div>
    </div>
  );
};

export default NewsList;
