import React from "react";
import { useNavigate } from "react-router-dom";

import success from "../../assests/success.gif";

const EventPaymentSuccess = ({
  handleLoginModal,
}: {
  handleLoginModal: () => void;
}) => {
  let navigate = useNavigate();

  return (
    <div className="flex flex-col mt-10 relative items-center h-screen w-full p-5">
      <img src={success} alt="Payment Success" className="h-48" />
      <h2 className="text-xl md:text-2xl mb-4">Event Registered</h2>
      <p className="text-gray-600 mb-8 text-xs md:text-lg text-center">
        Payment successful. Thank you!
        <br />
        You've been successfully registered for the event
      </p>
      <button
        data-modal-toggle="error-modal"
        onClick={() => {
          navigate("/events");
        }}
        type="button"
        className="text-white w-fit bg-GTI-BLUE-default hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center mr-2"
      >
        View Event
      </button>
    </div>
  );
};

export default EventPaymentSuccess;
