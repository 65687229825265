import * as actionTypes from "../actiontypes/actionTypes";

export function setUser(user: USER) {
  const action: USER_ACTION = {
    type: actionTypes.SET_USER,
    USER: user,
  };

  return Dispatcher(action);
}

export function resetUser(user: USER) {
  const action: UserDelAction = {
    type: actionTypes.RESET_USER,
  };
  return (dispatch: DispatchType2) => {
    dispatch(action);
  };
}

export function Dispatcher(action: USER_ACTION) {
  return (dispatch: DispatchType) => {
    dispatch(action);
  };
}
