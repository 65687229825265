import { Dispatch, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Helmet } from "react-helmet";

import {
  InnovationItemFetched,
  INNOVATION_FULL_CONTENT_OPTIONAL,
  INNOVATION_FULL_CONTENT_OUTCOME,
  INNOVATION_FULL_CONTENT_POSSIBLE,
  INNOVATION_FULL_CONTENT_PREVIOUS,
  INNOVATION_FULL_CONTENT_STEP,
  INNOVATION_PARTIAL_CONTENT_BOTTOM,
  INNOVATION_PARTIAL_CONTENT_TOP,
  INNOVATION,
  title,
  metaData,
  LIMIT,
} from "../constants";
import { getCalls } from "../../store/actioncreators/innovationactions";
import { ScreenSpinner } from "../utils/loader";
import { getQueryParams } from "../../utils";
import globe from "../../assests/home/globe.png";
import "./style.css";

const Card = ({ item }: { item: InnovationItemFetched }) => {
  const DOS = new Date(item.endDate);
  const navigate = useNavigate();
  const handleView = () => {
    navigate(`/innovation-call-view/${item._id}`, { state: { id: item._id } });
  };
  return (
    <div
      className="h-full w-full bg-white border border-gray-200 rounded-lg shadow flex flex-col md:flex-row items-center md:items-start cursor-pointer p-5 gap-5"
      onClick={handleView}
    >
      <a
        href={`/innovation-call-view/${item._id}`}
        className="h-[150px] w-[150px] flex items-center justify-center"
      >
        <img
          className="rounded-md w-full max-h-[150px] max-w-[150px] object-contain"
          src={item.companyLogo}
          alt={item.title}
        />
      </a>
      <div className="h-full flex flex-col items-start justify-start md:max-w-[80%] w-full">
        <a href={`/innovation-call-view/${item._id}`}>
          <h5 className="text-lg md:text-xl font-bold tracking-tight text-gray-900">
            {item.title}
          </h5>
        </a>

        <p className=" text-gray-600 text-sm">{item.companyName}</p>

        <div className="justify-self-end mt-auto">
          <p className="mb-3 font-normal text-gray-700 ">
            Deadline:&nbsp;
            <span className="font-normal text-GTI-BLUE-default">
              {DOS.toLocaleString("default", {
                month: "short",
                day: "2-digit",
                year: "numeric",
              })}
            </span>
          </p>

          <div className="w-full flex gap-2">
            <div
              className={`inline-flex items-center px-3 py-2 text-sm font-medium text-center rounded-lg ${
                DOS.getTime() < new Date().getTime()
                  ? "bg-red-200 text-red-700"
                  : "bg-green-200 text-green-700"
              }`}
            >
              {DOS.getTime() < new Date().getTime() ? "Closed" : "Active"}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const Innovation = () => {
  const dispatch: Dispatch<any> = useDispatch();
  const spinner: LOADER = useSelector((state: STATE) => state.LOADER.LOADER);
  const skip = getQueryParams("skip");
  const navigator = useNavigate();
  const [more, setMore] = useState(false);
  const [page, setPage] = useState({
    skip: skip ? skip : "0",
    limit: LIMIT,
  });
  const [maxSkip, setMaxSkip] = useState(0);

  const innovation: INNOVATION = useSelector(
    (state: STATE) => state.INNOVATION.INNOVATION
  );

  useEffect(() => {
    setMaxSkip(Math.ceil(innovation.INNOVATION_COUNT / 9));
  }, [page, innovation]);

  useEffect(() => {
    setPage({
      skip: skip ? skip : "0",
      limit: LIMIT,
    });
    dispatch(getCalls(skip ?? "0", LIMIT));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [skip]);

  const fetchData = (value: number) => {
    let final =
      parseInt(page.skip) + value < 0
        ? parseInt(page.skip)
        : parseInt(page.skip) + value;
    navigator(INNOVATION + `?skip=${final}`);
  };

  return (
    <div className="innovation-main">
      <Helmet>
        <title>{title.INNOVATION_CALLS}</title>
        <meta
          name="description"
          key="description"
          content={metaData.INNOVATION_CALLS}
        />
        <meta name="title" key="title" content="Open Innovation Platform" />
        <meta property="og:title" content="Open Innovation Platform" />
        <meta
          property="og:description"
          content={`${INNOVATION_PARTIAL_CONTENT_TOP}`}
        />
        <meta property="og:image" content={globe} />
        <meta
          property="og:url"
          content={`${process.env.REACT_APP_BASE_URL}/innovation`}
        />
        <meta property="og:type" content="website" />
        <meta name="twitter:title" content="Open Innovation Platform" />
        <meta name="twitter:description" content={metaData.INNOVATION_CALLS} />
        <meta name="twitter:image" content={globe} />
        <meta name="twitter:card" content="Open Innovation Platform" />
      </Helmet>
      <div className="innovation-div flex-col items-center ">
        <div className="innovation-div justify-center flex-col items-center rounded-lg max-w-[1000px]">
          <div className="innovation-div flex-row justify-center items-center py-5 ">
            <h3 className="font-roboto text-xl md:text-4xl">
              Open Innovation Platform
            </h3>
          </div>
          <div className="innovation-div mb-10 duration-200 ease-in-out flex-col w-full  mx-auto  space-y-5 ">
            {!more && (
              <p className="hidden md:block text-justify text-GTI-BLUE-default font-roboto text-sm">
                {INNOVATION_PARTIAL_CONTENT_TOP}
              </p>
            )}
            {!more && (
              <p className="hidden md:block text-justify text-GTI-BLUE-default font-roboto text-sm">
                {INNOVATION_PARTIAL_CONTENT_BOTTOM}
              </p>
            )}
            {more && (
              <ul className="hidden md:flex flex-col w-full duration-200 ease-in-out px-36 ">
                <li className="whitespace-pre -translate-x-10">{"Steps:"}</li>
                <li className="whitespace-pre-wrap text-GTI-BLUE-default font-roboto text-sm">
                  {INNOVATION_FULL_CONTENT_STEP}
                </li>
                <li className="whitespace-pre -translate-x-10">
                  {"Optional services:"}
                </li>
                <li className="whitespace-pre-wrap text-GTI-BLUE-default font-roboto text-sm">
                  {INNOVATION_FULL_CONTENT_OPTIONAL}
                </li>
                <li className="whitespace-pre -translate-x-10">
                  {"Previous Innovation Calls:"}
                </li>
                <li className="whitespace-pre-wrap text-GTI-BLUE-default font-roboto text-sm">
                  {INNOVATION_FULL_CONTENT_PREVIOUS}
                </li>
                <li className="whitespace-pre -translate-x-10">
                  {"Possible forms of collaboration:"}
                </li>
                <li className="whitespace-pre-wrap text-GTI-BLUE-default font-roboto text-sm">
                  {INNOVATION_FULL_CONTENT_POSSIBLE}
                </li>
                <li className="whitespace-pre -translate-x-10">
                  {"Outcomes:"}
                </li>
                <li className="whitespace-pre-wrap text-GTI-BLUE-default font-roboto text-sm">
                  {INNOVATION_FULL_CONTENT_OUTCOME}
                </li>
                <li className="flex justify-center">
                  {more && (
                    <button
                      onClick={() => {
                        setMore(!more);
                      }}
                      className="innovation-button innovation-active"
                    >
                      Show Less
                    </button>
                  )}
                </li>
              </ul>
            )}
            {!more && (
              <button
                onClick={() => {
                  setMore(!more);
                }}
                className="hidden md:flex innovation-button innovation-active"
              >
                Show More
              </button>
            )}
          </div>
        </div>
        <div className="innovation-div flex-wrap justify-center gap-4">
          {spinner.SPINNER ? (
            <ScreenSpinner />
          ) : (
            innovation.INNOVATION_LIST &&
            innovation.INNOVATION_LIST.map(
              (item: InnovationItemFetched, id: number) => {
                return <Card item={item} key={id} />;
              }
            )
          )}
        </div>
      </div>
      {
        <div className="flex mb-5 py-5">
          <button
            disabled={page.skip === "0"}
            onClick={() => {
              fetchData(-1);
            }}
            className="inline-flex items-center py-2 px-4 text-sm font-medium text-GTI-BLUE-default disabled:text-gray-500 bg-white  rounded-lg border border-gray-300 hover:bg-gray-100 hover:text-gray-700 "
          >
            <svg
              aria-hidden="true"
              className="mr-2 w-5 h-5"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M7.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l2.293 2.293a1 1 0 010 1.414z"
                clipRule="evenodd"
              ></path>
            </svg>
            Previous
          </button>
          {parseInt(page.skip) + 1 <= maxSkip && (
            <button
              onClick={() => {
                fetchData(0);
              }}
              className="inline-flex items-center mx-2 py-2 px-4 text-sm font-medium text-gray-500 bg-white rounded-lg border border-gray-300 hover:bg-gray-100 hover:text-gray-700 "
            >
              {parseInt(page.skip) + 1}
            </button>
          )}
          {parseInt(page.skip) + 2 <= maxSkip && (
            <button
              onClick={() => {
                fetchData(1);
              }}
              className="inline-flex items-center mx-2 py-2 px-4 text-sm font-medium text-gray-500 bg-white rounded-lg border border-gray-300 hover:bg-gray-100 hover:text-gray-700 "
            >
              {parseInt(page.skip) + 2}
            </button>
          )}
          {parseInt(page.skip) + 3 <= maxSkip && (
            <button
              onClick={() => {
                fetchData(2);
              }}
              className="inline-flex items-center mx-2 py-2 px-4 text-sm font-medium text-gray-500 bg-white rounded-lg border border-gray-300 hover:bg-gray-100 hover:text-gray-700 "
            >
              {parseInt(page.skip) + 3}
            </button>
          )}
          {parseInt(page.skip) + 4 <= maxSkip && (
            <button
              disabled
              className="inline-flex items-center mx-2 py-2 px-4 text-sm font-medium text-gray-500 bg-white rounded-lg border border-gray-300 hover:bg-gray-100 hover:text-gray-700 "
            >
              -
            </button>
          )}
          {parseInt(page.skip) + 6 <= maxSkip && (
            <button
              onClick={() => {
                fetchData(maxSkip - parseInt(page.skip) - 3);
              }}
              className="inline-flex items-center mx-2 py-2 px-4 text-sm font-medium text-gray-500 bg-white rounded-lg border border-gray-300 hover:bg-gray-100 hover:text-gray-700 "
            >
              {maxSkip - 2}
            </button>
          )}
          {parseInt(page.skip) + 5 <= maxSkip && (
            <button
              onClick={() => {
                fetchData(maxSkip - parseInt(page.skip) - 2);
              }}
              className="inline-flex items-center mx-2 py-2 px-4 text-sm font-medium text-gray-500 bg-white rounded-lg border border-gray-300 hover:bg-gray-100 hover:text-gray-700 "
            >
              {maxSkip - 1}
            </button>
          )}
          {parseInt(page.skip) + 4 <= maxSkip && (
            <button
              onClick={() => {
                fetchData(maxSkip - parseInt(page.skip) - 1);
              }}
              className="inline-flex items-center mx-2 py-2 px-4 text-sm font-medium text-gray-500 bg-white rounded-lg border border-gray-300 hover:bg-gray-100 hover:text-gray-700 "
            >
              {maxSkip}
            </button>
          )}
          <button
            disabled={
              (parseInt(page.skip) + 1) * parseInt(page.limit) >=
              innovation.INNOVATION_COUNT
            }
            onClick={() => {
              fetchData(1);
            }}
            className="inline-flex items-center py-2 px-4 text-sm font-medium text-GTI-BLUE-default disabled:text-gray-500 bg-white rounded-lg border border-gray-300 hover:bg-gray-100 hover:text-gray-700 "
          >
            Next
            <svg
              aria-hidden="true"
              className="ml-2 w-5 h-5"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z"
                clipRule="evenodd"
              ></path>
            </svg>
          </button>
        </div>
      }
    </div>
  );
};
export default Innovation;
