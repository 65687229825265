import React, { useEffect } from "react";
import ReactDOM from "react-dom";

interface ICustomModal {
  open: boolean;
  onClose: () => void;
  children?: React.ReactElement;
}

const CustomModal: React.FC<any> = (props) => {
  const { open, onClose, children, ...restProps } = props as any;
  useEffect(() => {
    if (open) document.body.style.overflow = "hidden";
    else document.body.style.overflow = "auto";
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [open]);

  const content = open ? (
    <div className="z-10 pb-[100px] pt-4 fixed w-full h-screen bg-slate-700 bg-opacity-70 top-0 left-0 flex justify-center items-center overflow-auto">
      {/* <div className="z-10 top-10 flex flex-col w-3/5 h-fit items-center space-y-5 bg-white duration-200 ease-in-out border border-gray-300 rounded-lg justify-center p-10 relative"> */}
      <div className="relative w-full max-w-[1000px] max-h-full">
        <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
          <button
            type="button"
            className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white"
            data-modal-hide="popup-modal"
            onClick={onClose}
          >
            <svg
              aria-hidden="true"
              className="w-5 h-5"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clip-rule="evenodd"
              ></path>
            </svg>
            <span className="sr-only">Close modal</span>
          </button>
          <div className="p-6 text-center" {...restProps}>
            {children}
          </div>
        </div>
      </div>
      {/* </div> */}
    </div>
  ) : null;
  return ReactDOM.createPortal(content, document.body);
};

export default CustomModal;
