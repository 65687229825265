import axios from "axios";

import { store } from "../../store";
import * as CONSTANT from "../../Components/constants";
import {
  PUBLICATIONS_DELETE,
  PUBLICATIONS_GET,
  PUBLICATIONS_GET_ID,
} from "../actiontypes/actionTypes";
import { spinnerLoaderStop } from "./loaderactions";

export const createPublication =
  (item: CONSTANT.publicationCreate) => (dispatch: any) => {
    var data = JSON.stringify(item);

    const userData: any = JSON.parse(
      localStorage.getItem(CONSTANT.STORAGE_KEY) ?? ""
    );
    const token = userData ? userData?.token : "";
    if (!token) return;
    var config = {
      method: "post",
      url: `${process.env.REACT_APP_BASE_API}/publications`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        // console.log("publications  created ");
      })
      .catch(function (error) {
        // console.log("publications not created ", error);
      });
  };

export const getPublications =
  (skip: string, limit: string) => (dispatch: any) => {
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_BASE_API}/publications?skip=${skip}&limit=${limit}`,
      headers: {
        // Authorization: `Bearer ${store.getState().USER.USER.token}`,
        "Content-Type": "application/json",
      },
    };

    axios(config)
      .then(function (response) {
        const list: any = {
          ...response.data,
        };
        const action: PUBLICATIONS_ACTION = {
          type: PUBLICATIONS_GET,
          PUBLICATION: list,
        };
        // console.log("Publications fetched ", response);
        dispatch(action);
        dispatch(spinnerLoaderStop());
      })
      .catch(function (error) {
        // console.log("Publications not fetched ", error);
        dispatch(spinnerLoaderStop());
      });
  };

export const getPublicationId = (id: string) => (dispatch: any) => {
  var config = {
    method: "get",
    url: `${process.env.REACT_APP_BASE_API}/publications/${id}`,
    headers: {
      Authorization: `Bearer ${store.getState().USER.USER.token}`,
      "Content-Type": "application/json",
    },
  };

  axios(config)
    .then(function (response) {
      const list: any = {
        ...response.data,
      };
      const action: any = {
        type: PUBLICATIONS_GET_ID,
        BLOG: list,
      };
      // console.log("PUBLICATIONS_GET_ID fetched ");
      return dispatch(action);
    })
    .catch(function (error) {
      // console.log("PUBLICATIONS_GET_ID not fetched ", error);
    });
};

export const deletePublicationId = (id: string) => (dispatch: any) => {
  const userData: any = JSON.parse(
    localStorage.getItem(CONSTANT.STORAGE_KEY) ?? ""
  );
  const token = userData ? userData?.token : "";
  if (!token) return;
  var config = {
    method: "delete",
    url: `${process.env.REACT_APP_BASE_API}/publications/${id}`,
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  };

  axios(config)
    .then(function (response) {
      const list: any = {
        ...response.data,
      };
      const action: any = {
        type: PUBLICATIONS_DELETE,
        BLOG: list,
      };
      // console.log("publications deleted ");
      dispatch(action);
      dispatch(getPublications(CONSTANT.SKIP, CONSTANT.LIMIT));
    })
    .catch(function (error) {
      // console.log("publications not deleted ", error);
    });
};

export const updatePublication =
  (item: CONSTANT.publicationUpdate) => (dispatch: UPDATE_BLOG_DISPATCH) => {
    var data = JSON.stringify(item);

    var config = {
      method: "patch",
      url: `${process.env.REACT_APP_BASE_API}/publications`,
      headers: {
        Authorization: `Bearer ${store.getState().USER.USER.token}`,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        // console.log("publications updated ");
        window.location.reload();
      })
      .catch(function (error) {
        // console.log("publications not updated ", error);
      });
  };
