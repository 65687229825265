import axios from "axios";
import { STORAGE_KEY, sendchatItem } from "../../Components/constants";
import { store } from "../../store";
import { CHAT_GET, CHAT_DELETE } from "../actiontypes/actionTypes";

export const getChat =
  (receiver: string, skip: string, limit: string) =>
  (dispatch: CHAT_DISPATCH) => {
    const token = localStorage.getItem(STORAGE_KEY)?.split(" ")[0] ?? "";

    var config = {
      method: "get",
      url: `${process.env.REACT_APP_BASE_API}/chat?receiver=${receiver}&skip=${skip}&limit=${limit}`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };

    axios(config)
      .then(function (response) {
        // console.log("now total ", response.data);
        const list: CHAT = {
          CHAT: [...response.data.chats.reverse()],
        };
        const action: CHAT_ACTION = {
          type: CHAT_GET,
          CHAT: {
            CHAT_LIST: list,
            CHAT_TOTAL: +response.data.chatCount,
          },
        };
        return dispatch(action);
      })
      .catch(function (error) {
        // console.log("unable to recieve chat");
      });
  };

export const getMoreChat =
  (receiver: string, skip: string, limit: string) =>
  (dispatch: CHAT_DISPATCH) => {
    const token = localStorage.getItem(STORAGE_KEY)?.split(" ")[0] ?? "";

    var config = {
      method: "get",
      url: `${process.env.REACT_APP_BASE_API}/chat?receiver=${receiver}&skip=${skip}&limit=${limit}`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };

    axios(config)
      .then(function (response) {
        // console.log("now total ", response.data);
        const list: CHAT = {
          CHAT: [
            ...response.data.chats.reverse(),
            ...store.getState().CHAT.CHAT_LIST.CHAT,
          ],
        };
        const action: CHAT_ACTION = {
          type: CHAT_GET,
          CHAT: {
            CHAT_LIST: list,
            CHAT_TOTAL: +response.data.chatCount,
          },
        };
        return dispatch(action);
      })
      .catch(function (error) {
        // console.log("unable to recieve chat");
      });
  };
export const clearChats = () => async (dispatch: CHAT_DISPATCH) => {
  const list: CHAT = {
    CHAT: [],
  };
  const action: CHAT_ACTION = {
    type: CHAT_DELETE,
    CHAT: {
      CHAT_LIST: list,
      CHAT_TOTAL: 0,
    },
  };
  return dispatch(action);
};
export const sendChat =
  (item: sendchatItem, skip: number, limit: number) => (dispatch: any) => {
    var data: sendchatItem = {
      receiver: item.receiver,
      message: item.message,
    };
    var config = {
      method: "post",
      url: `${process.env.REACT_APP_BASE_API}/chat`,
      body: data,
      headers: {
        Authorization: `Bearer ${store.getState().USER.USER.token}`,
        "Content-Type": "application/json",
      },
    };

    axios
      .post(`${process.env.REACT_APP_BASE_API}/chat`, data, {
        headers: {
          Authorization: `Bearer ${store.getState().USER.USER.token}`,
          "Content-Type": "application/json",
        },
      })
      .then(function (response) {
        // console.log("chat send");
        dispatch(getChat(item.receiver, "0", ((skip + 1) * limit).toString()));
      })
      .catch(function (error) {
        // console.log("chat not  send");
      });
  };
