import * as Yup from "yup";

export const promotionsSchema = Yup.object().shape({
  title: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Promotions Title Required"),
  description: Yup.string()
    .min(10, "Too Short Description!")
    .required("Promotion description Required"),
  // images: Yup.array().required("Promotions images is required"),
});
