import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { BiLinkExternal } from "react-icons/bi";
import { BsChatDots } from "react-icons/bs";
import { AiOutlineCheckCircle } from "react-icons/ai";
import { RiUserFollowLine, RiUserUnfollowLine } from "react-icons/ri";
import { Helmet } from "react-helmet";

import { getUserData } from "../../api/user";
import { isSuccess, notify } from "../../utils";
import defaultPic from "../../assests/images/default-user.svg";
import { getConnections } from "../../store/actioncreators/connectionactions";
import {
  followUser,
  unfollowUser,
} from "../../store/actioncreators/followactions";
import {
  typeAndSizeOfPartners,
  checkBox,
  typeOfPartnershipRequired,
  metaData,
} from "../constants";
import "./style.css";

interface IUserData {
  _id: string;
  fullName: string;
  email: string;
  countryCode: string;
  company: Array<{
    name: string;
    logo: string;
    description: string;
    address: string;
    website: string;
    country: string;
    companyTurnover: string;
    typeAndSizeOfPartnersRequired: [];
    typesOfPartnershipConsidered: [];
  }>;
}

interface IViewProfile {
  handleLoginModal: () => {};
}

const ViewProfile: React.FC<IViewProfile> = ({ handleLoginModal }) => {
  const [userData, setUserData] = useState<IUserData | null>(null);
  const [connection, setConnection] = useState("Connect");
  const [following, setFollowing] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch<any>();
  const connections = useSelector(
    (state: STATE) => state.CONNECTION.CONNECTION.CONNECTION_LIST
  );

  function updateCheckBoxLabelStatus(
    inputArray: string[],
    checkBoxData: checkBox[]
  ) {
    for (let i = 0; i < inputArray.length; i++) {
      const exists = checkBoxData.findIndex(
        (data) => data.label === inputArray[i]
      );
      if (exists >= 0) {
        checkBoxData[exists].checked = true;
      }
    }

    return checkBoxData;
  }

  const [typeAndSizeOfPartner, setTypeAndSizeOfPartner] = useState(
    updateCheckBoxLabelStatus(
      userData?.company[0]?.typeAndSizeOfPartnersRequired ?? [],
      typeAndSizeOfPartners
    )
  );
  const [typeOfPartnershipsRequired, setTypeOfPartnershipsRerquired] = useState(
    updateCheckBoxLabelStatus(
      userData?.company[0]?.typesOfPartnershipConsidered ?? [],
      typeOfPartnershipRequired
    )
  );
  const currentUser: USER = useSelector((state: STATE) => state.USER.USER);

  const { id } = useParams();

  useEffect(() => {
    initFetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const [tab, selectTab] = useState(0);
  const handletab = async (value: any) => {
    if (value === 0) {
    } else {
    }
    selectTab(value);
  };
  const getUser = async () => {
    const res = await getUserData(id ?? "");
    if (isSuccess(res)) {
      setUserData(res.data);
      setTypeOfPartnershipsRerquired(
        updateCheckBoxLabelStatus(
          res.data?.typesOfPartnershipConsidered ?? [],
          typeOfPartnershipRequired
        )
      );
      setTypeAndSizeOfPartner(
        updateCheckBoxLabelStatus(
          res.data?.typeAndSizeOfPartnersRequired ?? [],
          typeOfPartnershipRequired
        )
      );
    } else {
      notify("Failed to fetch data!", "error");
    }
  };

  const isFollowing = (userid: string) => {
    const extoken: string =
      localStorage.getItem("GTI_data")?.split(" ")[0] ?? "";

    let config = {
      method: "get",
      url: `${process.env.REACT_APP_BASE_API}/users/checkisfollowing/${userid}`,
      headers: {
        Authorization: `Bearer ${extoken}`,
        "Content-Type": "application/json",
      },
    };

    axios(config)
      .then(function (response) {
        setFollowing(response.data);
      })
      .catch(function (error) {});
  };

  const isConnected = (userid: string) => {
    const extoken: string =
      localStorage.getItem("GTI_data")?.split(" ")[0] ?? "";

    let config = {
      method: "get",
      url: `${process.env.REACT_APP_BASE_API}/users/checkisconnected/${userid}`,
      headers: {
        Authorization: `Bearer ${extoken}`,
        "Content-Type": "application/json",
      },
    };

    axios(config)
      .then(function (response) {
        setConnection(response.data);
      })
      .catch(function (error) {});
  };

  const initFetch = async () => {
    getUser();
    isConnected(id ?? "");
    isFollowing(id ?? "");
    if (!connections?.connectionsCount) {
      dispatch(getConnections());
    }
  };

  const handleFollow = () => {
    if (currentUser?.admin !== -1) {
      if (!following) {
        dispatch(followUser(id ?? ""));
        setFollowing(true);
      }
      return;
    }
    handleLoginModal();
  };
  const handleUnfollow = () => {
    if (currentUser?.admin !== -1) {
      if (following) {
        dispatch(unfollowUser(id ?? ""));
        setFollowing(false);
      }
      return;
    }
    handleLoginModal();
  };

  const handleConnect = () => {
    if (connection) {
      navigate(`/chat/${id}`);
    } else {
    }
  };

  return (
    <div className="profile-main max-w-[1200px] h-min">
      <Helmet>
        <title>{userData?.fullName}</title>
        <meta
          name="description"
          key="description"
          content={metaData.USER_PROFILE}
        />
        <meta name="title" key="title" content={userData?.fullName} />
        <meta property="og:title" content={userData?.fullName} />
        <meta property="og:description" content={metaData.USER_PROFILE} />
        <meta
          property="og:image"
          content={userData?.company[0]?.logo ?? defaultPic}
        />
        <meta
          property="og:url"
          content={`${process.env.REACT_APP_BASE_URL}/view-profile/${userData?._id}`}
        />
        <meta property="og:type" content="website" />
        <meta name="twitter:title" content={userData?.fullName} />
        <meta name="twitter:description" content={metaData?.USER_PROFILE} />
        <meta
          name="twitter:image"
          content={userData?.company[0]?.logo ?? defaultPic}
        />
        <meta name="twitter:card" content={userData?.fullName} />
      </Helmet>
      <div className="profile-main-left-2">
        <div className="profile-top-cover"></div>
        <div className="profile-top-row-2 py-[40px] px-[20px]">
          <div className="inline-flex lg:flex-row gap-5">
            <img
              src={userData?.company[0]?.logo ?? defaultPic}
              className="profile-pic-2"
              alt={userData?.fullName}
            />
            <div className="flex flex-col translate-y-10">
              <h4 className="text-GTI-BLUE-default whitespace-nowrap  font-bold font-roboto text-xl">
                {userData?.fullName}
              </h4>
              <h4 className="text-GTI-BLUE-default">
                {userData?.company[0]?.name}
              </h4>
            </div>
          </div>
          <div className="w-full inline-flex  justify-end">
            <button
              type="button"
              className={"button active inline-flex items-center"}
              disabled={connection === "Connection Requested"}
              onClick={() => {
                handleConnect();
              }}
            >
              {connection === "Connected" ? (
                <BsChatDots className="mr-2" />
              ) : (
                <AiOutlineCheckCircle className="mr-2" />
              )}
              {connection === "Connected" ? "Message" : connection}
            </button>
            <button
              type="button"
              className={"button not-active inline-flex items-center"}
              onClick={following ? handleUnfollow : handleFollow}
            >
              {following ? (
                <RiUserUnfollowLine className="mr-2" />
              ) : (
                <RiUserFollowLine className="mr-2" />
              )}
              {following ? "Unfollow" : "Follow"}
            </button>
          </div>
        </div>
      </div>
      <div className="profile-main-right">
        <ul className="profile-main-right-content">
          <li
            className={
              "profile-main-right-content-title " +
              (tab === 0 ? "text-GTI-BLUE-default" : "text-slate-500")
            }
            onClick={() => {
              handletab(0);
            }}
          >
            <button>Personal Details</button>
          </li>
          {userData?.company && userData?.company?.length ? (
            <li
              className={
                "profile-main-right-content-title " +
                (tab === 1 ? "text-GTI-BLUE-default" : "text-slate-500")
              }
              onClick={() => {
                handletab(1);
              }}
            >
              <button>Company Details</button>
            </li>
          ) : null}
        </ul>
        <div className="flex justify-center">
          {tab === 0 && (
            <div className="w-2/5">
              <div className="group relative">
                <input
                  disabled
                  value={userData?.fullName}
                  type="text"
                  className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-GTI-BLUE-default bg-transparent rounded-lg border-2 appearance-none  focus:outline-none focus:ring-0 focus:border-blue-700 shadow-lg peer"
                />
                <label className="absolute text-sm bg-white  text-slate-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] px-3 peer-focus:px-2 peer-focus:text-blue-600  peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-1 peer-focus:scale-75 peer-focus:-translate-y-4 left-1">
                  Name
                </label>
              </div>
              <div className="group relative">
                <input
                  disabled
                  value={userData?.email}
                  type="text"
                  className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-GTI-BLUE-default bg-transparent rounded-lg border-2 appearance-none  focus:outline-none focus:ring-0 focus:border-blue-700 shadow-lg peer"
                />
                <label className="absolute text-sm bg-white  text-slate-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] px-3 peer-focus:px-2 peer-focus:text-blue-600  peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-1 peer-focus:scale-75 peer-focus:-translate-y-4 left-1">
                  Email id
                </label>
              </div>

              <div className="group relative">
                <input
                  disabled
                  value={userData?.countryCode}
                  type="text"
                  className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-GTI-BLUE-default bg-transparent rounded-lg border-2 appearance-none  focus:outline-none focus:ring-0 focus:border-blue-700 shadow-lg peer"
                />
                <label className="absolute text-sm bg-white  text-slate-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] px-3 peer-focus:px-2 peer-focus:text-blue-600  peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-1 peer-focus:scale-75 peer-focus:-translate-y-4 left-1">
                  Reference Code
                </label>
              </div>
            </div>
          )}
          {tab === 1 && (
            <div className="w-2/5">
              <div className="group relative">
                <input
                  disabled
                  value={userData?.company[0].name}
                  type="text"
                  className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-GTI-BLUE-default bg-transparent rounded-lg border-2 appearance-none  focus:outline-none focus:ring-0 focus:border-blue-700 shadow-lg peer"
                />
                <label className="absolute text-sm bg-white  text-slate-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] px-3 peer-focus:px-2 peer-focus:text-blue-600  peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-1 peer-focus:scale-75 peer-focus:-translate-y-4 left-1">
                  Company Name
                </label>
              </div>
              <div className="group relative">
                <textarea
                  disabled
                  value={userData?.company[0].description}
                  className="block px-2.5 pb-2.5 h-auto min-h-30 max-h-30 pt-4 w-full text-sm text-GTI-BLUE-default bg-transparent rounded-lg border-2 appearance-none  focus:outline-none focus:ring-0 focus:border-blue-700 shadow-lg peer"
                />
                <label className="absolute text-sm bg-white  text-slate-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] px-3 peer-focus:px-2 peer-focus:text-blue-600  peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-1 peer-focus:scale-75 peer-focus:-translate-y-4 left-1">
                  Company Description
                </label>
              </div>
              <div className="group relative">
                <input
                  disabled
                  value={userData?.company[0].address}
                  type="text"
                  className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-GTI-BLUE-default bg-transparent rounded-lg border-2 appearance-none  focus:outline-none focus:ring-0 focus:border-blue-700 shadow-lg peer"
                />
                <label className="absolute text-sm bg-white  text-slate-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] px-3 peer-focus:px-2 peer-focus:text-blue-600  peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-1 peer-focus:scale-75 peer-focus:-translate-y-4 left-1">
                  Company Address
                </label>
              </div>
              <div className="group relative">
                <input
                  disabled
                  value={userData?.company[0].website}
                  type="text"
                  className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-GTI-BLUE-default bg-transparent rounded-lg border-2 appearance-none  focus:outline-none focus:ring-0 focus:border-blue-700 shadow-lg peer"
                />
                <label className="absolute text-sm bg-white  text-slate-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] px-3 peer-focus:px-2 peer-focus:text-blue-600  peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-1 peer-focus:scale-75 peer-focus:-translate-y-4 left-1">
                  Company Website
                </label>
              </div>
              <div className="group relative">
                <input
                  disabled
                  value={userData?.company[0].country}
                  type="text"
                  className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-GTI-BLUE-default bg-transparent rounded-lg border-2 appearance-none  focus:outline-none focus:ring-0 focus:border-blue-700 shadow-lg peer"
                />
                <label className="absolute text-sm bg-white  text-slate-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] px-3 peer-focus:px-2 peer-focus:text-blue-600  peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-1 peer-focus:scale-75 peer-focus:-translate-y-4 left-1">
                  Country
                </label>
              </div>
              <div className="group relative">
                <input
                  disabled
                  value={userData?.company[0].companyTurnover}
                  type="text"
                  className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-GTI-BLUE-default bg-transparent rounded-lg border-2 appearance-none  focus:outline-none focus:ring-0 focus:border-blue-700 shadow-lg peer"
                />
                <label className="absolute text-sm bg-white  text-slate-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] px-3 peer-focus:px-2 peer-focus:text-blue-600  peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-1 peer-focus:scale-75 peer-focus:-translate-y-4 left-1">
                  Turnover/fund(in USD)
                </label>
              </div>
              {userData?.company[0]?.website ? (
                <div className="group relative">
                  <a
                    className="flex gap-2 items-center cursor-pointer"
                    target="_blank"
                    href={userData?.company[0]?.website}
                    rel="noreferrer"
                  >
                    <input
                      disabled
                      value={userData?.company[0].website}
                      type="text"
                      className="block cursor-pointer px-2.5 pb-2.5 pt-4 w-full text-sm text-GTI-BLUE-default bg-transparent rounded-lg border-2 appearance-none  focus:outline-none focus:ring-0 focus:border-blue-700 shadow-lg peer"
                    />
                    <label className="absolute text-sm bg-white  text-slate-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] px-3 peer-focus:px-2 peer-focus:text-blue-600  peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-1 peer-focus:scale-75 peer-focus:-translate-y-4 left-1 flex gap-2 items-center">
                      Website
                      <BiLinkExternal />
                    </label>
                  </a>
                </div>
              ) : null}

              <div className="w-full mt-6">
                <div className="w-full">
                  <div className="relative mb-3">
                    <label className="profile-content-head-2">
                      Type and Size of Partner
                    </label>
                  </div>
                  <div className="flex flex-col flex-wrap justify-items-stretch items-stretch gap-2 ">
                    {typeAndSizeOfPartner.map((option) => (
                      <label
                        className="flex items-center space-x-2 ml-4"
                        key={option.id}
                      >
                        <input
                          type="checkbox"
                          disabled
                          className="h-4 w-4 text-indigo-600 rounded border-gray-300 focus:ring-indigo-500"
                          checked={option.checked}
                        />
                        <span className="text-gray-700 personal-input">
                          {option.label}
                        </span>
                      </label>
                    ))}
                  </div>
                </div>
              </div>

              <div className="w-full mt-6">
                <div className="w-full">
                  <div className="relative mb-3">
                    <label className="profile-content-head-2">
                      Type of Partnerships Required
                    </label>
                  </div>
                  <div className="flex gap-2 flex-col">
                    {typeOfPartnershipsRequired.map((option) => (
                      <label
                        className="flex items-center space-x-2 ml-4"
                        key={option.id}
                      >
                        <input
                          type="checkbox"
                          disabled
                          className="h-4 w-4 text-indigo-600 rounded border-gray-300 focus:ring-indigo-500"
                          checked={option.checked}
                        />
                        <span className="text-gray-700 personal-input border-2 ">
                          {option.label}
                        </span>
                      </label>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ViewProfile;
