import React, { useState } from "react";
import ReactFlagsSelect from "react-flags-select";
import { useSelector } from "react-redux";
import axios from "axios";
import {
  checkBox,
  turnoverFundOptions,
  typeAndSizeOfClientOptions,
  typeAndSizeOfPartners,
  typeOfPartnershipRequired,
} from "../constants";

const Company = () => {
  const currentUser: USER = useSelector((state: STATE) => state.USER.USER);

  // console.log({ companyDetails: currentUser.company ?? "" });

  const [company_name, setCompName] = useState(currentUser.company[0]?.name);
  const [company_desc, setCompDesc] = useState(
    currentUser.company[0]?.description
  );
  const [company_addr, setCompAdd] = useState(currentUser.company[0]?.address);
  const [company_webs, setCompWebS] = useState(currentUser.company[0]?.website);
  const [company_country, setCompCountry] = useState(
    currentUser.company[0]?.country
  );
  const [turnover, setTurnover] = useState(
    currentUser.company[0]?.companyTurnover
  );
  const [typeAndSizeOfClient, setTypeAndSizeOfClient] = useState(
    currentUser.company[0]?.companyAndSizeOfClient
  );
  const [typeAndSizeOfPartner, setTypeAndSizeOfPartner] = useState(
    updateCheckBoxLabelStatus(
      currentUser.company[0]?.typeAndSizeOfPartnersRequired ?? [],
      typeAndSizeOfPartners
    )
  );
  const [typeOfPartnershipsRequired, setTypeOfPartnershipsRerquired] = useState(
    updateCheckBoxLabelStatus(
      currentUser.company[0]?.typesOfPartnershipConsidered ?? [],
      typeOfPartnershipRequired
    )
  );
  const [transnationalCoOperation, setTransnationalCoOperation] = useState(
    currentUser.company[0]?.transnationalCoOperation
  );

  const [edit, setEdit] = useState(false);

  function updateCheckBoxLabelStatus(
    inputArray: string[],
    checkBoxData: checkBox[]
  ) {
    for (let i = 0; i < inputArray.length; i++) {
      const exists = checkBoxData.findIndex(
        (data) => data.label === inputArray[i]
      );
      if (exists >= 0) {
        checkBoxData[exists].checked = true;
      }
    }

    return checkBoxData;
  }

  function handleTypeAndSizeOfPartner(id: Number) {
    const updatedOptions = typeAndSizeOfPartner.map((option) => {
      if (option.id === id) {
        return { ...option, checked: !option.checked };
      }
      return option;
    });
    setTypeAndSizeOfPartner(updatedOptions);
  }

  function handleTypeOfPartnershipsRequired(id: Number) {
    const updatedOptions = typeOfPartnershipsRequired.map((option) => {
      if (option.id === id) {
        return { ...option, checked: !option.checked };
      }
      return option;
    });
    setTypeOfPartnershipsRerquired(updatedOptions);
  }

  const reset = () => {
    setCompDesc(currentUser?.company[0]?.description ?? "");
    setCompAdd(currentUser?.company[0]?.address ?? "");
    setCompWebS(currentUser?.company[0]?.website ?? "");
    setCompCountry(currentUser?.company[0]?.country ?? "");
  };

  const update = () => {
    const typeAndSizeOfPartnerData: string[] = [];
    const typeOfPartnershipsRequiredData: string[] = [];

    typeAndSizeOfPartner.map((data) => {
      if (data.checked) typeAndSizeOfPartnerData.push(data.label);
    });

    typeOfPartnershipsRequired.map((data) => {
      if (data.checked) typeOfPartnershipsRequiredData.push(data.label);
    });

    const data = JSON.stringify({
      name: company_name,
      website: company_webs,
      description: company_desc,
      address: company_addr,
      country: company_country,
      companyAndSizeOfClient: typeAndSizeOfClient,
      companyTurnover: turnover,
      typeAndSizeOfPartnersRequired: typeAndSizeOfPartnerData,
      typesOfPartnershipConsidered: typeOfPartnershipsRequiredData,
      transnationalCoOperation,
    });

    const extoken: string =
      localStorage.getItem("GTI_data")?.split(" ")[0] ?? "";

    const config = {
      method: "patch",
      url: `${process.env.REACT_APP_BASE_API}/company/updatecompanydetails`,
      headers: {
        Authorization: `Bearer ${extoken}`,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        // console.log("request send to update company");
      })
      .catch(function (error) {
        // console.log("error in updating company");
      });
  };

  const handleSaveCompany = async () => {
    await update();
    reset();
    setEdit(!edit);
  };

  const handleCancelCompany = async () => {
    reset();
    setEdit(!edit);
  };

  return (
    <div className="flex justify-center space-x-5 duration-200 ease-in-out h-min">
      <div className="w-5/6 border-2 rounded-lg">
        <div className="profile-row-group">
          <div className="profile-group">
            <div className="relative mb-3">
              <label className="profile-content-head-2">Company Name</label>
            </div>
            <input
              type="text"
              disabled={!edit}
              value={company_name}
              onChange={(e) => {
                setCompName(e.target.value);
              }}
              className={
                "personal-input " + (!edit ? "text-gray-500" : "text-gray-700")
              }
              placeholder={company_name}
            />
          </div>
          <div className="profile-group">
            <div className="relative mb-3">
              <label className="profile-content-head-2">
                Company's Website
              </label>
            </div>
            <input
              type="text"
              disabled={!edit}
              value={company_webs}
              onChange={(e) => {
                setCompWebS(e.target.value);
              }}
              className={
                "personal-input " + (!edit ? "text-gray-500" : "text-gray-700")
              }
              placeholder={company_webs}
            />
          </div>
        </div>

        <div className="profile-row-group">
          <div className="profile-group">
            <div className="relative mb-3">
              <label className="profile-content-head-2">Country</label>
            </div>

            {!edit ? (
              <div className="inline-flex items-center">
                <input
                  type="text"
                  disabled={!edit}
                  value={company_country}
                  className={
                    "personal-input " +
                    (!edit ? "text-gray-500" : "text-gray-700")
                  }
                />
              </div>
            ) : (
              <ReactFlagsSelect
                selected={company_country ?? ""}
                onSelect={(code) => {
                  setCompCountry(code);
                  // setCompCountry(countries.countries[code as keyof typeof countries.countries].name);
                }}
                fullWidth={false}
                selectedSize={18}
                searchable
                className="block  px-1 py-1 text-sm text-gray-900 bg-transparent rounded-lg border-1 appearance-none  focus:outline-none"
              />
            )}
          </div>
          <div className="profile-group">
            <div className="flex flex-col">
              <label
                htmlFor="dropdown"
                className="text-gray-700 font-bold mb-2 profile-content-head-2"
              >
                Turnover/fund (in USD):
              </label>
              <div className="flex flex-col w-full">
                <select
                  id="turnover"
                  value={turnover}
                  onChange={(event) => setTurnover(event.target.value)}
                  disabled={!edit}
                  className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                >
                  <option value="" disabled>
                    Turnover/Fund (in USD)
                  </option>
                  {turnoverFundOptions.map((option) => (
                    <option value={option.value}>{option.value}</option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          <div className="profile-group">
            <div className="flex flex-col">
              <label
                htmlFor="dropdown"
                className="text-gray-700 font-bold mb-2 profile-content-head-2"
              >
                Type and Size of Client:
              </label>
              <div className="flex flex-col w-full">
                <select
                  id="turnover"
                  value={typeAndSizeOfClient}
                  onChange={(event) => {
                    setTypeAndSizeOfClient(event.target.value);
                  }}
                  disabled={!edit}
                  className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                >
                  <option value="" disabled>
                    Type and Size of Client
                  </option>
                  {typeAndSizeOfClientOptions.map((option) => (
                    <option value={option.value}>{option.value}</option>
                  ))}
                </select>
              </div>
            </div>
          </div>
        </div>

        <div className="profile-row-group">
          <div className="profile-group">
            <div className="relative mb-3">
              <label className="profile-content-head-2">
                Company's Description
              </label>
            </div>
            <textarea
              disabled={!edit}
              value={company_desc}
              onChange={(e) => {
                setCompDesc(e.target.value);
              }}
              className={
                "personal-input " + (!edit ? "text-gray-500" : "text-gray-700")
              }
              style={{ height: "80px", overflow: "hidden" }}
              placeholder={company_desc}
            />
          </div>
        </div>

        <div className="profile-row-group">
          <div className="profile-group">
            <div className="relative mb-3">
              <label className="profile-content-head-2">
                Company's Address
              </label>
            </div>
            <textarea
              disabled={!edit}
              value={company_addr}
              onChange={(e) => {
                setCompAdd(e.target.value);
              }}
              className={
                "personal-input " + (!edit ? "text-gray-500" : "text-gray-700")
              }
              style={{ height: "80px", overflow: "hidden" }}
              placeholder={company_addr}
            />
          </div>
        </div>

        <div className="profile-row-group">
          <div className="profile-group">
            <div className="relative mb-3">
              <label className="profile-content-head-2">
                Type and Size of Partner
              </label>
            </div>
            <div className="grid grid-cols-1 gap-4 md:grid-cols-3">
              {typeAndSizeOfPartner.map((option) => (
                <label
                  className="flex items-center space-x-2 ml-4"
                  key={option.id}
                >
                  <input
                    type="checkbox"
                    disabled={!edit}
                    className="h-4 w-4 text-indigo-600 rounded border-gray-300 focus:ring-indigo-500"
                    checked={option.checked}
                    onChange={() => handleTypeAndSizeOfPartner(option.id)}
                  />
                  <span className="text-gray-700 personal-input">
                    {option.label}
                  </span>
                </label>
              ))}
            </div>
          </div>
        </div>

        <div className="profile-row-group">
          <div className="profile-group">
            <div className="relative mb-3">
              <label className="profile-content-head-2">
                Type of Partnerships Required
              </label>
            </div>
            <div className="grid grid-cols-1 gap-4 md:grid-cols-3">
              {typeOfPartnershipsRequired.map((option) => (
                <label
                  className="flex items-center space-x-2 ml-4"
                  key={option.id}
                >
                  <input
                    type="checkbox"
                    disabled={!edit}
                    className="h-4 w-4 text-indigo-600 rounded border-gray-300 focus:ring-indigo-500"
                    checked={option.checked}
                    onChange={() => handleTypeOfPartnershipsRequired(option.id)}
                  />
                  <span className="text-gray-700 personal-input">
                    {option.label}
                  </span>
                </label>
              ))}
            </div>
          </div>
        </div>

        <div className="profile-row-group">
          <div className="profile-group">
            <div className="flex items-center space-x-4">
              <label
                htmlFor="toggle"
                className="flex items-center cursor-pointer ml-4"
              >
                <div className="relative">
                  <label className="profile-content-head-2">
                    Transnational co-operation &nbsp; &nbsp;
                  </label>
                </div>
                <div className="relative">
                  <input
                    id="toggle"
                    disabled={!edit}
                    type="checkbox"
                    className="sr-only"
                    checked={transnationalCoOperation}
                    onChange={() =>
                      setTransnationalCoOperation(!transnationalCoOperation)
                    }
                  />
                  <div className="w-8 h-4 bg-gray-400 rounded-full shadow-inner"></div>
                  <div
                    className={`${
                      transnationalCoOperation ? "bg-green-500" : "bg-gray-200"
                    } absolute left-0 top-0 w-4 h-4 rounded-full transition-colors duration-300 ease-in-out transform ${
                      transnationalCoOperation
                        ? "translate-x-full"
                        : "translate-x-0"
                    }`}
                  ></div>
                </div>
                <div className="grid grid-cols-1 gap-4 md:grid-cols-3 profile-content-head-2 text-gray-700 font-medium">
                  {transnationalCoOperation ? "YES" : "NO"}
                </div>
              </label>
            </div>
          </div>
        </div>
      </div>

      <div className="duration-200 py-5">
        {!edit ? (
          <div className="flex duration-200">
            <button
              type="button"
              onClick={() => {
                setEdit(true);
              }}
              className="text-white font-roboto bg-GTI-BLUE-default hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 focus:outline-none "
            >
              Edit Profile
            </button>
          </div>
        ) : (
          <div className="flex duration-200">
            <button
              type="button"
              onClick={() => {
                handleSaveCompany();
              }}
              className="text-white font-roboto bg-GTI-BLUE-default hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 focus:outline-none "
            >
              Save
            </button>
            <button
              onClick={() => {
                handleCancelCompany();
              }}
              className="py-2.5 px-5 mr-2 mb-2 text-sm font-medium text-GTI-BLUE-default focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 "
            >
              Cancel
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default Company;
