import React, { Dispatch, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { getPromotions } from "../../store/actioncreators/promotionsaction";
import { ScreenSpinner } from "../utils/loader";
import { LIMIT, PROMOTIONS, SKIP, promotionsType } from "../constants";
import { spinnerLoaderStart } from "../../store/actioncreators/loaderactions";
import { VerificationStatus } from "../../shared/enum";
import RenderHTML from "../utils/RenderHTML";
import { getQueryParams } from "../../utils";

const Card = ({ item }: { item: promotionsType }) => {
  const dispatch: Dispatch<any> = useDispatch();
  const navigate = useNavigate();

  const handleView = () => {
    dispatch(spinnerLoaderStart());
    navigate(`/featured/${item._id}`, { state: { product: item } });
  };

  const DOP = new Date(item.createdAt);

  return (
    <div
      className="product-card-main"
      onClick={() => {
        handleView();
      }}
    >
      <div className="product-card-img">
        <img
          src={item.images[0]}
          className="flex w-full rounded-t-lg hover:rounded-lg object-contain lg:h-56"
          alt={item.title}
          loading="lazy"
        />
      </div>
      <div className="flex text-left px-4 py-1">
        <h4 className="font-roboto text-xs text-slate-500 ">
          {DOP.toLocaleString("default", {
            month: "short",
            day: "2-digit",
            year: "numeric",
          })}
        </h4>
        <h4
          className={`inline-flex items-center px-2 py-1 text-xs font-medium text-center rounded-lg ml-auto ${
            item.verificationStatus === VerificationStatus.PENDING
              ? "bg-gray-200 text-gray-700"
              : item.verificationStatus === VerificationStatus.ACCEPTED
              ? "bg-green-200 text-green-700"
              : "bg-red-200 text-red-700"
          }`}
        >
          {item.verificationStatus}
        </h4>
      </div>
      <div className="product-card-title">
        <h4 className="font-roboto text-lg text-GTI-BLUE-default ">
          {item.title.length > 25
            ? item.title.substring(0, 25) + "..."
            : item.title}
        </h4>
      </div>
      <div className="product-card-title">
        <h4 className="font-roboto  text-xs text-slate-500 ">
          {item.description.length > 150 ? (
            <RenderHTML html={item.description.substring(0, 150) + "..."} />
          ) : (
            <RenderHTML html={item.description.substring(0, 150)} />
          )}
        </h4>
      </div>
    </div>
  );
};

const PromotionsList = ({
  handleLoginModal,
}: {
  handleLoginModal: () => void;
}) => {
  const dispatch: Dispatch<any> = useDispatch();
  const spinner: LOADER = useSelector((state: STATE) => state.LOADER.LOADER);
  const promotions: PROMOTIONS_STATE = useSelector(
    (state: STATE) => state.PROMOTIONS
  );

  const navigator = useNavigate();

  const [page, setPage] = useState({
    skip: getQueryParams("skip") ? getQueryParams("skip") : SKIP,
    limit: LIMIT,
  });
  const [maxSkip, setMaxSkip] = useState(0);

  const fetchData = (value: number) => {
    let final =
      parseInt(page.skip) + value < 0
        ? parseInt(page.skip)
        : parseInt(page.skip) + value;
    setPage({ skip: final.toString(), limit: page.limit });
    navigator(PROMOTIONS + `?skip=${final}`);
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    setMaxSkip(Math.ceil(promotions.promotionsCount / parseInt(LIMIT)));
  }, [page, promotions]);

  useEffect(() => {
    dispatch(getPromotions(page.skip, page.limit));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page.skip, page.limit]);

  return (
    <div className="flex flex-col w-full px-10">
      <h1 className="text-GTI-BLUE-default font-semibold text-3xl lg:text-4xl mb-5 w-full text-center">
        YOUR FEATURED
      </h1>
      {spinner.SPINNER ? (
        <ScreenSpinner />
      ) : promotions?.promotionsCount > 0 ? (
        <div className="product-list-main">
          {promotions?.promotions.map((item: promotionsType, id: number) => {
            return <Card item={item} key={id} />;
          })}
        </div>
      ) : (
        <h3 className="text-center text-2xl text-semibold text-gray-400">
          No Featured created!
        </h3>
      )}
    </div>
  );
};

export default PromotionsList;
