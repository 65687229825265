import * as ACTION from "../actiontypes/actionTypes";

const initialState: FOLLOW_STATE = {};

export const followreducer = (
  state: FOLLOW_STATE = initialState,
  action: FOLLOW_ACTION
): FOLLOW_STATE => {
  switch (action.type) {
    case ACTION.FOLLOW_USER:
      return { ...state };
    case ACTION.UNFOLLOW_USER:
      return { ...state };
    default:
      return { ...state };
  }
  // return state;
};
