import axios from "axios";
import { Dispatch, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import Footer from "./Components/Footer/Footer";
import Navbar from "./Components/Header/Navbar";
import Notification from "./Components/Notifications/Notification";
import Signin from "./Components/Signup/SignIn";
import * as route from "./Components/constants";
import Toast from "./Components/toasts/Toast";
import { setUser } from "./store/actioncreators/actionCreators";
import { getEvents } from "./store/actioncreators/eventactionss";
import { getCalls } from "./store/actioncreators/innovationactions";
import {
  getNotificationsCount,
  viewAllNotification,
} from "./store/actioncreators/notificationactions";
import { getOpportunities } from "./store/actioncreators/opportunityactions";
import { getProducts } from "./store/actioncreators/productactions";

import Article from "./Components/Articles/Article";
import Articles from "./Components/Articles/Articles";
import Chat from "./Components/Chat/Chat";
import Company from "./Components/Company/Company";
import Connections from "./Components/Connections/Connections";
import Contact from "./Components/Contact/Contact";
import Event from "./Components/Events/Event";
import Events from "./Components/Events/Events";
import Helpdesk from "./Components/HelpDesk/Helpdesk";
import Homepage from "./Components/Home/Homepage";
import LearnMore from "./Components/LearnMore/LearnMore";
import NotificationView from "./Components/Notifications/NotificationView";
import CompanyOpportunites from "./Components/Opportunity/CompanyOpportunites";
import Opportunites from "./Components/Opportunity/Opportunites";
import Opportunity from "./Components/Opportunity/Opportunity";
import YourOpportunites from "./Components/Opportunity/YourOpportunites";
import YourOpportunity from "./Components/Opportunity/YourOpportunity";
import Password from "./Components/Password/Password";
import Personal from "./Components/Peronal/Personal";
import CompanyProducts from "./Components/Products/CompanyProducts";
import Product from "./Components/Products/Product";
import Products from "./Components/Products/Products";
import YourProduct from "./Components/Products/YourProduct";
import YourProducts from "./Components/Products/YourProducts";
import UserProfile from "./Components/Profile/UserProfile";
import Signup from "./Components/Signup/Signup";
import Call from "./Components/innovation/Call";
import CallView from "./Components/innovation/CallView";
import Innovation from "./Components/innovation/Innovation";
import { useSelector } from "react-redux";
import { withScrollToTop } from "./hoc/withScrollToTop";
import { getQueryParams } from "./utils";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ForgotPassword from "./Components/forgotPassword";
import EmailVerifySuccess from "./Components/emailVerify/EmailVerifySuccess";
import ViewProfile from "./Components/viewProfile/ViewProfile";
import TermsCondition from "./Components/statics/TermsConditions";
import PrivacyPolicy from "./Components/statics/PrivacyPolicy";
import Publications from "./Components/Publications/Publications";
import Publication from "./Components/Publications/Publication";
import News from "./Components/News/News";
import NewsSingle from "./Components/News/NewsSingle";
import Promotions from "./Components/Promotions/Promotions";
import Promotion from "./Components/Promotions/Promotion";
import PromotionsList from "./Components/Promotions/PromotionsList";
import PromotionsPaymentSuccess from "./Components/Promotions/PromotionsPaymentSuccess";
import PromotionsPaymentFailure from "./Components/Promotions/PromotionsPaymentFailure";
import PageNotFound from "./Components/statics/PageNotFound";
import RefundAndCancellationPolicy from "./Components/statics/RefundAndCancellationPolicy";
import Featured from "./Components/Promotions/Featured";
import EventVideo from "./Components/Events/EventVideo";
import EventPaymentSuccess from "./Components/Events/EventPaymentSuccess";
import EventPaymentFailure from "./Components/Events/EventPaymentFailure";

export enum PROFILE_TYPES {
  DISPLAYER = "DISPLAYER",
  SCOUTER = "SCOUTER",
  GENERAL_SUBSCRIBER = "GENERAL_SUBSCRIBER",
}

const WrappedArticle = withScrollToTop(Article);
const WrappedArticles = withScrollToTop(Articles);
const WrappedPublication = withScrollToTop(Publication);
const WrappedPublications = withScrollToTop(Publications);
const WrappedNews = withScrollToTop(News);
const WrappedNewsSingle = withScrollToTop(NewsSingle);
const WrappedChat = withScrollToTop(Chat);
const WrappedCompany = withScrollToTop(Company);
const WrappedConnections = withScrollToTop(Connections);
const WrappedContact = withScrollToTop(Contact);
const WrappedEvent = withScrollToTop(Event);
const WrappedEvents = withScrollToTop(Events);
const WrappedHelpdesk = withScrollToTop(Helpdesk);
const WrappedHomepage = withScrollToTop(Homepage);
const WrappedLearnMore = withScrollToTop(LearnMore);
const WrappedNotificationView = withScrollToTop(NotificationView);
const WrappedCompanyOpportunites = withScrollToTop(CompanyOpportunites);
const WrappedOpportunites = withScrollToTop(Opportunites);
const WrappedOpportunity = withScrollToTop(Opportunity);
const WrappedYourOpportunites = withScrollToTop(YourOpportunites);
const WrappedPassword = withScrollToTop(Password);
const WrappedPersonal = withScrollToTop(Personal);
const WrappedCompanyProducts = withScrollToTop(CompanyProducts);
const WrappedProduct = withScrollToTop(Product);
const WrappedProducts = withScrollToTop(Products);
const WrappedYourProduct = withScrollToTop(YourProduct);
const WrappedYourProducts = withScrollToTop(YourProducts);
const WrappedUserProfile = withScrollToTop(UserProfile);
const WrappedViewProfile = withScrollToTop(ViewProfile);
const WrappedSignup = withScrollToTop(Signup);
// const WrappedCall = withScrollToTop(Call);
const WrappedCallView = withScrollToTop(CallView);
const WrappedInnovation = withScrollToTop(Innovation);
const WrappedTermsCondition = withScrollToTop(TermsCondition);
const WrappedPrivacyPolicy = withScrollToTop(PrivacyPolicy);
const WrappedPageNotFound = withScrollToTop(PageNotFound);
const WrappedPromotions = withScrollToTop(Promotions);
const WrappedPromotion = withScrollToTop(Promotion);
const WrappedRefundAndCancellationPolicy = withScrollToTop(
  RefundAndCancellationPolicy
);
const WrappedFeatured = withScrollToTop(Featured);
const WrappedPromotionPaymentSuccess = withScrollToTop(
  PromotionsPaymentSuccess
);
const WrappedPromotionPaymentFailure = withScrollToTop(
  PromotionsPaymentFailure
);
const WrappedEventVideo = withScrollToTop(EventVideo);
const WrappedEventPaymentSuccess = withScrollToTop(EventPaymentSuccess);
const WrappedEventPaymentFailure = withScrollToTop(EventPaymentFailure);
const WrappedPromotionsList = withScrollToTop(PromotionsList);

function App() {
  const dispatch: Dispatch<any> = useDispatch();
  const currentUser: USER = useSelector((state: STATE) => state.USER.USER);
  let fetchDetails = async () => {
    const extoken: string =
      localStorage.getItem("GTI_data")?.split(" ")[0] ?? "";

    var config = {
      method: "get",
      url: `${process.env.REACT_APP_BASE_API}/users/getLoggedInUserDetails`,
      headers: {
        Authorization: `Bearer ${extoken}`,
      },
    };

    await axios(config)
      .then((response) => {
        let getuser: USER = {
          ...response.data,
          id: response.data._id,
          admin: +response.data.userRole,
          token: extoken,
          loader: false,
          user: {
            name: response.data.fullName,
            email: response.data.email,
            phone: response.data.phoneNumber,
            country: response.data.countryCode,
            ref: response.data.referenceCode,
            pwd: "",
            emailVerified: response.data.isEmailVerified,
            coverImg: response.data.bannerImage,
            rejected: response.data.isRejected,
            userVerified: response.data.isUserVerified,
            profileImg: response.data.profileImage,
          },
          company:
            response.data.company && response.data.company.length > 0
              ? response.data.company
              : [],
        };

        dispatch(setUser(getuser));
      })
      .catch((error) => {});
  };

  const [showNotification, setModal] = useState(false);
  const handleNotificationModal = () => {
    setModal(!showNotification);
  };
  const handleLoginModal = () => {
    setLoginModal(!showLoginModal);
  };

  useEffect(() => {
    if (localStorage.getItem("GTI_data")) {
      fetchDetails();
      dispatch(getNotificationsCount());
    }
    dispatch(getProducts(route.SKIP, "9"));
    dispatch(getOpportunities(route.SKIP, "9"));
    dispatch(getEvents(route.SKIP, "9"));
    dispatch(getCalls(getQueryParams("skip") ?? "0", "8"));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (showNotification) {
      dispatch(viewAllNotification());
      dispatch(getNotificationsCount());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showNotification]);

  const [showLoginModal, setLoginModal] = useState<boolean>(false);

  return (
    <div className="w-full scroll-smooth hover:scroll-auto hover:scroll-m-1 flex flex-col">
      <ToastContainer />
      <BrowserRouter>
        <div className="flex w-full flex-col items-center justify-between">
          <div className="flex w-full z-10">
            <Navbar
              handleLoginModal={handleLoginModal}
              handleNotificationModal={handleNotificationModal}
            />
            {showNotification && (
              <Notification handleNotificationModal={handleNotificationModal} />
            )}
            <div
              className={
                "duration-500 ease-in-out " +
                (showLoginModal ? "opacity-100" : "opacity-0")
              }
            >
              {showLoginModal && (
                <Signin
                  handleNotificationModal={handleNotificationModal}
                  handleLoginModal={handleLoginModal}
                />
              )}
            </div>
          </div>
          <div className="flex w-full min-h-screen h-full mb-10 translate-y-20 relative">
            <Routes>
              <Route
                path={route.HOME}
                element={<WrappedHomepage handleLogin={handleLoginModal} />}
              />
              <Route
                path={route.SIGNUP}
                element={<WrappedSignup handleLoginModal={handleLoginModal} />}
              />
              <Route
                path={route.TECHNOLOGY}
                element={
                  <WrappedProducts handleLoginModal={handleLoginModal} />
                }
              />
              <Route
                path={route.PRODUCT}
                element={<WrappedProduct handleLoginModal={handleLoginModal} />}
              />
              <Route
                path={route.YOUR_TECHNOLOGY}
                element={<WrappedYourProducts />}
              />
              <Route
                path={route.YOUR_PRODUCT}
                element={
                  <WrappedYourProduct handleLoginModal={handleLoginModal} />
                }
              />
              <Route
                path={route.COMPANY_TECHNOLOGY}
                element={<WrappedCompanyProducts />}
              />
              <Route
                path={route.OPPORTUNITY}
                element={<WrappedOpportunites />}
              />
              <Route
                path={route.OPP}
                element={
                  <WrappedOpportunity handleLoginModal={handleLoginModal} />
                }
              />
              <Route
                path={route.YOUR_OPPORTUNITY}
                element={<WrappedYourOpportunites />}
              />
              <Route
                path={route.YOUR_OPP}
                element={
                  <YourOpportunity handleLoginModal={handleLoginModal} />
                }
              />
              <Route
                path={route.COMPANY_OPPORTUNITY}
                element={<WrappedCompanyOpportunites />}
              />
              <Route path={route.INNOVATION} element={<WrappedInnovation />} />
              {/* <Route
                path={route.INNOVATION_CALL_ID}
                element={<WrappedCall />}
              /> */}
              <Route
                path={route.INNOVATION_CALL_ID_VIEW}
                element={
                  <WrappedCallView handleLoginModal={handleLoginModal} />
                }
              />
              <Route path={route.HELPDESK} element={<WrappedHelpdesk />} />
              <Route path={route.ABOUT} element={<WrappedLearnMore />}></Route>
              <Route
                path={route.ARTICLES}
                element={<WrappedArticles />}
              ></Route>
              <Route
                path={`${route.ARTICLE}`}
                element={<WrappedArticle />}
              ></Route>
              <Route
                path={route.PUBLICATIONS}
                element={<WrappedPublications />}
              ></Route>
              <Route
                path={`${route.PUBLICATION}`}
                element={
                  <WrappedPublication handleLoginModal={handleLoginModal} />
                }
              ></Route>
              <Route path={route.NEWS} element={<WrappedNews />}></Route>
              <Route
                path={`${route.NEWS_ID}`}
                element={
                  <WrappedNewsSingle handleLoginModal={handleLoginModal} />
                }
              ></Route>
              <Route path={route.EVENTS} element={<WrappedEvents />}></Route>
              <Route
                path={route.EVENT}
                element={<WrappedEvent handleLoginModal={handleLoginModal} />}
              ></Route>
              <Route
                path={route.NOTIFICATIONS}
                element={<WrappedNotificationView />}
              ></Route>
              <Route path={route.CHAT_ID} element={<WrappedChat />}></Route>
              <Route path={route.CHAT} element={<WrappedChat />}></Route>
              <Route
                path={route.CONTACTUS}
                element={<WrappedContact />}
              ></Route>
              <Route
                path={route.EMAIL_VERIFY}
                element={<EmailVerifySuccess />}
              />
              <Route
                path={route.FORGOT_PASSWORD}
                element={<ForgotPassword />}
              />
              <Route
                path={route.VIEW_PROFILE}
                element={<WrappedViewProfile />}
              />

              <Route
                path={route.PROMOTIONS}
                element={
                  <WrappedPromotions handleLoginModal={handleLoginModal} />
                }
              />
              <Route
                path={route.PROMOTION}
                element={
                  <WrappedPromotion handleLoginModal={handleLoginModal} />
                }
              />
              <Route
                path={route.PROMOTIONS_LIST}
                element={
                  <WrappedPromotionsList handleLoginModal={handleLoginModal} />
                }
              />
              <Route
                path={route.PROMOTION_VIEW}
                element={
                  <WrappedFeatured handleLoginModal={handleLoginModal} />
                }
              />
              <Route
                path={route.PROMOTION_PAYMENT_SUCCESS}
                element={
                  <WrappedPromotionPaymentSuccess
                    handleLoginModal={handleLoginModal}
                  />
                }
              />
              <Route
                path={route.PROMOTION_PAYMENT_FAILURE}
                element={
                  <WrappedPromotionPaymentFailure
                    handleLoginModal={handleLoginModal}
                  />
                }
              />

              <Route path={route.TERMS} element={<WrappedTermsCondition />} />
              <Route path={route.PRIVACY} element={<WrappedPrivacyPolicy />} />
              <Route path={route.TERMS} element={<WrappedTermsCondition />} />
              <Route path={route.PRIVACY} element={<WrappedPrivacyPolicy />} />
              <Route
                path={route.REFUND_AND_CANCELLATION_POLICY}
                element={<WrappedRefundAndCancellationPolicy />}
              />
              <Route path={route.EVENT_VIDEO} element={<WrappedEventVideo />} />

              <Route
                path={route.EVENT_PAYMENT_SUCCESS}
                element={
                  <WrappedEventPaymentSuccess
                    handleLoginModal={handleLoginModal}
                  />
                }
              />
              <Route
                path={route.EVENT_PAYMENT_FAILURE}
                element={
                  <WrappedEventPaymentFailure
                    handleLoginModal={handleLoginModal}
                  />
                }
              />

              {currentUser.admin !== -1 ? (
                <Route path={route.PROFILE} element={<WrappedUserProfile />}>
                  <Route path={route.PERSONAL} element={<WrappedPersonal />} />
                  <Route path={route.COMPANY} element={<WrappedCompany />} />
                  <Route path={route.PASSWORD} element={<WrappedPassword />} />
                  <Route
                    path={route.NETWORK}
                    element={<WrappedConnections />}
                  />
                </Route>
              ) : (
                <Route path="*" element={<Navigate to="/" />} />
              )}
            </Routes>
            <Toast />
          </div>
          <Footer />
        </div>
      </BrowserRouter>
    </div>
  );
}

export default App;
