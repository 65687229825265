import * as ACTION from "../actiontypes/actionTypes";

const initialState: any = {
  promotions: [],
  homepagePromotions: [],
  promotionsCount: 0,
};

export const promotionsreducer = (
  state: PROMOTIONS_STATE = initialState,
  action: PROMOTIONS_ACTION
): PROMOTIONS_STATE => {
  switch (action.type) {
    case ACTION.PROMOTIONS_GET:
      return { ...state, ...action.PROMOTIONS };
    case ACTION.PROMOTIONS_CREATE:
      return { ...state, ...action.PROMOTIONS };
    case ACTION.PROMOTIONS_HOMEPAGE_GET:
      return {
        ...state,
        homepagePromotions: action.PROMOTIONS.homepagePromotions,
      };
    default:
      return { ...state };
  }
};
