import React, { Dispatch, useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import {
  APPROVED,
  CONTENT_TYPE,
  CONTENT_TYPE_DOC,
  FILE_PATH,
  FILE_TYPE,
  NONE,
  oppotunityItemPartialFetched,
  oppotunityUpdateItem,
  PENDING,
  presignedData,
  REJECTED,
  sectorItem,
  subsectorItem,
  YOUR_OPP,
} from "../constants";
import { useNavigate } from "react-router-dom";
import { MdOutlineEdit } from "react-icons/md";
import axios from "axios";
import oppbanner from "../../assests/images/product_alt.png";
import {
  failToast,
  successToast,
} from "../../store/actioncreators/toastactions";
import { Formik, Form } from "formik";
import {
  getYourOpportunities,
  updateOppotunity,
} from "../../store/actioncreators/opportunityactions";
import { oppSchema } from "../validations/oppValidations";
import { ScreenSpinner } from "../utils/loader";
import { Editor } from "@tinymce/tinymce-react";
import RenderHTML from "../utils/RenderHTML";
import { RequestMethods } from "../../shared/RequestMethods";

interface MyFormValues {
  tech_require: string;
  description: string;
  sectorId: string;
  subSectorId: string;
}

type files = {
  image: Boolean;
  document: Boolean;
  imageFile: File | null;
  documentFiles: FileList | null;
};

type productModal = {
  tech_require: string;
  description: string;
  document: FileList | null;
  image: File | null;
  sectorId: string;
  subSectorId: string;
};

const OppModal = ({
  currentOpp,
  handleModal,
}: {
  currentOpp: oppotunityItemPartialFetched;
  handleModal: () => void;
}) => {
  const sectorlist: SECTOR = useSelector((state: STATE) => state.SECTOR.SECTOR);
  const subsectorlist: SUB_SECTOR = useSelector(
    (state: STATE) => state.SUB_SECTOR.SUB_SECTOR
  );

  const initialValues: MyFormValues = {
    tech_require: currentOpp.technologyPartnerRequirement,
    description: currentOpp.description,
    sectorId: currentOpp.sectorId,
    subSectorId: currentOpp.subSectorId,
  };

  const dispatch: Dispatch<any> = useDispatch();

  const [files, setFiles] = useState<files>({
    image: false,
    document: false,
    imageFile: null,
    documentFiles: null,
  });

  const handleImage = function (e: React.ChangeEvent<HTMLInputElement>) {
    const fileList = e.target.files;

    if (!fileList) return;

    setFiles({ ...files, imageFile: fileList[0], image: false });
  };

  const handleDocuments = function (e: React.ChangeEvent<HTMLInputElement>) {
    const fileList = e.target.files;

    if (!fileList) return;
    setFiles({ ...files, documentFiles: fileList, document: false });
  };

  const getPresigned = async (content: presignedData) => {
    const data = JSON.stringify(content);
    let result: string = "";
    const config = {
      method: RequestMethods.POST,
      url: `${process.env.REACT_APP_BASE_API}/users/getPresignedUrl`,
      headers: {
        "Content-Type": "application/json",
      },
      data,
    };

    await axios(config)
      .then(function (response) {
        result = response.data;
      })
      .catch(function (error) {
        result = "error";
        dispatch(failToast());
      });

    return result;
  };

  const postLogo = async (signed: string) => {
    var config = {
      method: RequestMethods.PUT,
      url: signed,
      headers: {
        "Content-Type": CONTENT_TYPE,
        "Access-Control-Allow-Origin": true,
      },
      data: files.imageFile,
    };

    await axios(config)
      .then(function (response) {
        dispatch(successToast());
      })
      .catch(function (error) {});
  };

  const postDocument = async (signed: string, file: File) => {
    var config = {
      method: RequestMethods.PUT,
      url: signed,
      headers: {
        "Content-Type": CONTENT_TYPE_DOC,
        "Access-Control-Allow-Origin": true,
      },
      data: file,
    };

    await axios(config)
      .then(function (response) {
        dispatch(successToast());
      })
      .catch(function (error) {});
  };

  const handleCreate = async (values: MyFormValues) => {
    let signedLogoURL: string = "";
    let signedDocumentURLWhole: string = "";

    if (files.imageFile) {
      const signedLogoData: presignedData = {
        fileName: files.imageFile.name,
        filePath: FILE_PATH.PRODUCTS_IMAGE,
        fileType: FILE_TYPE.PNG,
      };
      signedLogoURL = await getPresigned(signedLogoData);
      await postLogo(signedLogoURL);
    }

    if (files.documentFiles) {
      Array.from(files.documentFiles).forEach(async (document, i) => {
        let signedDocumentData: presignedData = {
          fileName: document.name,
          filePath: FILE_PATH.COMPANY_DOCS,
          fileType: FILE_TYPE.PDF,
        };
        let tempurl = (await getPresigned(signedDocumentData)) + " ";
        signedDocumentURLWhole = tempurl.split("?")[0] + " ";
        postDocument(tempurl, document);
      });
    }
    setTimeout(() => {
      const data: oppotunityUpdateItem = {
        opportunityId: currentOpp._id,
        technologyPartnerRequirement: values.tech_require,
        description: values.description,
        document: files.documentFiles
          ? signedDocumentURLWhole
          : currentOpp.document,
        image: files.imageFile ? signedLogoURL.split("?")[0] : currentOpp.image,
        sectorId: values.sectorId,
        subSectorId: values.subSectorId,
      };
      dispatch(updateOppotunity(data));
      handleModal();
    }, 2000);
  };
  const content = (
    <div className="z-10 pb-[200px] pt-4 fixed w-full h-screen bg-slate-700 bg-opacity-70 top-0 left-0 flex justify-center overflow-auto">
      <div className="product-modal-main relative">
        <div className="flex">
          <h4 className="text-lg font-roboto">Edit</h4>
          <button
            onClick={() => {
              handleModal();
            }}
            className="absolute right-0 top-0 font-bold hover:text-red-500 duration-300 border border-slate-100 px-3 py-1 rounded"
          >
            X
          </button>
        </div>
        <Formik
          initialValues={initialValues}
          validationSchema={oppSchema}
          onSubmit={(values) => handleCreate(values)}
        >
          {({ handleChange, setFieldValue, handleSubmit, errors, values }) => (
            <>
              <Form className="flex flex-col w-full space-y-4 justify-center items-center">
                <div className="flex flex-col w-full space-x-2 relative">
                  <div className="relative">
                    <textarea
                      defaultValue={currentOpp.technologyPartnerRequirement}
                      onChange={(e) =>
                        setFieldValue("tech_require", e.target.value)
                      }
                      id="floating_outlined"
                      className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                      placeholder=" "
                    />
                    <label
                      htmlFor="floating_outlined"
                      className="absolute text-sm text-gray-500  duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white  px-2 peer-focus:px-2 peer-focus:text-blue-600  peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1"
                    >
                      Technology Requirement
                    </label>
                  </div>
                  {errors.tech_require && (
                    <p
                      id="filled_error_help"
                      className="mt-2 text-xs text-red-600 dark:text-red-400"
                    >
                      {errors.tech_require}
                    </p>
                  )}
                </div>
                <div className="flex flex-col w-full space-x-2 relative">
                  <div className="relative">
                    <Editor
                      apiKey={process.env.REACT_APP_TINYMCE_API_KEY}
                      id="floating_outlined"
                      init={{
                        height: 200,
                        menubar: false,
                        plugins: [
                          "advlist autolink lists link image charmap print preview anchor",
                          "searchreplace visualblocks code fullscreen",
                          "insertdatetime media table paste code help wordcount",
                        ],
                        toolbar:
                          "undo redo | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | help",
                        placeholder:
                          "Write your opportunity description here...",
                      }}
                      initialValue={currentOpp?.description}
                      onEditorChange={(e) => setFieldValue("description", e)}
                    />
                    <label
                      htmlFor="floating_outlined"
                      className="absolute text-sm text-gray-500  duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white  px-2 peer-focus:px-2 peer-focus:text-blue-600  peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1"
                    >
                      Opportunity Description
                    </label>
                  </div>
                  {errors.description && (
                    <p
                      id="filled_error_help"
                      className="mt-2 text-xs text-red-600 dark:text-red-400"
                    >
                      {errors.description}
                    </p>
                  )}
                </div>

                <div className="flex flex-col w-full">
                  <div className="flex flex-row w-full space-x-5 items-center">
                    <h3 className="font-robot text-gray-800 text-sm whitespace-nowrap  ">
                      Sector Type:
                    </h3>
                    <select
                      onChange={(e) =>
                        setFieldValue("sectorId", e.target.value)
                      }
                      defaultValue={currentOpp.sectorId}
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
                    >
                      {sectorlist.SECTOR_LIST.map((item: sectorItem, id) => {
                        return <option value={item._id}>{item.name}</option>;
                      })}
                    </select>
                  </div>
                  {errors.sectorId && (
                    <p
                      id="filled_error_help"
                      className="mt-2 text-xs text-red-600 dark:text-red-400"
                    >
                      {errors.sectorId}
                    </p>
                  )}
                </div>
                <div className="flex flex-col w-full">
                  <div className="flex flex-row w-full space-x-5 items-center">
                    <h3 className="font-robot text-gray-800 text-sm whitespace-nowrap  ">
                      Sub Sector Type:
                    </h3>
                    <select
                      onChange={(e) =>
                        setFieldValue("subsecId", e.target.value)
                      }
                      defaultValue={currentOpp.subSectorId}
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
                    >
                      {subsectorlist.SUB_SECTOR_LIST.map(
                        (item: subsectorItem, id) => {
                          return <option value={item._id}>{item.name}</option>;
                        }
                      )}
                    </select>
                  </div>
                  {errors.subSectorId && (
                    <p
                      id="filled_error_help"
                      className="mt-2 text-xs text-red-600 dark:text-red-400"
                    >
                      {errors.subSectorId}
                    </p>
                  )}
                </div>
                <div className="flex flex-col w-full">
                  <label
                    className="block mb-2 text-sm font-medium text-gray-900"
                    htmlFor="logo"
                  >
                    Upload Product Image{" "}
                    <span className="text-red-500 text-xs">(.png only)</span>
                  </label>
                  <input
                    onChange={handleImage}
                    accept=".png"
                    type="file"
                    id="logo"
                    aria-label="company-logo"
                    className="modal-input"
                    placeholder="Click to upload Company's Logo"
                  />
                  <p
                    id="filled_error_help"
                    className={
                      "mt-2 text-xs text-red-600 dark:text-red-400 " +
                      (!files.image ? "hidden" : "")
                    }
                  >
                    {"Please upload product Image"}
                  </p>
                </div>
                <div className="flex flex-col w-full">
                  <label
                    className="block mb-2 text-sm font-medium text-gray-900"
                    htmlFor="documents"
                  >
                    Upload Product Documents
                    <span className="text-red-500 text-xs">(.pdf only)</span>
                  </label>
                  <input
                    onChange={handleDocuments}
                    accept=".pdf"
                    type="file"
                    id="documents"
                    multiple
                    aria-label="company-documents"
                    className="modal-input"
                    placeholder="Click to upload Document"
                  />
                  <p
                    id="filled_error_help"
                    className={
                      "mt-2 text-xs text-red-600 dark:text-red-400 " +
                      (!files.document ? "hidden" : "")
                    }
                  >
                    {"Please upload product documents"}
                  </p>
                </div>
                <button
                  type="submit"
                  onClick={() => handleSubmit}
                  className="button active"
                >
                  Update
                </button>
              </Form>
            </>
          )}
        </Formik>
      </div>
    </div>
  );
  return ReactDOM.createPortal(content, document.body);
};

const Card = ({
  item,
  handleSelect,
}: {
  item: oppotunityItemPartialFetched;
  handleSelect: (data: oppotunityItemPartialFetched) => void;
}) => {
  const dispatch: Dispatch<any> = useDispatch();
  const navigate = useNavigate();

  const handleView = () => {
    navigate(YOUR_OPP, { state: { product: item } });
  };

  const DOP = new Date(item.createdAt);

  const handleEdit = (e: any) => {
    e.stopPropagation();

    handleSelect(item);
  };

  return (
    <div onClick={handleView} className="product-card-main">
      <div className="product-card-img">
        <img
          src={item.image === NONE ? oppbanner : item.image}
          className="flex  w-full lg:h-56  rounded-t-lg hover:rounded-lg object-contain"
          alt={item.description}
          loading="lazy"
        />
      </div>
      <div className="product-card-title">
        <h4 className="font-roboto text-xs text-slate-500 ">
          {"Posted on:"}
          {DOP.toLocaleString("default", {
            month: "short",
            day: "2-digit",
            year: "numeric",
          })}
        </h4>
      </div>
      <div className="product-card-title">
        <h4 className="font-roboto text-lg text-GTI-BLUE-default ">
          {item.technologyPartnerRequirement.length > 25
            ? item.technologyPartnerRequirement.substring(0, 25) + "..."
            : item.technologyPartnerRequirement}
        </h4>
      </div>
      <div className="product-card-title">
        <h4 className="font-roboto  text-xs text-slate-500 ">
          {item.description.length > 150 ? (
            <RenderHTML html={item.description.substring(0, 150) + "..."} />
          ) : (
            <RenderHTML html={item.description} />
          )}
        </h4>
      </div>
      <div className="flex flex-row justify-evenly">
        <button
          onClick={handleEdit}
          className={`inline-flex mr-2 items-center gap-2 p-1 px-2 font-medium text-center rounded-lg border-2 text-black`}
        >
          <MdOutlineEdit className="w-6 h-6 " /> {" Edit"}
        </button>
      </div>
    </div>
  );
};

const YourOpportunityList = ({
  type,
  skip,
  limit,
  secId,
  subsecId,
}: {
  type: number;
  skip: string;
  limit: string;
  subsecId: string;
  secId: string;
}) => {
  const dispatch: Dispatch<any> = useDispatch();
  const spinner: LOADER = useSelector((state: STATE) => state.LOADER.LOADER);
  const opp: OPP = useSelector((state: STATE) => state.OPP.OPP);
  const user: USER = useSelector((state: STATE) => state.USER.USER);
  const navigate = useNavigate();
  useEffect(() => {
    switch (type) {
      case 0:
        dispatch(getYourOpportunities(skip, limit, APPROVED));
        break;
      case 1:
        dispatch(getYourOpportunities(skip, limit, PENDING));
        break;
      default:
        dispatch(getYourOpportunities(skip, limit, REJECTED));
    }
  }, [secId, subsecId, skip, type]);

  const [oppEditModal, setModal] = useState(false);

  const [currentOpp, setOpp] = useState<oppotunityItemPartialFetched>({
    technologyPartnerRequirement: "",
    _id: "",
    description: "",
    image: "",
    displayOnHomePage: false,
    isApprovedBySubAdmin: false,
    isApprovedByAdmin: false,
    isRejected: false,
    document: "",
    sectorId: "",
    subSectorId: "",
    userId: "",
    createdAt: "",
    __v: -1,
    sectors: {
      _id: "",
      name: "",
      slug: "",
      image: "",
      createdAt: "",
      __v: -1,
    },
    subsectors: {
      _id: "",
      name: "",
      slug: "",
      sectorId: "",
      createdAt: "",
      __v: -1,
    },
    company: {
      logo: "",
      name: "",
    },
  });

  const handleModel = () => {
    setModal(!oppEditModal);
    switch (type) {
      case 0:
        dispatch(getYourOpportunities(skip, limit, APPROVED));
        break;
      case 1:
        dispatch(getYourOpportunities(skip, limit, PENDING));
        break;
      default:
        dispatch(getYourOpportunities(skip, limit, REJECTED));
    }
  };

  const handleSelect = (item: oppotunityItemPartialFetched) => {
    if (item.__v !== -1) {
      setOpp(item);
      handleModel();
    }
  };

  return (
    <div className="flex w-full h-full justify-center items-center px-10 flex-col">
      {spinner.SPINNER ? (
        <ScreenSpinner />
      ) : opp && opp.YOUR_OPP_LIST.length > 0 ? (
        <div className="opp-list-main">
          {opp &&
            opp.YOUR_OPP_LIST.map(
              (item: oppotunityItemPartialFetched, id: number) => {
                return (
                  <Card item={item} key={id} handleSelect={handleSelect} />
                );
              }
            )}
        </div>
      ) : (
        <p className="text-gray-400">No data to display</p>
      )}

      {oppEditModal && (
        <OppModal currentOpp={currentOpp} handleModal={handleModel} />
      )}
    </div>
  );
};

export default YourOpportunityList;
