import React, { Dispatch, useEffect, useState } from "react";
import { SlCalender } from "react-icons/sl";
import { useDispatch, useSelector } from "react-redux";
import { BsBell } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";

import { notificationItem, title, metaData } from "../constants";
import { getAllNotification } from "../../store/actioncreators/notificationactions";
import { markNotification } from "../../api/user";
import { getQueryParams } from "../../utils";
import globe from "../../assests/home/globe.png";

const Card = ({ item }: { item: notificationItem }) => {
  const DOC = new Date(item.createdAt);
  return (
    <div className="flex flex-row w-5/6 my-2 justify-center">
      <a
        href={item.link}
        className={`flex flex-row w-4/5 items-center justify-center rounded-lg px-2 ${
          item.isViewed ? "bg-gray-100" : "bg-gray-300"
        }`}
      >
        <div className="flex flex-row items-start justify-start p-2 w-full  ">
          {item.image ? (
            <img
              src={item.image}
              className="rounded-full lg:w-16 lg:h-16 w-8 h-8 object-contain"
              alt="Notifications"
            />
          ) : (
            <BsBell />
          )}
          <div className="flex flex-col p-3">
            <p className="text-sm font-roboto text-left text-slate-800">
              {item.title ? item.title : "Error in displaying message"}
            </p>
          </div>
        </div>
        <div className="flex flex-col w-1/5 items-end justify-start p-5 text-slate-400">
          <p className="flex items-center text-sm text-center font-roboto text-slate-400 whitespace-nowrap">
            <SlCalender className="w-4 h-4 mr-1 text-slate-400" />
            {DOC.toLocaleString("default", {
              month: "long",
              day: "2-digit",
              year: "numeric",
            })}
          </p>
          <p className="text-sm text-center font-roboto text-slate-400">
            {DOC.toLocaleString("default", {
              hour: "2-digit",
              minute: "2-digit",
              hourCycle: "h24",
            })}
          </p>
        </div>
      </a>
    </div>
  );
};

const NotificationView = () => {
  const dispatch: Dispatch<any> = useDispatch();
  const notifications: NOTIFICATION = useSelector(
    (state: STATE) => state.NOTIFICATION.NOTIFICATION_LIST
  );

  const navigate = useNavigate();
  const skip = getQueryParams("skip") ?? "0";
  const [page, setPage] = useState({
    skip: "0",
    limit: "8",
  });

  const fetchData = (val: number) => {
    let newSkip = parseInt(page.skip) + val;
    if (newSkip >= 0) {
      navigate(`/notifications?skip=${newSkip}`);
      setPage({
        skip: newSkip.toString(),
        limit: page.limit,
      });
      dispatch(getAllNotification(skip ? skip : "0", "12"));
    }
  };

  useEffect(() => {
    dispatch(getAllNotification(skip ? skip : "0", "12"));
    markNotification();
    window.scrollTo(0, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [skip]);

  return (
    <div className="flex flex-col w-full p-10 items-center justify-center">
      <Helmet>
        <title>{title.NOTIFICATIONS}</title>
        <meta
          name="description"
          key="description"
          content={metaData.NOTIFICATIONS}
        />
        <meta name="title" key="title" content="Notifications" />
        <meta property="og:title" content="Notifications" />
        <meta property="og:description" content="Notifications" />
        <meta property="og:image" content={globe} />
        <meta
          property="og:url"
          content={`${process.env.REACT_APP_BASE_URL}/notifications`}
        />
        <meta property="og:type" content="website" />
        <meta name="twitter:title" content="Notifications" />
        <meta name="twitter:description" content={metaData.NOTIFICATIONS} />
        <meta name="twitter:image" content={globe} />
        <meta name="twitter:card" content="Notifications" />
      </Helmet>
      <p className="text-4xl font-roboto font-semibold py-10">Notifications</p>
      {notifications.NOTIFICATION.map((item: notificationItem, id: number) => {
        return <Card item={item} key={id} />;
      })}
      <div className="flex mb-5 py-5">
        <button
          disabled={page.skip === "0"}
          onClick={() => {
            fetchData(-1);
          }}
          className="inline-flex items-center py-2 px-4 text-sm font-medium text-GTI-BLUE-default disabled:text-gray-500 bg-white  rounded-lg border border-gray-300 hover:bg-gray-100 hover:text-gray-700 "
        >
          <svg
            aria-hidden="true"
            className="mr-2 w-5 h-5"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              d="M7.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l2.293 2.293a1 1 0 010 1.414z"
              clipRule="evenodd"
            ></path>
          </svg>
          Previous
        </button>
        <button
          disabled
          className="inline-flex items-center mx-2 py-2 px-4 text-sm font-medium text-gray-500 bg-white rounded-lg border border-gray-300 hover:bg-gray-100 hover:text-gray-700 "
        >
          {parseInt(page.skip) + 1}
        </button>
        <button
          disabled={
            (parseInt(page.skip) + 1) * parseInt(page.limit) >=
            notifications?.COUNT
          }
          onClick={() => {
            fetchData(1);
          }}
          className="inline-flex items-center py-2 px-4 text-sm font-medium text-GTI-BLUE-default disabled:text-gray-500 bg-white rounded-lg border border-gray-300 hover:bg-gray-100 hover:text-gray-700 "
        >
          Next
          <svg
            aria-hidden="true"
            className="ml-2 w-5 h-5"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z"
              clipRule="evenodd"
            ></path>
          </svg>
        </button>
      </div>
    </div>
  );
};

export default NotificationView;
