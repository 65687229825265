import axios from "axios";
import { store } from "../../store";
import * as INNOVATION from "../..//Components/constants";
import {
  INNOVATION_DELETE,
  INNOVATION_GET,
  INNOVATION_GET_ID,
  INNOVATION_REGISTERED_USERS,
} from "../actiontypes/actionTypes";
import { spinnerLoaderStart, spinnerLoaderStop } from "./loaderactions";
import { notify } from "../../utils";

export const createCall =
  (item: INNOVATION.InnovationItem) =>
  (dispatch: CREATE_INNOVATION_DISPATCH) => {
    var data = JSON.stringify({
      title: item.title,
      description: item.description,
      companyName: item.companyName,
      companyLogo: item.companyLogo,
      companyDocument: item.companyDocument,
      imageUrl: item.imageUrl,
      startDate: item.startDate,
      endDate: item.endDate,
    });

    var config = {
      method: "post",
      url: `${process.env.REACT_APP_BASE_API}/innovation-calls`,
      headers: {
        Authorization: `Bearer ${store.getState().USER.USER.token}`,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        // console.log("Innovation Call created ");
      })
      .catch(function (error) {
        // console.log("Innovation Call not created ", error);
      });
  };

export const updateCall =
  (item: INNOVATION.InnovationUpdateItem) =>
  (dispatch: UPDATE_INNOVATION_DISPATCH) => {
    var data = JSON.stringify({
      innovationCallsId: item.innovationCallsId,
      title: item.title,
      description: item.description,
      companyName: item.companyName,
      companyLogo: item.companyLogo,
      companyDocument: item.companyDocument,
      imageUrl: item.imageUrl,
      startDate: item.startDate,
      endDate: item.endDate,
    });

    var config = {
      method: "patch",
      url: `${process.env.REACT_APP_BASE_API}/innovation-calls`,
      headers: {
        Authorization: `Bearer ${store.getState().USER.USER.token}`,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        // console.log("call updated ");
      })
      .catch(function (error) {
        // console.log("call not updated ", error);
      });
  };

export const getCalls = (skip: string, limit: string) => (dispatch: any) => {
  dispatch(spinnerLoaderStart());

  var config = {
    method: "get",
    url: `${process.env.REACT_APP_BASE_API}/innovation-calls?skip=${skip}&limit=${limit}`,
    headers: {
      "Content-Type": "application/json",
    },
  };

  axios(config)
    .then(function (response) {
      const list: INNOVATION = {
        INNOVATION_LIST: response.data.innovationCalls,
        INNOVATION_COUNT: response.data.innovationsCallsCount,
        USER_LIST: [],
      };
      const action: INNOVATION_ACTION = {
        type: INNOVATION_GET,
        INNOVATION: list,
      };
      dispatch(action);
      dispatch(spinnerLoaderStop());
    })
    .catch(function (error) {
      // console.log("Calls not fetched ", error);
      const list: INNOVATION = {
        INNOVATION_LIST: [],
        INNOVATION_COUNT: 0,
        USER_LIST: [],
      };
      dispatch(spinnerLoaderStop());
      const action: INNOVATION_ACTION = {
        type: INNOVATION_GET,
        INNOVATION: list,
      };
      dispatch(action);
    });
};

export const getCallId =
  (id: string) => (dispatch: GET_INNOVATION_ID_DISPATCH) => {
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_BASE_API}/innovation-calls/${id}`,
      headers: {
        "Content-Type": "application/json",
      },
    };
    axios(config)
      .then(function (response) {
        const list: INNOVATION = {
          INNOVATION_LIST: response.data,
          INNOVATION_COUNT: 0,
          USER_LIST: [],
        };
        const action: INNOVATION_ACTION = {
          type: INNOVATION_GET_ID,
          INNOVATION: list,
        };
        // console.log("Innovaton id fetched ");
        return dispatch(action);
      })
      .catch(function (error) {
        // console.log("Innovation id not fetched ", error);
      });
  };
export const getCallUsers =
  (id: string) => (dispatch: GET_INNOVATION_USERS_DISPATCH) => {
    var config = {
      method: "post",
      url: `${process.env.REACT_APP_BASE_API}/innovation-calls/getregisteredusers/${id}`,
      headers: {
        Authorization: `Bearer ${store.getState().USER.USER.token}`,
        "Content-Type": "application/json",
      },
    };

    axios(config)
      .then(function (response) {
        const list: INNOVATION = {
          INNOVATION_LIST: [],
          INNOVATION_COUNT: 0,
          USER_LIST: response.data,
        };
        const action: INNOVATION_ACTION = {
          type: INNOVATION_REGISTERED_USERS,
          INNOVATION: list,
        };
        // console.log("call Users fetched ");
        return dispatch(action);
      })
      .catch(function (error) {
        // console.log("call users not fetched ", error);
      });
  };
export const deleteCallId =
  (id: string) => (dispatch: DELETE_INNOVATION_DISPATCH) => {
    var config = {
      method: "delete",
      url: `${process.env.REACT_APP_BASE_API}/innovation-calls/${id}`,
      headers: {
        Authorization: `Bearer ${store.getState().USER.USER.token}`,
        "Content-Type": "application/json",
      },
    };

    axios(config)
      .then(function (response) {
        const list: INNOVATION = {
          INNOVATION_LIST: response.data,
          INNOVATION_COUNT: 0,
          USER_LIST: [],
        };
        const action: INNOVATION_ACTION = {
          type: INNOVATION_DELETE,
          INNOVATION: list,
        };
        // console.log("Call deleted ");
        return dispatch(action);
      })
      .catch(function (error) {
        // console.log("Call not deleted ", error);
      });
  };

export const registerCall =
  (id: string) => (dispatch: REGISTER_INNOVATION_DISPATCH) => {
    var data = {
      innovationCallId: id,
      registrationDetails: {},
    };
    var config = {
      method: "post",
      url: `${process.env.REACT_APP_BASE_API}/innovation-calls/register`,
      headers: {
        Authorization: `Bearer ${store.getState().USER.USER.token}`,
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios(config)
      .then(function (response) {
        notify("Registered Successfully!", "success");
      })
      .catch(function (error) {
        notify("Failed to register", "error");
      });
  };
