import axios from "axios";
import { store } from "../../store";
import * as SECTOR from "../../Components/constants";
import {
  FOLLOW_USER,
  SECTOR_GET,
  TOAST_SUCCESS,
  UNFOLLOW_USER,
} from "../actiontypes/actionTypes";
import { notify } from "../../utils";

export const followUser = (id: string) => async (dispatch: any) => {
  var config = {
    method: "patch",
    url: `${process.env.REACT_APP_BASE_API}/users/followuser/${id}`,
    headers: {
      Authorization: `Bearer ${store.getState().USER.USER.token}`,
      "Content-Type": "application/json",
    },
  };

  await axios(config)
    .then(function (response) {
      // console.log("Following user ");
      const action1: FOLLOW_ACTION = {
        type: FOLLOW_USER,
      };
      const action = async (dispatch: any) => {
        dispatch(action1);
      };
      notify("Followed successfully!", "success");
      return dispatch(action);
    })
    .catch(function (error) {
      notify("Failed to follow!", "error");
      // console.log("Unable to follow user ", error);
    });
};
export const unfollowUser = (id: string) => async (dispatch: any) => {
  var config = {
    method: "patch",
    url: `${process.env.REACT_APP_BASE_API}/users/unfollowuser/${id}`,
    headers: {
      Authorization: `Bearer ${store.getState().USER.USER.token}`,
      "Content-Type": "application/json",
    },
  };

  await axios(config)
    .then(function (response) {
      // console.log("Unfollowing user ");
      const action1: FOLLOW_ACTION = {
        type: UNFOLLOW_USER,
      };
      const action = async (dispatch: any) => {
        dispatch(action1);
      };

      notify("Unfollowed successfully!", "success");
      return dispatch(action);
    })
    .catch(function (error) {
      notify("Failed to unfollow!", "success");
      // console.log("Unable to unfollow user ", error);
    });
};
