import * as ACTION from "../actiontypes/actionTypes";

const initialState: NOTIFICATION_STATE = {
  NOTIFICATION_LIST: {
    NOTIFICATION: [],
    NOTIFICATION_NOT_VIEWED: [],
    COUNT: 0,
  },
};

export const notificationreducer = (
  state: NOTIFICATION_STATE = initialState,
  action: NOTIFICATION_ACTION
): NOTIFICATION_STATE => {
  switch (action.type) {
    case ACTION.NOTIFICATION_GET:
      let notifications: NOTIFICATION = action.NOTIFICATION.NOTIFICATION_LIST;
      return { ...state, NOTIFICATION_LIST: notifications };
    default:
      return { ...state };
  }
  // return state;
};
