import React from "react";
import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import axios from "axios";
import { resetUser } from "../../store/actioncreators/actionCreators";
import { Dispatch } from "redux";
import { redirect } from "react-router-dom";

const Password = () => {
  let [oldpass, setOldPass] = useState("");
  let [sOP, setSOP] = useState(false);

  let [newpass, setNewPass] = useState("");
  let [sNP, setSNP] = useState(false);

  let [rnewpass, rsetNewPass] = useState("");
  let [sRNP, setSRNP] = useState(false);

  let regexpPass = new RegExp(
    "^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,16}$"
  );

  const dispatch: Dispatch<any> = useDispatch();
  const resetUserDetails = React.useCallback(
    () => dispatch(resetUser(currentUser)),
    [dispatch]
  );

  const currentUser: USER = useSelector((state: STATE) => state.USER.USER);

  let check = () => {
    let opass = document.getElementById("oPass") as HTMLInputElement;
    let nPass = document.getElementById("nPass") as HTMLInputElement;
    let rnPass = document.getElementById("rnPass") as HTMLInputElement;

    if (!oldpass) {
      opass?.classList.remove("profile-content-head-2");
      opass?.classList.add("profile-content-head-2-error");
      opass.textContent = "Enter current password";
      return false;
    } else {
      opass?.classList.remove("profile-content-head-2-error");
      opass?.classList.add("profile-content-head-2");
    }

    if (!regexpPass.test(newpass)) {
      nPass?.classList.remove("profile-content-head-2");
      nPass?.classList.add("profile-content-head-2-error");
      nPass.textContent = "Enter strong Password";
      return false;
    } else if (newpass === oldpass) {
      nPass?.classList.remove("profile-content-head-2");
      nPass?.classList.add("profile-content-head-2-error");
      nPass.textContent = "New Password should not be same as Old Password";
    } else {
      nPass?.classList.remove("profile-content-head-2-error");
      nPass?.classList.add("profile-content-head-2");
    }
  
    if (newpass !== rnewpass) {
      rnPass?.classList.remove("profile-content-head-2");
      rnPass?.classList.add("profile-content-head-2-error");
      rnPass.textContent = "Re-entered Password does not matches the New Password";
      return false;
    } else {
      rnPass?.classList.remove("profile-content-head-2-error");
      rnPass?.classList.add("profile-content-head-2");
    }

    return true;
  };

  let reset = () => {
    var data = JSON.stringify({
      password: newpass,
      oldPassword: oldpass,
    });

    var config = {
      method: "patch",
      url: `${process.env.REACT_APP_BASE_API}/users/resetpassword`,
      headers: {
        Authorization: `Bearer ${currentUser.token}`,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        // console.log("password reset request send", response);
        resetUserDetails();
        redirect(process.env.REACT_APP_HOME!);
      })
      .catch(function (error) {
        // console.log(error);
      });
  };

  let handleSaveCompany = async () => {
    if (check()) {
      reset();
    }
  };

  return (
    <div className="flex justify-center space-x-5 duration-200 ease-in-out">
      <div className="w-3/4 border-2 rounded-lg">
        <div className="profile-row-group">
          <div className="profile-group">
            <div className="relative mb-3">
              <label id="oPass" className="profile-content-head-2">
                Old Password
              </label>
            </div>
            <div className="flex items-center space-x-3">
              <input
                id="oldpass"
                type={sOP ? "text" : "password"}
                value={oldpass}
                onChange={(e) => {
                  setOldPass(e.target.value);
                }}
                className="bg-gray-50 border border-gray-300 text-gray-900 placeholder-slate-400 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                placeholder={"Your current password"}
              />
              {sOP ? (
                <AiFillEye
                  onClick={() => {
                    setSOP(!sOP);
                  }}
                  style={{ width: "2em", height: "2em" }}
                />
              ) : (
                <AiFillEyeInvisible
                  onClick={() => {
                    setSOP(!sOP);
                  }}
                  style={{ width: "2em", height: "2em" }}
                />
              )}
            </div>
          </div>
        </div>
        <div className="profile-row-group">
          <div className="profile-group">
            <div className="relative mb-3">
              <label id="nPass" className="profile-content-head-2">
                New Password
              </label>
            </div>
            <div className="flex items-center space-x-3">
              <input
                id="newpass"
                type={sNP ? "text" : "password"}
                value={newpass}
                onChange={(e) => {
                  setNewPass(e.target.value);
                }}
                className="bg-gray-50 border border-gray-300 text-gray-900 placeholder-slate-400 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                placeholder={"New Password"}
              />
              {sNP ? (
                <AiFillEye
                  onClick={() => {
                    setSNP(!sNP);
                  }}
                  style={{ width: "2em", height: "2em" }}
                />
              ) : (
                <AiFillEyeInvisible
                  onClick={() => {
                    setSNP(!sNP);
                  }}
                  style={{ width: "2em", height: "2em" }}
                />
              )}
            </div>
          </div>
        </div>
        <div className="profile-row-group">
          <div className="profile-group">
            <div className="relative mb-3">
              <label id="rnPass" className="profile-content-head-2">
                Re-enter new password
              </label>
            </div>
            <div className="flex items-center space-x-3">
              <input
                id="rnewpass"
                type={sRNP ? "text" : "password"}
                value={rnewpass}
                onChange={(e) => {
                  rsetNewPass(e.target.value);
                }}
                className="bg-gray-50 border border-gray-300 text-gray-900 placeholder-slate-400 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                placeholder={"Re-enter new password"}
              />
              {sRNP ? (
                <AiFillEye
                  onClick={() => {
                    setSRNP(!sRNP);
                  }}
                  style={{ width: "2em", height: "2em" }}
                />
              ) : (
                <AiFillEyeInvisible
                  onClick={() => {
                    setSRNP(!sRNP);
                  }}
                  style={{ width: "2em", height: "2em" }}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="my-5">
        <button
          type="button"
          onClick={() => {
            handleSaveCompany();
          }}
          className="text-white font-roboto bg-GTI-BLUE-default hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-10 py-3 mr-2 mb-2 focus:outline-none "
        >
          Save
        </button>
      </div>
    </div>
  );
};

export default Password;
