import React, { Dispatch, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import articlebanner from "../../assests/banners/articlebanner.png";
import ReactPlayer from "react-player";
import { articleItemFetched, LIMIT, NONE } from "../constants";
import { getArticles } from "../../store/actioncreators/articleactions";
import { useNavigate } from "react-router-dom";
import { getQueryParams } from "../../utils";

const Card = ({ item, secId }: { item: articleItemFetched; secId: string }) => {
  const DOC = new Date(item.createdAt);
  const dispatch: Dispatch<any> = useDispatch();
  const navigate = useNavigate();

  const handleView = () => {
    navigate(`/articles/${item._id}`, { state: { id: item._id } });
  };

  return (
    <div
      className="w-full max-w-[350px] cursor-pointer overflow-hidden rounded-lg bg-white shadow h-full max-h-[320px]"
      onClick={handleView}
    >
      {!item.youtubeLink || item.youtubeLink === "none" ? (
        <img
          src={item?.imageUrl ?? ""}
          className="aspect-video w-full object-contain"
          alt={item.topic}
        />
      ) : (
        <ReactPlayer
          url={item.youtubeLink === NONE ? articlebanner : item.youtubeLink}
          className="flex rounded w-full justify-center"
          height="200px"
          width="auto"
          alt={item.topic}
          light
        />
      )}
      <div className="p-4">
        <p className="mb-1 text-sm text-primary-500">
          <time>
            {DOC.toLocaleString("default", {
              month: "short",
              day: "2-digit",
              year: "numeric",
            })}
          </time>
        </p>
        <h3
          dangerouslySetInnerHTML={{ __html: item.topic }}
          className="text-sm md:text-xl font-medium text-gray-900 truncate"
        ></h3>
        <p className="text-sm mt-1 text-gray-500 truncate">
          {item.shortDescription}
        </p>
      </div>
    </div>
  );
};

const ArticlesList = ({
  skip: parentSkip,
  limit: parentLimit,
  secId,
}: {
  skip: string;
  limit: string;
  secId: string;
}) => {
  const dispatch: Dispatch<any> = useDispatch();
  const articles: ARTICLE = useSelector(
    (state: STATE) => state.ARTICLE.ARTICLE
  );

  const [loadMore, setMore] = useState(false);
  const navigate = useNavigate();
  const [page, setPage] = useState({
    skip: "0",
    limit: LIMIT,
  });
  const skip = getQueryParams("skip") ?? "0";
  const [maxSkip, setMaxSkip] = useState(0);

  const fetchData = (val: number) => {
    let newSkip = parseInt(page.skip) + val;
    if (newSkip >= 0) {
      navigate(`/articles?skip=${newSkip}`);
      setPage({
        skip: newSkip.toString(),
        limit: page.limit,
      });
      dispatch(getArticles(secId, newSkip.toString(), page.limit));
    }
  };

  useEffect(() => {
    setMaxSkip(Math.ceil(articles.articlesCount / 9));
  }, [page, articles]);

  useEffect(() => {
    dispatch(getArticles(secId, skip, page.limit));
    setPage({
      skip: skip ? skip : "0",
      limit: page.limit,
    });
    window.scrollTo(0, 0);
  }, [skip, secId]);

  return (
    <div className="flex flex-col justify-center items-center">
      <div className="w-full flex flex-row flex-wrap gap-20 p-4 items-center justify-center">
        {articles.articles.map((item: articleItemFetched, id: number) => {
          return <Card item={item} key={id} secId={secId} />;
        })}
      </div>
      <div className="flex justify-center mb-5 py-5">
        <div className="flex mb-5 py-5">
          <button
            disabled={page.skip === "0"}
            onClick={() => {
              fetchData(-1);
            }}
            className="inline-flex items-center py-2 px-4 text-sm font-medium text-GTI-BLUE-default disabled:text-gray-500 bg-white  rounded-lg border border-gray-300 hover:bg-gray-100 hover:text-gray-700 "
          >
            <svg
              aria-hidden="true"
              className="mr-2 w-5 h-5"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M7.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l2.293 2.293a1 1 0 010 1.414z"
                clipRule="evenodd"
              ></path>
            </svg>
            Previous
          </button>
          {parseInt(page.skip) + 1 <= maxSkip && (
            <button
              onClick={() => {
                fetchData(0);
              }}
              className="inline-flex items-center mx-2 py-2 px-4 text-sm font-medium text-gray-500 bg-white rounded-lg border border-gray-300 hover:bg-gray-100 hover:text-gray-700 "
            >
              {parseInt(page.skip) + 1}
            </button>
          )}
          {parseInt(page.skip) + 2 <= maxSkip && (
            <button
              onClick={() => {
                fetchData(1);
              }}
              className="inline-flex items-center mx-2 py-2 px-4 text-sm font-medium text-gray-500 bg-white rounded-lg border border-gray-300 hover:bg-gray-100 hover:text-gray-700 "
            >
              {parseInt(page.skip) + 2}
            </button>
          )}
          {parseInt(page.skip) + 3 <= maxSkip && (
            <button
              onClick={() => {
                fetchData(2);
              }}
              className="inline-flex items-center mx-2 py-2 px-4 text-sm font-medium text-gray-500 bg-white rounded-lg border border-gray-300 hover:bg-gray-100 hover:text-gray-700 "
            >
              {parseInt(page.skip) + 3}
            </button>
          )}
          {parseInt(page.skip) + 4 <= maxSkip && (
            <button
              disabled
              className="inline-flex items-center mx-2 py-2 px-4 text-sm font-medium text-gray-500 bg-white rounded-lg border border-gray-300 hover:bg-gray-100 hover:text-gray-700 "
            >
              -
            </button>
          )}
          {parseInt(page.skip) + 6 <= maxSkip && (
            <button
              onClick={() => {
                fetchData(maxSkip - parseInt(page.skip) - 3);
              }}
              className="inline-flex items-center mx-2 py-2 px-4 text-sm font-medium text-gray-500 bg-white rounded-lg border border-gray-300 hover:bg-gray-100 hover:text-gray-700 "
            >
              {maxSkip - 2}
            </button>
          )}
          {parseInt(page.skip) + 5 <= maxSkip && (
            <button
              onClick={() => {
                fetchData(maxSkip - parseInt(page.skip) - 2);
              }}
              className="inline-flex items-center mx-2 py-2 px-4 text-sm font-medium text-gray-500 bg-white rounded-lg border border-gray-300 hover:bg-gray-100 hover:text-gray-700 "
            >
              {maxSkip - 1}
            </button>
          )}
          {parseInt(page.skip) + 4 <= maxSkip && (
            <button
              onClick={() => {
                fetchData(maxSkip - parseInt(page.skip) - 1);
              }}
              className="inline-flex items-center mx-2 py-2 px-4 text-sm font-medium text-gray-500 bg-white rounded-lg border border-gray-300 hover:bg-gray-100 hover:text-gray-700 "
            >
              {maxSkip}
            </button>
          )}
          <button
            disabled={
              (parseInt(page.skip) + 1) * parseInt(page.limit) >=
              articles.articlesCount
            }
            onClick={() => {
              fetchData(1);
            }}
            className="inline-flex items-center py-2 px-4 text-sm font-medium text-GTI-BLUE-default disabled:text-gray-500 bg-white rounded-lg border border-gray-300 hover:bg-gray-100 hover:text-gray-700 "
          >
            Next
            <svg
              aria-hidden="true"
              className="ml-2 w-5 h-5"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z"
                clipRule="evenodd"
              ></path>
            </svg>
          </button>
        </div>
      </div>
    </div>
  );
};

export default ArticlesList;
