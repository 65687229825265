import React, { Dispatch, useEffect, useState, useRef } from "react";
import ReactDOM from "react-dom";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { AiOutlineClose } from "react-icons/ai";
import { useDetectClickOutside } from "react-detect-click-outside";
import { Editor } from "@tinymce/tinymce-react";
import { Form, Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { Helmet } from "react-helmet";
import globe from "../../assests/home/globe.png";

import {
  CONTENT_TYPE,
  developmentStage,
  files,
  FILE_PATH,
  FILE_TYPE,
  HEADER_YOUR_TECHNOLOGIES,
  iprStatus,
  LIMIT,
  MyFormValues,
  presignedData,
  productItem,
  sectorItem,
  SKIP,
  subsectorItem,
  title,
  metaData,
} from "../constants";
import { getSector } from "../../store/actioncreators/sectoractions";
import {
  getSubSector,
  getSubSectorBySector,
} from "../../store/actioncreators/sub-sectoractions";
import YourProductList from "./YourProductList";
import {
  failToast,
  successToast,
} from "../../store/actioncreators/toastactions";
import { productSchema } from "../validations/productValidations";
import { notify } from "../../utils";
import { PROFILE_TYPES } from "../../shared/enum";
import SuccessModal from "./SuccessModal";
import { RequestMethods } from "../../shared/RequestMethods";
import { createProduct } from "../../store/actioncreators/productactions";
import "./style.css";

const ProductModal = ({
  changeModalState,
  handleSuccessModal,
}: {
  changeModalState: () => void;
  handleSuccessModal: (isOpen: boolean, state: string, message: string) => void;
}) => {
  const sectorlist: SECTOR = useSelector((state: STATE) => state.SECTOR.SECTOR);
  const user: USER = useSelector((state: STATE) => state.USER.USER);
  const subsectorlist: SUB_SECTOR = useSelector(
    (state: STATE) => state.SUB_SECTOR.SUB_SECTOR
  );

  useEffect(() => {
    if (user.id && user.userType === PROFILE_TYPES.GENERAL_SUBSCRIBER) {
      notify("Unauthorized", "error");
      navigate("/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const initialValues: MyFormValues = {
    name: "",
    description: "",
    sectorId: sectorlist?.SECTOR_LIST?.length
      ? sectorlist.SECTOR_LIST[0]._id
      : "",
    subSectorId: subsectorlist.SUB_SECTOR_LIST?.length
      ? subsectorlist.SUB_SECTOR_LIST[0]?._id
      : "",
    developmentStage: developmentStage[0].value,
    iprStatus: [],
  };

  const docInputRef = useRef<HTMLInputElement>(null);
  const vidInputRef = useRef<HTMLInputElement>(null);
  const navigate = useNavigate();
  const dispatch: Dispatch<any> = useDispatch();

  const [iprStatusCheckbox, setIprStatusCheckbox] = useState(iprStatus);

  function handleIprStatus(id: Number) {
    const updatedOptions = iprStatusCheckbox.map((option) => {
      if (option.id === id) {
        return { ...option, checked: !option.checked };
      }
      return option;
    });
    setIprStatusCheckbox(updatedOptions);
  }

  function getIprStatus() {
    const iprStatus = [];
    for (let i = 0; i < iprStatusCheckbox.length; i++) {
      if (iprStatusCheckbox[i].checked) {
        iprStatus.push(iprStatusCheckbox[i].value);
      }
    }
    return iprStatus;
  }

  const [files, setFiles] = useState<files>({
    image: false,
    document: false,
    imageFile: [],
    documents: [],
    videos: [],
  });

  const handleImage = function (e: React.ChangeEvent<HTMLInputElement>) {
    const fileList = e.target.files || [];
    if (!fileList) return;

    const images = [];
    const totalFiles = fileList?.length || 0;
    for (let i = 0; i < totalFiles; i++) {
      images.push(fileList[i]);
    }

    setFiles({ ...files, imageFile: images, image: false });
  };

  const handleDocuments = function (e: React.ChangeEvent<HTMLInputElement>) {
    const fileList = e.target.files || [];
    if (!fileList) return;

    const documents = [];
    const totalFiles = fileList?.length || 0;
    for (let i = 0; i < totalFiles; i++) {
      documents.push(fileList[i]);
    }

    setFiles({ ...files, documents, document: false });
  };

  const handleVideo = (e: React.ChangeEvent<HTMLInputElement>) => {
    const fileList = e?.target.files || [];
    if (!fileList) return;

    const videos = [];
    const totalFiles = fileList?.length || 0;
    for (let i = 0; i < totalFiles; i++) {
      const fileSizeInMB = fileList[i].size / (1024 * 1024); // Convert file size to MB
      if (fileSizeInMB <= 50) {
        videos.push(fileList[i]);
      } else {
        alert("Please select a file smaller than 50 MB.");
      }
    }

    setFiles({ ...files, videos });
  };

  const getPresigned = async (content: presignedData) => {
    const data = JSON.stringify(content);
    let result: string = "";
    const config = {
      method: RequestMethods.POST,
      url: `${process.env.REACT_APP_BASE_API}/users/getPresignedUrl`,
      headers: {
        "Content-Type": "application/json",
      },
      data,
    };

    await axios(config)
      .then(function (response) {
        result = response.data;
      })
      .catch(function (error) {
        result = error.message;
        dispatch(failToast());
      });

    return result;
  };

  const postLogo = async (signed: string, imageFile: File) => {
    var config = {
      method: RequestMethods.PUT,
      url: signed,
      headers: {
        "Content-Type": CONTENT_TYPE,
        "Access-Control-Allow-Origin": true,
      },
      data: imageFile,
    };

    await axios(config)
      .then(function (response) {
        dispatch(successToast());
      })
      .catch(function (error) {});
  };

  const postDocument = async (signed: string, file: File) => {
    var config = {
      method: RequestMethods.PUT,
      url: signed,
      headers: {
        "Content-Type": file.type,
        "Access-Control-Allow-Origin": true,
      },
      data: file,
    };

    await axios(config)
      .then(function (response) {})
      .catch(function (error) {});
  };

  const uploadVideo = async (selectedFile: any, presignedUrl: string) => {
    if (!selectedFile) {
      return;
    }

    try {
      await axios.put(presignedUrl, selectedFile, {
        headers: {
          "Content-Type": selectedFile.type,
          "Access-Control-Allow-Origin": true,
        },
      });
    } catch (error) {
      notify("Failed to upload video", "error");
    }
  };

  const handleCreate = async (values: MyFormValues) => {
    try {
      handleSuccessModal(true, "LOADING", "");
      if (!files.imageFile) {
        return setFiles({ ...files, image: true });
      }
      setFiles({ ...files, document: false, image: false });

      let productImages: string[] = [];
      let videos: string[] = [];
      let documents: string[] = [];

      for (const file of files.imageFile) {
        const signedLogoData: presignedData = {
          fileName: file.name ?? values.name,
          filePath: FILE_PATH.PRODUCTS_IMAGE,
          fileType: FILE_TYPE.PNG,
        };
        let imageUrl = await getPresigned(signedLogoData);
        await postLogo(imageUrl, file);
        productImages.push(imageUrl.split("?")[0]);
      }

      for (const file of files.videos) {
        const videoData: presignedData = {
          fileName: file.name ?? values.name,
          filePath: FILE_PATH.PRODUCTS_VIDEO,
          fileType: file.type ?? "",
        };
        let videoUrl = await getPresigned(videoData);
        await uploadVideo(file, videoUrl);
        videos.push(videoUrl.split("?")[0]);
      }

      for (const file of files.documents) {
        const signedDocumentData: presignedData = {
          fileName: file.name || values.name,
          filePath: FILE_PATH.COMPANY_DOCS,
          fileType: FILE_TYPE.PDF,
        };
        let documentUrl = await getPresigned(signedDocumentData);
        await postDocument(documentUrl, file);
        documents.push(documentUrl.split("?")[0]);
      }

      const data: productItem = {
        name: values.name,
        description: values.description,
        images: productImages,
        sectorId: values.sectorId,
        subSectorId: values.subSectorId,
        developmentStage: values.developmentStage,
        iprStatus: getIprStatus(),
        videos,
        documents,
      };

      dispatch(createProduct(data));
      handleSuccessModal(
        true,
        "SUCCESS",
        "Product has been created successfully, it will be reviewed and approved by Admin."
      );
      changeModalState();
    } catch (err) {
      handleSuccessModal(
        true,
        "ERROR",
        "There was an error while creating the product"
      );
      notify("Failed to create product!", "error");
    }
  };

  const fetchSubsector = async (sectorId: string, currentId: string) => {
    if (currentId !== sectorId || currentId === "")
      dispatch(getSubSectorBySector(sectorId));
  };

  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "auto";
    };
  }, []);

  const content = (
    <div className="z-10 pb-[200px] pt-4 fixed w-full h-screen bg-slate-700 bg-opacity-70 top-0 left-0 flex justify-center overflow-auto">
      <div className="product-modal-main relative">
        <div className="flex">
          <h4 className="text-lg font-roboto font-bold">Create Product</h4>
          <button
            onClick={() => {
              changeModalState();
            }}
            className="absolute right-0 top-0 font-bold hover:text-red-500 border border-slate-100 px-3 py-1 rounded"
          >
            <AiOutlineClose />
          </button>
        </div>
        <Formik
          initialValues={initialValues}
          validationSchema={productSchema}
          onSubmit={(values) => handleCreate(values)}
        >
          {({ handleChange, setFieldValue, handleSubmit, errors, values }) => {
            return (
              <>
                <Form className="flex flex-col w-full space-y-4 justify-center items-center">
                  <div className="flex flex-col w-full space-x-2 relative">
                    <div className="relative">
                      <input
                        onChange={(e) => setFieldValue("name", e.target.value)}
                        type="text"
                        id="floating_outlined"
                        className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                        placeholder=" "
                      />
                      <label
                        htmlFor="floating_outlined"
                        className="absolute text-sm text-gray-500  duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white  px-2 peer-focus:px-2 peer-focus:text-blue-600  peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1"
                      >
                        Product Name
                      </label>
                    </div>
                    {errors.name && (
                      <p
                        id="filled_error_help"
                        className="mt-2 text-xs text-red-600 dark:text-red-400"
                      >
                        {errors.name}
                      </p>
                    )}
                  </div>

                  <div className="flex flex-col w-full space-x-2 relative">
                    <div className="relative">
                      <Editor
                        apiKey={process.env.REACT_APP_TINYMCE_API_KEY}
                        init={{
                          height: 200,
                          menubar: false,
                          plugins: [
                            "advlist autolink lists link image charmap print preview anchor",
                            "searchreplace visualblocks code fullscreen",
                            "insertdatetime media table paste code help wordcount",
                          ],
                          toolbar:
                            "undo redo | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | help",
                          placeholder:
                            "Write your products description here...",
                        }}
                        onEditorChange={(e) => setFieldValue("description", e)}
                      />
                    </div>
                    {errors.description && (
                      <p
                        id="filled_error_help"
                        className="mt-2 text-xs text-red-600 dark:text-red-400"
                      >
                        {errors.description}
                      </p>
                    )}
                  </div>
                  <div className="flex flex-col w-full">
                    <div className="flex flex-row w-full space-x-5 items-center">
                      <h3 className="font-robot text-gray-800 text-sm whitespace-nowrap  ">
                        Sector Type:
                      </h3>
                      <select
                        onChange={(e) => {
                          fetchSubsector(e.target.value, values.sectorId);
                          setFieldValue("sectorId", e.target.value);
                        }}
                        defaultValue={values.sectorId}
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
                      >
                        {sectorlist.SECTOR_LIST.map((item: sectorItem, id) => {
                          return <option value={item._id}>{item.name}</option>;
                        })}
                      </select>
                    </div>
                    {errors.sectorId && (
                      <p
                        id="filled_error_help"
                        className="mt-2 text-xs text-red-600 dark:text-red-400"
                      >
                        {errors.sectorId}
                      </p>
                    )}
                  </div>
                  {subsectorlist.SUB_SECTOR_LIST?.length ? (
                    <div className="flex flex-col w-full">
                      <div className="flex flex-row w-full space-x-5 items-center">
                        <h3 className="font-robot text-gray-800 text-sm whitespace-nowrap  ">
                          Sub Sector Type:
                        </h3>
                        <select
                          onChange={(e) =>
                            setFieldValue("subSectorId", e.target.value)
                          }
                          defaultValue={initialValues.subSectorId}
                          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
                        >
                          {subsectorlist.SUB_SECTOR_LIST.map(
                            (item: subsectorItem, id) => {
                              return (
                                <option value={item._id}>{item.name}</option>
                              );
                            }
                          )}
                        </select>
                      </div>
                    </div>
                  ) : null}
                  <div className="flex flex-col w-full">
                    <div className="flex flex-row w-full space-x-5 items-center">
                      <h3 className="font-robot text-gray-800 text-sm whitespace-nowrap  ">
                        Development Stage:
                      </h3>
                      <select
                        onChange={(e) =>
                          setFieldValue("developmentStage", e.target.value)
                        }
                        defaultValue={values.developmentStage}
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
                      >
                        {developmentStage.map((stage, id) => {
                          return (
                            <option value={stage.value}>{stage.value}</option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                  <div className="flex flex-col w-full space-x-2 relative">
                    <div className="relative">
                      <div className="relative mb-3">
                        <label className="profile-content-head-2">
                          IPR Status
                        </label>
                      </div>
                      <div className="grid grid-cols-1 gap-4 md:grid-cols-3">
                        {iprStatusCheckbox.map((option) => (
                          <label
                            className="flex items-center space-x-2 ml-4"
                            key={option.id}
                          >
                            <input
                              type="checkbox"
                              className="h-4 w-4 text-indigo-600 rounded border-gray-300 focus:ring-indigo-500"
                              checked={option.checked}
                              onChange={() => handleIprStatus(option.id)}
                            />
                            <span className="text-gray-700 personal-input">
                              {option.value}
                            </span>
                          </label>
                        ))}
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col w-full">
                    <label
                      className="block mb-2 text-sm font-medium text-gray-900"
                      htmlFor="logo"
                    >
                      Upload Product Image&nbsp;
                      <span className="text-red-500 text-xs">(.png only)</span>
                    </label>
                    <input
                      onChange={handleImage}
                      accept=".png"
                      type="file"
                      id="logo"
                      aria-label="company-logo"
                      className="modal-input"
                      placeholder="Click to upload Company's Logo"
                      multiple
                      max={3}
                    />
                    <p
                      id="filled_error_help"
                      className={
                        "mt-2 text-xs text-red-600 dark:text-red-400 " +
                        (!files.image ? "hidden" : "")
                      }
                    >
                      {"Please upload product Image"}
                    </p>
                  </div>
                  <div className="flex flex-col w-full relative">
                    <label
                      className="block mb-2 text-sm font-medium text-gray-900"
                      htmlFor="documents"
                    >
                      Upload Product Documents <i>(Optional)</i>&nbsp;
                      <span className="text-red-500 text-xs">(.pdf only)</span>
                    </label>
                    <input
                      onChange={handleDocuments}
                      accept=".pdf"
                      type="file"
                      id="documents"
                      ref={docInputRef}
                      aria-label="company-documents"
                      className="modal-input"
                      placeholder="Click to upload Document"
                      multiple
                      max={3}
                    />
                  </div>
                  <div className="flex flex-col w-full relative">
                    <label
                      className="block mb-2 text-sm font-medium text-gray-900"
                      htmlFor="video"
                    >
                      Upload Product Video <i>(Optional)</i>&nbsp;
                      <span className="text-red-500 text-xs">
                        (.mp4 /.webm only)
                      </span>
                    </label>
                    <input
                      onChange={handleVideo}
                      type="file"
                      accept=".mp4, .webm"
                      id="video"
                      ref={vidInputRef}
                      aria-label="product-video"
                      className="modal-input"
                      placeholder="Click to upload Video"
                      multiple
                      max={3}
                    />
                  </div>
                  <button
                    type="submit"
                    onClick={() => handleSubmit}
                    className="button active"
                  >
                    Create Product
                  </button>
                </Form>
              </>
            );
          }}
        </Formik>
      </div>
    </div>
  );
  return ReactDOM.createPortal(content, document.body);
};

const YourProducts = () => {
  let [successModal, setSuccessModal] = useState<boolean>(false);
  const [state, setState] = useState("LOADING");
  const [message, setMessage] = useState("");

  const products: PRODUCTS = useSelector(
    (state: STATE) => state.PRODUCTS.PRODUCTS
  );
  const dispatch: Dispatch<any> = useDispatch();
  const [productModal, setModel] = useState(false);

  const [page, setPage] = useState({
    skip: SKIP,
    limit: LIMIT,
  });

  const [type, setType] = useState({
    drop: false,
    selected: "Approved",
    id: 0,
    total: products.TOTAL_APPROVED,
  });

  const ref1 = useDetectClickOutside({
    onTriggered: () => setType({ ...type, drop: false }),
  });

  const [sector, setSector] = useState({
    drop: false,
    selected: "",
    id: "",
  });
  const [subSector, setSubSector] = useState({
    drop: false,
    selected: "",
    id: "",
  });

  const fetchData = (value: number) => {
    let final = +page.skip + value < 0 ? +page.skip : +page.skip + value;
    setPage({ skip: final.toString(), limit: page.limit });
  };

  useEffect(() => {
    dispatch(getSector());
    dispatch(getSubSector());
    setSector({
      ...sector,
      selected: "All Sectors",
      id: "",
    });
    setSubSector({
      ...subSector,
      selected: "All Sub-Sectors",
      id: "",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const changeModalState = async () => {
    setModel(!productModal);
  };

  const handleSuccessModal = (
    isOpen: boolean,
    state: string,
    message: string
  ) => {
    setSuccessModal(isOpen);
    setState(state);
    setMessage(message);
  };

  const handleType = (index: number) => {
    if (index === 0) {
      setType({
        ...type,
        selected: "Approved",
        id: 0,
        total: products.TOTAL_APPROVED,
        drop: false,
      });
    } else if (index === 1) {
      setType({
        ...type,
        selected: "Pending",
        id: 1,
        total: products.TOTAL_PENDING,
        drop: false,
      });
    } else {
      setType({
        ...type,
        selected: "Rejected",
        id: 2,
        total: products.TOTAL_REJECTED,
        drop: false,
      });
    }
  };

  return (
    <div className="flex flex-col relative  py-10 items-center w-full">
      <Helmet>
        <title>{title.YOUR_TECHNOLOGIES}</title>
        <meta
          name="description"
          key="description"
          content={metaData.YOUR_TECHNOLOGIES}
        />
        <meta name="title" key="title" content={title?.YOUR_TECHNOLOGIES} />
        <meta property="og:title" content={title.YOUR_TECHNOLOGIES} />
        <meta property="og:description" content={metaData.YOUR_TECHNOLOGIES} />
        <meta property="og:image" content={globe} />
        <meta
          property="og:url"
          content={`${process.env.REACT_APP_BASE_URL}/your-technology`}
        />
        <meta property="og:type" content="website" />
        <meta name="twitter:title" content={title?.YOUR_TECHNOLOGIES} />
        <meta
          name="twitter:description"
          content={metaData?.YOUR_TECHNOLOGIES}
        />
        <meta name="twitter:image" content={globe} />
        <meta name="twitter:card" content={title?.YOUR_TECHNOLOGIES} />
      </Helmet>
      <div className="flex flex-row items-center">
        <h1 className="text-2xl font-roboto ">{HEADER_YOUR_TECHNOLOGIES}</h1>
      </div>
      <div className="flex w-full justify-between items-center  mx-10 py-5">
        <div
          ref={ref1}
          className="flex flex-row justify-end duration-200 z-10 px-5"
        >
          <button
            id="dropdownDefault"
            data-dropdown-toggle="dropdown"
            className="w-fit text-white border-2 bg-GTI-BLUE-default focus:outline-none font-medium font-roboto rounded-lg m-1 text-sm px-4 py-2.5  text-center inline-flex items-center border-slate-300 justify-center flex-shrink"
            type="button"
            onClick={() => {
              setType({ ...type, drop: !type.drop });
            }}
          >
            {type.selected}
            <svg
              className="ml-2 w-4 h-4"
              aria-hidden="true"
              fill="grey"
              stroke="grey"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M19 9l-7 7-7-7"
              ></path>
            </svg>
          </button>
          <div
            id="dropdown"
            className={"relative z-10 rounded " + (!type.drop ? "hidden" : "")}
            data-popper-placement="bottom"
          >
            <ul
              className="absolute flex flex-col  -left-28  top-14 text-sm font-roboto bg-slate-100"
              aria-labelledby="dropdownDefault"
            >
              <li
                className="block z-10 py-2 px-4 rounded  text-GTI-BLUE-default  whitespace-nowrap hover:text-slate-500 "
                onClick={() => {
                  handleType(0);
                }}
              >
                Approved
              </li>
              <li
                className="block z-10 py-2 px-4 rounded  text-GTI-BLUE-default  whitespace-nowrap hover:text-slate-500 "
                onClick={() => {
                  handleType(1);
                }}
              >
                Pending
              </li>
              <li
                className="block z-10 py-2 px-4 rounded  text-GTI-BLUE-default  whitespace-nowrap hover:text-slate-500 "
                onClick={() => {
                  handleType(2);
                }}
              >
                Rejected
              </li>
            </ul>
          </div>
        </div>
        <div className="flex  h-fit mx-10">
          <button
            type="button"
            className="button active"
            onClick={() => {
              setModel(true);
            }}
          >
            + Create New
          </button>
        </div>
      </div>
      <YourProductList
        type={type.id}
        skip={page.skip}
        limit={LIMIT}
        secId={sector.id}
        subsecId={subSector.id}
      />
      {productModal && (
        <ProductModal
          changeModalState={changeModalState}
          handleSuccessModal={handleSuccessModal}
        />
      )}
      <div className="flex mb-5 py-5">
        <button
          disabled={page.skip === "0"}
          onClick={() => {
            fetchData(-1);
          }}
          className="inline-flex items-center py-2 px-4 text-sm font-medium text-GTI-BLUE-default disabled:text-gray-500 bg-white  rounded-lg border border-gray-300 hover:bg-gray-100 hover:text-gray-700 "
        >
          <svg
            aria-hidden="true"
            className="mr-2 w-5 h-5"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              d="M7.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l2.293 2.293a1 1 0 010 1.414z"
              clipRule="evenodd"
            ></path>
          </svg>
          Previous
        </button>
        <button
          disabled
          className="inline-flex items-center mx-2 py-2 px-4 text-sm font-medium text-gray-500 bg-white rounded-lg border border-gray-300 hover:bg-gray-100 hover:text-gray-700 "
        >
          {+page.skip + 1}
        </button>
        <button
          disabled={(+page.skip + 1) * +page.limit >= type.total}
          onClick={() => {
            fetchData(1);
          }}
          className="inline-flex items-center py-2 px-4 text-sm font-medium text-GTI-BLUE-default disabled:text-gray-500 bg-white rounded-lg border border-gray-300 hover:bg-gray-100 hover:text-gray-700 "
        >
          Next
          <svg
            aria-hidden="true"
            className="ml-2 w-5 h-5"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z"
              clipRule="evenodd"
            ></path>
          </svg>
        </button>
      </div>
      {successModal && (
        <SuccessModal
          state={state}
          message={message}
          show={successModal}
          toggle={handleSuccessModal}
        />
      )}
    </div>
  );
};

export default YourProducts;
