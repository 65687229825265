import axios from "axios";
import { store } from "../../store";
import * as SUB_SECTOR from "../../Components/constants";
import { SUB_SECTOR_GET, TOAST_SUCCESS } from "../actiontypes/actionTypes";

export const getSubSector = () => async (dispatch: any) => {
  var config = {
    method: "get",
    url: `${process.env.REACT_APP_BASE_API}/sub-sectors`,
    headers: {
      Authorization: `Bearer ${store.getState().USER.USER.token}`,
      "Content-Type": "application/json",
    },
  };

  await axios(config)
    .then(function (response) {
      // console.log("sub-Sector list fetched ");

      const list: SUB_SECTOR.subsectorItem[] = response.data;
      const action1: TOAST_ACTION = {
        type: TOAST_SUCCESS,
      };
      const action2: SUB_SECTOR_ACTION = {
        type: SUB_SECTOR_GET,
        SUB_SECTOR: {
          SUB_SECTOR_LIST: list,
        },
      };
      const action = async (dispatch: any) => {
        // dispatch(action1);
        dispatch(action2);
      };

      return dispatch(action);
    })
    .catch(function (error) {
      // console.log("Sector not list fetched ", error);
    });
};
export const getSubSectorById = (id: string) => async (dispatch: any) => {
  var config = {
    method: "get",
    url: `${process.env.REACT_APP_BASE_API}/sub-sectors/${id}`,
    headers: {
      Authorization: `Bearer ${store.getState().USER.USER.token}`,
      "Content-Type": "application/json",
    },
  };

  await axios(config)
    .then(function (response) {
      // console.log("sub-Sector fetched by id");

      const list: SUB_SECTOR.subsectorItem[] = [response.data];
      const action1: TOAST_ACTION = {
        type: TOAST_SUCCESS,
      };
      const action2: SUB_SECTOR_ACTION = {
        type: SUB_SECTOR_GET,
        SUB_SECTOR: {
          SUB_SECTOR_LIST: list,
        },
      };
      const action = async (dispatch: any) => {
        // dispatch(action1);
        dispatch(action2);
      };

      return dispatch(action);
    })
    .catch(function (error) {
      // console.log("sub-Sector not fetched by id ", error);
    });
};
export const getSubSectorBySector = (id: string) => async (dispatch: any) => {
  var config = {
    method: "get",
    url: `${process.env.REACT_APP_BASE_API}/sub-sectors/subsectorbysectorid/${id}`,
    headers: {
      Authorization: `Bearer ${store.getState().USER.USER.token}`,
      "Content-Type": "application/json",
    },
  };

  await axios(config)
    .then(function (response) {
      // console.log("sub-Sector fetched by id", response.data);

      const list: SUB_SECTOR.subsectorItem[] = response.data;
      const action1: TOAST_ACTION = {
        type: TOAST_SUCCESS,
      };
      const action2: SUB_SECTOR_ACTION = {
        type: SUB_SECTOR_GET,
        SUB_SECTOR: {
          SUB_SECTOR_LIST: list,
        },
      };
      const action = async (dispatch: any) => {
        // dispatch(action1);
        dispatch(action2);
      };

      return dispatch(action);
    })
    .catch(function (error) {
      // console.log("sub-Sector not fetched by id ", error);
    });
};
