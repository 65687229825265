import React from "react";
import { Helmet } from "react-helmet";

import { title, metaData } from "../constants";
import globe from "../../assests/home/globe.png";

const RefundAndCancellationPolicy = () => {
  const data = [
    "<p>At GTI, we strive to provide exceptional services to all our clients. However, we understand that circumstances may arise where you might need to request a refund. Please read the following refund policy carefully before engaging our services.</p>",
    `<strong>1. Refund Eligibility</strong>`,
    `<p style="margin-left: 20px;">We offer refunds within 4-5 days of purchase under the following circumstances:</p>`,
    `<p style="margin-left: 20px;">1.1. If the service purchased has not been initiated or utilized within the stipulated time frame.</p>`,
    `<p style="margin-left: 20px;">1.2. If the service delivered does not meet the specifications outlined in the service agreement or falls below the promised standards.</p>`,
    `<p style="margin-left: 20px;">1.3. If there are technical issues or unforeseen circumstances that prevent the completion of the service.</p>`,
    `<strong>2. How to Request a Refund or Cancellation</strong>`,
    `<p style="margin-left: 20px;">To request a refund or cancellation, please contact our customer support team via email at info@globaltechinterface.com within the eligible refund period. Please provide the following details in your refund request:</p>`,
    `<p style="margin-left: 20px;">2.1. Your full name and contact information.</p>`,
    `<p style="margin-left: 20px;">2.2. Order or invoice number.</p>`,
    `<p style="margin-left: 20px;">2.3. Reason for the refund request with supporting details or evidence.</p>`,
    `<strong>3. Refund Process</strong>`,
    `<p style="margin-left: 20px;">Once your refund request is received and reviewed, we will assess the situation and notify you of the approval or rejection of your refund. If approved, the refund will be processed and will be credited to the original method of payment.</p>`,
    `<strong>4. Non-Refundable Items</strong>`,
    `<p style="margin-left: 20px;">Certain services or products may not be eligible for refunds, including:</p>`,
    `<p style="margin-left: 20px;">4.1. Services that have already commenced or been partially completed.</p>`,
    `<p style="margin-left: 20px;">4.2. Customized or personalized services tailored to the client's specific requirements.</p>`,
    `<p style="margin-left: 20px;">4.3. Downloadable digital products or services.</p>`,
    `<strong>5. Contact Us</strong>`,
    `<p style="margin-left: 20px;">If you have any questions or concerns regarding our refund policy, please feel free to contact us via email at info@globaltechinterface.com. Our dedicated support team is available to assist you and provide clarification regarding our refund process.</p>`,
  ];

  return (
    <div className="flex flex-col items-center px-12 py-16">
      <Helmet>
        <title>{title.REFUND_AND_CANCELLATION_POLICY}</title>
        <meta
          name="description"
          key="description"
          content={metaData.REFUND_AND_CANCELLATION_POLICY}
        />
        <meta
          name="title"
          key="title"
          content={title.REFUND_AND_CANCELLATION_POLICY}
        />
        <meta
          property="og:title"
          content={title.REFUND_AND_CANCELLATION_POLICY}
        />
        <meta
          property="og:description"
          content={metaData.REFUND_AND_CANCELLATION_POLICY}
        />
        <meta property="og:image" content={globe} />
        <meta
          property="og:url"
          content={`${process.env.REACT_APP_BASE_URL}/termsconditioins`}
        />
        <meta property="og:type" content="website" />
        <meta
          name="twitter:title"
          content={title?.REFUND_AND_CANCELLATION_POLICY}
        />
        <meta
          name="twitter:description"
          content={metaData?.REFUND_AND_CANCELLATION_POLICY}
        />
        <meta name="twitter:image" content={globe} />
        <meta
          name="twitter:card"
          content={title?.REFUND_AND_CANCELLATION_POLICY}
        />
      </Helmet>
      <h1 className="text-3xl font-semibold mb-2 font-pj-sans">
        Refund & Cancellation Policy
      </h1>
      <p className="mb-8">The Global Technology Interface®</p>
      <div className="flex flex-col md:flex-row gap-6">
        <div className="flex flex-col gap-2 w-full">
          {data.map((item, index) => (
            <p
              key={index}
              className="text-sm text-gray-700 font-dm-sans"
              dangerouslySetInnerHTML={{ __html: item }}
            ></p>
          ))}
        </div>
      </div>
    </div>
  );
};

export default RefundAndCancellationPolicy;
