import * as ACTION from "../actiontypes/actionTypes";

const initialState: ARTICLE_STATE = {
  ARTICLE: {
    articles: [],
    articlesCount: 0,
  },
};

export const articlereducer = (
  state: ARTICLE_STATE = initialState,
  action: ARTICLE_ACTION
): ARTICLE_STATE => {
  switch (action.type) {
    case ACTION.ARTICLE_GET:
      let articlelist: ARTICLE = action.ARTICLE;
      return { ARTICLE: articlelist };
    case ACTION.ARTICLE_GET_ID:
      let article: ARTICLE = action.ARTICLE;

      return { ARTICLE: article };
    default:
      return { ...state };
  }
};
