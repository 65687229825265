import React, { Dispatch, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import oppbanner from "../../assests/images/product_alt.png";
import { NONE, OPP, oppotunityItemPartialFetched } from "../constants";
import { useNavigate } from "react-router-dom";
import { getOpportunities } from "../../store/actioncreators/opportunityactions";
import { ScreenSpinner } from "../utils/loader";
import RenderHTML from "../utils/RenderHTML";
import biotechnology from "../../assests/images/biotechnology.jpg";
import cleantech from "../../assests/images/cleantech.jpg";
import informationTechnology from "../../assests/images/information-technology.jpg";

const Card = ({ item }: { item: oppotunityItemPartialFetched }) => {
  const dispatch: Dispatch<any> = useDispatch();
  const navigate = useNavigate();

  const handleView = () => {
    navigate(`/opportunity/${item._id}`, {
      state: { product: item },
    });
  };

  const getDefaultImage = (sectorName: string) => {
    if (sectorName === "Biotechnology") {
      return biotechnology;
    } else if (sectorName === "Clean Technology") {
      return cleantech;
    } else {
      return informationTechnology;
    }
  };

  const DOP = new Date(item.createdAt);
  return (
    <div
      onClick={() => {
        handleView();
      }}
      className="product-card-main"
    >
      <div className="product-card-img">
        <img
          src={
            item.image
              ? item.image
              : item.company.logo
              ? item.company.logo
              : getDefaultImage(item.sectors.name)
          }
          className="flex w-full lg:h-56 rounded-t-lg hover:rounded-lg object-contain"
          alt={item.description}
          loading="lazy"
        />
      </div>
      <div className="product-card-title">
        <h4 className="font-roboto text-xs text-slate-500 ">
          {"Posted on:"}
          {DOP.toLocaleString("default", {
            month: "short",
            day: "2-digit",
            year: "numeric",
          })}
        </h4>
      </div>
      <div className="product-card-title">
        <h4 className="font-roboto text-lg text-GTI-BLUE-default ">
          {item?.technologyPartnerRequirement.length > 25
            ? item.technologyPartnerRequirement
                ?.replace(/(<([^>]+)>)/gi, "")
                .substring(0, 25) + "..."
            : item.technologyPartnerRequirement?.replace(/(<([^>]+)>)/gi, "")}
        </h4>
      </div>
      <div className="product-card-title">
        <h4 className="font-roboto  text-xs text-slate-500 ">
          {item.description.length > 150 ? (
            <RenderHTML html={item.description.substring(0, 150) + "..."} />
          ) : (
            <RenderHTML html={item.description} />
          )}
        </h4>
      </div>
    </div>
  );
};

const OpportunityList = ({
  skip,
  limit,
  secId,
  subSecId,
  search,
}: {
  skip: string;
  limit: string;
  subSecId: string;
  secId: string;
  search: string;
}) => {
  const dispatch: Dispatch<any> = useDispatch();
  const spinner: LOADER = useSelector((state: STATE) => state.LOADER.LOADER);
  const opportunity: OPP = useSelector((state: STATE) => state.OPP.OPP);

  useEffect(() => {
    dispatch(getOpportunities(skip, limit, secId, subSecId, search));
  }, [secId, subSecId, skip, search]);

  return (
    <div className="flex w-full flex-col">
      {spinner.SPINNER ? (
        <ScreenSpinner />
      ) : (
        <div className="opp-list-main">
          {opportunity.OPP_LIST?.opportunities &&
            opportunity.OPP_LIST?.opportunities.map(
              (item: oppotunityItemPartialFetched, id: number) => {
                return <Card item={item} key={id} />;
              }
            )}
        </div>
      )}
    </div>
  );
};
export default OpportunityList;
