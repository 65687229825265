import React, { useState, useEffect } from "react";
import H_left from "./H_left";
import H_mid from "./H_mid";
import H_right from "./H_right";

// interfaces
import LoginInterface from "../Modules/LoginModalInterface/LoginModalInterface";
import { useLocation } from "react-router-dom";
import { HOME } from "../constants";
import {
  getAllNotification,
  getNotificationsCount,
} from "../../store/actioncreators/notificationactions";
import { useDispatch } from "react-redux";

interface NavbarMidRightInterface {
  handleLoginModal: () => void;
  handleNotificationModal: () => void;
  show: boolean;
  handleShow: () => void;
}

const NavbarMidRight: React.FC<NavbarMidRightInterface> = ({
  handleLoginModal,
  handleNotificationModal,
  handleShow,
  show,
}) => {
  const location = useLocation();
  return (
    <div
      className={
        "lg:flex bg-opacity-100 w-6/12 p-2 lg:w-full items-center lg:sticky lg:top-0 fixed bottom-0 py-2 md:space-y-2 transition-all duration-500" +
        (show
          ? " left-0 block bg-white top-[55px] "
          : " left-0 hidden bg-white ") +
        (location.pathname === HOME
          ? " bg-white"
          : " bg-white lg:bg-GTI-BLUE-default")
      }
    >
      <H_mid handleShow={handleShow} show={show} />
      <H_right
        handleLoginModal={handleLoginModal}
        handleNotificationModal={handleNotificationModal}
        show={show}
        handleShow={handleShow}
      />
    </div>
  );
};

const Navbar: React.FC<LoginInterface> = ({
  handleLoginModal,
  handleNotificationModal,
}) => {
  const location = useLocation();
  const dispatch: any = useDispatch();
  // navbar start
  const [show, setshow] = useState(false);

  useEffect(() => {
    dispatch(getAllNotification("", ""));
    dispatch(getNotificationsCount());
  }, []);
  const handleShow = () => {
    setshow(!show);
  };
  // navbar end

  return (
    <div
      className={
        "flex fixed top-0 z-10 flex-row justify-between items-center w-full " +
        (location.pathname === HOME ? "bg-white" : "bg-GTI-BLUE-default")
      }
    >
      <H_left handleShow={handleShow} show={show} />
      {!show ? (
        <NavbarMidRight
          handleLoginModal={handleLoginModal}
          handleNotificationModal={handleNotificationModal}
          handleShow={handleShow}
          show={show}
        />
      ) : (
        <div
          className={
            "bg-opacity-50 w-full p-2 lg:p-0 items-center lg:sticky lg:top-0 fixed bottom-0 py-2 lg:py-0 md:space-y-2 transition-all duration-500" +
            (show
              ? ` left-0 block bg-gray-300 top-[55px] ${
                  location.pathname === HOME
                    ? "bg-white"
                    : "lg:bg-GTI-BLUE-default"
                }  `
              : ` left-0 hidden bg-gray-300 lg:bg-GTI-BLUE-default `)
          }
          onClick={(e: React.MouseEvent<HTMLElement>) => {
            const targetElement = e.target as HTMLElement;
            if (targetElement?.tagName === "DIV") handleShow();
          }}
        >
          <NavbarMidRight
            handleLoginModal={handleLoginModal}
            handleNotificationModal={handleNotificationModal}
            handleShow={handleShow}
            show={show}
          />
        </div>
      )}
    </div>
  );
};

export default Navbar;
