/* eslint-disable no-multi-str */
import React, { Dispatch, useEffect, useState } from "react";
import { AiOutlineRight } from "react-icons/ai";
import { AiOutlineLeft } from "react-icons/ai";
import { useSelector } from "react-redux";
import Slider from "react-slick";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Helmet } from "react-helmet";
import ReactPlayer from "react-player";

import learn_more from "../../assests/home/learn_more.png";
import sign_up from "../../assests/home/sign_up.png";
import home_globe_1 from "../../assests/home/home_globe_1.png";
import home_globe_2 from "../../assests/home/home_globe_2.png";
import home_globe_3 from "../../assests/home/home_globe_3.png";
import home_globe_4 from "../../assests/home/home_globe_4.png";
import globe from "../../assests/home/globe.png";
import tech_displayer from "../../assests/home/tech_displayer.png";
import tech_scouter from "../../assests/home/tech_scouter.png";
import tech_investor from "../../assests/home/tech_investor.png";
import tech_displayer_icon from "../../assests/home/tech_displayer_icon.png";
import tech_scouter_icon from "../../assests/home/tech_scouter_icon.png";
import investor_icon from "../../assests/home/investor_icon.png";
import tech_displayer_icon_dark from "../../assests/home/tech_displayer_icon_dark.png";
import tech_scouter_icon_dark from "../../assests/home/tech_scouter_icon_dark.png";
import investor_icon_dark from "../../assests/home/investor_icon_dark.png";
import clean_tech from "../../assests/home/clean_tech.png";
import bio_tech from "../../assests/home/bio_tech.png";
import digi_tech from "../../assests/home/digi_tech.png";
import group_tech from "../../assests/home/group_tech.png";
import group_incub from "../../assests/home/group_incub.png";
import group_cluster from "../../assests/home/group_cluster.png";
import group_ngo from "../../assests/home/group_ngo.png";
import group_gov from "../../assests/home/group_gov.png";
import group_investor from "../../assests/home/group_investor.png";
import group_startup from "../../assests/home/group_startup.png";
import group_corporate from "../../assests/home/group_corporate.png";
import tech_display from "../../assests/home/tech_display.png";
import market_opp from "../../assests/home/market_opp.png";
import innovation_call from "../../assests/home/innovation_call.png";
import investor_connect from "../../assests/home/investor_connect.png";
import knowledge_hub from "../../assests/home/knowledge_hub.png";
import feature_profile from "../../assests/images/featured-profile.svg";
import star from "../../assests/home/star.png";
import * as C from "../constants";
import { getProducts } from "../../store/actioncreators/productactions";
import { getOpportunities } from "../../store/actioncreators/opportunityactions";
import { getCalls } from "../../store/actioncreators/innovationactions";
import NewsLetter from "../LatestUpdates/NewsLetter";
import { getQueryParams } from "../../utils";
import RenderHTML from "../utils/RenderHTML";
import { getArticles } from "../../store/actioncreators/articleactions";
import biotechnology from "../../assests/images/biotechnology.jpg";
import cleantech from "../../assests/images/cleantech.jpg";
import informationTechnology from "../../assests/images/information-technology.jpg";
import { getNews } from "../../store/actioncreators/newsAction";
import articlebanner from "../../assests/banners/articlebanner.png";

import "./style.css";
import { getTechnologyPartners } from "../../store/actioncreators/technologypartneractions";
import { fetchHomeapgePromotion } from "../../store/actioncreators/promotionsaction";

function SampleNextArrow(props: any) {
  const { className, style, onClick } = props;
  return (
    <AiOutlineRight
      size={56}
      className={className}
      style={{
        ...style,
        display: "flex",
        width: 40,
        height: 40,
        marginLeft: 22,
        padding: 2,
        color: "#BDC5E9",
      }}
      onClick={onClick}
    />
  );
}
function SamplePrevArrow(props: any) {
  const { className, style, onClick } = props;
  return (
    <AiOutlineLeft
      size={56}
      className={className}
      style={{
        ...style,
        display: "flex",
        width: 40,
        height: 40,
        marginRight: 22,
        padding: 2,
        color: "#BDC5E9",
      }}
      onClick={onClick}
    />
  );
}

const getDefaultImage = (sectorName: string) => {
  if (sectorName === "Biotechnology") {
    return biotechnology;
  } else if (sectorName === "Clean Technology") {
    return cleantech;
  } else {
    return informationTechnology;
  }
};

const LatestTechCard = ({ item }: { item: C.productItemFullFetched }) => {
  return (
    <div className="flex flex-row w-full mx-2">
      <img
        src={item.image ? item.image : getDefaultImage(item.sectors.name)}
        alt={item.company.name}
        className="w-24 h-24 m-2 object-contain rounded-xl"
      />
      <div className="flex flex-col m-2">
        <p className="text-sm font-roboto text-gray-400">
          Posted on:{new Date(item.createdAt).getDate()}
        </p>
        <p className="font-roboto">
          {item.name.length > 35
            ? item.name.substring(0, 20) + "..."
            : item.name}
        </p>
        <p className="text-xs font-roboto text-gray-400">
          {item.description.length > 35 ? (
            <RenderHTML html={item.description.substring(0, 35) + "..."} />
          ) : (
            <RenderHTML html={item.description} />
          )}
        </p>
      </div>
    </div>
  );
};

const LatestOppCard = ({ item }: { item: C.oppotunityItemPartialFetched }) => {
  return (
    <div className="flex flex-row w-full mx-2">
      <img
        src={
          item.image
            ? item.image
            : item.company.logo
            ? item.company.logo
            : getDefaultImage(item.sectors.name)
        }
        alt={item.company.name}
        className="w-24 h-24 m-2 object-contain rounded-xl"
      />
      <div className="flex flex-col m-2">
        <p className="text-sm font-roboto text-gray-400">
          Posted on:{new Date(item.createdAt).getDate()}
        </p>
        <p className="font-roboto">
          {item.technologyPartnerRequirement.length > 35
            ? item.technologyPartnerRequirement.substring(0, 20) + "..."
            : item.technologyPartnerRequirement}
        </p>
        <p className="text-xs font-roboto text-gray-400">
          {item.description.length > 35 ? (
            <RenderHTML html={item.description.substring(0, 35) + "..."} />
          ) : (
            <RenderHTML html={item.description} />
          )}
        </p>
      </div>
    </div>
  );
};
const LatestCallCard = ({ item }: { item: C.InnovationItemFetched }) => {
  return (
    <div className="flex flex-row w-full mx-2">
      <img
        src={item.companyLogo}
        alt={item.title}
        className="w-24 h-24 m-2 object-contain rounded-xl"
      />
      <div className="flex flex-col m-2">
        <p className="text-sm font-roboto text-gray-400">
          Posted on:{new Date(item.createdAt).getDate()}
        </p>
        <p className="font-roboto">
          {item.companyName.length > 35
            ? item.companyName.substring(0, 20) + "..."
            : item.companyName}
        </p>
        <p className="text-xs font-roboto text-gray-400">
          {item.description.length > 35 ? (
            <RenderHTML html={item.description.substring(0, 35) + "..."} />
          ) : (
            <RenderHTML html={item.description} />
          )}
        </p>
      </div>
    </div>
  );
};

const LatestArticlesCard = ({ item }: { item: C.articleItemFetched }) => {
  return (
    <div className="flex flex-row w-full mx-2">
      {!item.youtubeLink || item.youtubeLink === "none" ? (
        <img
          src={item.imageUrl}
          className="w-24 h-24 m-2 object-contain rounded-xl"
          alt={item.topic}
        />
      ) : (
        <ReactPlayer
          url={item.youtubeLink === C.NONE ? articlebanner : item.youtubeLink}
          height="6rem"
          width="6rem"
          alt={item}
          light
        />
      )}

      <div className="flex flex-col m-2">
        <p className="text-sm font-roboto text-gray-400">
          Posted on:{new Date(item.createdAt).getDate()}
        </p>
        <p className="font-roboto">
          {item?.topic?.length > 35
            ? item?.topic?.replace(/(<([^>]+)>)/gi, "").substring(0, 20) + "..."
            : item?.topic?.replace(/(<([^>]+)>)/gi, "")}
        </p>
        <p className="text-xs font-roboto text-gray-400">
          {item.shortDescription.length > 35
            ? item.shortDescription
                .substring(0, 35)
                ?.replace(/(<([^>]+)>)/gi, "") + "..."
            : item.shortDescription?.replace(/(<([^>]+)>)/gi, "")}
        </p>
      </div>
    </div>
  );
};

const LatestNewsCard = ({ item }: { item: C.newsFetched }) => {
  return (
    <div className="flex flex-row w-full mx-2">
      <img
        src={item.imageUrl}
        alt={item.title}
        className="w-24 h-24 m-2 object-contain rounded-xl"
      />
      <div className="flex flex-col m-2">
        <p className="text-sm font-roboto text-gray-400">
          Posted on:{new Date(item.createdAt).getDate()}
        </p>
        <p className="font-roboto">
          {item?.title?.length > 35
            ? item?.title?.replace(/(<([^>]+)>)/gi, "").substring(0, 20) + "..."
            : item?.title?.replace(/(<([^>]+)>)/gi, "")}
        </p>
        <p className="text-xs font-roboto text-gray-400">
          {item.shortDescription.length > 35
            ? item.shortDescription
                .substring(0, 35)
                ?.replace(/(<([^>]+)>)/gi, "") + "..."
            : item.shortDescription?.replace(/(<([^>]+)>)/gi, "")}
        </p>
      </div>
    </div>
  );
};

const Featured = ({ promotion }: { promotion: C.featuredItem }) => {
  const navigate = useNavigate();

  return (
    <div
      className="border-gray-200 border-spacing-72 p-2 cursor-pointer h-full"
      onClick={() => {
        navigate(`/featuredview/${promotion._id}`);
      }}
    >
      <img
        src={promotion.images[0]}
        alt="Featured"
        className="w-full h-full max-h-[200px] object-contain"
      />
      <h2 className="text-GTI-BLUE-default font-semibold text-2xl pb-2">
        {promotion.contentTitle}
      </h2>
      <p className="text-sm font-roboto text-gray-400">
        {promotion.contentDescription.length > 500 ? (
          <RenderHTML
            html={promotion.contentDescription.substring(0, 500) + "..."}
          />
        ) : (
          <RenderHTML html={promotion.contentDescription} />
        )}
      </p>
    </div>
  );
};

const Homepage = ({ handleLogin }: { handleLogin: () => void }) => {
  const dispatch: Dispatch<any> = useDispatch();

  useEffect(() => {
    dispatch(getProducts(C.SKIP, "4"));
    dispatch(getOpportunities(C.SKIP, "4"));
    dispatch(getCalls(getQueryParams("skip") ?? "0", "4"));
    dispatch(getArticles("", C.SKIP, "0"));
    dispatch(getNews(C.SKIP, "0"));
    dispatch(getTechnologyPartners(C.SKIP, "100"));
    dispatch(fetchHomeapgePromotion());
  }, []);

  const navigate = useNavigate();
  const client_review = [
    {
      comment:
        "I contacted GBI because my client, an Italian hi-tech \
        company active in the development of innovative cloud applications for engineering workflow \
        automation, was looking for skilled software developers in India. After the first contact, \
        GBI suggested uploading my client's request to their internal GTI matching portal, and at the \
        same time, they identified two Indian IT companies to match my client with. Their team was extremely \
        helpful throughout the entire matching process and was always available for clarifications to queries \
        coming from me or my client. GBI and I organized a first discovery meeting between my client and the \
        two Indian companies. We are actively supporting the matching process, hoping this could be an initial \
        step towards a fruitful collaboration between the companies.",
      name: "Luca Picariello",
      img: "https://s3.ap-south-1.amazonaws.com/dev-gti/companies_logos/default.png",
      about:
        "EU Project Advisor - EU-Opportunities & Enterprise Europe Network | NOI Techpark",
    },
    {
      comment:
        "Thanks to GBI we have got more than we \
      had bargained for in terms of different companies from various segments!",
      name: "Mr Ahmad Syafik Jafar",
      img: "https://s3.ap-south-1.amazonaws.com/dev-gti/companies_logos/default.png",
      about: "Head- Strategy, Ira Noah PLT",
    },
    {
      comment:
        "Thanks to GBI that helped to avail this GMP B2B meeting platform and meet Astariz Ventures and sign an MOU to work together in India!",
      name: "Mr Rajesh Gulhane",
      img: "https://s3.ap-south-1.amazonaws.com/dev-gti/companies_logos/default.png",
      about: "MD, ARG Technologies",
    },
    {
      comment:
        "On behalf of all Taiwan delegates, I thank you so much for coordinating these wonderful events in Bangalore and Delhi last week. Without your help, it wouldn’t be possible for us to meet so many great people from different organizations in India in such a short stay. We highly appreciate your tremendous support and effort throughout these events.",
      name: "Dr. Chun-hsu Lin",
      img: "https://s3.ap-south-1.amazonaws.com/dev-gti/companies_logos/default.png",
      about:
        "Ph.D. Research Fellow / Deputy Director Center for Green Economy, Chung-Hua Institution for Economic Research, Taiwan",
    },
    {
      comment:
        "We cannot thank GBI enough for giving us the opportunity (to attend Cleantech Venture Day, London). Oizom got to meet impact investing bodies and is now part of Norway’s Katapult Accelerator.",
      name: "Jainam Mehta",
      img: "https://s3.ap-south-1.amazonaws.com/dev-gti/companies_logos/default.png",
      about: "Chief Communications Officer, Oizom",
    },
    {
      comment:
        "GBI / GTI Team helped us to set up a call for our investment program in the Netherlands. \
      Communication with the coordinators was great and we received many applications for our program on the GTI platform as a result. By helping us to spread the word about our program within their \
      network, the GBI-team really managed to get us on the radar of the Indian startup \
      community. Thanks a lot GBI!",
      name: "Koen van der Burg",
      img: "https://s3.ap-south-1.amazonaws.com/dev-gti/companies_logos/default.png",
      about: "Investment Manager at Crosspring B.V",
    },
    {
      comment:
        "We are absolutely thrilled with the work that GBI team has done for us. \
      Their ability to meet deadlines and exceed expectations has been outstanding. We have been partnering with them for \
      some time now, and I have no doubt that our relationship will continue to flourish. The team is professional, responsive, \
      and dedicated to delivering exceptional results. They consistently go above and beyond to ensure that our needs are met \
      and that we are completely satisfied with the work they produce. I cannot recommend GBI team highly enough - they are an \
      absolute pleasure to work with and a true asset to our organization.",
      name: "Ines Jurisic",
      img: "https://s3.ap-south-1.amazonaws.com/dev-gti/companies_logos/default.png",
      about: "Senior Business Developer | Innovation Networks and Partnerships",
    },
    {
      comment:
        "First of all thank you very much for your wonderful and very sufficient support during Global Bio-India. \
      Everything was on time and in very precise manner. You and your team made a great job for us. \
      We had many visitors at the booth. They came from the business sector. Agri input producers, distributors. \
      Some universities also visited us. They were very interested in out new technology for reducing the evaporation \
      loss. They were glad to meet with the Commercial Attaché of Hungary who was with us and they talked about possibility \
      to find cooperation with Hungarian Universities. \
      Thank you very much again your kind support!",
      name: "Vattay Richard",
      img: "https://s3.ap-south-1.amazonaws.com/dev-gti/companies_logos/default.png",
      about: "Director & co-founder at Water&Soil Ltd.",
    },
    {
      comment:
        "On behalf of UKCAS Culture Innovation Pvt Ltd, we would like to express our sincere gratitude for the opportunity to participate in Global Bio India 2023 as a startup company. We are truly honored to have been given the chance to showcase our flagship product, the ready-made culture plate, and explore the international market through this esteemed event. \
        We are delighted to share that our participation in Global Bio India 2023 has been immensely fruitful. Our marketing team had the privilege of meeting potential customers who showed keen interest in our products. We provided them with our comprehensive product catalogue and relevant quotations, which have generated positive responses so far. Furthermore, we are thrilled to inform you that we successfully identified suitable suppliers for our required grant material, dehydrated powders, and plastic petri dishes from the Indian market during the event. This will greatly contribute to the smooth operation and growth of our business. \
        We would like to extend our heartfelt appreciation for the invaluable support you have provided us throughout our journey, from our country to your arrival. Your prompt responsiveness and unwavering assistance whenever we encountered any challenges have been instrumental in our success. We are truly grateful for your hospitality and dedication. \
        Once again, we would like to thank you for the opportunity to participate in Global Bio India 2023 and for your continuous support. We sincerely hope that you will continue to foster such collaborative initiatives in the future, enabling startups like ours to thrive in the international market.",
      name: "Himesha Lakshani Galkotwa",
      img: "https://s3.ap-south-1.amazonaws.com/dev-gti/companies_logos/default.png",
      about: "Executive Coordinator to COO",
    },
  ];

  const products: PRODUCTS = useSelector(
    (state: STATE) => state.PRODUCTS.PRODUCTS
  );

  const opp: OPP = useSelector((state: STATE) => state.OPP.OPP);
  const innovations: INNOVATION = useSelector(
    (state: STATE) => state.INNOVATION.INNOVATION
  );

  const articlelist: ARTICLE = useSelector(
    (state: STATE) => state.ARTICLE.ARTICLE
  );

  const newsList: NEWS_STATE = useSelector((state: STATE) => state.NEWS);

  const technologyPartners: TECHNOLOGY_PARTNER_STATE = useSelector(
    (state: STATE) => state.TECHNOLOGY_PARTNER
  );

  const { homepagePromotions }: PROMOTIONS_STATE = useSelector(
    (state: STATE) => state.PROMOTIONS
  );

  const [isHovered, setIsHovered] = useState(false);
  const [clientId, setClientId] = useState(-1);

  const client_carousal_settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: window.innerWidth < 1200 ? 1 : 3,
    slidesToScroll: 1,
    arrows: true,
    swipeToSlide: true,
    nextArrow: <SampleNextArrow size={32} />,
    prevArrow: <SamplePrevArrow />,
    autoplay: true,
    autoplaySpeed: 1000,
  };

  const partner_carousal_settings = {
    infinite: true,
    speed: 1000,
    slidesToShow: window.innerWidth < 1200 ? 1 : 3,
    slidesToScroll: 1,
    swipeToSlide: true,
    autoplay: true,
    autoplaySpeed: 1000,
  };

  const sidebar_carousal_settings = {
    dots: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    swipeToSlide: true,
    autoplay: true,
    autoplaySpeed: 4000,
  };

  const featured_carousal_settings = {
    dots: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    swipeToSlide: true,
    autoplay: true,
    autoplaySpeed: 4000,
  };

  return (
    <div className="bg-white h-full w-full relative mb-20 sm:py-2">
      <Helmet>
        <title>{C.title.HOMEPAGE}</title>
        <meta
          name="description"
          key="description"
          content={C.metaData.HOMEPAGE}
        />
        <meta name="title" key="title" content="GLOBAL TECHNOLOGY INTERFACE" />
        <meta property="og:title" content="GLOBAL TECHNOLOGY INTERFACE" />
        <meta
          property="og:description"
          content="Display, Discover, Develop and Deploy"
        />
        <meta property="og:image" content={globe} />
        <meta property="og:url" content={`${process.env.REACT_APP_BASE_URL}`} />
        <meta property="og:type" content="website" />
        <meta name="twitter:title" content="GLOBAL TECHNOLOGY INTERFACE" />
        <meta
          name="twitter:description"
          content="GLOBAL TECHNOLOGY INTERFACE"
        />
        <meta name="twitter:image" content={globe} />
        <meta name="twitter:card" content="GLOBAL TECHNOLOGY INTERFACE" />
      </Helmet>
      <div className="flex flex-col sm:flex-row w-full max-w-screen">
        <div className="hidden sm:flex order-2 sm:order-1 flex-col sm:w-2/5 text-left items-start sm:pl-5">
          <h1 className="hidden sm:block text-GTI-BLUE-default text-2xl xl:text-6xl font-semibold font-montserrat py-5 px-10">
            GLOBAL TECHNOLOGY INTERFACE
          </h1>
          <h4 className="hidden sm:block sm:text-lg text-sm font-pj-sans text-GTI-BLUE-default pl-10 mr-14 pb-5">
            A platform to showcase innovative technologies and market
            opportunities.
          </h4>
          <h4 className="hidden sm:block sm:text-lg text-sm font-pj-sans text-GTI-BLUE-default pl-10 pr-5 pb-10">
            Access our global network to make meaningful partnerships and
            explore significant collaboration opportunities, all in one place.
          </h4>
          <div className="hidden sm:flex flex-row px-10 w-full">
            <div
              className="flex w-52 bg-contain h-16 rounded-sm  items-center justify-center cursor-pointer"
              style={{
                backgroundImage: `url(${learn_more})`,
                backgroundRepeat: "no-repeat",
              }}
              onClick={() => navigate(C.ABOUT)}
            >
              <p className="text-GTI-BLUE-default text-xl font-semibold">
                Learn More
              </p>
            </div>
            <div
              className="flex w-52 bg-contain h-16 justify-center items-center  rounded-sm text-center cursor-pointer "
              style={{
                backgroundImage: `url(${sign_up})`,
                backgroundRepeat: "no-repeat",
              }}
              onClick={() => navigate("/signup")}
            >
              <p className="text-white text-xl font-semibold">Sign up</p>
            </div>
          </div>
        </div>
        <div className="sm:hidden flex order-1 flex-col sm:w-2/5">
          <h1 className="sm:hidden text-GTI-BLUE-default text-xl font-semibold font-pj-sans py-5 px-5">
            GLOBAL TECHNOLOGY INTERFACE
          </h1>
        </div>
        <div className="flex flex-col order-1 sm:order-2 w-full sm:w-3/5 justify-center items-center">
          {homepagePromotions?.length ? (
            <div className="flex flex-col justify-center w-4/5 h-full max-w-screen-lg relative z-0">
              <Slider
                {...featured_carousal_settings}
                className="flex flex-row justify-center z-10 h-full"
              >
                {homepagePromotions.map((promotion) => (
                  <Featured key={promotion._id} promotion={promotion} />
                ))}
              </Slider>
            </div>
          ) : (
            <div className="flex w-9/12 sm:w-3/5 rounded-full border-gray-50 border-2 sm:p-5 justify-center items-center relative">
              <div className="rounded-full w-full h-full border-gray-200 border-2 m-5 justify-center items-center ">
                <img
                  src={globe}
                  alt="GTI® Globe"
                  className="rounded-full object-contain w-full h-full"
                />
              </div>
              <div className="absolute top-10 left-10">
                <img
                  id="globe_1"
                  src={home_globe_2}
                  alt="GTI® Globe"
                  className="bg-white border border-gray-200 shadow-sm rounded-full w-10 h-10 sm:w-20 sm:h-20 p-2 object-contain"
                />
                <div className="absolute -top-11 right-2 md:-top-5 md:right-20 flex-col text-center flex bg-contain rounded-lg object-contain items-center justify-center border-2 w-auto md:w-[160px] px-4 bg-white shadow-lg">
                  <div className="w-full h-full relative">
                    <p className="w-full text-GTI-BLUE-default text-sm sm:text-sm">
                      Technology Showcase
                    </p>
                    <p className="hidden sm:block w-full text-gray-700 text-xxs sm:text-xs">
                      To showcase and scale technology access and deployment for
                      the UN SDGs
                    </p>
                  </div>
                </div>
              </div>
              <div className="absolute top-10 right-10">
                <img
                  src={home_globe_3}
                  alt="GTI® Globe"
                  className="bg-white border border-gray-200 shadow-sm rounded-full w-10 h-10 sm:w-20 sm:h-20 p-2 object-contain"
                />
                <div className="absolute -top-11 left-5 md:-top-10 md:left-20 flex-col text-center flex bg-contain rounded-lg object-contain items-center justify-center border-2 w-auto sm:w-[160px] px-4 bg-white shadow-lg">
                  <p className="w-full text-GTI-BLUE-default text-sm sm:text-sm">
                    Innovation Calls
                  </p>
                  <p className="hidden sm:block w-full text-gray-700 text-xxs sm:text-xs">
                    To facilitate the identification of innovative technologies
                    to support strategic growth and development
                  </p>
                </div>
              </div>

              <div className="absolute bottom-10 left-10">
                <img
                  src={home_globe_1}
                  alt="GTI® Globe"
                  className=" bg-white border border-gray-200 shadow-sm rounded-full w-10 h-10 sm:w-20 sm:h-20  p-2 object-contain"
                />
                <div className="absolute -bottom-11 right-1 md:-bottom-10 md:right-20 flex-col text-center flex bg-contain rounded-lg object-contain items-center justify-center border-2 w-auto sm:w-[160px] px-4 bg-white shadow-lg">
                  <p className="w-full text-GTI-BLUE-default text-sm sm:text-sm">
                    Market Opportunities
                  </p>
                  <p className="hidden sm:block w-full text-gray-700 text-xs">
                    To enable the discovery of new markets, customers, partners
                    and investors
                  </p>
                </div>
              </div>

              <div className="absolute bottom-10 right-10">
                <img
                  src={home_globe_4}
                  alt="GTI® Globe"
                  className=" bg-white border border-gray-200 shadow-sm rounded-full w-10 h-10 sm:w-20 sm:h-20 p-2 object-contain"
                />
                <div className="absolute -bottom-11 left-5 md:-bottom-5 md:left-20  flex-col text-center flex bg-contain rounded-lg object-contain items-center justify-center border-2 w-auto sm:w-[160px] px-4 bg-white shadow-lg">
                  <p className="w-full text-GTI-BLUE-default text-sm sm:text-sm">
                    Knowledge Hubs
                  </p>
                  <p className="hidden sm:block w-full text-gray-700 text-xs">
                    To provide access to global business, innovation and
                    technology news and events
                  </p>
                </div>
              </div>
            </div>
          )}

          <div
            className="flex w-1/2 sm:w-full rounded-full pt-5 sm:pt-5 justify-center items-center relative cursor-pointer"
            onClick={() => navigate("/featured")}
          >
            <div className="rounded-full w-4/5 h-full border-gray-200 justify-center items-center">
              <img src={feature_profile} alt="Feature Profile" />
            </div>
          </div>
        </div>

        <div className="sm:hidden flex order-1 flex-col sm:w-2/5">
          <h4 className="sm:hidden text-sm font-pj-sans text-GTI-BLUE-default ml-5 mr-5 mt-10 mb-0">
            A platform to showcase innovative technologies and market
            opportunities.
          </h4>
          <h4 className="sm:hidden text-sm font-pj-sans text-GTI-BLUE-default mt-2 ml-5 mr-5 mb-0">
            Access our global network to make meaningful partnerships and
            explore significant collaboration opportunities, all in one place.
          </h4>
          <div className="flex sm:hidden flex-row mt-5 px-20 w-full">
            <div
              className="flex w-52 bg-contain h-8 rounded-sm  items-center justify-center cursor-pointer"
              style={{
                backgroundImage: `url(${learn_more})`,
                backgroundRepeat: "no-repeat",
              }}
              onClick={() => navigate(C.ABOUT)}
            >
              <p className="text-GTI-BLUE-default text-sm font-semibold">
                Learn More
              </p>
            </div>
            <div
              className="flex w-52 bg-contain h-8 justify-center items-center  rounded-sm text-center cursor-pointer "
              style={{
                backgroundImage: `url(${sign_up})`,
                backgroundRepeat: "no-repeat",
              }}
              onClick={() => navigate("/signup")}
            >
              <p className="text-white text-sm font-semibold">Sign up</p>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col w-full max-w-screen relative items-center py-16">
        <p className="text-sm sm:text-2xl lg:text-4xl xl:text-6xl text-GTI-BLUE-default font-pj-sans font-bold">
          Display, Discover, Develop and Deploy
        </p>
      </div>
      <div className="flex flex-row w-full max-w-screen">
        <div className="w-full xl:w-4/5">
          <div
            className="flex flex-col w-full items-center py-10"
            style={{ backgroundColor: "#EEF0FF" }}
          >
            <p className="text-GTI-BLUE-default text-2xl lg:text-4xl font-pj-sans py-10 font-semibold">
              Who uses GTI?
            </p>
            <div className="grid grid-cols-3 gap-x-5">
              <div className="flex flex-col relative items-center text-center group">
                <img
                  src={tech_displayer}
                  alt="Tech Displayer"
                  className="group-hover:opacity-0 duration-200"
                />
                <div className="absolute top-0 opacity-0 group-hover:opacity-100 bg-white p-7 space-y-3 rounded-xl ">
                  <img
                    src={group_tech}
                    alt="Tech Companies"
                    className="w-full"
                  />
                  <img
                    src={group_incub}
                    alt="Group Incubators"
                    className="w-full"
                  />
                  <img
                    src={group_cluster}
                    alt="Group Cluster"
                    className="w-full"
                  />
                </div>
                <img
                  className="absolute -top-7 right-14 w-15 h-15 p-2 bg-white group-hover:opacity-0 rounded-full duration-200"
                  src={tech_displayer_icon}
                  alt="Tech Displayer"
                />
                <img
                  className="absolute -top-7 right-14 w-15 h-15 p-2 bg-black opacity-0 group-hover:opacity-100 rounded-full duration-200"
                  src={tech_displayer_icon_dark}
                  alt="Tech Displayer"
                />
                <p className="text-base lg:text-lg py-2 font-dm-sans font-semibold">
                  Technology Displayers
                </p>
                <p className="text-GTI-BLUE-default font-dm-sans text-xs lg:text-base">
                  Technology companies, incubators and accelerators looking to
                  access new markets and opportunities to deploy their products
                  and/or services
                </p>
              </div>
              <div className="flex flex-col relative items-center text-center group">
                <img
                  src={tech_scouter}
                  alt="Tech Scouter"
                  className="group-hover:opacity-0 duration-200"
                />
                <div className="absolute top-0  opacity-0 group-hover:opacity-100 bg-white p-7 space-y-3 rounded-xl duration-200">
                  <img
                    src={group_ngo}
                    alt="Tech Companies"
                    className="w-full"
                  />
                  <img
                    src={group_gov}
                    alt="Group Government"
                    className="w-full"
                  />
                  <img
                    src={group_corporate}
                    alt="Group corporates"
                    className="w-full"
                  />
                </div>
                <img
                  className="absolute -top-7 right-14 w-15 h-15 p-2 bg-white group-hover:opacity-0 rounded-full duration-200"
                  src={tech_scouter_icon}
                  alt="Tech Scouters"
                />
                <img
                  className="absolute -top-7 right-14 w-15 h-15 p-2 bg-black opacity-0 group-hover:opacity-100 rounded-full duration-200"
                  src={tech_scouter_icon_dark}
                  alt="Tech Scouters"
                />
                <p className="text-base lg:text-lg  py-2 font-dm-sans font-semibold">
                  Technology Scouters
                </p>
                <p className="text-GTI-BLUE-default font-dm-sans text-xs lg:text-base">
                  NGOs , Government institutions & corporates who are searching
                  for innovative solution providers to solve business
                  requirements
                </p>
              </div>
              <div className="flex flex-col relative items-center text-center group">
                <img
                  src={tech_investor}
                  alt="Tech Investor"
                  className="group-hover:opacity-0 duration-200"
                />
                <div className="absolute top-0  opacity-0 group-hover:opacity-100 bg-white p-7 py-16 space-y-3 rounded-xl ">
                  <img
                    src={group_investor}
                    alt="Group Investor"
                    className="w-full"
                  />
                  <img
                    src={group_startup}
                    alt="Group Startup"
                    className="w-full"
                  />
                </div>
                <img
                  className="absolute -top-7 right-14 w-15 h-15 p-2 bg-white group-hover:opacity-0 rounded-full duration-200"
                  src={investor_icon}
                  alt="Investor"
                />
                <img
                  className="absolute -top-7 right-14 w-15 h-15 p-2 bg-black opacity-0 group-hover:opacity-100 rounded-full duration-200"
                  src={investor_icon_dark}
                  alt="Investor"
                />
                <p className="text-base lg:text-lg py-2 font-dm-sans font-semibold">
                  Investors
                </p>
                <p className="text-GTI-BLUE-default font-dm-sans text-xs lg:text-base">
                  Entities who assist the start-ups with capital and also bring
                  in a wealth of market and business knowledge to help them
                  scale in the right direction
                </p>
              </div>
            </div>
          </div>
          <div className="flex flex-col w-full items-center">
            <p className="text-GTI-BLUE-default text-2xl lg:text-4xl font-pj-sans py-10 font-semibold">
              Sectors we work in
            </p>
            <div className="grid grid-cols-3 gap-x-5">
              <div className="flex flex-col relative  items-center text-center">
                <img src={clean_tech} alt="Clean Tech" />
                <p className="text-base lg:text-lg py-2 font-pj-sans font-semibold">
                  Clean Technology
                </p>
                <p className="text-GTI-BLUE-default font-dm-sans text-xs lg:text-base">
                  Products or services that promote climate action and reduce
                  negative environmental impacts through the reduction of carbon
                  emissions and/or the sustainable use of resources
                </p>
              </div>
              <div className="flex flex-col relative items-center text-center">
                <img src={bio_tech} alt="Bio Tech" />
                <p className="text-base lg:text-lg py-2 font-pj-sans font-semibold">
                  BioTechnology and Healthtech
                </p>
                <p className="text-GTI-BLUE-default font-dm-sans text-xs lg:text-base">
                  Products or services that are designed to improve health
                  and/or the provision of healthcare
                </p>
              </div>
              <div className="flex flex-col relative  items-center text-center">
                <img src={digi_tech} alt="Digi Tech" />
                <p className="text-base lg:text-lg py-2 font-pj-sans font-semibold">
                  Digital and Information Technology
                </p>
                <p className="text-GTI-BLUE-default font-dm-sans text-xs lg:text-base">
                  Products or services focused on digital transformation and
                  innovation to prioritize and Increase the use of information
                  technologies focusing on resilience and sustainability
                </p>
              </div>
            </div>
          </div>
          <div className="flex flex-col items-center">
            <p className="text-GTI-BLUE-default text-2xl lg:text-4xl font-pj-sans pt-16 font-semibold">
              How do we make it possible
            </p>
            <div className="grid grid-cols-2 gap-x-0 gap-y-0 py-10">
              <img
                src={tech_display}
                alt="Tech Displayer"
                className="w-3/5 lg:w-4/5 object-contain h-4/5 translate-x-10"
              />
              <div className="flex flex-col w-full text-center items-center justify-center h-4/5 -translate-x-10">
                <p className="text-lg lg:text-2xl py-3 lg:py-2 font-roboto font-semibold">
                  Technology Display
                </p>
                <p className="text-sm lg:text-lg text-GTI-BLUE-default lg:px-10 font-dm-sans">
                  Use our platform to showcase your innovative technology,
                  connect with relevant stakeholders, access market
                  opportunities and grow your business. We feature your unique
                  technologies on our social media pages, boosting visibility
                  and accelerating connections with partners and customers
                </p>
              </div>
              <div className="flex flex-col w-full text-center items-center justify-center h-4/5 translate-x-30 lg:-translate-x-10">
                <p className="text-lg lg:text-2xl py-3 lg:py-2 font-roboto font-semibold">
                  Market Opportunities
                </p>
                <p className="text-sm lg:text-lg text-GTI-BLUE-default lg:px-20">
                  Governments, corporates and enterprises can leverage the GTI
                  platform with the goal of scouting for innovative
                  technologies, intellectual properties (IP), technologies and
                  solutions in order to improve their existing businesses
                </p>
              </div>
              <img
                src={market_opp}
                alt="Market Opportunities"
                className="w-3/5 lg:w-4/5 object-contain h-4/5 translate-x-10"
              />
              <img
                src={innovation_call}
                alt="Innovation Calls"
                className="w-3/5 lg:w-4/5 object-contain h-4/5 translate-x-10"
              />
              <div className="flex flex-col w-full text-center items-center justify-center h-4/5 -translate-x-10">
                <p className="text-lg lg:text-2xl py-3 lg:py-2 font-roboto font-semibold">
                  Innovation Calls
                </p>
                <p className="text-sm lg:text-lg text-GTI-BLUE-default  lg:px-20">
                  Organizations can source innovative ideas and solutions
                  globally to address specific needs and challenges
                </p>
              </div>
              <div className="flex flex-col w-full text-center items-center justify-center h-4/5 translate-x-30 lg:-translate-x-10">
                <p className="text-lg lg:text-2xl py-3 lg:py-2 font-roboto font-semibold">
                  Investor Connect
                </p>
                <p className="text-sm lg:text-lg text-GTI-BLUE-default lg:px-20">
                  We support startups in connecting with investors or potential
                  partners who can help in growth stage company funding and
                  allow them to reach new heights
                </p>
              </div>
              <img
                src={investor_connect}
                alt="Investor"
                className="w-3/5 lg:w-4/5 object-contain h-4/5 translate-x-10"
              />
              <img
                src={knowledge_hub}
                alt="Knowledge Hub"
                className="w-3/5 lg:w-4/5 object-contain h-4/5 translate-x-10"
              />
              <div className="flex flex-col w-full text-center items-center justify-center h-4/5 -translate-x-10">
                <p className="text-lg lg:text-2xl py-3 lg:py-2 font-roboto font-semibold">
                  Knowledge Hub
                </p>
                <p className="text-sm lg:text-lg text-GTI-BLUE-default lg:px-20">
                  Get access to our latest publications, articles and
                  newsletters and keep updated on ongoing opportunities and
                  events globally
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="sticky hidden xl:flex flex-col h-fit top-0  w-1/5 overflow-hidden">
          <p className="bg-GTI-BLUE-default text-white text-xl font-roboto py-4 px-2">
            Latest on GTI
          </p>
          <div
            className="flex flex-col py-2 items-center cursor-pointer"
            onClick={() => {
              navigate(C.TECHNOLOGY);
            }}
          >
            <p className="text-lg ">Latest Technologies</p>
            <hr className="w-1/2 h-0.5 mx-auto bg-gray-200  rounded " />
            <Slider
              {...sidebar_carousal_settings}
              className="flex flex-row  py-3 w-full"
            >
              {products.PRODUCTS_LIST?.products.map(
                (item: C.productItemFullFetched, id) => {
                  return <LatestTechCard item={item} key={id} />;
                }
              )}
            </Slider>
          </div>
          <div
            className="flex flex-col py-2 items-center cursor-pointer"
            onClick={() => {
              navigate(C.OPPORTUNITY);
            }}
          >
            <p className="text-lg ">Market Opportunities</p>
            <hr className="w-1/2 h-0.5 mx-auto bg-gray-200  rounded " />
            <Slider
              {...sidebar_carousal_settings}
              className="flex flex-row  py-3 w-full"
            >
              {opp.OPP_LIST?.opportunities.map(
                (item: C.oppotunityItemPartialFetched, id) => {
                  return <LatestOppCard item={item} key={id} />;
                }
              )}
            </Slider>
          </div>
          <div
            className="flex flex-col py-2 items-center cursor-pointer"
            onClick={() => {
              navigate(C.INNOVATION);
            }}
          >
            <p className="text-lg ">Innovation Calls</p>
            <hr className="w-1/2 h-0.5 mx-auto bg-gray-200 rounded " />
            <Slider
              {...sidebar_carousal_settings}
              className="flex flex-row  py-3 w-full"
            >
              {innovations.INNOVATION_LIST.map(
                (item: C.InnovationItemFetched, id) => {
                  return <LatestCallCard item={item} key={id} />;
                }
              )}
            </Slider>
          </div>
          <div
            className="flex flex-col py-2 items-center cursor-pointer"
            onClick={() => {
              navigate(C.NEWS);
            }}
          >
            <p className="text-lg ">Recent News</p>
            <hr className="w-1/2 h-0.5 mx-auto bg-gray-200 rounded " />
            <Slider
              {...sidebar_carousal_settings}
              className="flex flex-row  py-3 w-full"
            >
              {newsList?.news && newsList.news.length > 0
                ? newsList.news.map((item: C.newsFetched, id) => {
                    return <LatestNewsCard item={item} key={id} />;
                  })
                : null}
            </Slider>
          </div>
          <div
            className="flex flex-col py-2 items-center cursor-pointer"
            onClick={() => {
              navigate(C.ARTICLES);
            }}
          >
            <p className="text-lg ">Recent Articles</p>
            <hr className="w-1/2 h-0.5 mx-auto bg-gray-200  rounded " />
            <Slider
              {...sidebar_carousal_settings}
              className="flex flex-row  py-3 w-full"
            >
              {articlelist?.articles && articlelist.articles.length > 0
                ? articlelist.articles.map((item: C.articleItemFetched, id) => {
                    return <LatestArticlesCard item={item} key={id} />;
                  })
                : null}
            </Slider>
          </div>
        </div>
      </div>
      <div
        className="hidden lg:flex flex-col w-full max-w-screen items-center"
        style={{ backgroundColor: "#EEF0FF" }}
      >
        <p className="text-GTI-BLUE-default text-4xl py-10 font-pj-sans font-semibold">
          What our clients say about us
        </p>
        <div className="flex flex-col justify-center w-full h-full max-w-screen  pt-10 py-20 relative z-0 ">
          <Slider
            {...client_carousal_settings}
            className="flex flex-row mx-24 justify-center items-center z-10"
          >
            {client_review.map((item, id) => {
              return (
                <div
                  key={id}
                  className="flex w-full justify-center items-center"
                  onMouseEnter={() => {
                    setIsHovered(true);
                    setClientId(id);
                  }}
                  onMouseLeave={() => {
                    setIsHovered(false);
                    setClientId(-1);
                  }}
                >
                  <div
                    className={`${
                      isHovered && clientId == id && item.comment.length > 400
                        ? "h-fit"
                        : "h-96"
                    } flex flex-col w-80 mx-auto bg-white rounded-2xl flex-end`}
                  >
                    {isHovered && clientId == id ? (
                      <p className="text-sm px-8 py-8 text-left">
                        {item.comment}
                      </p>
                    ) : (
                      <p className="text-sm px-8 py-8 text-left">
                        {item.comment.length < 430
                          ? item.comment
                          : item.comment.slice(0, 430).concat("...")}
                      </p>
                    )}

                    <div className="flex flex-row px-4 w-full ">
                      <img
                        src={item.img}
                        alt="Client Profile"
                        className="w-20 h-20 rounded-full py-3 px-2"
                      />
                      <div className="flex flex-col">
                        <p className="text-sm font-semibold font-roboto">
                          {item.name}
                        </p>
                        <p className="text-xs text-gray-400 font-roboto">
                          {item.about}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </Slider>
          <img
            src={star}
            alt="Start"
            className="absolute top-0 left-28 z-5 w-24 h-24"
          />
          <img
            src={star}
            alt="Star"
            className="absolute bottom-10 right-28 z-5 w-24 h-24"
          />
        </div>
      </div>
      <div className="hidden lg:flex flex-col w-full max-w-screen items-center">
        <p className="text-GTI-BLUE-default text-4xl py-10 font-pj-sans font-semibold">
          Partners & Clients
        </p>
        <div className="flex flex-col justify-center w-full h-full max-w-screen-lg relative z-0">
          <Slider
            {...partner_carousal_settings}
            className="flex flex-row mx-12 justify-center z-10 h-full"
          >
            {technologyPartners.technologyPartner.map((partner) => {
              return (
                <div
                  key={partner._id}
                  className="flex flex-row justify-center items-center h-full"
                >
                  <img
                    className="w-48 h-48 m-2 object-contain mx-auto"
                    src={partner.image}
                    alt={partner.name}
                  />
                </div>
              );
            })}
          </Slider>
        </div>
      </div>
      <NewsLetter />
    </div>
  );
};

export default Homepage;
