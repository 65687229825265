import * as ACTION from "../actiontypes/actionTypes";

const initialState: CHAT_STATE = {
  CHAT_LIST: {
    CHAT: [],
  },
  CHAT_TOTAL: 0,
};

export const chatreducer = (
  state: CHAT_STATE = initialState,
  action: CHAT_ACTION
): CHAT_STATE => {
  switch (action.type) {
    case ACTION.CHAT_GET:
      const chat: CHAT_STATE = action.CHAT;
      return {
        ...state,
        CHAT_LIST: chat.CHAT_LIST,
        CHAT_TOTAL: chat.CHAT_TOTAL,
      };
    case ACTION.CHAT_DELETE:
      let chat_arr: CHAT_STATE = {
        CHAT_LIST: {
          CHAT: [],
        },
        CHAT_TOTAL: 0,
      };
      return {
        ...state,
        CHAT_LIST: chat_arr.CHAT_LIST,
        CHAT_TOTAL: chat_arr.CHAT_TOTAL,
      };
    default:
      return { ...state };
  }
  // return state;
};
