import React, { useState } from "react";
import { withScrollToTop } from "../../hoc/withScrollToTop";
import { useNavigate, useParams } from "react-router-dom";
import { forgotPassword } from "../../api/user";
import { getQueryParams, isSuccess, notify } from "../../utils";
import { replace } from "formik";
import { BsFillPatchCheckFill } from "react-icons/bs";
import ReactDOM from "react-dom";

const SuccessModal = ({
  show,
  toggle,
}: {
  show: boolean;
  toggle: () => void;
}) => {
  let navigate = useNavigate();

  const content = (
    <div
      id="error-modal"
      tabIndex={-1}
      className={show ? "container fixed top-0 left-0" : "hidden"}
      aria-hidden="true"
    >
      <div className="bg-white justify-center md:inline-flex max-w-md py-4 px-8 rounded-lg md:mx-auto mx-4 bg-opacity-100 -translate-y-1/2 relative  h-full md:h-auto">
        <div className="relative bg-white rounded-lg space-y-2">
          <div className="flex flex-col justify-evenly items-center p-6 text-center">
            <BsFillPatchCheckFill fill="green" className="w-16 m-6 h-16" />
            <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
              {
                "Password changed successfully, please login with new credentials."
              }
            </h3>
            <button
              data-modal-toggle="error-modal"
              onClick={() => {
                toggle();
                navigate("/");
              }}
              type="button"
              className="text-white w-fit bg-GTI-BLUE-default hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center mr-2"
            >
              Home
            </button>
          </div>
        </div>
      </div>
    </div>
  );
  return ReactDOM.createPortal(content, document.body);
};

const ForgotPassword = () => {
  const [error, setError] = useState("");
  const [password, setPassword] = useState("");
  const [confPassword, setConfPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const token = getQueryParams("token").split(" ").join("+") ?? "";
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();

  const toggleModal = () => {
    setShowModal((prev) => !prev);
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    setLoading(true);
    setError("");
    if (password !== confPassword) {
      setError("Password should match!");
      return;
    } else if (!token) {
      setError("Invalid token!");
      return;
    }
    const response = await forgotPassword({
      password,
      token,
    });

    if (isSuccess(response)) {
      notify("Password updated successfully!", "success");
      navigate("/", { replace: true });
    } else {
      notify("Failed to update password!", "error");
    }
    setLoading(false);
  };
  return (
    <section className="w-full bg-gray-50 dark:bg-gray-900">
      <div className="flex flex-col items-center justify-center px-6 py-8 mt-10 mx-auto lg:py-0">
        {/* <a
          href="#"
          className="flex items-center mb-6 text-2xl font-semibold text-gray-900 dark:text-white"
        >
          GTI®
        </a> */}
        <div className="w-full p-6 bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md dark:bg-gray-800 dark:border-gray-700 sm:p-8">
          <h2 className="mb-1 text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
            Change Password
          </h2>
          <form
            className="mt-4 space-y-4 lg:mt-5 md:space-y-5"
            onSubmit={handleSubmit}
          >
            <div>
              <label
                htmlFor="password"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                New Password
              </label>
              <input
                type="password"
                name="password"
                id="password"
                placeholder="••••••••"
                className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                required
                value={password}
                onChange={(e: any) => setPassword(e.target.value)}
              />
            </div>
            <div>
              <label
                htmlFor="confirm-password"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                Confirm password
              </label>
              <input
                type="password"
                name="confirm-password"
                id="confirm-password"
                placeholder="••••••••"
                className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                required
                value={confPassword}
                onChange={(e: any) => setConfPassword(e.target.value)}
              />
            </div>
            <div className="flex items-start">
              <div className="ml-3 text-sm">
                {error && (
                  <span className="font-light text-red-500 dark:text-gray-300">
                    {error}
                  </span>
                )}
              </div>
            </div>
            <button
              type="submit"
              disabled={loading}
              className="w-full text-white bg-GTI-BLUE-default hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
            >
              Submit
            </button>
          </form>
        </div>
      </div>
      <SuccessModal show={showModal} toggle={toggleModal} />
    </section>
  );
};

export default withScrollToTop(ForgotPassword);
