import React, { Dispatch } from "react";
import { Form, Formik } from "formik";
import { BsFillTelephoneFill } from "react-icons/bs";
import { GrMail } from "react-icons/gr";
import { IoLocationSharp } from "react-icons/io5";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";

import { createContact } from "../../store/actioncreators/contactactions";
import {
  contactItem,
  CONTACT_ADDRESS,
  CONTACT_EMAIL,
  CONTACT_NUMBER,
  HOME,
  title,
  metaData,
  NONE,
} from "../constants";
import { contactSchema } from "../validations/contactValidations";

interface MyFormValues {
  fullName: string;
  phone: string;
  email: string;
  message: string;
}
const Contact = () => {
  const dispatch: Dispatch<any> = useDispatch();
  const navigate = useNavigate();
  const initialValues: MyFormValues = {
    fullName: "",
    phone: "",
    email: "",
    message: "",
  };

  const handleSubmit = (values: MyFormValues) => {
    const data: contactItem = {
      email: values.email,
      name: values.fullName,
      message: values.message,
      phoneNumber: values.phone,
    };
    dispatch(createContact(data));
    navigate(HOME);
  };

  return (
    <div className="md:flex h-full flex-row w-11/12 md:w-4/6 mt-0 md:mt-20 m-20 mx-auto rounded-lg">
      <Helmet>
        <title>{title.CONTACT}</title>
        <meta name="description" key="description" content={metaData.CONTACT} />
        <meta name="title" key="title" content="Contact Us" />
        <meta property="og:title" content="Contact Us" />
        <meta property="og:description" content="Contact Us" />
        <meta property="og:image" content={NONE} />
        <meta
          property="og:url"
          content={`${process.env.REACT_APP_BASE_URL}/contact-us`}
        />
        <meta property="og:type" content="website" />
        <meta name="twitter:title" content="Contact Us" />
        <meta name="twitter:description" content={metaData.CONTACT} />
        <meta name="twitter:image" content={NONE} />
        <meta name="twitter:card" content="Contact Us" />
      </Helmet>
      <div className="flex rounded-lg md:rounded-l-lg flex-col p-6 md:p-10 bg-GTI-BLUE-default justify-center md:w-1/2">
        <p className="text-white font-roboto text-2xl md:text-4xl -translate-y-5 ">
          Contact Us
        </p>
        <p className="text-white items-center py-2 inline-flex font-roboto text-sm md:text-lg">
          <span>
            <BsFillTelephoneFill className="mr-2 w-5 h-5" />
          </span>
          {CONTACT_NUMBER}
        </p>
        <p className="text-white items-center py-2 inline-flex font-roboto text-sm md:text-lg">
          <span>
            <GrMail className="mr-2 w-5 h-5" />
          </span>
          {CONTACT_EMAIL}
        </p>
        <p className="text-white items-center py-2 inline-flex font-roboto text-sm md:text-lg">
          <span>
            <IoLocationSharp className="mr-2 w-5 h-5" />
          </span>
          {CONTACT_ADDRESS}
        </p>
      </div>
      <div className="flex flex-col md:w-1/2 ">
        <Formik
          initialValues={initialValues}
          validationSchema={contactSchema}
          onSubmit={(values, { resetForm }) => {
            handleSubmit(values);
            resetForm();
          }}
        >
          {({ handleChange, setFieldValue, handleSubmit, errors, values }) => (
            <>
              <Form className="flex flex-col rounded-lg justify-center items-center pt-6 pb-6 md:p-10 border border-gray-200">
                <div className="flex flex-row w-full px-5 justify-between mb-4">
                  <div className="flex flex-col w-full space-x-2 mr-2 relative">
                    <div className="relative w-full">
                      <input
                        onChange={(e) =>
                          setFieldValue("fullName", e.target.value)
                        }
                        type="text"
                        id="floating_outlined"
                        className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                        placeholder=" "
                      />
                      <label
                        htmlFor="floating_outlined"
                        className="absolute text-sm text-gray-500  duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white  px-2 peer-focus:px-2 peer-focus:text-blue-600  peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1"
                      >
                        Full Name
                      </label>
                    </div>
                    {errors.fullName && (
                      <p
                        id="filled_error_help"
                        className="mt-2 text-xs text-red-600 dark:text-red-400"
                      >
                        {errors.fullName}
                      </p>
                    )}
                  </div>
                </div>
                <div className="flex flex-col w-full px-5 space-x-2 relative mb-4">
                  <div className="relative">
                    <input
                      onChange={(e) => setFieldValue("phone", e.target.value)}
                      type="number"
                      id="floating_outlined"
                      className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                      placeholder=" "
                    />
                    <label
                      htmlFor="floating_outlined"
                      className="absolute text-sm text-gray-500  duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white  px-2 peer-focus:px-2 peer-focus:text-blue-600  peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1"
                    >
                      Phone Number
                    </label>
                  </div>
                  {errors.phone && (
                    <p
                      id="filled_error_help"
                      className="mt-2 text-xs text-red-600 dark:text-red-400"
                    >
                      {errors.phone}
                    </p>
                  )}
                </div>
                <div className="flex flex-col w-full px-5 space-x-2 relative mb-4">
                  <div className="relative">
                    <input
                      onChange={(e) => setFieldValue("email", e.target.value)}
                      type="text"
                      id="floating_outlined"
                      className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                      placeholder=" "
                    />
                    <label
                      htmlFor="floating_outlined"
                      className="absolute text-sm text-gray-500  duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white  px-2 peer-focus:px-2 peer-focus:text-blue-600  peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1"
                    >
                      Email id
                    </label>
                  </div>
                  {errors.email && (
                    <p
                      id="filled_error_help"
                      className="mt-2 text-xs text-red-600 dark:text-red-400"
                    >
                      {errors.email}
                    </p>
                  )}
                </div>
                <div className="flex flex-col w-full px-5 space-x-2 relative mb-4">
                  <div className="relative">
                    <textarea
                      onChange={(e) => setFieldValue("message", e.target.value)}
                      id="floating_outlined"
                      className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer max-h-[300px] min-h-[100px]"
                      placeholder=" "
                    />
                    <label
                      htmlFor="floating_outlined"
                      className="absolute text-sm text-gray-500  duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white  px-2 peer-focus:px-2 peer-focus:text-blue-600  peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1"
                    >
                      Message
                    </label>
                  </div>
                  {errors.message && (
                    <p
                      id="filled_error_help"
                      className="mt-2 text-xs text-red-600 dark:text-red-400"
                    >
                      {errors.message}
                    </p>
                  )}
                </div>

                <button
                  type="submit"
                  className="px-4 py-2 rounded-lg bg-GTI-BLUE-default text-white font-roboto"
                >
                  Send Message
                </button>
              </Form>
            </>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default Contact;
