import * as ACTION from "../actiontypes/actionTypes";

const initialState: TOAST_STATE = {
  TOAST: {
    MESSAGE: "",
    STATUS: false,
    TYPE: 0,
  },
};

export const toastreducer = (
  state: TOAST_STATE = initialState,
  action: TOAST_ACTION
): TOAST_STATE => {
  switch (action.type) {
    case ACTION.TOAST_SUCCESS:
      let t1: TOAST = {
        MESSAGE: "Success",
        STATUS: true,
        TYPE: 1,
      };
      return { ...state, TOAST: t1 };
    case ACTION.TOAST_FAIL:
      let t2: TOAST = {
        MESSAGE: "Failed",
        STATUS: true,
        TYPE: 2,
      };
      // console.log("failed");
      return { ...state, TOAST: t2 };
    case ACTION.TOAST_CLOSE:
      let t3: TOAST = {
        MESSAGE: "",
        STATUS: false,
        TYPE: 0,
      };
      return { ...state, TOAST: t3 };
    default:
      return { ...state };
  }
  // return state;
};
