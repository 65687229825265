import React, { Dispatch, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { closeToast } from "../../store/actioncreators/toastactions";

const Toast = () => {
    let dispatch: Dispatch<any> = useDispatch();
    let toast = useSelector<STATE, any>((state: TOAST_STATE) => state.TOAST);
    let handleClose = () => {
        dispatch(closeToast());
    };
    useEffect(() => {
        setTimeout(() => {
            dispatch(closeToast());
        }, 2000);
    }, [toast.TOAST.STATUS]);
    return (
        <React.Fragment>
            <div
                id="toast-success"
                className={
                    "absolute z-20  flex right-10 justify-center items-center mt-10 p-4 mb-4 w-full max-w-xs text-gray-500 bg-slate-200 rounded-lg shadow " +
                    (!toast.TOAST.STATUS ? "hidden" : "")
                }
                role="alert"
            >
                {toast.TOAST.TYPE == 1 && (
                    <div className="inline-flex flex-shrink-0 justify-center items-center w-8 h-8 text-green-500 bg-green-100 rounded-lg dark:bg-green-800 dark:text-green-200">
                        <svg
                            aria-hidden="true"
                            className="w-5 h-5"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                fillRule="evenodd"
                                d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                clipRule="evenodd"
                            ></path>
                        </svg>
                        <span className="sr-only">Check icon</span>
                    </div>
                )}
                {toast.TOAST.TYPE == 2 && (
                    <div className="inline-flex flex-shrink-0 justify-center items-center w-8 h-8 text-red-500 bg-red-100 rounded-lg dark:bg-red-800 dark:text-red-200">
                        <svg
                            aria-hidden="true"
                            className="w-5 h-5"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                fillRule="evenodd"
                                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                clipRule="evenodd"
                            ></path>
                        </svg>
                        <span className="sr-only">Error icon</span>
                    </div>
                )}
                <div className="ml-3 text-sm font-normal">{toast.TOAST.MESSAGE}</div>
                <button
                    type="button"
                    onClick={() => {
                        handleClose();
                    }}
                    className="ml-auto -mx-1.5 -my-1.5  text-gray-500 hover:text-red-600 rounded-lg focus:ring-2 focus:ring-gray-300 p-1.5  inline-flex h-8 w-8 "
                    data-dismiss-target="#toast-success"
                    aria-label="Close"
                >
                    <span className="sr-only">Close</span>
                    <svg
                        aria-hidden="true"
                        className="w-5 h-5"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            fillRule="evenodd"
                            d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                            clipRule="evenodd"
                        ></path>
                    </svg>
                </button>
            </div>
        </React.Fragment>
    );
};
export default Toast;
