import ReactDOM from "react-dom";
import { useNavigate } from "react-router-dom";
import loading from "../../assests/loading.webp";
import success from "../../assests/success.gif";
import error from "../../assests/error.gif";
import { useEffect, useState } from "react";

const SuccessModal = ({
  show,
  state,
  message,
  toggle,
}: {
  show: boolean;
  state: string;
  message: string;
  toggle: (isOpen: boolean, state: string, message: string) => void;
}) => {
  let navigate = useNavigate();

  const [modalDetails, setModalDetails] = useState({
    image: loading,
    message,
    buttonText: "",
  });

  useEffect(() => {
    if (state === "LOADING") {
      setModalDetails({
        image: loading,
        message,
        buttonText: "",
      });
    } else if (state === "SUCCESS") {
      setModalDetails({
        image: success,
        message,
        buttonText: "Your Opportunity",
      });
    } else if (state === "ERROR") {
      setModalDetails({
        image: error,
        message,
        buttonText: "Edit Opportunity",
      });
    }
  }, [state, message]);

  const content = (
    <div
      id="error-modal"
      tabIndex={-1}
      className={show ? "container fixed top-0 left-0" : "hidden"}
      aria-hidden="true"
    >
      <div className="bg-white justify-center md:inline-flex max-w-md py-4 px-8 rounded-lg md:mx-auto mx-4 bg-opacity-100 -translate-y-1/2 relative  h-full md:h-auto">
        <div className="relative bg-white rounded-lg space-y-2">
          <div className="flex flex-col justify-evenly items-center p-6 text-center">
            <img
              src={modalDetails.image}
              alt="Loading"
              style={{ maxWidth: "50%", height: "auto" }}
            />
            <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
              {modalDetails.message}
            </h3>
            <button
              data-modal-toggle="error-modal"
              onClick={() => {
                toggle(false, "LOADING", "");
                if (state === "SUCCESS") {
                  navigate("/your-opportunities");
                }
              }}
              type="button"
              className={`${
                state === "LOADING" ? "hidden" : "block"
              } text-white w-fit bg-GTI-BLUE-default hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center mr-2`}
            >
              {modalDetails.buttonText}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
  return ReactDOM.createPortal(content, document.body);
};

export default SuccessModal;
