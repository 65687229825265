import React, { Dispatch, useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import {
  CONTENT_TYPE,
  LIMIT,
  presignedData,
  publicationFetched,
  publicationUpdate,
} from "../constants";
import axios from "axios";
import {
  failToast,
  successToast,
} from "../../store/actioncreators/toastactions";
import { useNavigate } from "react-router-dom";
import { Editor } from "@tinymce/tinymce-react";
import { ScreenSpinner } from "../utils/loader";
import { getQueryParams } from "../../utils";
import {
  getPublications,
  updatePublication,
} from "../../store/actioncreators/publicationActions";

type publicationsModalItem = {
  _id: string;
  topic: string;
  description: string;
  imageUrl: File | null;
  documentUrl: File | null;
  displayOnHomePage: boolean;
  isDeleted: boolean;
  createdAt: string;
  updatedAt: string;
};

const PublicationsEditModel = ({
  currPublication,
  handleModelUpdate,
}: {
  currPublication: publicationFetched;
  handleModelUpdate: () => void;
}) => {
  const dispatch: Dispatch<any> = useDispatch();
  const editorRef2 = useRef<any>(null);
  const [publicationModal, setModalData] = useState<publicationsModalItem>({
    ...currPublication,
    imageUrl: null,
    documentUrl: null,
  });
  const handleImageChange = function (e: React.ChangeEvent<HTMLInputElement>) {
    const fileList = e.target.files;

    if (!fileList) return;

    setModalData({ ...publicationModal, imageUrl: fileList[0] });
  };
  const handleDocumentChange = function (
    e: React.ChangeEvent<HTMLInputElement>
  ) {
    const fileList = e.target.files;

    if (!fileList) return;

    setModalData({ ...publicationModal, documentUrl: fileList[0] });
  };

  const getPresigned = async (content: presignedData): Promise<string> => {
    const data = JSON.stringify(content);
    let result: string = "";
    const config = {
      method: "post",
      url: `${process.env.REACT_APP_BASE_API}/users/getPresignedUrl`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    await axios(config)
      .then(function (response) {
        result = response.data;
        postFile(result);
      })
      .catch(function (error) {
        result = "error";
        dispatch(failToast());
      });

    return result;
  };

  const postFile = async (signed: string) => {
    var config = {
      method: "put",
      url: signed,
      headers: {
        "Content-Type": CONTENT_TYPE,
      },
      data: publicationModal.imageUrl,
    };

    await axios(config)
      .then(function (response) {
        dispatch(successToast());
      })
      .catch(function (error) {});
  };

  const handleUpdate = async () => {
    let signedURL: string = "";
    const signedData: presignedData = {
      fileName: publicationModal.imageUrl?.name || publicationModal.topic,
      filePath: "publication_image",
      fileType: "png",
    };
    if (publicationModal.imageUrl) {
      signedURL = await getPresigned(signedData);
      postFile(signedURL);
    }
    const data: publicationUpdate = {
      topic: publicationModal.topic,
      description: publicationModal.description,
      imageUrl: publicationModal.imageUrl
        ? signedURL.split("?")[0]
        : currPublication.imageUrl,
      documentUrl: publicationModal.documentUrl
        ? signedURL.split("?")[0]
        : currPublication.imageUrl,
      publicationId: publicationModal?._id,
    };
    await dispatch(updatePublication(data));
    handleModelUpdate();
  };

  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "auto";
    };
  }, []);

  const content = (
    <div className="z-10 pb-[200px] pt-4 fixed w-full h-screen bg-slate-700 bg-opacity-70 top-0 left-0 flex justify-center overflow-auto">
      <div
        className={
          "duration-200 ease-in-out w-2/5 h-fit flex flex-col space-y-1 justify-evenly text-center bg-white shadow-lg p-10  shadow-GTI-BLUE-default rounded "
        }
      >
        <div className="flex flex-row justify-center relative">
          <h4 className="font-roboto text-xl pt-5 text-GTI-BLUE-default">
            Edit Publication
          </h4>
          <button
            onClick={() => {
              handleModelUpdate();
            }}
            className="absolute right-0 -top-5 font-bold hover:text-red-500 duration-300 border border-slate-100 px-3 py-1 rounded"
          >
            X
          </button>
        </div>
        <div className="flex flex-col w-full">
          <div className="flex flex-col space-y-3">
            <div className="flex flex-col justify-center space-y-3">
              <input
                type="text"
                defaultValue={publicationModal.topic}
                onChange={(e) => {
                  setModalData({
                    ...publicationModal,
                    topic: e.target.value,
                  });
                }}
                aria-label="Title"
                className="mb-6 bg-gray-100 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
                placeholder="Title"
              />
              <Editor
                onInit={(evt, editor) => (editorRef2.current = editor)}
                initialValue={publicationModal.description}
                onChange={(e) => {
                  setModalData({
                    ...publicationModal,
                    description: editorRef2.current.getContent(),
                  });
                }}
                init={{
                  height: 200,
                  menubar: false,
                  plugins: [
                    "advlist",
                    "autolink",
                    "lists",
                    "link",
                    "image",
                    "charmap",
                    "preview",
                    "anchor",
                    "searchreplace",
                    "visualblocks",
                    "code",
                    "fullscreen",
                    "insertdatetime",
                    "media",
                    "table",
                    "code",
                    "help",
                    "wordcount",
                  ],
                  toolbar:
                    "undo redo | blocks | " +
                    "bold italic forecolor | alignleft aligncenter " +
                    "alignright alignjustify | bullist numlist outdent indent | " +
                    "removeformat ",
                  content_style:
                    "body { font-family:Helvetica,Arial,sans-serif,roboto; font-size:14px }",
                }}
              />
              <input
                onChange={(e) => {
                  handleImageChange(e);
                }}
                type="file"
                accept={CONTENT_TYPE}
                aria-label="Click to uplod photos(*png only)"
                className="mb-6 bg-gray-100 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
                placeholder="Click to uplod photos(*png only)"
              />
              <input
                onChange={(e) => {
                  handleDocumentChange(e);
                }}
                accept=".pdf"
                type="file"
                aria-label="Click to uplod document(.pdf only)"
                className="mb-6 bg-gray-100 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
                placeholder="Click to uplod document(.pdf only)"
              />
            </div>
            <div className="flex justify-center mx-10">
              <button
                type="button"
                className="button active"
                onClick={() => {
                  handleUpdate();
                }}
              >
                Update
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
  return ReactDOM.createPortal(content, document.body);
};

const Card = ({
  item,
  handleModel,
}: {
  item: publicationFetched;
  handleModel: (data: publicationFetched) => void;
}) => {
  const DOC = new Date(item.createdAt);
  const navigate = useNavigate();

  const handleView = () => {
    navigate(`/publications/${item._id}`, { state: { id: item._id } });
  };
  return (
    <div
      className="w-full max-w-[350px] cursor-pointer overflow-hidden rounded-lg bg-white shadow h-full max-h-[320px]"
      onClick={handleView}
    >
      {item.imageUrl ? (
        <img
          src={item?.imageUrl ?? ""}
          className="aspect-video w-full object-contain"
          alt={item.topic?.replace(/(<([^>]+)>)/gi, "")}
        />
      ) : null}
      <div className="p-4">
        <p className="mb-1 text-sm text-primary-500">
          <time>
            {DOC.toLocaleString("default", {
              month: "short",
              day: "2-digit",
              year: "numeric",
            })}
          </time>
        </p>
        <h3 className="text-sm md:text-xl font-medium text-gray-900 truncate">
          {item.topic?.replace(/(<([^>]+)>)/gi, "")}
        </h3>
        <div
          className="mt-1 text-gray-500 truncate mb-2"
          dangerouslySetInnerHTML={{
            __html: item.description?.split("<br>")?.join(""),
          }}
        ></div>
      </div>
    </div>
  );
};

const PublicationsList = () => {
  const dispatch: Dispatch<any> = useDispatch();
  const { publications, publicationsCount }: any = useSelector(
    (state: STATE) => state.PUBLICATION
  );
  const spinner: LOADER = useSelector((state: STATE) => state.LOADER.LOADER);

  const [publicationModal, setPublicationModal] = useState(false);
  const [currPublication, setCurrPublication] = useState<publicationFetched>({
    topic: "",
    description: "",
    documentUrl: "",
    imageUrl: "",
    _id: "",
    displayOnHomePage: false,
    isDeleted: false,
    createdAt: "",
    updatedAt: "",
  });
  const navigate = useNavigate();
  const handleModelUpdate = async () => {
    setPublicationModal(!publicationModal);
    dispatch(getPublications(page.skip, page.limit));
  };
  const skip = getQueryParams("skip") ?? "0";
  const [maxSkip, setMaxSkip] = useState(0);

  const handleModel = (item: publicationFetched) => {
    setCurrPublication(item);
    setPublicationModal(!publicationModal);
  };

  const [page, setPage] = useState({
    skip: skip ? skip : "0",
    limit: LIMIT,
  });

  useEffect(() => {
    setMaxSkip(Math.ceil(publicationsCount / parseInt(LIMIT)));
  }, [page, publicationsCount]);

  useEffect(() => {
    dispatch(getPublications(page.skip, page.limit));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  const fetchData = (val: number) => {
    let newSkip = parseInt(page.skip) + val;
    if (newSkip >= 0) {
      setPage({
        skip: newSkip + "",
        limit: page.limit,
      });
      navigate(`/publications?skip=${newSkip}`);
      window.scrollTo(0, 0);
    }
  };

  return (
    <div className="flex flex-col w-full items-center justify-center">
      {spinner.SPINNER ? (
        <ScreenSpinner />
      ) : (
        <div className="w-full flex flex-row flex-wrap gap-5 p-4 items-center justify-center">
          {publications.map((item: publicationFetched, id: number) => {
            return <Card item={item} key={id} handleModel={handleModel} />;
          })}
        </div>
      )}
      {publicationModal && (
        <PublicationsEditModel
          currPublication={currPublication}
          handleModelUpdate={handleModelUpdate}
        />
      )}
      <div className="flex justify-center mb-5 py-5">
        <div className="flex mb-5 py-5">
          <button
            disabled={page.skip === "0"}
            onClick={() => {
              fetchData(-1);
            }}
            className="inline-flex items-center py-2 px-4 text-sm font-medium text-GTI-BLUE-default disabled:text-gray-500 bg-white  rounded-lg border border-gray-300 hover:bg-gray-100 hover:text-gray-700 "
          >
            <svg
              aria-hidden="true"
              className="mr-2 w-5 h-5"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M7.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l2.293 2.293a1 1 0 010 1.414z"
                clipRule="evenodd"
              ></path>
            </svg>
            Previous
          </button>
          {parseInt(page.skip) + 1 <= maxSkip && (
            <button
              onClick={() => {
                fetchData(0);
              }}
              className="inline-flex items-center mx-2 py-2 px-4 text-sm font-medium text-gray-500 bg-white rounded-lg border border-gray-300 hover:bg-gray-100 hover:text-gray-700 "
            >
              {parseInt(page.skip) + 1}
            </button>
          )}
          {parseInt(page.skip) + 2 <= maxSkip && (
            <button
              onClick={() => {
                fetchData(1);
              }}
              className="inline-flex items-center mx-2 py-2 px-4 text-sm font-medium text-gray-500 bg-white rounded-lg border border-gray-300 hover:bg-gray-100 hover:text-gray-700 "
            >
              {parseInt(page.skip) + 2}
            </button>
          )}
          {parseInt(page.skip) + 3 <= maxSkip && (
            <button
              onClick={() => {
                fetchData(2);
              }}
              className="inline-flex items-center mx-2 py-2 px-4 text-sm font-medium text-gray-500 bg-white rounded-lg border border-gray-300 hover:bg-gray-100 hover:text-gray-700 "
            >
              {parseInt(page.skip) + 3}
            </button>
          )}
          {parseInt(page.skip) + 4 <= maxSkip && (
            <button
              disabled
              className="inline-flex items-center mx-2 py-2 px-4 text-sm font-medium text-gray-500 bg-white rounded-lg border border-gray-300 hover:bg-gray-100 hover:text-gray-700 "
            >
              -
            </button>
          )}
          {parseInt(page.skip) + 6 <= maxSkip && (
            <button
              onClick={() => {
                fetchData(maxSkip - parseInt(page.skip) - 3);
              }}
              className="inline-flex items-center mx-2 py-2 px-4 text-sm font-medium text-gray-500 bg-white rounded-lg border border-gray-300 hover:bg-gray-100 hover:text-gray-700 "
            >
              {maxSkip - 2}
            </button>
          )}
          {parseInt(page.skip) + 5 <= maxSkip && (
            <button
              onClick={() => {
                fetchData(maxSkip - parseInt(page.skip) - 2);
              }}
              className="inline-flex items-center mx-2 py-2 px-4 text-sm font-medium text-gray-500 bg-white rounded-lg border border-gray-300 hover:bg-gray-100 hover:text-gray-700 "
            >
              {maxSkip - 1}
            </button>
          )}
          {parseInt(page.skip) + 4 <= maxSkip && (
            <button
              onClick={() => {
                fetchData(maxSkip - parseInt(page.skip) - 1);
              }}
              className="inline-flex items-center mx-2 py-2 px-4 text-sm font-medium text-gray-500 bg-white rounded-lg border border-gray-300 hover:bg-gray-100 hover:text-gray-700 "
            >
              {maxSkip}
            </button>
          )}
          <button
            disabled={
              parseInt(page.limit) * (parseInt(page.skip) + 1) >=
              publicationsCount
            }
            onClick={() => {
              fetchData(1);
            }}
            className="inline-flex items-center py-2 px-4 text-sm font-medium text-GTI-BLUE-default disabled:text-gray-500 bg-white rounded-lg border border-gray-300 hover:bg-gray-100 hover:text-gray-700 "
          >
            Next
            <svg
              aria-hidden="true"
              className="ml-2 w-5 h-5"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z"
                clipRule="evenodd"
              ></path>
            </svg>
          </button>
        </div>
      </div>
    </div>
  );
};

export default PublicationsList;
