import React, { Dispatch, useEffect, useState, useRef } from "react";
import ReactDOM from "react-dom";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import productbanner from "../../assests/images/product_alt.png";
import {
  APPROVED,
  CONTENT_TYPE,
  CONTENT_TYPE_DOC,
  developmentStage,
  FILE_PATH,
  FILE_TYPE,
  iprStatus,
  NONE,
  PENDING,
  presignedData,
  productItemFullFetched,
  productItemPartialFetched,
  productUpdateItem,
  REJECTED,
  sectorItem,
  subsectorItem,
  YOUR_PRODUCT,
} from "../constants";
import { useNavigate } from "react-router-dom";
import {
  getYourProducts,
  updateProduct,
} from "../../store/actioncreators/productactions";
import { MdOutlineEdit } from "react-icons/md";
import axios from "axios";
import {
  failToast,
  successToast,
} from "../../store/actioncreators/toastactions";
import { Formik, Form } from "formik";
import { productSchema } from "../validations/productValidations";
import { ScreenSpinner } from "../utils/loader";
import RenderHTML from "../utils/RenderHTML";
import { Editor } from "@tinymce/tinymce-react";
import { notify } from "../../utils";
import { FiTrash2 } from "react-icons/fi";

interface MyFormValues {
  name: string;
  description: string;
  sectorId: string;
  subSectorId: string;
  developmentStage: string;
  iprStatus: string[];
}

type files = {
  image: Boolean;
  document: Boolean;
  imageFile: File | null;
  video: any;
  documentFiles: FileList | null;
};

type productModal = {
  name: string;
  description: string;
  document: FileList | null;
  image: File | null;
  sectorId: string;
  subSectorId: string;
};

const ProductModal = ({
  currentProduct,
  handleModal,
}: {
  currentProduct: productItemPartialFetched;
  handleModal: () => void;
}) => {
  const sectorlist: SECTOR = useSelector((state: STATE) => state.SECTOR.SECTOR);
  const subsectorlist: SUB_SECTOR = useSelector(
    (state: STATE) => state.SUB_SECTOR.SUB_SECTOR
  );
  const docInputRef = useRef<HTMLInputElement>(null);
  const vidInputRef = useRef<HTMLInputElement>(null);

  const initialValues: MyFormValues = {
    name: currentProduct.name,
    description: currentProduct.description,
    sectorId: currentProduct.sectorId,
    subSectorId: currentProduct.subSectorId,
    developmentStage: currentProduct.developmentStage,
    iprStatus: currentProduct.iprStatus,
  };

  const dispatch: Dispatch<any> = useDispatch();

  const [iprStatusCheckbox, setIprStatusCheckbox] = useState(
    getInitialValueForIprCheckbox()
  );

  function handleIprStatus(id: Number) {
    const updatedOptions = iprStatusCheckbox.map((option) => {
      if (option.id === id) {
        return { ...option, checked: !option.checked };
      }
      return option;
    });
    setIprStatusCheckbox(updatedOptions);
  }

  function getIprStatus() {
    const iprStatus = [];

    for (let i = 0; i < iprStatusCheckbox.length; i++) {
      if (iprStatusCheckbox[i].checked) {
        iprStatus.push(iprStatusCheckbox[i].value);
      }
    }

    return iprStatus;
  }

  function getInitialValueForIprCheckbox() {
    const updatedOptions = iprStatus.map((option) => {
      if (
        initialValues.iprStatus.findIndex(
          (status) => status === option.value
        ) >= 0
      ) {
        return { ...option, checked: !option.checked };
      }
      return option;
    });

    return updatedOptions;
  }

  const [files, setFiles] = useState<files>({
    image: false,
    document: false,
    imageFile: null,
    documentFiles: null,
    video: null,
  });

  const handleImage = function (e: React.ChangeEvent<HTMLInputElement>) {
    const fileList = e.target.files;

    if (!fileList) return;

    setFiles({ ...files, imageFile: fileList[0], image: false });
    // files.imageFile = fileList;
  };

  const handleDocuments = function (e: React.ChangeEvent<HTMLInputElement>) {
    const fileList = e.target.files;

    if (!fileList) return;
    // initialValues.document = fileList
    setFiles({ ...files, documentFiles: fileList, document: false });
  };

  const getPresigned = async (content: presignedData) => {
    const data = JSON.stringify(content);
    let result: string = "";
    const config = {
      method: "post",
      url: `${process.env.REACT_APP_BASE_API}/users/getPresignedUrl`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    await axios(config)
      .then(function (response) {
        // console.log("url fetched", response);
        result = response.data;
      })
      .catch(function (error) {
        // console.log("Error", error);
        result = "error";
        dispatch(failToast());
      });

    return result;
  };

  const postLogo = async (signed: string) => {
    var config = {
      method: "put",
      url: signed,
      headers: {
        "Content-Type": CONTENT_TYPE,
        "Access-Control-Allow-Origin": true,
      },
      data: files.imageFile,
    };

    await axios(config)
      .then(function (response) {
        // console.log("logo uploaded");
        dispatch(successToast());
      })
      .catch(function (error) {
        // console.log("error uploading logo");
      });
  };

  const postDocument = async (signed: string, file: File) => {
    var config = {
      method: "put",
      url: signed,
      headers: {
        "Content-Type": CONTENT_TYPE_DOC,
        "Access-Control-Allow-Origin": true,
      },
      data: file,
    };

    await axios(config)
      .then(function (response) {
        // console.log("document uploaded");
        dispatch(successToast());
      })
      .catch(function (error) {
        // console.log("error uploading document");
      });
  };
  const handleVideo = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e?.target.files ? e.target.files[0] : null;
    if (!file) return;
    const fileSizeInMB = file.size / (1024 * 1024); // Convert file size to MB
    if (fileSizeInMB <= 50) {
      setFiles((prev) => ({ ...prev, video: file }));
    } else {
      alert("Please select a file smaller than 50 MB.");
    }
  };
  const uploadVideo = async (selectedFile: any, presignedUrl: string) => {
    if (!selectedFile) {
      return;
    }

    try {
      const response = await axios.put(presignedUrl, selectedFile, {
        headers: {
          "Content-Type": selectedFile.type,
          "Access-Control-Allow-Origin": true,
        },
      });

      // Perform any necessary actions upon successful upload
    } catch (error) {
      console.error("Error occurred during file upload:", error);
      notify("Failed to upload video", "error");
      // Handle any errors that occurred during the upload
    }
  };

  const handleCreate = async (values: MyFormValues) => {
    let signedLogoURL: string = "";
    let signedDocumentURLWhole: string = "";
    let videoUrl: string = "";

    // if (!productModal.image?.size && !productModal.document) return;

    if (files.imageFile) {
      const signedLogoData: presignedData = {
        fileName: files.imageFile.name || values.name,
        filePath: FILE_PATH.PRODUCTS_IMAGE,
        fileType: FILE_TYPE.PNG,
      };
      signedLogoURL = await getPresigned(signedLogoData);
      await postLogo(signedLogoURL);
    }

    const videoData: presignedData = {
      fileName: files?.video?.name ?? values.name,
      filePath: FILE_PATH.PRODUCTS_VIDEO,
      fileType: files?.video?.type ?? "",
    };
    if (files?.video) {
      videoUrl = await getPresigned(videoData);
      await uploadVideo(files.video, videoUrl);
    }

    if (files.documentFiles) {
      Array.from(files.documentFiles).forEach(async (document, i) => {
        let signedDocumentData: presignedData = {
          fileName: document.name || values.name,
          filePath: FILE_PATH.COMPANY_DOCS,
          fileType: FILE_TYPE.PDF,
        };
        let tempurl = (await getPresigned(signedDocumentData)) + " ";
        signedDocumentURLWhole = tempurl.split("?")[0] + " ";
        postDocument(tempurl, document);
        // signedDocumentURL.push(tempurl);
      });
    }
    // setTimeout(() => {
    const data: productUpdateItem = {
      productId: currentProduct._id,
      name: values.name,
      description: values.description,
      document: files.documentFiles
        ? signedDocumentURLWhole
        : currentProduct.document,
      image: files.imageFile
        ? signedLogoURL.split("?")[0]
        : currentProduct.image,
      sectorId: values.sectorId,
      subSectorId: values.subSectorId,
      developmentStage: values.developmentStage,
      iprStatus: getIprStatus(),
    };
    if (videoUrl) {
      data.video = videoUrl.split("?")[0];
    }
    dispatch(updateProduct(data));
    handleModal();
    // }, 2000);
  };

  const content = (
    <div className="z-10 pb-[200px] pt-4 fixed w-full h-screen bg-slate-700 bg-opacity-70 top-0 left-0 flex justify-center overflow-auto">
      <div className="product-modal-main relative">
        <div className="flex">
          <h4 className="text-lg font-roboto">Edit Product</h4>
          <button
            onClick={() => {
              handleModal();
            }}
            className="absolute right-0 top-0 font-bold hover:text-red-500 duration-300 border border-slate-100 px-3 py-1 rounded"
          >
            X
          </button>
        </div>
        <Formik
          initialValues={initialValues}
          validationSchema={productSchema}
          onSubmit={(values) => handleCreate(values)}
        >
          {({ handleChange, setFieldValue, handleSubmit, errors, values }) => {
            return (
              <>
                <Form className="flex flex-col w-full space-y-4 justify-center items-center">
                  <div className="flex flex-col w-full space-x-2 relative">
                    <div className="relative">
                      <input
                        defaultValue={currentProduct.name}
                        onChange={(e) => setFieldValue("name", e.target.value)}
                        type="text"
                        id="floating_outlined"
                        className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                        placeholder=" "
                      />
                      <label
                        htmlFor="floating_outlined"
                        className="absolute text-sm text-gray-500  duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white  px-2 peer-focus:px-2 peer-focus:text-blue-600  peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1"
                      >
                        Product Name
                      </label>
                    </div>
                    {errors.name && (
                      <p
                        id="filled_error_help"
                        className="mt-2 text-xs text-red-600 dark:text-red-400"
                      >
                        {errors.name}
                      </p>
                    )}
                  </div>

                  <div className="flex flex-col w-full space-x-2 relative">
                    <div className="relative">
                      <Editor
                        apiKey={process.env.REACT_APP_TINYMCE_API_KEY}
                        initialValue={currentProduct.description}
                        init={{
                          height: 200,
                          menubar: false,
                          plugins: [
                            "advlist autolink lists link image charmap print preview anchor",
                            "searchreplace visualblocks code fullscreen",
                            "insertdatetime media table paste code help wordcount",
                          ],
                          toolbar:
                            "undo redo | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | help",
                          placeholder:
                            "Write your products description here...",
                        }}
                        onEditorChange={(e) => setFieldValue("description", e)}
                      />
                    </div>
                    {errors.description && (
                      <p
                        id="filled_error_help"
                        className="mt-2 text-xs text-red-600 dark:text-red-400"
                      >
                        {errors.description}
                      </p>
                    )}
                  </div>
                  <div className="flex flex-col w-full">
                    <div className="flex flex-row w-full space-x-5 items-center">
                      <h3 className="font-robot text-gray-800 text-sm whitespace-nowrap  ">
                        Sector Type:
                      </h3>
                      <select
                        onChange={(e) =>
                          setFieldValue("sectorId", e.target.value)
                        }
                        defaultValue={currentProduct.sectorId}
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
                      >
                        {sectorlist.SECTOR_LIST.map((item: sectorItem, id) => {
                          return <option value={item._id}>{item.name}</option>;
                        })}
                      </select>
                    </div>
                    {errors.sectorId && (
                      <p
                        id="filled_error_help"
                        className="mt-2 text-xs text-red-600 dark:text-red-400"
                      >
                        {errors.sectorId}
                      </p>
                    )}
                  </div>
                  <div className="flex flex-col w-full">
                    <div className="flex flex-row w-full space-x-5 items-center">
                      <h3 className="font-robot text-gray-800 text-sm whitespace-nowrap  ">
                        Sub Sector Type:
                      </h3>
                      <select
                        onChange={(e) =>
                          setFieldValue("subsecId", e.target.value)
                        }
                        defaultValue={currentProduct.subSectorId}
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
                      >
                        {subsectorlist.SUB_SECTOR_LIST.map(
                          (item: subsectorItem, id) => {
                            return (
                              <option value={item._id}>{item.name}</option>
                            );
                          }
                        )}
                      </select>
                    </div>
                    {errors.subSectorId && (
                      <p
                        id="filled_error_help"
                        className="mt-2 text-xs text-red-600 dark:text-red-400"
                      >
                        {errors.subSectorId}
                      </p>
                    )}
                  </div>
                  <div className="flex flex-col w-full">
                    <div className="flex flex-row w-full space-x-5 items-center">
                      <h3 className="font-robot text-gray-800 text-sm whitespace-nowrap  ">
                        Development Stage:
                      </h3>
                      <select
                        onChange={(e) =>
                          setFieldValue("developmentStage", e.target.value)
                        }
                        defaultValue={values.developmentStage}
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
                      >
                        {developmentStage.map((stage, id) => {
                          return (
                            <option value={stage.value}>{stage.value}</option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                  <div className="flex flex-col w-full space-x-2 relative">
                    <div className="relative">
                      <div className="relative mb-3">
                        <label className="profile-content-head-2">
                          IPR Status
                        </label>
                      </div>
                      <div className="grid grid-cols-1 gap-4 md:grid-cols-3">
                        {iprStatusCheckbox.map((option) => (
                          <label
                            className="flex items-center space-x-2 ml-4"
                            key={option.id}
                          >
                            <input
                              type="checkbox"
                              className="h-4 w-4 text-indigo-600 rounded border-gray-300 focus:ring-indigo-500"
                              checked={option.checked}
                              onChange={() => handleIprStatus(option.id)}
                            />
                            <span className="text-gray-700 personal-input">
                              {option.value}
                            </span>
                          </label>
                        ))}
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col w-full">
                    <label
                      className="block mb-2 text-sm font-medium text-gray-900"
                      htmlFor="logo"
                    >
                      Upload Product Image
                      <span className="text-red-500 text-xs">(.png only)</span>
                    </label>
                    <input
                      onChange={handleImage}
                      accept=".png"
                      type="file"
                      id="logo"
                      aria-label="company-logo"
                      className="modal-input"
                      placeholder="Click to upload Company's Logo"
                    />
                    <p
                      id="filled_error_help"
                      className={
                        "mt-2 text-xs text-red-600 dark:text-red-400 " +
                        (!files.image ? "hidden" : "")
                      }
                    >
                      {"Please upload product Image"}
                    </p>
                  </div>

                  <div className="flex flex-col w-full relative">
                    <label
                      className="block mb-2 text-sm font-medium text-gray-900"
                      htmlFor="documents"
                    >
                      Upload Product Documents <i>(Optional)</i>{" "}
                      <span className="text-red-500 text-xs">(.pdf only)</span>
                    </label>
                    <input
                      onChange={handleDocuments}
                      accept=".pdf"
                      type="file"
                      id="documents"
                      multiple
                      ref={docInputRef}
                      aria-label="company-documents"
                      className="modal-input"
                      placeholder="Click to upload Document"
                    />
                    <button
                      className="inline-flex items-center absolute border-2 p-2 rounded-md bg-white right-4 top-10"
                      onClick={() => {
                        setFiles((prev) => ({
                          ...prev,
                          documentFiles: null,
                          document: false,
                        }));
                        if (docInputRef.current) {
                          docInputRef.current.files = null;
                          docInputRef.current.value = "";
                        }
                      }}
                      type="button"
                    >
                      <FiTrash2 className="w-5 h-5" />
                    </button>
                  </div>
                  <div className="flex flex-col w-full relative">
                    <label
                      className="block mb-2 text-sm font-medium text-gray-900"
                      htmlFor="video"
                    >
                      Upload Product Video <i>(Optional)</i>{" "}
                      <span className="text-red-500 text-xs">
                        (.mp4 /.webm only)
                      </span>
                    </label>
                    <input
                      onChange={handleVideo}
                      type="file"
                      accept=".mp4, .webm"
                      id="video"
                      ref={vidInputRef}
                      aria-label="product-video"
                      className="modal-input"
                      placeholder="Click to upload Video "
                    />
                    <button
                      className="inline-flex items-center absolute border-2 p-2 rounded-md bg-white right-4 top-10"
                      onClick={() => {
                        setFiles((prev) => ({
                          ...prev,
                          video: null,
                        }));
                        if (vidInputRef.current) {
                          vidInputRef.current.files = null;
                          vidInputRef.current.value = "";
                        }
                      }}
                      type="button"
                    >
                      <FiTrash2 className="w-5 h-5" />
                    </button>
                  </div>
                  <button
                    type="submit"
                    onClick={() => handleSubmit}
                    className="button active"
                  >
                    Update
                  </button>
                </Form>
              </>
            );
          }}
        </Formik>
      </div>
    </div>
  );
  return ReactDOM.createPortal(content, document.body);
};

const Card = ({
  item,
  handleSelect,
}: {
  item: productItemPartialFetched;
  handleSelect: (data: productItemPartialFetched) => void;
}) => {
  const dispatch: Dispatch<any> = useDispatch();
  const navigate = useNavigate();

  const handleView = () => {
    navigate(YOUR_PRODUCT, { state: { product: item } });
  };

  const DOP = new Date(item.createdAt);
  // console.log(item.image);

  const handleEdit = (e: any) => {
    e.stopPropagation();
    handleSelect(item);
  };
  const handleDelete = async () => {};
  return (
    <div
      className="product-card-main"
      onClick={() => {
        handleView();
      }}
    >
      <div className="product-card-img">
        <img
          src={item.image === NONE ? productbanner : item.image}
          className="flex  w-full lg:h-56  rounded-t-lg hover:rounded-lg object-contain"
          alt={item.name}
          loading="lazy"
        />
      </div>
      <div className="product-card-title">
        <h4 className="font-roboto text-xs text-slate-500 ">
          {"Posted on:"}
          {DOP.toLocaleString("default", {
            month: "short",
            day: "2-digit",
            year: "numeric",
          })}
        </h4>
      </div>
      <div className="product-card-title">
        <h4 className="font-roboto text-lg text-GTI-BLUE-default ">
          {item.name.length > 25
            ? item.name.substring(0, 25) + "..."
            : item.name}
        </h4>
      </div>
      <div className="product-card-title overflow-hidden">
        <h4 className="font-roboto  text-xs text-slate-500 ">
          {item.description.length > 150 ? (
            <RenderHTML html={item.description.substring(0, 150) + "..."} />
          ) : (
            <RenderHTML html={item.description.substring(0, 150)} />
          )}
        </h4>
      </div>
      <div className="flex flex-row justify-evenly mt-auto">
        <button
          onClick={handleEdit}
          className={`inline-flex mr-2 items-center gap-2 p-1 px-2 font-medium text-center rounded-lg border-2 text-black`}
        >
          <MdOutlineEdit className="w-6 h-6 " /> {" Edit"}
        </button>
      </div>
    </div>
  );
};

const YourProductList = ({
  type,
  skip,
  limit,
  secId,
  subsecId,
}: {
  type: number;
  skip: string;
  limit: string;
  subsecId: string;
  secId: string;
}) => {
  const dispatch: Dispatch<any> = useDispatch();
  const products: PRODUCTS = useSelector(
    (state: STATE) => state.PRODUCTS.PRODUCTS
  );
  const user: USER = useSelector((state: STATE) => state.USER.USER);
  const spinner: LOADER = useSelector((state: STATE) => state.LOADER.LOADER);
  const navigate = useNavigate();
  useEffect(() => {
    switch (type) {
      case 0:
        dispatch(getYourProducts(skip, limit, APPROVED));
        break;
      case 1:
        dispatch(getYourProducts(skip, limit, PENDING));
        break;
      default:
        dispatch(getYourProducts(skip, limit, REJECTED));
    }
  }, [secId, subsecId, skip, type]);

  const [productEditModal, setModal] = useState(false);

  const [currentProduct, setProduct] = useState<productItemPartialFetched>({
    _id: "",
    name: "",
    description: "",
    image: "",
    displayOnHomePage: false,
    isApprovedBySubAdmin: false,
    isApprovedByAdmin: false,
    isRejected: false,
    document: "",
    sectorId: "",
    subSectorId: "",
    userId: "",
    createdAt: "",
    developmentStage: "",
    iprStatus: [],
    __v: -1,
    sectors: {
      _id: "",
      name: "",
      slug: "",
      image: "",
      createdAt: "",
      __v: -1,
    },
    subsectors: {
      _id: "",
      name: "",
      slug: "",
      sectorId: "",
      createdAt: "",
      __v: -1,
    },
  });

  const handleModel = () => {
    setModal(!productEditModal);
  };

  const handleSelect = (item: productItemPartialFetched) => {
    if (item.__v !== -1) {
      setProduct(item);
      handleModel();
    }
  };

  return (
    <div className="flex w-full  items-center px-10 flex-col h-full">
      {spinner.SPINNER ? (
        <ScreenSpinner />
      ) : products.YOUR_PRODUCT_LIST?.products &&
        products.YOUR_PRODUCT_LIST?.products.length > 0 ? (
        <div className="product-list-main">
          {products.YOUR_PRODUCT_LIST?.products.map(
            (item: productItemFullFetched, id: number) => {
              return <Card item={item} key={id} handleSelect={handleSelect} />;
            }
          )}
        </div>
      ) : (
        <p className="text-gray-400">No data to display</p>
      )}

      {productEditModal && (
        <ProductModal
          currentProduct={currentProduct}
          handleModal={handleModel}
        />
      )}
    </div>
  );
};
export default YourProductList;
