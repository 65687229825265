import axios from "axios";
import { TECHNOLOGY_PARTNER_GET } from "../actiontypes/actionTypes";
import { RequestMethods } from "../../shared/RequestMethods";
import { spinnerLoaderStop } from "./loaderactions";

export const getTechnologyPartners =
  (skip: string, limit: string) => (dispatch: any) => {
    let config = {
      method: RequestMethods.GET,
      url: `${process.env.REACT_APP_BASE_API}/technology-partners?skip=${skip}&limit=${limit}`,
      headers: {
        "Content-Type": "application/json",
      },
    };

    axios(config)
      .then(function (response) {
        const action: TECHNOLOGY_PARTNER_ACTION = {
          type: TECHNOLOGY_PARTNER_GET,
          technologyPartner: response.data,
        };
        dispatch(action);
        dispatch(spinnerLoaderStop());
      })
      .catch(function (error) {
        dispatch(spinnerLoaderStop());
      });
  };
