import axios from "axios";
import { store } from "../../store";
import { NEWS_GET, NEWS_GET_ID } from "../actiontypes/actionTypes";
import { spinnerLoaderStop } from "./loaderactions";

export const getNews = (skip: string, limit: string) => (dispatch: any) => {
  var config = {
    method: "get",
    url: `${process.env.REACT_APP_BASE_API}/news?skip=${skip}&limit=${limit}`,
    headers: {
      "Content-Type": "application/json",
    },
  };

  axios(config)
    .then(function (response) {
      const list: any = {
        ...response.data,
      };
      const action: NEWS_ACTION = {
        type: NEWS_GET,
        NEWS: list,
      };
      // console.log("News fetched ", response);
      dispatch(action);
      dispatch(spinnerLoaderStop());
    })
    .catch(function (error) {
      // console.log("News not fetched ", error);
      dispatch(spinnerLoaderStop());
    });
};

export const getNewsId = (id: string) => (dispatch: any) => {
  var config = {
    method: "get",
    url: `${process.env.REACT_APP_BASE_API}/news/${id}`,
    headers: {
      Authorization: `Bearer ${store.getState().USER.USER.token}`,
      "Content-Type": "application/json",
    },
  };

  axios(config)
    .then(function (response) {
      const list: any = {
        ...response.data,
      };
      const action: any = {
        type: NEWS_GET_ID,
        BLOG: list,
      };
      // console.log("NEWS_GET_ID fetched ");
      return dispatch(action);
    })
    .catch(function (error) {
      // console.log("NEWS_GET_ID not fetched ", error);
    });
};
