import React, { Dispatch, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { BiLinkExternal } from "react-icons/bi";
import { useSelector } from "react-redux";
import ReactCountryFlag from "react-country-flag";
import Slider from "react-slick";
import { Helmet } from "react-helmet";

import { NONE } from "../constants";
import productbanner from "../../assests/banners/product_banner_alt.png";
import companyLogo from "../../assests/banners/company_logo.png";
import {
  followUser,
  unfollowUser,
} from "../../store/actioncreators/followactions";
import { sendConnection } from "../../store/actioncreators/connectionactions";
import { ScreenSpinner } from "../utils/loader";
import { spinnerLoaderStop } from "../../store/actioncreators/loaderactions";
import RenderHTML from "../utils/RenderHTML";
import PdfDownloader from "../../shared/PdfDownloader";

const Product = ({ handleLoginModal }: { handleLoginModal: () => void }) => {
  const dispatch: Dispatch<any> = useDispatch();
  const spinner: LOADER = useSelector((state: STATE) => state.LOADER.LOADER);
  let [product, setProduct] = useState({
    _id: "",
    name: "",
    description: "",
    image: "",
    images: [],
    video: "",
    displayOnHomePage: false,
    isApprovedBySubAdmin: false,
    isApprovedByAdmin: false,
    isRejected: false,
    document: "",
    sectorId: "",
    subSectorId: "",
    userId: "",
    createdAt: "",
    developmentStage: "",
    iprStatus: [],
    videos: [],
    documents: [],
    __v: -1,
    users: {
      _id: "",
      fullName: "",
      email: "",
      phoneNumber: "",
      countryCode: "",
      referenceCode: "",
      isEmailVerified: false,
      isUserVerified: false,
      isRejected: false,
      password: "",
      userRole: -1,
      userType: "",
      companyId: "",
      follower: [],
      following: [],
      connections: [
        {
          connectionStatus: "",
          userId: "",
        },
      ],
      createdAt: "",
      __v: -1,
    },
    company: {
      _id: "",
      name: "",
      logo: "",
      description: "",
      address: "",
      website: "",
      country: "",
      companyTurnover: -1,
      companyId: "",
      typeAndSizeOfPartnersRequired: [],
      typesOfPartnershipConsidered: [],
      createdAt: "",
      developmentStage: "",
      iprStatus: [],
      __v: -1,
    },
    sectors: {
      _id: "",
      name: "",
      slug: "",
      image: "",
      createdAt: "",
      __v: -1,
    },
    subsectors: {
      _id: "",
      name: "",
      slug: "",
      sectorId: "",
      createdAt: "",
      __v: -1,
    },
  });

  const sidebar_carousal_settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    swipeToSlide: true,
    autoplay: true,
  };

  const user: USER = useSelector((state: STATE) => state.USER.USER);
  const location = useLocation();
  const navigate = useNavigate();

  const [following, setFollowing] = useState(false);
  const [connected, setConnection] = useState("Connect");

  const addQuery = async (companyName: string, userType: string) => {
    const regex = /[!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]/g;

    const queryParams = new URLSearchParams(location.search);
    queryParams.set(
      "company",
      companyName.toLocaleLowerCase().replaceAll(regex, "").replaceAll(" ", "_")
    );
    queryParams.set("type", userType.toLocaleLowerCase());
    const newSearchString = queryParams.toString();
    navigate({
      pathname: location.pathname,
      search: newSearchString,
    });
  };

  const loadProduct = async (id: string) => {
    const extoken: string =
      localStorage.getItem("GTI_data")?.split(" ")[0] ?? "";
    let configSigned = {
      method: "get",
      url: `${process.env.REACT_APP_BASE_API}/products/loggedinusers/${id}`,
      headers: {
        Authorization: `Bearer ${extoken}`,
        "Content-Type": "application/json",
      },
    };
    let configUnsigned = {
      method: "get",
      url: `${process.env.REACT_APP_BASE_API}/products/${id}`,
      headers: {
        Authorization: `Bearer ${extoken}`,
        "Content-Type": "application/json",
      },
    };

    await axios(user?.admin !== -1 ? configSigned : configUnsigned)
      .then(function (response) {
        setProduct(response.data);
        dispatch(spinnerLoaderStop());
      })
      .catch(function (error) {
        dispatch(spinnerLoaderStop());
      });
  };

  const isFollowing = async (userid: string) => {
    const extoken: string =
      localStorage.getItem("GTI_data")?.split(" ")[0] ?? "";

    let config = {
      method: "get",
      url: `${process.env.REACT_APP_BASE_API}/users/checkisfollowing/${userid}`,
      headers: {
        Authorization: `Bearer ${extoken}`,
        "Content-Type": "application/json",
      },
    };

    await axios(config)
      .then(function (response) {
        setFollowing(response.data);
      })
      .catch(function (error) {});
  };

  const isConnected = async (userid: string) => {
    const extoken: string =
      localStorage.getItem("GTI_data")?.split(" ")[0] ?? "";

    let config = {
      method: "get",
      url: `${process.env.REACT_APP_BASE_API}/users/checkisconnected/${userid}`,
      headers: {
        Authorization: `Bearer ${extoken}`,
        "Content-Type": "application/json",
      },
    };

    await axios(config)
      .then(function (response) {
        if (typeof response.data == "string") {
          setConnection(response.data);
        }
      })
      .catch(function (error) {});
  };

  let { id } = useParams();
  let productId: string = id ? id : "";

  useEffect(() => {
    loadProduct(productId);
    isFollowing(product.userId);
    isConnected(product.userId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const DOC = new Date(product.createdAt);

  const handleFollow = () => {
    if (user?.admin !== -1) {
      if (!following) {
        dispatch(followUser(product.userId));
        setFollowing(true);
      }
      return;
    }
    handleLoginModal();
  };
  const handleUnfollow = () => {
    if (user?.admin !== -1) {
      if (following) {
        dispatch(unfollowUser(product.userId));
        setFollowing(false);
      }
      return;
    }
    handleLoginModal();
  };
  const handleConnect = () => {
    if (user?.admin !== -1) {
      if (connected === "Connect") {
        dispatch(sendConnection(product.userId));
        setConnection("Connection Requested");
      }
      return;
    }
    handleLoginModal();
  };

  useEffect(() => {
    console.log(product?.company?.name, product?.users?.userType);
    if (product?.company?.name)
      addQuery(product?.company?.name, product?.users?.userType);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product]);

  return (
    <React.Fragment>
      <Helmet>
        <title>{product?.company?.name}</title>
        <meta
          name="description"
          key="description"
          content={
            product?.description
              ? product?.description
              : product?.company?.description
          }
        />
        <meta name="title" key="title" content={product?.company?.name} />
        <meta property="og:title" content={product.company.name} />
        <meta
          property="og:description"
          content={
            product.description
              ? product.description
              : product.company.description
          }
        />
        <meta
          property="og:image"
          content={product.image === NONE ? productbanner : product.image}
        />
        <meta
          property="og:url"
          content={`${process.env.REACT_APP_BASE_URL}/product/${product._id}`}
        />
        <meta property="og:type" content="website" />
        <meta name="twitter:title" content={product?.company?.name} />
        <meta
          name="twitter:description"
          content={
            product?.description
              ? product?.description
              : product?.company?.description
          }
        />
        <meta
          name="twitter:image"
          content={product?.image === NONE ? productbanner : product?.image}
        />
        <meta name="twitter:card" content={product?.company?.name} />
      </Helmet>
      <div className="flex flex-col p-2 md:p-10 w-full h-full">
        {spinner.SPINNER ? (
          <ScreenSpinner />
        ) : (
          <>
            <div className="flex flex-col md:flex-row w-full justify-between md:space-x-10 ">
              <div className="w-full md:w-3/5 flex flex-col md:px-10 md:m-5 md:space-y-5">
                <p className="font-roboto text-xl md:text-3xl font-bold">
                  {product.name}
                </p>
                <p className="md:hidden text-gray-400 mb-2 md:mb-0 text-xxs font-roboto">
                  Posted on:&nbsp;
                  {DOC.toLocaleString("default", {
                    month: "long",
                    day: "2-digit",
                    year: "numeric",
                  })}
                </p>
                <div className="hidden md:flex flex-row mb-1 md:mb-0">
                  <p className="font-roboto text-xs md:text-sm font-bold">
                    Sector -
                  </p>
                  <p className="font-roboto text-xs md:text-sm px-2 text-gray-500">
                    {product.sectors.name}
                  </p>
                </div>
                <div className="hidden md:flex flex-row mb-1 md:mb-0">
                  <p className="font-roboto text-xs md:text-sm font-bold">
                    Sub Sector -
                  </p>
                  <p className="font-roboto text-xs md:text-sm px-2 text-gray-500">
                    {product.subsectors.name}
                  </p>
                </div>
                <div className="flex flex-row mb-5 md:mb-0">
                  <p className="font-roboto text-xs md:text-sm font-bold">
                    Development Stage -
                  </p>
                  <p className="font-roboto text-xs md:text-sm px-2 text-gray-500">
                    {product.developmentStage}
                  </p>
                </div>

                <p className="hidden md:block font-sans md:font-roboto text-xxs md:text-sm">
                  <RenderHTML html={product.description} />
                </p>
              </div>

              <div className="md:w-5/12 flex flex-col md:px-10 md:m-5 space-y-3 md:space-y-5">
                <Slider
                  {...sidebar_carousal_settings}
                  className="hidden md:flex md:flex-row w-full md:max-h-[330px] text-black border-2 p-2 border-blue-800"
                >
                  {product?.images?.length > 0 ? (
                    product.images.map((image) => (
                      <img
                        className="hidden md:block w-full h-full max-h-[200px] md:max-h-[300px] object-contain"
                        src={image}
                        alt={product.name}
                      />
                    ))
                  ) : (
                    <img
                      className="hidden md:block w-full h-full max-h-[200px] md:max-h-[300px] object-contain"
                      src={
                        product.image === NONE ? productbanner : product.image
                      }
                      alt={product.name}
                    />
                  )}
                  {product?.video ? (
                    <video
                      className="hidden md:block w-full h-full max-h-[200px] md:max-h-[300px] object-contain"
                      controls
                    >
                      <source src={product.video} type="video/mp4" />
                      Your browser does not support the video tag.
                    </video>
                  ) : null}
                  {product?.videos?.length
                    ? product?.videos.map((video) => (
                        <video controls>
                          <source src={video} type="video/mp4" />
                          Your browser does not support the video tag.
                        </video>
                      ))
                    : null}
                </Slider>

                <p className="md:hidden font-sans md:font-roboto text-xxs md:text-sm">
                  <RenderHTML html={product.description} />
                </p>

                <p className="hidden md:block text-gray-400 text-xs font-roboto">
                  Posted on:&nbsp;
                  {DOC.toLocaleString("default", {
                    month: "long",
                    day: "2-digit",
                    year: "numeric",
                  })}
                </p>

                {!!product?.document ? (
                  <div className="flex flex-row gap-4 items-center">
                    <PdfDownloader
                      className="flex flex-row items-center gap-2 font-roboto text-sm justify-center"
                      url={product?.document}
                    >
                      <p className="font-roboto text-sm ">Document</p>
                      <BiLinkExternal />
                    </PdfDownloader>
                  </div>
                ) : null}
                {product?.documents?.length ? (
                  <div className="flex flex-row gap-4 items-center">
                    {product.documents?.map((document) => (
                      <PdfDownloader
                        className="flex flex-row items-center gap-2 font-roboto text-sm justify-center"
                        url={document}
                      >
                        <p className="font-roboto text-sm ">Document</p>
                        <BiLinkExternal />
                      </PdfDownloader>
                    ))}
                  </div>
                ) : null}

                <div className="flex flex-col gap-2">
                  <p className="font-roboto text-sm whitespace-nowrap font-bold">
                    IPR Status
                  </p>
                  <div className="flex flex-wrap gap-2">
                    {product?.iprStatus?.map((status, index) => (
                      <button
                        key={index}
                        className="bg-gray-300 text-black py-2 px-2 rounded inline-flex text-xxs md:text-xs"
                      >
                        {status}
                      </button>
                    ))}
                  </div>
                </div>
              </div>
            </div>
            <br className="md:hidden" />
            <hr />

            <div className="flex flex-col md:flex-row w-full md:space-x-8 py-5 md:py-10">
              <span className="block md:hidden font-roboto text-sm md:text-lg text-black-500 font-bold text-justify pb-5 md:pb-0">
                {product.company?.name}
                <span className="font-roboto text-sm md:text-lg text-black-500">
                  ({product.company?.country})
                </span>
              </span>

              <div className="md:w-64 md:h-64 flex flex-col px-5 md:px-10 md:mx-5 space-y-5">
                <img
                  className="self-center w-1/2 md:w-full h-1/2 md:h-3/5 object-contain shadow-xl"
                  src={
                    !product.company?.logo ? companyLogo : product.company.logo
                  }
                  alt={product.company.name}
                />

                <span className="hidden md:block font-roboto text-sm text-black-500 font-bold">
                  {product.company?.name}
                  <br />
                  <span className="font-roboto text-sm text-black-500">
                    {product?.company?.country ? (
                      <ReactCountryFlag
                        countryCode={product.company?.country}
                        svg
                        style={{ width: "2em", height: "2em" }}
                        className="mr-2"
                      />
                    ) : null}
                  </span>
                </span>

                {product?.userId !== user?.id && (
                  <div className="flex flex-row self-center ">
                    <button
                      type="button"
                      className={
                        "button active whitespace-nowrap disabled:cursor-not-allowed disabled:hover:bg-GTI-BLUE-default disabled:bg-opacity-50 disabled:hover:bg-opacity-50"
                      }
                      disabled={connected !== "Connect"}
                      onClick={() => {
                        handleConnect();
                      }}
                    >
                      {connected}
                    </button>

                    <button
                      type="button"
                      className={"button active"}
                      onClick={() => {
                        !following ? handleFollow() : handleUnfollow();
                      }}
                    >
                      {following ? "Unfollow" : "Follow"}
                    </button>
                  </div>
                )}
              </div>
              <p className="font-sans text-xxs md:text-base text-gray-500">
                {product.company?.description}
              </p>
            </div>
          </>
        )}
      </div>
    </React.Fragment>
  );
};

export default Product;
