import React, { Dispatch, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import "./style.css";
import { useSelector } from "react-redux";
import eventbanner from "../../assests/banners/eventbanner.png";
import ReactPlayer from "react-player";
import { eventItemsFetched, LIMIT, NONE } from "../constants";
import { useNavigate } from "react-router-dom";
import { getEvents } from "../../store/actioncreators/eventactionss";
import eventsDefault from "../../assests/images/events-default.jpg";
import { getQueryParams } from "../../utils";

const Card = ({ item }: { item: eventItemsFetched }) => {
  const DOS = new Date(item.startDate);
  const DOE = new Date(item.endDate);
  const navigate = useNavigate();

  const handleView = () => {
    navigate(`/events/${item._id}`, { state: { id: item._id } });
  };

  return (
    <div
      className="w-full max-w-[350px] cursor-pointer overflow-hidden rounded-lg bg-white shadow h-full max-h-[420px]"
      onClick={handleView}
    >
      <img
        src={item?.imageUrl ? item?.imageUrl : eventsDefault}
        className="aspect-video w-full object-contain"
        alt={item?.topic}
      />
      {/* {!item.youtubeLink || item.youtubeLink === "none" ? (
        <img
          src={item?.imageUrl ? item?.imageUrl : eventsDefault}
          className="aspect-video w-full object-contain"
          alt={item?.topic}
        />
      ) : (
        <ReactPlayer
          url={item.imageUrl === NONE ? eventbanner : item.imageUrl}
          className="flex rounded w-full justify-center"
          height="200px"
          width="auto"
          alt="Youtube video"
          light
        />
      )} */}
      <div className="p-4">
        <h3
          className="text-lg font-medium text-gray-900 truncate"
          dangerouslySetInnerHTML={{
            __html: item.topic?.replace(/(<([^>]+)>)/gi, ""),
          }}
        ></h3>
        <p className="mb-1 text-sm text-primary-500">
          Starts At:&nbsp;
          <time>
            {DOS.toLocaleString("default", {
              month: "short",
              day: "2-digit",
              year: "numeric",
            })}
          </time>
        </p>
        <p className="text-sm text-primary-500 mb-3">
          Ends At:&nbsp;
          <time>
            {DOE.toString() !== "Invalid Date"
              ? DOE.toLocaleString("default", {
                  month: "short",
                  day: "2-digit",
                  year: "numeric",
                })
              : DOS.toLocaleString("default", {
                  month: "short",
                  day: "2-digit",
                  year: "numeric",
                })}
          </time>
        </p>
        <div className="w-full flex gap-2">
          <button className="linear bg-GTI-BLUE-default rounded-[20px] bg-brand-900 px-4 py-2 text-base font-medium text-white transition duration-200 hover:bg-brand-800 active:bg-brand-700">
            View Details
          </button>
        </div>
      </div>
    </div>
  );
};

const EventList = ({
  skip: parentSkip,
  limit: parentLimit,
  eventType,
}: {
  skip: string;
  limit: string;
  eventType: string;
}) => {
  const dispatch: Dispatch<any> = useDispatch();
  const events: EVENT = useSelector((state: STATE) => state.EVENT.EVENT);

  const navigate = useNavigate();
  const skip = getQueryParams("skip") ?? "0";
  const [page, setPage] = useState({
    skip: "0",
    limit: LIMIT,
  });
  const [maxSkip, setMaxSkip] = useState(0);

  useEffect(() => {
    setMaxSkip(Math.ceil(events.EVENT_LIST.eventsCount / 9));
  }, [page, events.EVENT_LIST.eventsCount]);

  const fetchData = (val: number) => {
    let newSkip = parseInt(page.skip) + val;
    if (newSkip >= 0) {
      navigate(`/events?skip=${newSkip}`);
      setPage({
        skip: newSkip.toString(),
        limit: page.limit,
      });
      dispatch(getEvents(skip, page.limit, eventType));
    }
  };

  useEffect(() => {
    dispatch(getEvents(skip, page.limit, eventType));
    setPage({
      skip: skip ? skip : "0",
      limit: page.limit,
    });
    window.scrollTo(0, 0);
  }, [skip, eventType]);

  return (
    <div className="flex w-full flex-col relative justify-center items-center cursor-pointer">
      <div className="w-full flex flex-wrap gap-20 p-10 h-full items-start justify-center">
        {events.EVENT_LIST.events.map((item: eventItemsFetched, id: number) => {
          return <Card item={item} key={id} />;
        })}
      </div>
      <div className="flex justify-center mb-5 py-5">
        <div className="flex mb-5 py-5">
          <button
            disabled={page.skip === "0"}
            onClick={() => {
              fetchData(-1);
            }}
            className="inline-flex items-center py-2 px-4 text-sm font-medium text-GTI-BLUE-default disabled:text-gray-500 bg-white  rounded-lg border border-gray-300 hover:bg-gray-100 hover:text-gray-700 "
          >
            <svg
              aria-hidden="true"
              className="mr-2 w-5 h-5"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M7.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l2.293 2.293a1 1 0 010 1.414z"
                clipRule="evenodd"
              ></path>
            </svg>
            Previous
          </button>
          {parseInt(page.skip) + 1 <= maxSkip && (
            <button
              onClick={() => {
                fetchData(0);
              }}
              className="inline-flex items-center mx-2 py-2 px-4 text-sm font-medium text-gray-500 bg-white rounded-lg border border-gray-300 hover:bg-gray-100 hover:text-gray-700 "
            >
              {parseInt(page.skip) + 1}
            </button>
          )}
          {parseInt(page.skip) + 2 <= maxSkip && (
            <button
              onClick={() => {
                fetchData(1);
              }}
              className="inline-flex items-center mx-2 py-2 px-4 text-sm font-medium text-gray-500 bg-white rounded-lg border border-gray-300 hover:bg-gray-100 hover:text-gray-700 "
            >
              {parseInt(page.skip) + 2}
            </button>
          )}
          {parseInt(page.skip) + 3 <= maxSkip && (
            <button
              onClick={() => {
                fetchData(2);
              }}
              className="inline-flex items-center mx-2 py-2 px-4 text-sm font-medium text-gray-500 bg-white rounded-lg border border-gray-300 hover:bg-gray-100 hover:text-gray-700 "
            >
              {parseInt(page.skip) + 3}
            </button>
          )}
          {parseInt(page.skip) + 4 <= maxSkip && (
            <button
              disabled
              className="inline-flex items-center mx-2 py-2 px-4 text-sm font-medium text-gray-500 bg-white rounded-lg border border-gray-300 hover:bg-gray-100 hover:text-gray-700 "
            >
              -
            </button>
          )}
          {parseInt(page.skip) + 6 <= maxSkip && (
            <button
              onClick={() => {
                fetchData(maxSkip - parseInt(page.skip) - 3);
              }}
              className="inline-flex items-center mx-2 py-2 px-4 text-sm font-medium text-gray-500 bg-white rounded-lg border border-gray-300 hover:bg-gray-100 hover:text-gray-700 "
            >
              {maxSkip - 2}
            </button>
          )}
          {parseInt(page.skip) + 5 <= maxSkip && (
            <button
              onClick={() => {
                fetchData(maxSkip - parseInt(page.skip) - 2);
              }}
              className="inline-flex items-center mx-2 py-2 px-4 text-sm font-medium text-gray-500 bg-white rounded-lg border border-gray-300 hover:bg-gray-100 hover:text-gray-700 "
            >
              {maxSkip - 1}
            </button>
          )}
          {parseInt(page.skip) + 4 <= maxSkip && (
            <button
              onClick={() => {
                fetchData(maxSkip - parseInt(page.skip) - 1);
              }}
              className="inline-flex items-center mx-2 py-2 px-4 text-sm font-medium text-gray-500 bg-white rounded-lg border border-gray-300 hover:bg-gray-100 hover:text-gray-700 "
            >
              {maxSkip}
            </button>
          )}
          <button
            disabled={
              (parseInt(page.skip) + 1) * parseInt(page.limit) >=
              events.EVENT_LIST.eventsCount
            }
            onClick={() => {
              fetchData(1);
            }}
            className="inline-flex items-center py-2 px-4 text-sm font-medium text-GTI-BLUE-default disabled:text-gray-500 bg-white rounded-lg border border-gray-300 hover:bg-gray-100 hover:text-gray-700 "
          >
            Next
            <svg
              aria-hidden="true"
              className="ml-2 w-5 h-5"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z"
                clipRule="evenodd"
              ></path>
            </svg>
          </button>
        </div>
      </div>
    </div>
  );
};

export default EventList;
