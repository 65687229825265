import React from "react";
import { useState } from "react";
import { BsFillPersonFill } from "react-icons/bs";
import { TiContacts } from "react-icons/ti";
import { MdEmail } from "react-icons/md";
import { CgPhone } from "react-icons/cg";
import ReactFlagsSelect from "react-flags-select";
import ReactCountryFlag from "react-country-flag";
import { useSelector } from "react-redux";
import axios from "axios";

const Personal = () => {
  const currentUser: USER = useSelector((state: STATE) => state.USER.USER);
  let [emp_name, setEmpName] = useState(currentUser.user.name);
  let [org_contact, setOrgContact] = useState("Contact Name");
  let [emp_email, setEmpEmail] = useState(currentUser.user.email);
  let [emp_phone, setEmpPhone] = useState(currentUser.user.phone);
  let [emp_phoneCountry, setEmpPhoneCountry] = useState(
    currentUser.user.country
  );
  let [emp_ref_code, setEmpRefCode] = useState("referal code");
  let [edit, setEdit] = useState(false);
  const phoneCountryCode = emp_phoneCountry;

  let updateDetails = () => {
    var data = JSON.stringify({
      fullName: emp_name,
      email: emp_email,
      countryCode: emp_phoneCountry,
      phoneNumber: emp_phone,
    });

    var config = {
      method: "patch",
      url: `${process.env.REACT_APP_BASE_API}/users/updateuserdetails`,
      headers: {
        Authorization: `Bearer ${currentUser.token}`,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {})
      .catch(function (error) {});
  };
  let resetDetails = () => {
    setEmpEmail(currentUser.user.email);
    setEmpName(currentUser.user.name);
    setEmpPhone(currentUser.user.phone);
    setEmpPhoneCountry(currentUser.user.country);
    setEmpRefCode(currentUser.user.ref);
  };

  let handleSavePersonal = async () => {
    await updateDetails();
    resetDetails();
    setEdit(!edit);
  };
  let handleCancelPersonal = () => {
    resetDetails();
    setEdit(!edit);
  };

  return (
    <div className="flex justify-center space-x-5 duration-200 ease-in-out">
      <div className="w-5/6 border-2 rounded-lg">
        <div className="profile-row-group">
          <div className="profile-group">
            <div className="relative mb-3">
              <div className="flex absolute inset-y-0 left-0 items-center pl-2 pointer-events-none">
                <BsFillPersonFill />
              </div>
              <label className="profile-content-head">Full Name</label>
            </div>
            <input
              type="text"
              disabled={!edit}
              value={emp_name}
              onChange={(e) => {
                setEmpName(e.target.value);
              }}
              className={
                "personal-input " + (!edit ? "text-gray-500" : "text-gray-700")
              }
              placeholder={emp_name}
            />
          </div>
          {/* <div className="profile-group">
            <div className="relative mb-3">
              <div className="flex absolute inset-y-0 left-0 items-center pl-2 pointer-events-none">
                <TiContacts />
              </div>
              <label className="profile-content-head">Contact Name</label>
            </div>
            <input
              type="text"
              disabled={!edit}
              value={org_contact}
              onChange={(e) => {
                setOrgContact(e.target.value);
              }}
              className={
                "personal-input " + (!edit ? "text-gray-500" : "text-gray-700")
              }
              placeholder={org_contact}
            />
          </div> */}
        </div>
        <div className="profile-row-group">
          <div className="profile-group">
            <div className="relative mb-3">
              <div className="flex absolute inset-y-0 left-0 items-center pl-2 pointer-events-none">
                <MdEmail />
              </div>
              <label className="profile-content-head">Email</label>
            </div>
            <input
              type="text"
              value={emp_email}
              disabled
              className={
                "personal-input " + (!edit ? "text-gray-500" : "text-gray-700")
              }
              placeholder={emp_email}
            />
          </div>
        </div>
        <div className="profile-row-group">
          <div className="profile-group">
            <div className="relative mb-3">
              <div className="flex absolute inset-y-0 left-0 items-center pl-2 pointer-events-none">
                <CgPhone />
              </div>
              <label className="profile-content-head">Phone Number</label>
            </div>
            <span className="inline-flex items-center w-full">
              {!edit ? null : (
                <ReactFlagsSelect
                  selected={emp_phoneCountry}
                  onSelect={(code) => {
                    setEmpPhoneCountry(code);
                  }}
                  fullWidth={false}
                  selectedSize={12}
                  showSelectedLabel={false}
                  showSecondarySelectedLabel={false}
                  showSecondaryOptionLabel={false}
                  searchable
                  className="block  px-1 py-1 text-sm  bg-transparent rounded-lg border-1 appearance-none  focus:outline-none"
                />
              )}
              <div className="relative w-full">
                <input
                  type="text"
                  disabled={!edit}
                  value={`${phoneCountryCode}-${emp_phone}`}
                  onChange={(e) => {
                    setEmpPhone(+e.target.value);
                  }}
                  className={
                    "personal-input  " +
                    (!edit ? "text-gray-500" : "text-gray-700")
                  }
                  placeholder={"" + emp_phone}
                />
              </div>
            </span>
          </div>
        </div>
        <div className="profile-row-group">
          <div className="profile-group">
            <div className="relative mb-3">
              <div className="flex absolute inset-y-0 left-0 items-center pl-2 pointer-events-none">
                <BsFillPersonFill />
              </div>
              <label className="profile-content-head">Reference Code</label>
            </div>
            <input
              type="text"
              disabled={!edit}
              value={emp_ref_code}
              onChange={(e) => {
                setEmpRefCode(e.target.value);
              }}
              className={
                "bg-gray-50 b{order border-gray-300 placeholder-slate-400 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full relative p-2.5 " +
                (!edit ? "text-gray-500" : "text-gray-700")
              }
              placeholder={emp_ref_code}
            />
          </div>
        </div>
      </div>
      <div className="duration-200 py-5">
        {!edit ? (
          <div className="flex duration-200">
            <button
              type="button"
              onClick={() => {
                setEdit(true);
              }}
              className="text-white font-roboto bg-GTI-BLUE-default hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 focus:outline-none "
            >
              Edit Profile
            </button>
          </div>
        ) : (
          <div className="flex duration-200">
            <button
              type="button"
              onClick={() => {
                handleSavePersonal();
              }}
              className="text-white font-roboto bg-GTI-BLUE-default hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 focus:outline-none "
            >
              Save
            </button>
            <button
              onClick={() => {
                handleCancelPersonal();
              }}
              className="py-2.5 px-5 mr-2 mb-2 text-sm font-medium text-GTI-BLUE-default focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 "
            >
              Cancel
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default Personal;
