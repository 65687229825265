import React, { Dispatch, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import Card2 from "../Pager/Card2";
import { connectionItem } from "../Modules/getData";
import pic from "../../assests/test/product/product2.jpg";
import { useDispatch } from "react-redux";
import { getConnections } from "../../store/actioncreators/connectionactions";
import { connectionOptions, ConnectionStatus } from "../constants";

const Connections = () => {
  const dispatch: Dispatch<any> = useDispatch();

  const [dropdownConnectionStatus, setDropdownConnectionStatus] = useState(
    ConnectionStatus.CONNECTED
  );

  let [page, updatePage] = useState({
    prev: 0,
    next: 1,
    loading: false,
  });

  let [type, setType] = useState({
    name: ConnectionStatus.CONNECTED,
    id: 1,
    drop: false,
  });

  let [search, setSearch] = useState({
    key: "",
    process: false,
  });

  let [connection_data, setData] = useState([]);

  const currentUser: USER = useSelector((state: STATE) => state.USER.USER);
  const connections: CONNECTION = useSelector(
    (state: STATE) => state.CONNECTION.CONNECTION
  );

  const fetchData = async (value: number) => {
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_BASE_API}/users/getconnections?skip=${
        page.next - 1 + value
      }&limit=1&connectionStatus=${dropdownConnectionStatus}`,
      headers: {
        Authorization: `Bearer ${currentUser.token}`,
      },
    };

    await axios(config)
      .then(function (response) {
        // console.log({ response });
        setData(response.data.connections);
        updatePage({
          next: page.next + value,
          prev: page.prev + value,
          loading: false,
        });
      })
      .catch(function (error) {
        // console.log("error fetching data");
      });
  };

  // const handleSearch = (e: any) => {
  //   if (e.target.value === "") {
  //     setSearch({ key: "", process: false });
  //     return;
  //   }
  //   setSearch({ key: e.target.value, process: true });
  // };

  useEffect(() => {
    dispatch(getConnections(dropdownConnectionStatus));
  }, [dropdownConnectionStatus]);

  return (
    <div className="flex flex-col px-10 items-center mb-20">
      <div className="profile-group">
        <div className="flex flex-col">
          <label
            htmlFor="dropdown"
            className="text-gray-700 font-bold mb-2 profile-content-head-2"
          >
            Connection Status
          </label>
          <div className="flex flex-col w-full">
            <select
              id="turnover"
              value={dropdownConnectionStatus}
              onChange={(event) => {
                const status: ConnectionStatus = event.target
                  .value as ConnectionStatus;
                setDropdownConnectionStatus(status);
              }}
              className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
            >
              {connectionOptions.map((option) => (
                <option value={option.value}>{option.value}</option>
              ))}
            </select>
          </div>
        </div>
      </div>

      <div className="flex flex-wrap gap-5 w-full justify-center mt-4">
        {connections &&
          connections.CONNECTION_LIST.connections.map(
            (item: any, id: number) => {
              return (
                <div key={id} className="w-full max-w-[300px] min-h-[250px]">
                  <Card2
                    title={item.fullName}
                    user={item}
                    org={item.companyId}
                    email={""}
                    Img={item.profileImage}
                    status={dropdownConnectionStatus}
                  />
                </div>
              );
            }
          )}
      </div>
      {/* {search.process &&
        connections &&
        connections.CONNECTION_LIST.connections.filter((item: connectionItem) =>
          item.fullName.match(new RegExp(search.key, "i"))
        ).map((item: any, id: number) => {
          return (
            <div className="grid grid-cols-5 w-full  py-4 mx-5 ">
              <Card2
                title={item.fullName}
                user={item}
                org={item.companyId}
                email={""}
                Img={item.profileImage}
              />
              ;
            </div>
          );
        })}

      {!search.process &&
        connections.CONNECTION_LIST.connections.map(
          (item: connectionItem, index: number) => {
            return (
              <div className="grid grid-cols-5 w-full  py-4 mx-5 " key={index}>
                <Card2
                  title={item.fullName}
                  org={item.companyId}
                  email={""}
                  Img={item.profileImage}
                  user={item}
                />
              </div>
            );
          }
        )} */}
    </div>
  );
};

export default Connections;
