import React from "react";
import { Helmet } from "react-helmet";

import blob from "../../assests/images/learnmore/bg_blob.png";
import learn_1 from "../../assests/images/learnmore/learn_more_1.png";
import video_placeholder from "../../assests/home/video_placeholder.png";
import collab_reserach from "../../assests/images/learnmore/collab_reserach.png";
import comp from "../../assests/images/learnmore/comp.png";
import gbi_logo from "../../assests/images/learnmore/gbi_logo.png";
import global_market from "../../assests/images/learnmore/global_market.png";
import indiv from "../../assests/images/learnmore/indiv.png";
import stake from "../../assests/images/learnmore/stake.png";
import tech_discovery from "../../assests/images/learnmore/tech_discovery.png";
import tech_enabler from "../../assests/images/learnmore/tech_enabler.png";
import tech_nucl from "../../assests/images/learnmore/tech_nucl.png";
import tech_pitch from "../../assests/images/learnmore/tech_pitch.png";
import tech_showcase from "../../assests/images/learnmore/tech_showcase.png";
import tech_unit from "../../assests/images/learnmore/tech_unit.png";
import vas from "../../assests/images/learnmore/vas.png";
import NewsLetter from "../LatestUpdates/NewsLetter";
import { title, metaData } from "../constants";

const LearnMore = () => {
  return (
    <div className="flex flex-col min-h-screen w-full justify-center items-center mb-20 py-2 ">
      <Helmet>
        <title>{title.LEARN_MORE}</title>
        <meta
          name="description"
          key="description"
          content={metaData.LEARN_MORE}
        />
        <meta name="title" key="title" content="Learn More" />
        <meta property="og:title" content="Learn More" />
        <meta property="og:description" content={metaData.LEARN_MORE} />
        <meta property="og:image" content={blob} />
        <meta
          property="og:url"
          content={`${process.env.REACT_APP_BASE_URL}/about`}
        />
        <meta property="og:type" content="website" />
        <meta name="twitter:title" content="Learn More" />
        <meta name="twitter:description" content={metaData.LEARN_MORE} />
        <meta name="twitter:image" content={blob} />
        <meta name="twitter:card" content="Learn More" />
      </Helmet>
      <div className="md:flex flex-row w-full md:pt-20 p-5">
        <div className="flex flex-col justify-center items-center md:w-1/2">
          <p className="text-2xl md:text-5xl font-roboto font-bold">
            Why GTI® ?
          </p>
          <p className="text-xl md:text-2xl font-roboto font-bold text-slate-500 py-5">
            Technology and Innovation Platform
          </p>
          <div className="flex relative justify-center items-center ">
            <img
              src={blob}
              alt="Technology and Innovation Platform"
              className="w-3/6 md:w-full"
            />
            <img
              src={learn_1}
              alt="Learn More"
              className="absolute top-20 w-3/12 md:w-full"
            />
          </div>
        </div>
        <div className="flex flex-col justify-center items-center md:w-1/2">
          <img
            src={video_placeholder}
            alt="The Global Technology Interface®"
            className="w-4/6"
          />
          <p className="text-xl md:text-2xl font-roboto font-bold">
            The Global Technology Interface®
          </p>
          <p className="text-sm md:text-xl text-justify font-roboto font-bold text-slate-500 p-10">
            The Global Technology Interface® (GTI®) is an online platform for
            companies, entrepreneurs, MSMEs, startups and R&D institutions to
            showcase innovative technologies and facilitate collaborations in
            technology, science, research, and business on a global platform in
            a sustained manner. GTI® functions as a global market place where
            biotech, clean-tech and ICT entities can identify partners,
            investors and customers across geographic boundaries.
          </p>
        </div>
      </div>
      <p className="text-xl md:text-3xl font-roboto font-bold py-10 p-5 md:py-10">
        Entities joining the GTI® platform can take advantage of the following
        features -
      </p>
      <div className="flex flex-col justify-center items-center">
        <div className="w-full grid grid-cols-1 md:grid-cols-3 md:grid-rows-2 gap-y-20 justify-center">
          <div className="w-4/6 md:w-3/4 mx-auto border border-gray-100 shadow-xl rounded-3xl flex flex-col items-center ">
            <img
              src={tech_showcase}
              className="md:w-full w-3/5"
              alt="Tech Showcase"
            />
            <p className="font-roboto text-lg md:text-xl text-GTI-BLUE-default pt-5">
              Technology Showcase
            </p>
            <p className="font-roboto text-sm md:text-lg text-gray-500 text-center leading-loose pb-16 px-5">
              Dedicated virtual display space where technology companies can
              show case products, solutions and other display materials with the
              aim of deriving interest from other visitors to the platform.
            </p>
          </div>
          <div className="w-4/6 md:w-3/4 mx-auto border border-gray-100 shadow-xl rounded-3xl flex flex-col items-center ">
            <img
              src={tech_discovery}
              className="md:w-full w-3/5"
              alt="Tech Discovery"
            />
            <p className="font-roboto text-lg md:text-xl text-GTI-BLUE-default pt-5">
              Technology Discovery
            </p>
            <p className="font-roboto text-sm md:text-lg text-gray-500 text-center leading-loose pb-16 px-5">
              Interested parties can discover new international technologies,
              partners, customers and investors, participate in podcasts by
              experts, companies and researchers.
            </p>
          </div>
          <div className="w-4/6 md:w-3/4 mx-auto border border-gray-100 shadow-xl rounded-3xl flex flex-col items-center ">
            <img
              src={global_market}
              className="md:w-full w-3/5"
              alt="Global Market"
            />
            <p className="font-roboto text-lg md:text-xl text-GTI-BLUE-default pt-3">
              Global Marketplace
            </p>
            <p className="font-roboto text-sm md:text-lg text-gray-500 text-center leading-loose pb-5 px-5">
              The platform will act as a global marketplace for businesses,
              researchers to reach partners and end customers. The "Connect"
              feature allows a Technology Scouter to connect with a Technology
              Displayer and vice versa. These connects can be mentored by GTI®
              tech management team for collaboration if required by both
              parties.
            </p>
          </div>
          <div className="w-4/6 md:w-3/4 mx-auto border border-gray-100 shadow-xl rounded-3xl flex flex-col items-center ">
            <img
              src={tech_pitch}
              className="md:w-full w-3/5"
              alt="Tech Pitch"
            />
            <p className="font-roboto text-lg md:text-xl text-GTI-BLUE-default pt-5">
              Technology Pitch Sessions
            </p>
            <p className="font-roboto text-sm md:text-lg text-gray-500 text-center leading-loose pb-5 px-5">
              The GTI® team will organize technology pitch (physical and
              virtual) sessions where researchers, business groups,
              entrepreneurs and startups will have the opportunity to present
              their innovations to global market players including corporates
              and investors
            </p>
          </div>
          <div className="w-4/6 md:w-3/4 mx-auto border border-gray-100 shadow-xl rounded-3xl flex flex-col items-center ">
            <img
              src={collab_reserach}
              className="md:w-full w-3/5"
              alt="Collaborative Research"
            />
            <p className="font-roboto text-lg md:text-xl text-center px-10 text-GTI-BLUE-default pt-3">
              Collaborative Research and Development
            </p>
            <p className="font-roboto text-sm md:text-lg text-gray-500 text-center leading-loose pb-16 px-5">
              GBI will facilitate collaborations and support in developing a
              consortium with global research ecosystems.
            </p>
          </div>
          <div className="w-4/6 md:w-3/4 mx-auto border border-gray-100 shadow-xl rounded-3xl flex flex-col items-center ">
            <img
              src={tech_nucl}
              className="md:w-full w-3/5"
              alt="Tech Nucleus"
            />
            <p className="font-roboto text-lg text-center md:text-xl text-GTI-BLUE-default pt-5">
              Technology Intelligence Nucleus
            </p>
            <p className="font-roboto text-sm md:text-lg text-gray-500 text-center leading-loose pb-16 px-5">
              The GTI® platform will be the library for innovation, knowledge,
              events and information exchange on sustained and real time basis.
            </p>
          </div>
        </div>
        <div className="w-full grid grid-cols-1 md:grid-cols-4 md:gap-x-10 gap-y-20 md:pt-20 justify-around">
          <div></div>
          <div className="w-4/6 md:w-full mx-auto border border-gray-100 shadow-xl rounded-3xl flex flex-col  items-center ">
            <img
              src={tech_enabler}
              className="md:w-full w-3/5"
              alt="Tech Enabler"
            />
            <p className="font-roboto text-lg text-center md:text-xl text-GTI-BLUE-default pt-3">
              Technology Enabler (School)
            </p>
            <p className="font-roboto text-sm md:text-lg text-gray-500 text-center leading-loose pb-16 px-5">
              GTI® will provide an education platform for students and
              researchers to be up to date on the latest innovative work
              happening in their fields of study.
            </p>
          </div>
          <div className="w-4/6 md:w-full mx-auto border border-gray-100 shadow-xl rounded-3xl flex flex-col  items-center ">
            <img src={tech_unit} className="md:w-full w-3/5" alt="Tech Unit" />
            <p className="font-roboto text-lg text-center md:text-xl text-GTI-BLUE-default pt-3">
              Technology Management Unit
            </p>
            <p className="font-roboto text-sm md:text-lg text-gray-500 text-center leading-loose pb-16 px-5">
              GTI® will be monitored by a team of experts to manage business
              deals and research collaborations. They will also provide
              additional value added services.
            </p>
          </div>
        </div>
      </div>
      <p className="text-xl md:text-5xl font-roboto font-bold py-10">
        Who should leverage GTI?
      </p>
      <div className="w-full grid grid-cols-1 md:grid-cols-3 gap-y-20 justify-center">
        <div className="w-3/4 mx-auto border border-gray-100  shadow-xl rounded-3xl flex flex-col  items-center ">
          <img src={stake} className="md:w-full w-3/5" alt="Stake" />
          <p className="font-roboto text-lg md:text-xl text-GTI-BLUE-default pt-5 px-5">
            Stakeholders
          </p>
          <p className="font-roboto text-sm md:text-lg text-GTI-BLUE-default px-10 pb-3 text-center">
            Clusters, Accelerators, Incubators, universities, research
            institutes
          </p>
          <ul className="list-disc font-roboto text-sm md:text-lg text-gray-500 leading-loose px-10 pb-5">
            <li>Access to GTI® and to showcase technologies.</li>
            <li>
              Scout for research collaborations, bi/multi lateral research
              calls.
            </li>
            <li>
              Promote open innovation projects/calls/challenges through global
              networks.
            </li>
            <li>
              Promote annual programs and events: like exhibitions, seminars,
              conferences, webinars and pitch sessions to propagate the benefits
              of their regions, companies and ecosystem.
            </li>
            <li>
              Promote internationalization of companies, technologies, products
              from their regions.
            </li>
          </ul>
        </div>
        <div className="w-3/4 mx-auto border border-gray-100  shadow-xl rounded-3xl flex flex-col  items-center ">
          <img src={comp} className="md:w-full w-3/5" alt="Companies" />
          <p className="font-roboto text-lg md:text-xl text-GTI-BLUE-default pt-5 px-5">
            Companies
          </p>
          <p className="font-roboto text-sm md:text-lg text-GTI-BLUE-default px-10 pb-3 text-center">
            SME
          </p>
          <ul className="list-disc font-roboto text-sm md:text-lg text-gray-500 leading-loose px-10 pb-5">
            <li>Access to GTI® and to showcase/scout for technologies.</li>
            <li>
              Scout for research collaborations, bi/multi lateral research
              calls.
            </li>
            <li>
              Promote open innovation projects/calls/challenges through global
              networks.
            </li>
            <li>
              Direct access to pre-verified entities such as other companies,
              universities etc. involved with technology
            </li>
            <li>
              Opportunities to potentially look for investments or to invest in
              other technology entities.
            </li>
          </ul>
        </div>
        <div className="w-3/4 mx-auto border border-gray-100  shadow-xl rounded-3xl flex flex-col  items-center ">
          <img src={indiv} className="md:w-full w-3/5" alt="Individuals" />
          <p className="font-roboto text-lg md:text-xl text-GTI-BLUE-default pt-5 px-5">
            Individuals
          </p>
          <p className="font-roboto text-sm md:text-lg text-GTI-BLUE-default px-10 pb-3 text-center">
            Start-ups, Entrepreneurs, Investors
          </p>
          <ul className="list-disc font-roboto text-sm md:text-lg text-gray-500 leading-loose px-10 pb-5">
            <li>Access to GTI® to showcase technologies.</li>
            <li>
              Apply to open innovation projects/calls/challenges through global
              networks.
            </li>
            <li>Access to Global Technology Innovations®.</li>
            <li>Scout for business opportunities in new markets.</li>
            <li>
              Scout for research collaborations, bi/multi lateral research
              calls.
            </li>
            <li>
              Direct access to pre-verified entities such as companies,
              universities etc. involved with technology
            </li>
          </ul>
        </div>
      </div>
      {/* <div className="md:flex flex-row w-full pt-20 p-5">
        <div className="flex flex-col justify-evenly items-center w-full md:w-1/2">
          <p className="text-xl md:text-5xl font-roboto font-bold">
            Value Added Services
          </p>
          <div className="flex relative justify-center items-center ">
            <img
              src={blob}
              alt="Value Added Services"
              className="w-3/6 md:w-full"
            />
            <img
              src={vas}
              alt="Learn More"
              className="absolute top-20 w-4/12 md:w-full"
            />
          </div>
        </div>
        <div className="flex flex-col justify-center items-center w-full md:w-1/2">
          <p className="text-sm md:text-xl text-left font-roboto  text-slate-500 p-10">
            Entering a new and dynamic market like India requires a sustained
            effort and presence; entrepreneurs and SMEs will need to adhere to a
            very structured approach to be successful and have a sustained
            market presence in India. India being a very cost sensitive market,
            looks for high value products at low (optimal) costs. Economies of
            scale play a huge role in driving down costs as well indigenization
            of the technology and adopting local supply chains. Cost
            Optimization for the Indian market is key. Hence local business
            models will need to be developed which analyses and prepares for
            optimal costs. This does not have to mean lower profits. Company
            should develop a strong customer focus, understand how much the
            customer is willing to pay and the volume of customer base, policy
            framework and tax structures, etc to develop a localized business
            model that balances between cost and profits. The commercialization
            strategy for India needs to be inclusive and considerate of the
            nature of Indian demand. There are two main strategies most
            international entrepreneurs and SMEs tend to adopt based on the
            in-depth understanding of the India market: a non-equity based
            strategy which includes direct selling / export and contractual
            agreements or an equity based strategy that includes joint venture,
            subsidiary or by acquisition of an India company. In order to reach
            this decision entrepreneurs and SMEs will need to pass through
            various stages from discovery to deployment they can engage at any
            stage based on their current readiness and capacity to enter a new
            market.
          </p>
        </div>
      </div>
      <p className="text-xl md:text-4xl font-roboto font-bold py-10">
        International market access services includes
      </p>
      <div className="flex flex-col mx-5 md:mx-20 space-y-10">
        <div className="md:flex flex-row border border-gray-100 rounded-2xl shadow-lg p-10 items-center justify-center">
          <p className="font-roboto text-lg md:text-3xl text-GTI-BLUE-default pt-5 px-5 whitespace-nowrap">
            Short Term
          </p>
          <p className="font-roboto text-sm text-gray-500 pt-5 px-5">
            Market Analysis: GBI provides valuable information for entrepreneurs
            and SMEs to look at short term objectives such at understanding the
            size and nature of the market, how they will need to position their
            technology or service, regulatory issues and the cultural influences
            of the local ecosystem. Market Validation: GBI facilitates very
            structure market research and validation trips of the target market
            for entrepreneurs and SMEs spanning over a week to 10 days to meet
            with pre-qualified leads, with key stakeholders encompassing the
            entire ecosystem required for technology deployment in the target
            market. Partner/Investor/Customer connects: GBI helps identify the
            right Partner/Investor/Customer or R&D collaborator or help set up
            local operations/ subsidiary in India.
          </p>
        </div>
        <div className="md:flex flex-row border border-gray-100 rounded-2xl shadow-lg p-10 items-center justify-center">
          <p className="font-roboto text-lg md:text-3xl text-GTI-BLUE-default pt-5 px-5 whitespace-nowrap">
            Medium Term
          </p>
          <p className="font-roboto text-sm text-gray-500 pt-5 px-5">
            Business Analytics and Strategy: GBI assist with the detailed
            analytics includes techno-commercial feasibilities, revenue
            projections, marketing and sales strategies and the regulatory and
            IPR aspects. Technology Representation: GBI can appoint a local
            technology manager who will represent the interests of the
            entrepreneurs or SMEs in India, who on a part time or full time
            basis can provide real time feedback on how the technology or
            product can be positioned in the Indian market. Feasibility Studies:
            GBI will undertake technical feasibility studies in conjunction with
            local partners to test the viability of the technology product or
            service, assess potential risks and specific scenarios to
            investigate a variety of ways of organizing the business and
            positioning the product or service. Technology Indigenization:
            Technology or products companies will also need to look into
            indigenizing some of the key aspects such a local supply chain and
            sourcing, costing and pricing strategies as well operations and
            maintenance strategies. IP Support and Management: GBI provides
            patent protection services for setting entitles looking to setup
            local operations in India, the options include, partnership, joint
            venture or setting up local subsidiary in India.
          </p>
        </div>
        <div className="md:flex flex-row border border-gray-100 rounded-2xl shadow-lg p-10 items-center justify-center">
          <p className="font-roboto text-lg md:text-3xl text-GTI-BLUE-default pt-5 px-5 whitespace-nowrap">
            Long Term
          </p>
          <p className="font-roboto text-sm text-gray-500 pt-5 px-5">
            Technology Demonstration and Development: GBI assist with the
            validation of the performance of the technology product or service
            under local conditions using local inputs and data, technology needs
            to be demonstrated or piloted possibly with the help of local
            partners. Technology Commercialization and Financial Advisory: GBI
            assists with the seeking of grants and funding through various
            multilateral and bilateral developmental agencies, national and
            local government?s source, equity and debt funding sources available
            to scale up operations. Liaison with Local Ecosystem: One of the key
            aspects for sustainable growth in India is developing an ecosystem
            for technology deployment which includes engaging with the
            community, effectively liaisoning with the government, academia and
            the industry, GBI will help develop and liaise with the local eco
            system.
          </p>
        </div>
        <div className="md:flex flex-row p-5 md:p-20 items-center justify-center">
          <img src={gbi_logo} className="w-3/6" alt="GTI" />
          <div className="flex flex-col">
            <p className="font-roboto text-sm text-gray-500 pt-5 px-5">
              Global Business Inroads International Business Consultants Private
              Limited (GBI) was founded in 2009 and is a boutique international
              technology management consulting entity with expertise in
              technology transfer and project management focusing on the
              clean-tech, life sciences and ICT sectors. We also develop
              programs and ecosystems for international technology transfer,
              climate change mitigation and social impact. GBI has supported
              over 200 Institutions, SMEs and start-ups from around the world in
              various capacities.
            </p>
            <p className="font-roboto text-sm text-gray-500 pt-5 px-5">
              The platform will be managed by GBI to ensure proper due diligence
              of companies entering the platform and also ensure that
              connections made are based on mutual interests. This direct
              management is the niche feature of the GTI® platform and is the
              key differentiator from other similar platforms.
            </p>
          </div>
        </div>
      </div> */}
      <NewsLetter />
    </div>
  );
};

export default LearnMore;
