import { toast } from "react-toastify";

export const scrollToTop = () => {
  window.scrollTo(0, 0);
};
export const getQueryParams = (query: string) => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const queryVal = decodeURI(urlSearchParams.get(query) ?? "");
  return queryVal;
};

export const notify = (msg: string, type: string) => {
  if (type === "info") {
    return toast.info(msg, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  } else if (type === "success") {
    return toast.success(msg, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  } else if (type === "warning") {
    return toast.warn(msg, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  } else if (type === "error") {
    return toast.error(msg, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }
};

export const isSuccess = (res: any) => {
  return (
    (res?.status >= 200 && res?.status < 300) ||
    (res?.statusCode >= 200 && res?.statusCode < 30) ||
    res?.data?.statusCode === 200
  );
};

export function formatUserType(userType: string): string {
  const words = userType
    .split("_")
    .map((word) => word.toLowerCase()?.replace(/^\w/, (c) => c.toUpperCase()));
  return words.join(" ");
}
