import React, { Dispatch, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import axios from "axios";

import { store } from "../../store";
import { eventItemsFetched } from "../constants";
import { RequestMethods } from "../../shared/RequestMethods";

const EventVideo = ({ handleLoginModal }: { handleLoginModal: () => void }) => {
  const { id } = useParams();
  const [videoUrl, setVideoUrl] = useState("");
  const [isUserRegistered, setIsUserRegistered] = useState(false);
  const navigate = useNavigate();

  const state = {
    id: id ?? "",
  };

  let [event, setEvent] = useState<eventItemsFetched>({
    _id: "",
    topic: "",
    description: "",
    shortDescription: "",
    sectorId: "",
    subSectorId: "",
    eventType: "",
    imageUrl: "",
    startDate: "",
    endDate: "",
    externalLink: "",
    youtubeLink: "",
    webinarKey: "",
    webinarOrganizerKey: "",
    organizedBy: "",
    webinarRegistrationLink: "",
    createdAt: "",
    startTime: "",
    endTime: "",
    meetingLink: "",
    price: 0,
    videoUrl: "",
    __v: -1,
  });

  const loadEvent = (id: string) => {
    var config = {
      method: RequestMethods.GET,
      url: `${process.env.REACT_APP_BASE_API}/events/eventdetails/${id}`,
      headers: {
        Authorization: `Bearer ${store.getState().USER.USER.token}`,
        "Content-Type": "application/json",
      },
    };

    axios(config)
      .then(function (response) {
        setEvent(response.data);
        console.log(response?.data?.videoUrl);
        setVideoUrl(response?.data?.videoUrl);
      })
      .catch(function (error) {});
  };

  const getRegistration = () => {
    const token = localStorage.getItem("GTI_data")?.split(" ")[0] ?? "";

    let config = {
      method: "GET",
      url: `${process.env.REACT_APP_BASE_API}/events/isUserRegistered/${state.id}`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };

    axios(config)
      .then(function (response) {
        setIsUserRegistered(response?.data);
      })
      .catch(function (error) {});
  };

  useEffect(() => {
    loadEvent(state.id);
    getRegistration();
  }, []);

  useEffect(() => {
    setVideoUrl(event?.videoUrl);
  }, [event]);

  return (
    <React.Fragment>
      <div className="event-video">
        {videoUrl && isUserRegistered ? (
          <div className="h-full flex flex-col items-center">
            <h1 className="event-group text-lg font-bold lg:text-2xl font-roboto mb-2 mt-2">
              {event.topic}
            </h1>
            <div className="event-group text-xs lg:text-lg">
              <p dangerouslySetInnerHTML={{ __html: event.description }}></p>
            </div>
            <video
              className="rounded-lg"
              controls
              controlsList="nodownload"
              style={{ height: "40rem" }}
            >
              <source src={videoUrl} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
            <button
              data-modal-toggle="error-modal"
              onClick={() => {
                navigate(`/events/${event._id}`);
              }}
              type="button"
              className="text-white w-fit bg-GTI-BLUE-default hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center mt-10 mb-10"
            >
              View Event
            </button>
          </div>
        ) : isUserRegistered ? (
          <div className="h-screen flex flex-col mt-10 relative items-center  w-full p-5">
            <p className="text-gray-600 mb-8 text-xs md:text-lg text-center">
              Access Denied!
              <br />
              Please Register for the Event to Access
            </p>
            <button
              data-modal-toggle="error-modal"
              onClick={() => {
                navigate(`/events/${event._id}`);
              }}
              type="button"
              className="text-white w-fit bg-GTI-BLUE-default hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center mr-2"
            >
              View Event
            </button>
          </div>
        ) : (
          <h1 className="h-screen mt-52 text-gray-600 mb-8 text-xs md:text-lg text-center">
            {event.topic}
          </h1>
        )}
      </div>
    </React.Fragment>
  );
};

export default EventVideo;
