import React, { Dispatch, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { NONE, oppotunityItemPartialFetched, YOUR_OPP } from "../constants";
import { useNavigate } from "react-router-dom";
import oppbanner from "../../assests/images/product_alt.png";
import {
  getCompanyApprovedOPP,
  getCompanyPendingOPP,
  getCompanyRejectedOPP,
  getCompanyUnapprovedOPP,
} from "../../store/actioncreators/opportunityactions";

const Card = ({
  item,
  handleSelect,
}: {
  item: oppotunityItemPartialFetched;
  handleSelect: (data: oppotunityItemPartialFetched) => void;
}) => {
  const dispatch: Dispatch<any> = useDispatch();
  const navigate = useNavigate();

  const handleView = () => {
    navigate(YOUR_OPP, { state: { product: item } });
  };

  const DOP = new Date(item.createdAt);
  // console.log(item.image);

  const handleEdit = () => {
    handleSelect(item);
  };
  const handleDelete = async () => {};
  return (
    <div className="product-card-main">
      <div
        className="product-card-img"
        onClick={() => {
          handleView();
        }}
      >
        <img
          src={item.image === NONE ? oppbanner : item.image}
          className="flex  w-full lg:h-56  rounded-t-lg hover:rounded-lg object-contain"
          alt={item.description}
          loading="lazy"
        />
      </div>
      <div className="product-card-title">
        <h4 className="font-roboto text-xs text-slate-500 ">
          {"Posted on:"}
          {DOP.toLocaleString("default", {
            month: "short",
            day: "2-digit",
            year: "numeric",
          })}
        </h4>
      </div>
      <div className="product-card-title">
        <h4 className="font-roboto text-lg text-GTI-BLUE-default ">
          {item?.technologyPartnerRequirement.length > 25
            ? item?.technologyPartnerRequirement.substring(0, 25) + "..."
            : item?.technologyPartnerRequirement}
        </h4>
      </div>
      <div className="product-card-title">
        <h4 className="font-roboto  text-xs text-slate-500 ">
          {item.description.length > 150
            ? item.description.substring(0, 150) + "..."
            : item.description}
        </h4>
      </div>
      {/* <div className="flex flex-row justify-evenly">
        <div className="inline-flex items-center" onClick={() => { handleEdit() }}>
          <RiEditBoxFill className="w-5 h-5 mr-2" />Edit
        </div>
        <div className="inline-flex items-center" onClick={() => { handleView() }}>
          <MdPreview className="w-5 h-5 mr-2" />View
        </div>
        <div className="inline-flex items-center" onClick={() => { handleDelete() }}>
          <RiDeleteBin6Fill className="w-5 h-5" />Delete
        </div>
      </div> */}
    </div>
  );
};

const CompanyOpportunityList = ({
  skip,
  limit,
  secId,
  subsecId,
  type,
}: {
  skip: string;
  limit: string;
  subsecId: string;
  secId: string;
  type: number;
}) => {
  const dispatch: Dispatch<any> = useDispatch();
  const opp: OPP = useSelector((state: STATE) => state.OPP.OPP);
  const user: USER = useSelector((state: STATE) => state.USER.USER);
  const navigate = useNavigate();
  useEffect(() => {
    switch (type) {
      case 0:
        dispatch(getCompanyApprovedOPP(skip, limit));
        break;
      case 1:
        dispatch(getCompanyUnapprovedOPP(skip, limit));
        break;
      case 2:
        dispatch(getCompanyPendingOPP(skip, limit));
        break;
      default:
        dispatch(getCompanyRejectedOPP(skip, limit));
    }
  }, [secId, subsecId, skip, type]);

  const [productEditModal, setModal] = useState(false);

  const [currentProduct, setProduct] = useState<oppotunityItemPartialFetched>({
    _id: "",
    technologyPartnerRequirement: "",
    description: "",
    image: "",
    displayOnHomePage: false,
    isApprovedBySubAdmin: false,
    isApprovedByAdmin: false,
    isRejected: false,
    document: "",
    sectorId: "",
    subSectorId: "",
    userId: "",
    createdAt: "",
    __v: -1,
    sectors: {
      _id: "",
      name: "",
      slug: "",
      image: "",
      createdAt: "",
      __v: -1,
    },
    subsectors: {
      _id: "",
      name: "",
      slug: "",
      sectorId: "",
      createdAt: "",
      __v: -1,
    },
    company: {
      logo: "",
      name: "",
    },
  });

  const handleModel = () => {
    setModal(!productEditModal);
    switch (type) {
      case 0:
        dispatch(getCompanyApprovedOPP(skip, limit));
        break;
      case 1:
        dispatch(getCompanyUnapprovedOPP(skip, limit));
        break;
      case 2:
        dispatch(getCompanyPendingOPP(skip, limit));
        break;
      default:
        dispatch(getCompanyRejectedOPP(skip, limit));
    }
  };

  const handleSelect = (item: oppotunityItemPartialFetched) => {
    if (item.__v !== -1) {
      setProduct(item);
      handleModel();
    }
  };

  return (
    <div className="flex w-full justify-center items-center px-10 flex-col">
      <div className="product-list-main">
        {type === 0 &&
          opp.COMPANY_APPROVED_OPP_LIST &&
          opp.COMPANY_APPROVED_OPP_LIST.map(
            (item: oppotunityItemPartialFetched, id: number) => {
              return <Card item={item} key={id} handleSelect={handleSelect} />;
            }
          )}
        {type === 1 &&
          opp.COMPANY_UNAPPROVED_OPP_LIST &&
          opp.COMPANY_UNAPPROVED_OPP_LIST.map(
            (item: oppotunityItemPartialFetched, id: number) => {
              return <Card item={item} key={id} handleSelect={handleSelect} />;
            }
          )}
        {type === 2 &&
          opp.COMPANY_PENDING_OPP_LIST &&
          opp.COMPANY_PENDING_OPP_LIST.map(
            (item: oppotunityItemPartialFetched, id: number) => {
              return <Card item={item} key={id} handleSelect={handleSelect} />;
            }
          )}
        {type === 3 &&
          opp.COMPANY_REJECTED_OPP_LIST &&
          opp.COMPANY_REJECTED_OPP_LIST.map(
            (item: oppotunityItemPartialFetched, id: number) => {
              return <Card item={item} key={id} handleSelect={handleSelect} />;
            }
          )}
      </div>
    </div>
  );
};
export default CompanyOpportunityList;
