import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { IoIosNotifications } from "react-icons/io";
import { useSelector, useDispatch } from "react-redux";
import { resetUser } from "../../store/actioncreators/actionCreators";
import { Dispatch } from "redux";
import * as ROUTE from "../Constants/routes";
import { useDetectClickOutside } from "react-detect-click-outside";
import { HOME, NOTIFICATIONS } from "../constants";
import { notificationCount } from "../../api/user";

interface NavbarInterface {
  handleLoginModal: () => void;
  handleNotificationModal: () => void;
  show?: boolean;
  handleShow: () => void;
}

const H_Right: React.FC<NavbarInterface> = ({
  handleLoginModal,
  handleNotificationModal,
  show,
  handleShow,
}) => {
  const [sign_modal, setSignModal] = useState(1);
  const dispatch: Dispatch<any> = useDispatch();
  const location = useLocation();

  const resetUserDetails = React.useCallback(
    () => dispatch(resetUser(currentUser)),
    [dispatch]
  );

  const currentUser: USER = useSelector((state: STATE) => state.USER.USER);

  const [notificationCnt, setNotificationCnt] = useState(0);
  let navigate = useNavigate();
  let [loggedUser, setLoggedInUser] = useState({
    drop: false,
    productDrop: false,
  });

  const ref1 = useDetectClickOutside({
    onTriggered: () => setLoggedInUser({ productDrop: false, drop: false }),
  });
  const handleLogOut = () => {
    resetUserDetails();
    localStorage.removeItem("GTI_data");
    setLoggedInUser({ ...loggedUser, drop: false });
    navigate(process.env.REACT_APP_HOME!);
  };
  const handleSignSelectModal = (index: any) => {
    if (index == 1) {
      setSignModal(1);
      handleLoginModal();
    } else {
      setSignModal(2);
    }
  };
  const handleView = () => {
    navigate(NOTIFICATIONS);
  };

  useEffect(() => {
    fetchNotifications();
  }, []);

  const fetchNotifications = async () => {
    try {
      const res = await notificationCount();
      setNotificationCnt(res?.data);
    } catch (err) {}
  };

  return (
    <div>
      {currentUser.admin !== -1 ? (
        <div
          className={`flex flex-row items-center ${
            show ? "flex" : "hidden lg:flex"
          }`}
        >
          <div
            className="flex flex-row relative cursor-pointer"
            onClick={() => handleView()}
          >
            <IoIosNotifications
              className={`${
                location.pathname === HOME ? "fill-black" : "fill-white"
              } w-8 h-8`}
            />
            {notificationCnt > 0 ? (
              <h1 className="absolute text-white text-center justify-center text-xs font-semi-bold bg-red-500 rounded-full w-4 h-4"></h1>
            ) : null}
          </div>
          <div className="flex flex-col">
            <button
              id="profile_drop"
              onClick={() => {
                navigate(ROUTE.PROFILE);
              }}
              data-dropdown-toggle="dropdown"
              className={`${
                location.pathname === HOME ? "text-black" : "text-white"
              } item2 truncate max-w-[160px]`}
              type="button"
              ref={ref1}
            >
              <img
                src={
                  currentUser?.company[0]?.logo || currentUser.user.profileImg
                }
                className="mx-1 rounded-full object-cover w-8 h-8"
                alt={currentUser.user.name}
              />
              <svg
                onClick={() => {
                  setLoggedInUser({ ...loggedUser, drop: !loggedUser.drop });
                }}
                className="ml-1 w-4 h-4"
                aria-hidden="true"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M19 9l-7 7-7-7"
                ></path>
              </svg>
            </button>
            <div
              id="profile_dropdown"
              className={
                "relative z-10 translate-y-2  " +
                (loggedUser.drop ? "" : "hidden")
              }
              data-popper-reference-hidden=""
              data-popper-escaped=""
              data-popper-placement="bottom"
            >
              <ul
                className="absolute text-center flex flex-col bg-slate-100 shadow-md shadow-GTI-BLUE-default w-full rounded"
                aria-labelledby="dropdownDefault"
              >
                <li className="font-roboto block py-2 px-4 rounded  text-GTI-BLUE-default  hover:text-slate-500 ">
                  <Link to={ROUTE.PROFILE}>Profile</Link>
                </li>
                <li>
                  <div
                    onClick={() => {
                      handleLogOut();
                    }}
                    className="font-roboto block py-2 px-4 rounded  text-GTI-BLUE-default  hover:text-slate-500 cursor-pointer"
                  >
                    Log out
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      ) : (
        <div className="flex items-center space-x-2 duration-200 ease-in-out mt-5 sm:mt-0">
          <button
            onClick={() => {
              handleSignSelectModal(1);
              handleShow();
            }}
            className={
              "px-1 lg:px-4 py-2 font-bold " +
              (sign_modal === 1 ? "signModalActive " : "signModal ") +
              (location.pathname === HOME
                ? "rounded  shadow-lg shadow-GTI-BLUE-default"
                : "rounded border")
            }
          >
            Sign in
          </button>
          <Link className="" to="/signup">
            <button
              onClick={() => {
                handleSignSelectModal(2);
                handleShow();
              }}
              className={
                "px-2 lg:px-4 py-2 font-bold " +
                (sign_modal === 2 ? "signModalActive " : "signModal ") +
                (location.pathname === HOME
                  ? "rounded  shadow-lg shadow-GTI-BLUE-default"
                  : "rounded border")
              }
            >
              Sign up
            </button>
          </Link>
        </div>
      )}
    </div>
  );
};

export default H_Right;
