import React from "react";
import { Helmet } from "react-helmet";

import { title, metaData } from "../constants";
import globe from "../../assests/home/globe.png";

const TermsCondition: React.FC = () => {
  const data1 = [
    "The following terms and conditions apply to all access to and use of our platform the Global Technology Interface® (GTI®) www.globaltechinterface.com (the “Website”). Please read them carefully. We recommend that you print these Terms and Conditions off or save them to your computer so that you have a copy of them for future reference. Access to and any use of our Website constitutes your agreement to abide by the terms and conditions below, as amended from time to time. If you do not agree to all of the terms and conditions, you may choose to exit the website. We are committed to protecting the privacy of all personal information obtained from you during your visits to this Website. This privacy policy explains what personal information we may collect from you and the purposes for which it will be used. By using this Website you give your agreement to the data practices described in this policy. When you register and use the Website, we collect different types of information to offer personalized information, notify you of changes to our service, and ask your opinions and to help us improve our service to you. The types of information and uses are explained below:",
    `Information that you provide to us We collect and process information that you provide for specific purposes. This may include demographic information such as your name, email addresses, mailing address and telephone number. We use the information to provide you with the specific information, product or services that you request from us and to communicate with you. If you contact us we may keep a record of that correspondence. You will be asked to provide information about yourself when you use certain site facilities, including the following: Contact us: you will be asked for contact information when you use the contact facility of the Website. E-Alert: you will be asked for contact information when you register for our alert service.`,
    `Entities joining the GTI ® platform can take advantage of the following features:`,
    `Website usage information We receive and store certain information whenever you interact with us. We collect Website usage information from a number of sources: Our web server. URLs passed to us when you enter our site. "Tags" placed in web pages that send information to us or our processors. Some tags run a small piece of javascript on your machine (see below for more on javascript). Some information entered by you on forms on the Website. In web usage information where information is entered by a user who has not logged in, truncated postcodes (excluding the last two characters) and telephone area codes may be used to measure regional demographics, but are not traceable back to you. A selection of Internet service providers. A selection of search engine companies. IP addresses. Cookies. We collect web usage information during your visits to our site including information on the date, time, page viewed or searched for, publications ordered, guides printed, tools used, subscriptions and referrals made and other information relating to your browsing activity on our Website.`,
    `We collect web usage information to help us: assess the effectiveness of marketing campaigns develop and deliver services and information that better meet your needs inform you of events, services and products which we believe to be relevant to you, where you have consented to be contacted for such purposes.`,
    `IP Addresses We use IP addresses to analyse trends, track users' movement, and gather broad demographic information for aggregate use. IP addresses are not linked to personally identifiable information.`,
    `Cookies Cookies are small files that are often created when you visit a Website, and which are stored in the cookie directory of your computer. A cookie often includes an anonymous unique identifier. Cookies do not damage your computer. Each Website can send its own cookie to your browser if your browser's preferences allow it, but to protect your privacy your browser only permits a Website to access the cookies it has already sent to you, not the cookies sent to you by other sites. You do not have to accept cookies. You can decide if you want to accept cookies by changing the settings on your browser to either accept all cookies, reject all cookies, or notify you when a cookie is set. Switching off cookies will still allow you to view the majority of content on our site, although it may restrict your use of our interactive tools. There are two types of cookie. Persistent cookies remain on your computer for a specified time. We use persistent cookies to: identify that a registered user has returned collect information for use as described above hold the information given while using an interactive tool on the Website hold your details when you are logged into the site hold your search criteria while you are carrying out a search of the Website collect information for use as described above`,
    `Javascript We use javascript, a simple web scripting language, for interaction with users. We also employ it to collect web usage information for the purposes described above.`,
    `Disclosure of information We may disclose information to government bodies and law enforcement agencies for their enforcement purposes, as may be required by the laws of India. We may also disclose information provided by you to other third parties where this is necessary for the purposes set out in this privacy policy, to Global Technology Interface® Portal Operators and to suppliers that we engage to process your information on our behalf. We will not sell, rent or disclose your information to any third parties other than those set out in this privacy policy without your consent.`,
    `Linking to other websites This Website contains links to many other websites. Anonymous visit or session information may be passed to other websites for the web usage information purposes described above. None of this information can be related to an individual user of the Website. The operators of other sites may collect information from you which will be used by them in accordance with their own data protection policy. This privacy policy applies only to our Website. We are not responsible for the privacy practices within any other websites. You should always be aware of this when you leave this Website and we encourage you to read the privacy statement on any other Website that you visit.`,
  ];
  const data2 = [
    `1. PARTIES These Terms and Conditions, and your acceptance thereof, form a contract between us and you ("You").`,
    `2. COPYRIGHT AND TRADEMARKS The copyright in any material on this Website, and all other product or company names and devices, logos, icons, graphics or designs referred to on this Website are copyright and trade marks of their respective owners and are exhibited only in such a manner as is intended to be for the benefit of such owners and we intend no infringement of such copyright or trade marks. In addition, certain names, words, titles, phrases, logos, icons, graphics or designs on any of the pages of the Website may constitute our unregistered trade marks or trade names. The display of any of the above trade names or trade marks on this Website does not imply that any licence has been granted to You or any other third party in respect of the same. The appearance or absence of products, services, companies, or organisations, or advertising of third parties on this Website does not imply any endorsement or non-endorsement thereof by us. All rights to use any of our trade marks are reserved by us.`,
    `3.DISCLAIMER: CONTENT (NO WARRANTY) The information, materials and functions contained in the Website including text, graphics, links or other items are provided on an "as is" basis. To the fullest extent permitted by applicable law, we exclude all warranties, representations and undertakings whatsoever, express or implied, specifically including but not limited to warranties of satisfactory quality, fitness for a particular purpose, freedom from computer viruses, and non-infringement of third parties' rights. We reserve the right at any time and without notice to change, amend or otherwise vary these Terms and Conditions and the Website. Whilst we endeavour to ensure that the information provided on this Website is correct at the time of publishing, no warranty, express or implied, is given as to its accuracy and as far as is permitted by applicable law we do not accept any liability for any error or omission. Any information published on this Website or in any general information update email or newsletter does not constitute specific advice or recommendation in relation to any intellectual property technology or research and you should consult your professional advisors should you require specific advice.`,
    `4.YOUR USE OF OUR WEBSITE You agree not to attempt to breach any security or privacy mechanisms associated with the any part of our Website. You agree not to use our Website or any part of it to post or transmit any content which is unlawful, threatening, libellous, defamatory, obscene or pornographic. We reserve the right in our absolute discretion to remove or edit content or materials on our Website or any part of it and we will not be liable to You if we do so. We will be entitled to terminate or suspend Your use of the Website or any part of it and / or our service for any reason at any time in the event that the You are in breach of any part of these Terms and Conditions. We refer you to our Privacy Policy for information on how we will collect and use any personal data you provide to us over our Website.`,
    `5.DISCLAIMER: LIABILITY We will not be liable for any direct or indirect, special, incidental or consequential loss or damage whatsoever or for loss of profits, contracts, data, goodwill, work stoppage or computer failure resulting from the use of or inability to use this Website, any interruption or availability of this Website, its operation or transmission, computer viruses or otherwise in respect of the use of the Website and howsoever caused even if we have been advised of the possibility of such damages. We exclude any liability for any errors or omissions from information, materials and functions included in this Website except to the extent that such liability may not be lawfully excluded under any applicable law. To the fullest extent permitted by applicable law, we disclaim all such liability arising from our employees', partners’ or subcontractors' negligence. We are not responsible for nor shall we be liable for the content of other websites or home pages which are linked to this Website. We will have no liability in respect of material hyperlinked to this Website which may be misleading, inaccurate, defamatory, threatening or obscene or otherwise not in accordance with such regulations as may be from time to time in force. The provision by us of a link to another Website from this Website does not constitute any authorisation by us to You to access materials held at that location, nor is it evidence of any endorsement by us of the material held there.`,
    `6.CHANGES TO THIS POLICY This privacy policy will change from time to time as we develop the range of services on the Website. When this privacy policy changes, we will place an updated version on this page and where appropriate we will give you the opportunity to review the revised privacy policy and ask you to agree to it. Regularly reviewing this page ensures that you are always aware of what information we collect, how we use it and under what circumstances, if any, we will share it with other parties. You should continue to check the privacy policy before using this site, even if you have visited it in the past. This ensures that you will be aware of any changes.`,
    `7.YOUR RIGHTS If your use of this site falls under the Data Protection Act as a personal user, you have a right to request a copy of any information held about you. If you wish to do this, please contact us, explaining that your interest is to review your personal information. Except where the law obliges us to keep your data, Exemplas and St John’s Innovation Centre will ensure that your data is gathered and/or purged from our servers. We will need to receive appropriate identification that proves you are the correct person to request the information.`,
    `8.GOVERNING LAW AND JURISDICTION These Terms and Conditions shall be governed by and construed in accordance with Indian law. You agree to submit to the exclusive jurisdiction of the Indian courts. You are responsible for compliance with any applicable laws of the country from which you are accessing this Website. BY USING THIS WEBSITE YOU WILL BE DEEMED TO HAVE READ, UNDERSTOOD, ACCEPTED AND AGREED TO BEING BOUND BY THE FOREGOING TERMS AND CONDITIONS OF USE.`,
  ];

  return (
    <div className="flex flex-col items-center px-12 py-16">
      <Helmet>
        <title>{title.TERMS_AND_CONDITIONS}</title>
        <meta
          name="description"
          key="description"
          content={metaData.TERMS_AND_CONDITIONS}
        />
        <meta name="title" key="title" content={title.TERMS_AND_CONDITIONS} />
        <meta property="og:title" content={title.TERMS_AND_CONDITIONS} />
        <meta
          property="og:description"
          content={metaData.TERMS_AND_CONDITIONS}
        />
        <meta property="og:image" content={globe} />
        <meta
          property="og:url"
          content={`${process.env.REACT_APP_BASE_URL}/termsconditioins`}
        />
        <meta property="og:type" content="website" />
        <meta name="twitter:title" content={title?.TERMS_AND_CONDITIONS} />
        <meta
          name="twitter:description"
          content={metaData?.TERMS_AND_CONDITIONS}
        />
        <meta name="twitter:image" content={globe} />
        <meta name="twitter:card" content={title?.TERMS_AND_CONDITIONS} />
      </Helmet>
      <h1 className="text-3xl font-semibold mb-2 font-pj-sans">
        Terms & Conditions
      </h1>
      <p className="mb-8">The Global Technology Interface®</p>
      <div className="flex flex-col md:flex-row gap-6">
        <div className="flex flex-col gap-2 w-full min-w-[50%]">
          {data1.map((item, index) => (
            <p key={index} className="text-sm text-gray-700 font-dm-sans">
              {item}
            </p>
          ))}
        </div>
        <div className="flex flex-col gap-2 w-full min-w-[50%]">
          {data2.map((item, index) => (
            <p key={index} className="text-sm text-gray-700 font-dm-sans">
              {item}
            </p>
          ))}
        </div>
      </div>
    </div>
  );
};

export default TermsCondition;
