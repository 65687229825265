import { AxiosInstance } from ".";
import { STORAGE_KEY } from "../Components/constants";

interface IPostHelpdesk {
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string;
  message: string;
}

export const postHelpdesk = async (data: IPostHelpdesk) => {
  try {
    const userData: string =
      localStorage.getItem(STORAGE_KEY)?.split(" ")[0] ?? "";
    const config = {
      headers: {
        Authorization: `Bearer ${userData}`,
      },
    };
    const response = await AxiosInstance.post(
      `/contact-us/helpdesk`,
      data,
      config
    );
    return response;
  } catch (err: any) {
    // console.log("POST postHelpdesk ERR", err);
    return err?.response;
  }
};
