import { Editor } from "@tinymce/tinymce-react";
import { useFormik } from "formik";
import React, { Dispatch, useEffect, useRef, useState } from "react";
import { getSubSectorBySector } from "../../store/actioncreators/sub-sectoractions";
import { useDispatch } from "react-redux";
import {
  CONTENT_TYPE,
  FILE_PATH,
  FILE_TYPE,
  MyFormValues,
  developmentStage,
  files,
  iprStatus,
  presignedData,
  productItem,
  sectorItem,
  subsectorItem,
} from "../constants";
import { useSelector } from "react-redux";
import { productSchema } from "../validations/productValidations";
import { RequestMethods } from "../../shared/RequestMethods";
import axios from "axios";
import {
  failToast,
  successToast,
} from "../../store/actioncreators/toastactions";
import { notify } from "../../utils";
import {
  createProduct,
  createUserProduct,
} from "../../store/actioncreators/productactions";
import createProductLogo from "../../assests/images/signup/create_product.svg";
import {
  getSector,
  getSectors,
} from "../../store/actioncreators/sectoractions";
import { Spinner } from "../utils/loader";

const TechnologyDetails = ({
  handleTechnologySubmit,
  handleSuccessModal,
}: {
  handleTechnologySubmit: () => Promise<string>;
  handleSuccessModal: (isOpen: boolean, state: string, message: string) => void;
}) => {
  const dispatch: Dispatch<any> = useDispatch();
  const [sectors, setSectors] = useState<sectorItem[]>([]);
  const docInputRef = useRef<HTMLInputElement>(null);
  const vidInputRef = useRef<HTMLInputElement>(null);

  const handleFetchSectors = async () => {
    const fetchedSectors = await getSectors();
    setSectors(fetchedSectors);
  };

  useEffect(() => {
    handleFetchSectors();
  }, []);

  const subsectorlist: SUB_SECTOR = useSelector(
    (state: STATE) => state.SUB_SECTOR.SUB_SECTOR
  );
  const [iprStatusCheckbox, setIprStatusCheckbox] = useState(iprStatus);

  const initialValues: MyFormValues = {
    name: "",
    description: "",
    sectorId: sectors.length ? sectors[0]._id : "",
    subSectorId: "",
    developmentStage: developmentStage[0].value,
    iprStatus: [],
  };

  const [files, setFiles] = useState<files>({
    image: false,
    document: false,
    imageFile: [],
    documents: [],
    videos: [],
  });

  const fetchSubsector = async (sectorId: string, currentId: string) => {
    if (currentId !== sectorId || currentId === "")
      dispatch(getSubSectorBySector(sectorId));
  };

  useEffect(() => {
    if (sectors?.length) {
      fetchSubsector(sectors[0]?._id, "");
    }
  }, [sectors]);

  const getPresigned = async (content: presignedData) => {
    const data = JSON.stringify(content);
    let result: string = "";
    const config = {
      method: RequestMethods.POST,
      url: `${process.env.REACT_APP_BASE_API}/users/getPresignedUrl`,
      headers: {
        "Content-Type": "application/json",
      },
      data,
    };

    await axios(config)
      .then(function (response) {
        result = response.data;
      })
      .catch(function (error) {
        result = error.message;
        dispatch(failToast());
      });

    return result;
  };

  const postLogo = async (signed: string, imageFile: File) => {
    var config = {
      method: RequestMethods.PUT,
      url: signed,
      headers: {
        "Content-Type": CONTENT_TYPE,
        "Access-Control-Allow-Origin": true,
      },
      data: imageFile,
    };

    await axios(config)
      .then(function (response) {
        dispatch(successToast());
      })
      .catch(function (error) {});
  };

  const uploadVideo = async (selectedFile: any, presignedUrl: string) => {
    if (!selectedFile) {
      return;
    }

    try {
      await axios.put(presignedUrl, selectedFile, {
        headers: {
          "Content-Type": selectedFile.type,
          "Access-Control-Allow-Origin": true,
        },
      });
    } catch (error) {
      notify("Failed to upload video", "error");
    }
  };

  const postDocument = async (signed: string, file: File) => {
    var config = {
      method: RequestMethods.PUT,
      url: signed,
      headers: {
        "Content-Type": file.type,
        "Access-Control-Allow-Origin": true,
      },
      data: file,
    };

    await axios(config)
      .then(function (response) {})
      .catch(function (error) {});
  };

  const getIprStatus = () => {
    const iprStatus = [];
    for (let i = 0; i < iprStatusCheckbox.length; i++) {
      if (iprStatusCheckbox[i].checked) {
        iprStatus.push(iprStatusCheckbox[i].value);
      }
    }
    return iprStatus;
  };

  function handleIprStatus(id: Number) {
    const updatedOptions = iprStatusCheckbox.map((option) => {
      if (option.id === id) {
        return { ...option, checked: !option.checked };
      }
      return option;
    });
    setIprStatusCheckbox(updatedOptions);
  }

  const handleDocuments = function (e: React.ChangeEvent<HTMLInputElement>) {
    const fileList = e.target.files || [];
    if (!fileList) return;

    const documents = [];
    const totalFiles = fileList?.length || 0;
    for (let i = 0; i < totalFiles; i++) {
      documents.push(fileList[i]);
    }

    setFiles({ ...files, documents, document: false });
  };

  const handleImage = function (e: React.ChangeEvent<HTMLInputElement>) {
    const fileList = e.target.files || [];
    if (!fileList) return;

    const images = [];
    const totalFiles = fileList?.length || 0;
    for (let i = 0; i < totalFiles; i++) {
      images.push(fileList[i]);
    }

    setFiles({ ...files, imageFile: images, image: false });
  };

  const handleVideo = (e: React.ChangeEvent<HTMLInputElement>) => {
    const fileList = e?.target.files || [];
    if (!fileList) return;

    const videos = [];
    const totalFiles = fileList?.length || 0;
    for (let i = 0; i < totalFiles; i++) {
      const fileSizeInMB = fileList[i].size / (1024 * 1024); // Convert file size to MB
      if (fileSizeInMB <= 50) {
        videos.push(fileList[i]);
      } else {
        alert("Please select a file smaller than 50 MB.");
      }
    }

    setFiles({ ...files, videos });
  };

  const handleCreate = async (values: MyFormValues) => {
    try {
      handleSuccessModal(true, "LOADING", "Please Wait");

      const userId = await handleTechnologySubmit();
      if (!userId) return;

      if (!files.imageFile) {
        return setFiles({ ...files, image: true });
      }
      setFiles({ ...files, document: false, image: false });
      let productImages: string[] = [];
      let videos: string[] = [];
      let documents: string[] = [];
      for (const file of files.imageFile) {
        const signedLogoData: presignedData = {
          fileName: file.name ?? values.name,
          filePath: FILE_PATH.PRODUCTS_IMAGE,
          fileType: FILE_TYPE.PNG,
        };
        let imageUrl = await getPresigned(signedLogoData);
        await postLogo(imageUrl, file);
        productImages.push(imageUrl.split("?")[0]);
      }
      for (const file of files.videos) {
        const videoData: presignedData = {
          fileName: file.name ?? values.name,
          filePath: FILE_PATH.PRODUCTS_VIDEO,
          fileType: file.type ?? "",
        };
        let videoUrl = await getPresigned(videoData);
        await uploadVideo(file, videoUrl);
        videos.push(videoUrl.split("?")[0]);
      }
      for (const file of files.documents) {
        const signedDocumentData: presignedData = {
          fileName: file.name || values.name,
          filePath: FILE_PATH.COMPANY_DOCS,
          fileType: FILE_TYPE.PDF,
        };
        let documentUrl = await getPresigned(signedDocumentData);
        await postDocument(documentUrl, file);
        documents.push(documentUrl.split("?")[0]);
      }
      const data: productItem = {
        name: values.name,
        description: values.description,
        images: productImages,
        sectorId: values.sectorId,
        subSectorId: values.subSectorId,
        developmentStage: values.developmentStage,
        iprStatus: getIprStatus(),
        videos,
        documents,
      };
      dispatch(createUserProduct(data, userId));

      handleSuccessModal(
        true,
        "SUCCESS",
        "An email has been sent on your mail address, please verify it within 24 hours."
      );
    } catch (err) {
      handleSuccessModal(true, "ERROR", "Failed to Register");
      notify("Failed to create product!", "error");
    }
  };

  const technologyformik = useFormik<MyFormValues>({
    initialValues: initialValues,
    validationSchema: productSchema,
    onSubmit: (values, formikHelpers) => {
      handleCreate(values);
    },
  });

  return (
    <div className="flex items-center justify-center mt-10">
      <div
        className={
          "flex relative w-10/12 justify-center md:space-x-4 duration-200 mb-10"
        }
      >
        <div className="hidden md:flex flex-col items-center justify-start w-6/12 mb-10 ">
          <p className="text-4xl my-5 font-roboto">Product Details</p>
          <p className="text-base p-2 font-roboto border rounded-xl w-11/12">
            Displayer users can upload their technologies or products to
            showcase them to a global audience. This allows them to connect with
            potential investors, collaborators, and partners, enhancing
            visibility and market reach.
          </p>
          <div className="flex flex-col w-5/6 h-2/3 items-center">
            <img
              src={createProductLogo}
              alt="Company Details"
              className="w-full h-4/5"
            />
          </div>
        </div>
        <div className="flex flex-col h-full md:w-6/12 items-center">
          <div className="flex justify-center w-full items-center">
            <form
              onSubmit={technologyformik.handleSubmit}
              className="flex flex-col  space-y-4 justify-center items-center"
            >
              <div className="flex flex-col w-full space-x-2 relative">
                <div className="relative">
                  <input
                    onChange={(e) =>
                      technologyformik.setFieldValue("name", e.target.value)
                    }
                    type="text"
                    id="floating_outlined"
                    className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                    placeholder=" "
                  />
                  <label
                    htmlFor="floating_outlined"
                    className="absolute text-sm text-gray-500  duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white  px-2 peer-focus:px-2 peer-focus:text-blue-600  peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1"
                  >
                    Product/Service Name
                  </label>
                </div>
                {technologyformik.errors.name && (
                  <p
                    id="filled_error_help"
                    className="mt-2 text-xs text-red-600 dark:text-red-400"
                  >
                    {technologyformik.errors.name}
                  </p>
                )}
              </div>
              <div className="flex flex-col w-full space-x-2 relative">
                <div className="relative">
                  <Editor
                    apiKey={process.env.REACT_APP_TINYMCE_API_KEY}
                    init={{
                      height: 200,
                      menubar: false,
                      plugins: [
                        "advlist autolink lists link image charmap print preview anchor",
                        "searchreplace visualblocks code fullscreen",
                        "insertdatetime media table paste code help wordcount",
                      ],
                      toolbar:
                        "undo redo | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | help",
                      placeholder: "Write your product/service description here...",
                    }}
                    onEditorChange={(e) =>
                      technologyformik.setFieldValue("description", e)
                    }
                  />
                </div>
                {technologyformik.errors.description && (
                  <p
                    id="filled_error_help"
                    className="mt-2 text-xs text-red-600 dark:text-red-400"
                  >
                    {technologyformik.errors.description}
                  </p>
                )}
              </div>
              <div className="flex flex-col w-full">
                <div className="flex flex-row w-full space-x-5 items-center">
                  <h3 className="font-robot text-gray-800 text-sm whitespace-nowrap  ">
                    Sector Type:
                  </h3>
                  <select
                    onChange={(e) => {
                      fetchSubsector(
                        e.target.value,
                        technologyformik.values.sectorId
                      );
                      technologyformik.setFieldValue(
                        "sectorId",
                        e.target.value
                      );
                    }}
                    defaultValue={technologyformik.values.sectorId}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
                  >
                    <option>Select Sector</option>
                    {sectors.map((item: sectorItem, id) => {
                      return (
                        <option key={id} value={item._id}>
                          {item.name}
                        </option>
                      );
                    })}
                  </select>
                </div>
                {technologyformik.errors.sectorId && (
                  <p
                    id="filled_error_help"
                    className="mt-2 text-xs text-red-600 dark:text-red-400"
                  >
                    {technologyformik.errors.sectorId}
                  </p>
                )}
              </div>
              {subsectorlist.SUB_SECTOR_LIST?.length ? (
                <div className="flex flex-col w-full">
                  <div className="flex flex-row w-full space-x-5 items-center">
                    <h3 className="font-robot text-gray-800 text-sm whitespace-nowrap  ">
                      Sub Sector Type:
                    </h3>
                    <select
                      onChange={(e) =>
                        technologyformik.setFieldValue(
                          "subSectorId",
                          e.target.value
                        )
                      }
                      defaultValue={initialValues.subSectorId}
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
                    >
                      <option>Select Sub Sector</option>
                      {subsectorlist.SUB_SECTOR_LIST.map(
                        (item: subsectorItem, id) => {
                          return (
                            <option key={id} value={item._id}>
                              {item.name}
                            </option>
                          );
                        }
                      )}
                    </select>
                  </div>
                </div>
              ) : null}
              <div className="flex flex-col w-full">
                <div className="flex flex-row w-full space-x-5 items-center">
                  <h3 className="font-robot text-gray-800 text-sm whitespace-nowrap  ">
                    Development Stage:
                  </h3>
                  <select
                    onChange={(e) =>
                      technologyformik.setFieldValue(
                        "developmentStage",
                        e.target.value
                      )
                    }
                    defaultValue={technologyformik.values.developmentStage}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
                  >
                    {developmentStage.map((stage, id) => {
                      return (
                        <option key={id} value={stage.value}>
                          {stage.value}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
              <div className="flex flex-col w-full space-x-2 relative">
                <div className="relative">
                  <div className="relative mb-3">
                    <label className="profile-content-head-2">IPR Status</label>
                  </div>
                  <div className="grid grid-cols-1 gap-4 md:grid-cols-3">
                    {iprStatusCheckbox.map((option) => (
                      <label
                        className="flex items-center space-x-2 ml-4"
                        key={option.id}
                      >
                        <input
                          type="checkbox"
                          className="h-4 w-4 text-indigo-600 rounded border-gray-300 focus:ring-indigo-500"
                          checked={option.checked}
                          onChange={() => handleIprStatus(option.id)}
                        />
                        <span className="text-gray-700 personal-input">
                          {option.value}
                        </span>
                      </label>
                    ))}
                  </div>
                </div>
              </div>

              <div className="flex flex-col w-full">
                <label
                  className="block mb-2 text-sm font-medium text-gray-900"
                  htmlFor="logo"
                >
                  Product Image&nbsp;
                  <span className="text-red-500 text-xs">(.png only)</span>
                </label>
                <input
                  onChange={handleImage}
                  accept=".png"
                  type="file"
                  id="logo"
                  aria-label="company-logo"
                  className="modal-input"
                  placeholder=""
                  multiple
                  max={3}
                />
                <p
                  id="filled_error_help"
                  className={
                    "mt-2 text-xs text-red-600 dark:text-red-400 " +
                    (!files.image ? "hidden" : "")
                  }
                >
                  {"Please upload product Image"}
                </p>
              </div>

              <div className="flex flex-col w-full relative">
                <label
                  className="block mb-2 text-sm font-medium text-gray-900"
                  htmlFor="documents"
                >
                  Product Documents <i>(Optional)</i>&nbsp;
                  <span className="text-red-500 text-xs">(.pdf only)</span>
                </label>
                <input
                  onChange={handleDocuments}
                  accept=".pdf"
                  type="file"
                  id="documents"
                  ref={docInputRef}
                  aria-label="company-documents"
                  className="modal-input"
                  placeholder="Click to upload Document"
                  multiple
                  max={3}
                />
              </div>

              <div className="flex flex-col w-full relative">
                <label
                  className="block mb-2 text-sm font-medium text-gray-900"
                  htmlFor="video"
                >
                  Product Video <i>(Optional)</i>&nbsp;
                  <span className="text-red-500 text-xs">
                    (.mp4 /.webm only)
                  </span>
                </label>
                <input
                  onChange={handleVideo}
                  type="file"
                  accept=".mp4, .webm"
                  id="video"
                  ref={vidInputRef}
                  aria-label="product-video"
                  className="modal-input"
                  placeholder="Click to upload Video"
                  multiple
                  max={3}
                />
              </div>
              <button
                type="submit"
                className="flex w-full justify-center text-GTI-BLUE-default hover:text-white border border-GTI-BLUE-default  hover:bg-GTI-BLUE-default focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2"
              >
                SUBMIT
                {/* {loader.SPINNER && <Spinner />} */}
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TechnologyDetails;
