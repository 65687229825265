import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { Helmet } from "react-helmet";

import { newsFetched } from "../constants";
import articlebanner from "../../assests/banners/articlebanner.png";

const NewsSingle = ({ handleLoginModal }: { handleLoginModal: () => void }) => {
  let [news, setNews] = useState<newsFetched>({
    _id: "",
    title: "",
    link: "",
    shortDescription: "",
    description: "",
    imageUrl: "",
    author: "",
    metaDescription: "",
    enclosure: "",
    media: "",
    mediaCaptionUrl: "",
    mediaCaptionText: "",
    tags: [],
    partnersite: [],
    displayOnHomePage: true,
    isDeleted: false,
    createdAt: "",
    updatedAt: "",
  });

  const { id } = useParams();

  const loadNews = (id: string) => {
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_BASE_API}/news/${id}`,
      headers: {
        "Content-Type": "application/json",
      },
    };

    axios(config)
      .then(function (response) {
        setNews(response.data);
      })
      .catch(function (error) {});
  };

  useEffect(() => {
    loadNews(id ?? "");
  }, [id]);
  const DOC = new Date(news.createdAt);

  return (
    <React.Fragment>
      <div
        className="flex flex-col w-full items-center bg-slate-100"
        style={{ msOverflowStyle: "none", scrollbarWidth: "none" }}
      >
        <Helmet>
          <title>{news.title}</title>
          <meta
            name="description"
            key="description"
            content={news.shortDescription}
          />
          <meta name="title" key="title" content={news.title} />
          <meta property="og:title" content={news.title} />
          <meta property="og:description" content={`${news.description}`} />
          <meta property="og:image" content={news.imageUrl} />
          <meta
            property="og:url"
            content={`${process.env.REACT_APP_BASE_URL}/news/${news._id}`}
          />
          <meta property="og:type" content="website" />
          <meta name="twitter:title" content={news.title} />
          <meta name="twitter:description" content={news.description} />
          <meta name="twitter:image" content={news.imageUrl} />
          <meta name="twitter:card" content={news.title} />
        </Helmet>
        <div className="flex flex-col space-y-5 bg-white p-6 ">
          <h1 className="text-xl md:text-4xl mt-3 w-full pb-6 font-roboto text-center font-bold">
            {news.title}
          </h1>
          <div className="flex flex-col md:flex-row w-full h-fit justify-items-start space-x-6">
            {news.imageUrl ? (
              <div className="flex flex-col md:w-3/4">
                <img
                  src={news.imageUrl ? news.imageUrl : articlebanner}
                  className="flex max-w-full h-auto rounded object-contain"
                  alt={news.title}
                  loading="lazy"
                />
                <div className="flex md:block flex-row-end text-black-500 leading-5 text-xs">
                  <h4>{news.mediaCaptionText}</h4>
                </div>
              </div>
            ) : null}
            <div className="flex flex-col mt-5 md:w-2/3 ">
              <h4 className="font-bold text-sm md:text-lg font-popins">
                {news.shortDescription}
              </h4>
              <h4 className="text-gray-500 text-xs">
                {DOC.toLocaleString("default", {
                  month: "short",
                  day: "2-digit",
                  year: "numeric",
                })}
              </h4>
              <div className="flex flex-wrap gap-2 mt-5">
                {news?.partnersite?.map((status, index) => (
                  <button
                    key={index}
                    className="bg-gray-300  text-black py-2 px-2 rounded inline-flex text-xs"
                  >
                    {status}
                  </button>
                ))}
              </div>
              <div className="flex flex-wrap gap-2 mt-5">
                {news?.tags?.map((status, index) => (
                  <button
                    key={index}
                    className="bg-gray-300  text-black py-2 px-2 rounded inline-flex text-xs"
                  >
                    {status}
                  </button>
                ))}
              </div>
            </div>
          </div>

          <div dangerouslySetInnerHTML={{ __html: news.description }}></div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default NewsSingle;
