import React, { useEffect, useRef } from "react";
import { SocialMediaDetailsType } from "../constants";

interface SocialMediaDetailsProps {
  setSocialMediaDetails: (e: boolean) => void;
  logo: string;
  socialMediaDetails: SocialMediaDetailsType[];
}

const SocialMediaDetails: React.FC<SocialMediaDetailsProps> = ({
  setSocialMediaDetails,
  logo,
  socialMediaDetails,
}) => {
  const modalRef = useRef<HTMLDivElement>(null);

  const handleClickOutside = (event: MouseEvent) => {
    if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
      setSocialMediaDetails(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 bg-slate-700 bg-opacity-70">
      <div
        ref={modalRef}
        className="bg-white p-6 rounded shadow-lg w-96 relative"
      >
        <button
          onClick={() => setSocialMediaDetails(false)}
          className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
        >
          X
        </button>
        <img src={logo} alt="Logo" className="w-20 mx-auto mb-4" />
        {socialMediaDetails.map((details, index) => (
          <a
            key={index}
            href={details?.link}
            target="_blank"
            className="flex items-center mb-4"
          >
            <img src={logo} alt={details?.type} className="w-8 h-8 mr-2" />
            <h3 className="text-lg hover:underline">{details?.text}</h3>
          </a>
        ))}
      </div>
    </div>
  );
};

export default SocialMediaDetails;
