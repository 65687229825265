import { AxiosInstance } from ".";
import { STORAGE_KEY } from "../Components/constants";

export enum PROFILE_TYPES {
  DISPLAYER = "DISPLAYER",
  SCOUTER = "SCOUTER",
  GENERAL_SUBSCRIBER = "GENERAL_SUBSCRIBER",
}

interface ICreateUser {
  userType: PROFILE_TYPES;
  fullName: string;
  email: string;
  countryCode: string;
  phoneNumber: string;
  referenceCode: string;
  password: string;
  companyDetails?: {
    name: string;
    logo: string;
    description: string;
    address: string;
    website: string;
    country: string;
    companyTurnover: string;
  };
  companyDocuments?: Array<{
    document: string;
    documentType: string;
  }>;
}

interface IForgotPassword {
  password: string;
  token?: string;
}

export const checkIsConnected = async (id: string) => {
  try {
    const userData: string =
      localStorage.getItem(STORAGE_KEY)?.split(" ")[0] ?? "";
    const config = {
      headers: {
        Authorization: `Bearer ${userData}`,
      },
    };
    const response = await AxiosInstance.get(
      `/users/checkisconnected/${id}`,
      config
    );
    return response;
  } catch (err: any) {
    // console.log("GET ERR", err);
    return err?.response;
  }
};

export const acceptConnection = async (userId: string) => {
  try {
    const userData: string =
      localStorage.getItem(STORAGE_KEY)?.split(" ")[0] ?? "";

    const config = {
      headers: {
        Authorization: `Bearer ${userData}`,
      },
    };
    const response = await AxiosInstance.patch(
      `/users/acceptconnectionrequest/${userId}`,
      {},
      config
    );
    return response;
  } catch (err: any) {
    // console.log("PATCH ERR", err);
    return err?.response;
  }
};

export const getAllNotificationApi = async (skip: string, limit: string) => {
  try {
    const userData: string =
      localStorage.getItem(STORAGE_KEY)?.split(" ")[0] ?? "";
    const config = {
      headers: {
        Authorization: `Bearer ${userData}`,
      },
    };

    let response;

    if (userData) {
      response = await AxiosInstance.get(
        `/notifications?skip=${skip}&limit=${limit}`,
        config
      );
    }

    return response;
  } catch (err: any) {
    // console.log("GET ERR", err);
    return err?.response;
  }
};

export const markNotification = async () => {
  try {
    const userData: string =
      localStorage.getItem(STORAGE_KEY)?.split(" ")[0] ?? "";
    const config = {
      headers: {
        Authorization: `Bearer ${userData}`,
      },
    };
    const response = await AxiosInstance.patch(`/notifications`, {}, config);
    return response;
  } catch (err: any) {
    // console.log("PATCH ERR", err);
    return err?.response;
  }
};
export const notificationCount = async () => {
  try {
    const userData: string =
      localStorage.getItem(STORAGE_KEY)?.split(" ")[0] ?? "";
    const config = {
      headers: {
        Authorization: `Bearer ${userData}`,
      },
    };

    let response;

    if (userData) {
      response = await AxiosInstance.get(`/notifications/getcount`, config);
    }

    return response;
  } catch (err: any) {
    // console.log("PATCH ERR", err);
    return err?.response;
  }
};

export const getUserData = async (id: string) => {
  try {
    const userData: string =
      localStorage.getItem(STORAGE_KEY)?.split(" ")[0] ?? "";
    const config = {
      headers: {
        Authorization: `Bearer ${userData}`,
      },
    };
    const response = await AxiosInstance.get(`/users/${id}`, config);
    return response;
  } catch (err: any) {
    // console.log("GET ERR", err);
    return err?.response;
  }
};

export const createUser = async (data: ICreateUser) => {
  try {
    const userData: string =
      localStorage.getItem(STORAGE_KEY)?.split(" ")[0] ?? "";
    const config = {
      headers: {
        Authorization: `Bearer ${userData}`,
      },
    };
    const response = await AxiosInstance.post(`/users`, data, config);
    return response;
  } catch (err: any) {
    return err?.response;
  }
};

export const verifyEmail = async (token: string) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const response = await AxiosInstance.patch(
      `/users/verifyemail`,
      {},
      config
    );
    return response;
  } catch (err: any) {
    // console.log("PATCH ERR", err);
    return err?.response;
  }
};

export const forgotPassword = async (data: IForgotPassword) => {
  try {
    const { token, ...body } = data;
    if (!token) return;
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const response = await AxiosInstance.patch(
      `/users/forgetpassword`,
      body,
      config
    );
    return response;
  } catch (err: any) {
    // console.log("PATCH ERR", err);
    return err?.response;
  }
};
