import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { NONE, oppotunityItemFullFetched } from "../constants";
import axios from "axios";
import Slider from "react-slick";
import { BiLinkExternal } from "react-icons/bi";
import ReactCountryFlag from "react-country-flag";

import productbanner from "../../assests/banners/product_banner_alt.png";
import { store } from "../../store";
import PdfDownloader from "../../shared/PdfDownloader";
import RenderHTML from "../utils/RenderHTML";
import companyLogo from "../../assests/banners/company_logo.png";
import { useSelector } from "react-redux";
import { Helmet } from "react-helmet";

const YourOpportunity = ({
  handleLoginModal,
}: {
  handleLoginModal: () => void;
}) => {
  const sidebar_carousal_settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    swipeToSlide: true,
    autoplay: false,
  };

  let [opp, setOpp] = useState<oppotunityItemFullFetched>({
    _id: "",
    technologyPartnerRequirement: "",
    description: "",
    image: "",
    displayOnHomePage: false,
    isApprovedBySubAdmin: false,
    isApprovedByAdmin: false,
    isRejected: false,
    document: "",
    sectorId: "",
    subSectorId: "",
    userId: "",
    createdAt: "",
    documents: [],
    images: [],
    __v: -1,
    users: {
      _id: "",
      fullName: "",
      email: "",
      phoneNumber: "",
      countryCode: "",
      referenceCode: "",
      isEmailVerified: false,
      isUserVerified: false,
      isRejected: false,
      password: "",
      userRole: -1,
      userType: "",
      companyId: "",
      follower: [],
      following: [],
      connections: [
        {
          connectionStatus: "",
          userId: "",
        },
      ],
      createdAt: "",
      __v: -1,
    },
    company: {
      _id: "",
      name: "",
      logo: "",
      description: "",
      address: "",
      website: "",
      country: "",
      companyTurnover: "",
      companyId: "",
      typeAndSizeOfPartnersRequired: [],
      typesOfPartnershipConsidered: [],
      createdAt: "",
      iprStatus: [],
      __v: -1,
    },
    sectors: {
      _id: "",
      name: "",
      slug: "",
      image: "",
      createdAt: "",
      __v: -1,
    },
    subsectors: {
      _id: "",
      name: "",
      slug: "",
      sectorId: "",
      createdAt: "",
      __v: -1,
    },
  });
  const user: USER = useSelector((state: STATE) => state.USER.USER);

  const [following, setFollowing] = useState(false);
  const [connected, setConnection] = useState(false);

  const loadProduct = (id: string) => {
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_BASE_API}/opportunities/${id}`,
      headers: {
        "Content-Type": "application/json",
      },
    };

    axios(config)
      .then(function (response) {
        setOpp(response.data);
      })
      .catch(function (error) {});
  };
  const isFollowing = (userid: string) => {
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_BASE_API}/users/checkisfollowing/${userid}`,
      headers: {
        Authorization: `Bearer ${store.getState().USER.USER.token}`,
        "Content-Type": "application/json",
      },
    };

    axios(config)
      .then(function (response) {
        setFollowing(response.data);
      })
      .catch(function (error) {});
  };
  const isConnected = (userid: string) => {
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_BASE_API}/users/checkisconnected/${userid}`,
      headers: {
        Authorization: `Bearer ${store.getState().USER.USER.token}`,
        "Content-Type": "application/json",
      },
    };

    axios(config)
      .then(function (response) {
        setConnection(response.data);
      })
      .catch(function (error) {});
  };

  const { state } = useLocation();
  useEffect(() => {
    loadProduct(state.product._id);
    isFollowing(state.product.userId);
    isConnected(state.product.userId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const DOC = new Date(opp.createdAt);

  return (
    <React.Fragment>
      <div className="flex flex-col w-full p-10">
        <Helmet>
          <title>{opp?.company.name}</title>
          <meta
            name="description"
            key="description"
            content={opp?.technologyPartnerRequirement}
          />
          <meta name="title" key="title" content={opp?.company?.name} />
          <meta property="og:title" content={opp?.company?.name} />
          <meta
            property="og:description"
            content={opp?.technologyPartnerRequirement}
          />
          <meta
            property="og:image"
            content={opp?.image === NONE ? productbanner : opp?.image}
          />
          <meta
            property="og:url"
            content={`${process.env.REACT_APP_BASE_URL}/your-opportunity`}
          />
          <meta property="og:type" content="website" />
          <meta name="twitter:title" content={opp?.company?.name} />
          <meta
            name="twitter:description"
            content={opp?.technologyPartnerRequirement}
          />
          <meta
            name="twitter:image"
            content={opp?.image === NONE ? productbanner : opp?.image}
          />
          <meta name="twitter:card" content={opp?.company?.name} />
        </Helmet>
        <div className="flex flex-col md:flex-row w-full justify-between space-x-10 ">
          <div className="w-full md:w-3/5 flex flex-col px-10 m-5 space-y-5">
            <p className="w-full font-roboto text-xl text-justify font-bold">
              Technology Requirement
            </p>
            <p className="font-roboto text-sm text-gray-500">
              {opp?.technologyPartnerRequirement?.replace(/(<([^>]+)>)/gi, "")}
            </p>
            <div className="flex flex-row">
              <p className="font-roboto text-sm font-bold">Sector -</p>
              <p className="font-roboto text-sm px-2 text-gray-500">
                {opp.sectors.name}
              </p>
            </div>
            <div className="flex flex-row">
              <p className="font-roboto text-sm font-bold">Sub Sector -</p>
              <p className="font-roboto text-sm px-2 text-gray-500">
                {opp.subsectors.name}
              </p>
            </div>
            <p className="font-roboto text-sm">
              <RenderHTML html={opp.description} />
            </p>
          </div>

          <div className="w-full md:w-2/5 flex flex-col px-10 m-5 space-y-5">
            {opp?.images?.length > 0 || opp?.image ? (
              <Slider
                {...sidebar_carousal_settings}
                className="flex flex-row w-full md:max-h-[330px] text-black border-4 p-2"
              >
                {opp?.images?.length > 0 ? (
                  opp.images.map((image) => (
                    <img
                      className="w-full h-full object-contain max-h-[250px] "
                      src={image}
                      alt={opp.company.name}
                    />
                  ))
                ) : (
                  <img
                    className="w-full h-full object-contain max-h-[250px] "
                    src={opp.image === NONE ? productbanner : opp.image}
                    alt={opp.company.name}
                  />
                )}
              </Slider>
            ) : null}

            <p className="text-gray-400 text-xs font-roboto">
              Posted on:&nbsp;
              {DOC.toLocaleString("default", {
                month: "long",
                day: "2-digit",
                year: "numeric",
              })}
            </p>

            {!!opp?.document ? (
              <div className="flex flex-row gap-4 items-center">
                <PdfDownloader
                  className="flex flex-row items-center gap-2 font-roboto text-sm justify-center"
                  url={opp?.document}
                >
                  <p className="font-roboto text-sm ">Document</p>
                  <BiLinkExternal />
                </PdfDownloader>
              </div>
            ) : null}
            {opp?.documents?.length ? (
              <div className="flex flex-row gap-4 items-center">
                {opp.documents?.map((document) => (
                  <PdfDownloader
                    className="flex flex-row items-center gap-2 font-roboto text-sm justify-center"
                    url={document}
                  >
                    <p className="font-roboto text-sm ">Document</p>
                    <BiLinkExternal />
                  </PdfDownloader>
                ))}
              </div>
            ) : null}
          </div>
        </div>
        <hr />
        <div className="flex flex-col md:flex-row w-full md:space-x-8 py-5 md:py-10">
          <span className="block md:hidden font-roboto text-sm md:text-lg text-black-500 font-bold text-justify pb-5 md:pb-0">
            {opp.company?.name}
            <span className="font-roboto text-sm md:text-lg text-black-500">
              ({opp.company?.country})
            </span>
          </span>

          <div className="md:w-64 md:h-64 flex flex-col px-5 md:px-10 md:mx-5 space-y-5">
            <img
              className="self-center w-1/2 md:w-full h-1/2 md:h-3/5 object-contain shadow-xl"
              src={!opp.company?.logo ? companyLogo : opp.company.logo}
              alt={opp.company?.name}
            />

            <span className="hidden md:block font-roboto text-sm text-black-500 font-bold">
              {opp.company?.name}
              <br />
              <span className="font-roboto text-lg text-black-500">
                {opp?.company?.country ? (
                  <ReactCountryFlag
                    countryCode={opp.company?.country}
                    svg
                    style={{ width: "2em", height: "2em" }}
                    className="mr-2"
                  />
                ) : null}
              </span>
            </span>
          </div>
          <p className="font-roboto text-md text-gray-500 ">
            {opp.company?.description}
          </p>
        </div>
      </div>
    </React.Fragment>
  );
};
export default YourOpportunity;
