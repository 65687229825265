import * as Yup from "yup";

export const productSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Product Name Required"),
  description: Yup.string()
    .min(10, "Too Short Description!")
    // .max(300, "Too Long Description!")
    .required("Product description Required"),
  // ipr: Yup.string()
  //   .min(2, "Too Short!")
  //   .max(20, "Too Long!")
  //   .required("IPR Status Required"),
  sectorId: Yup.string().required("Sector Name Required"),
  // subSectorId: Yup.string().required("Sector Name Required"),
});
