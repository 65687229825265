import * as ACTION from "../actiontypes/actionTypes";

const initialState: LOADER_STATE = {
  LOADER: {
    BACKDROP: false,
    SPINNER: false,
  },
};

export const loaderreducer = (
  state: LOADER_STATE = initialState,
  action: LOADER_ACTION
): LOADER_STATE => {
  switch (action.type) {
    case ACTION.BACKDROP_LOADER_START:
      let l1: LOADER = action.LOADER;
      return { ...state, LOADER: l1 };
    case ACTION.BACKDROP_LOADER_STOP:
      let l2: LOADER = action.LOADER;
      return { ...state, LOADER: l2 };
    case ACTION.SPINNER_LOADER_START:
      let s1: LOADER = action.LOADER;
      return { ...state, LOADER: s1 };
    case ACTION.SPINNER_LOADER_STOP:
      let s2: LOADER = action.LOADER;
      return { ...state, LOADER: s2 };
    default:
      return { ...state };
  }
  // return state;
};
