import React, { Dispatch, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import ReactDOM from "react-dom";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { Form, Formik } from "formik";
import { AiOutlineClose } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";

import featured_button from "../../assests/images/featured/featured-button.svg";
import featured from "../../assests/images/featured/featured.svg";
import instagram from "../../assests/images/featured/instagram.svg";
import facebook from "../../assests/images/featured/facebook.svg";
import linkedin from "../../assests/images/featured/linkedin.svg";
import twitter from "../../assests/images/featured/twitter.svg";
import gti_homepage from "../../assests/images/featured/git-homepage.svg";
import economico_pro from "../../assests/images/featured/economico-pro.svg";
import cafe_europe from "../../assests/images/featured/cafe-europe.svg";
import circular_times from "../../assests/images/featured/circular-times.svg";
import punkt_info from "../../assests/images/featured/punkt-info.svg";
import swiss_trade from "../../assests/images/featured/swiss-trade.svg";
import your_featured from "../../assests/images/featured/your-featured.svg";
import personalised_promotion from "../../assests/images/personalised-promotion.svg";
import promote_profile from "../../assests/images/promote-profile.svg";
import gbi_home_logo from "../../assests/home/logo_home.svg";
import {
  CONTENT_TYPE,
  FEATURED,
  FILE_PATH,
  FILE_TYPE,
  LIMIT,
  SKIP,
  metaData,
  oppotunityItemFullFetched,
  presignedData,
  productItemFullFetched,
  promotionFiles,
  promotionsCreate,
  promotionsProfileCreate,
  title,
} from "../constants";
import { notify } from "../../utils";
import globe from "../../assests/home/globe.png";
import { RequestMethods } from "../../shared/RequestMethods";
import {
  failToast,
  successToast,
} from "../../store/actioncreators/toastactions";
import { promotionsSchema } from "../validations/promotionsValidation";
import SuccessModal from "./SuccessModal";
import { PROFILE_TYPES } from "../../shared/enum";
import { Editor } from "@tinymce/tinymce-react";
import {
  createPromotion,
  getPromotions,
} from "../../store/actioncreators/promotionsaction";
import biotechnology from "../../assests/images/biotechnology.jpg";
import cleantech from "../../assests/images/cleantech.jpg";
import informationTechnology from "../../assests/images/information-technology.jpg";
import { Company, SocialMedium } from "../../shared/constants";
import SocialMediaDetails from "../utils/SocialMediaDetails";
// import RenderRazorpay from "../utils/Razorpay";

const ProfilePromotionModal = ({
  changeModalState,
  handleSuccessModal,
}: {
  changeModalState: () => void;
  handleSuccessModal: (isOpen: boolean, state: string, message: string) => void;
}) => {
  const user: USER = useSelector((state: STATE) => state.USER.USER);
  const navigate = useNavigate();

  useEffect(() => {
    if (!user.id) {
      notify("Unauthorized", "error");
      navigate("/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const initialValues: promotionsCreate = {
    title: "",
    description: "",
    images: [],
  };

  const dispatch: Dispatch<any> = useDispatch();

  const [files, setFiles] = useState<promotionFiles>({
    image: false,
    imageFile: [],
  });

  const handleImage = function (e: React.ChangeEvent<HTMLInputElement>) {
    const fileList = e.target.files || [];
    if (!fileList) return;

    const images = [];
    const totalFiles = fileList?.length || 0;
    for (let i = 0; i < totalFiles; i++) {
      images.push(fileList[i]);
    }

    setFiles({ ...files, imageFile: images, image: false });
  };

  const getPresigned = async (content: presignedData) => {
    const data = JSON.stringify(content);
    let result: string = "";
    const config = {
      method: RequestMethods.POST,
      url: `${process.env.REACT_APP_BASE_API}/users/getPresignedUrl`,
      headers: {
        "Content-Type": "application/json",
      },
      data,
    };

    await axios(config)
      .then(function (response) {
        result = response.data;
      })
      .catch(function (error) {
        result = error.message;
        dispatch(failToast());
      });

    return result;
  };

  const postLogo = async (signed: string, imageFile: File) => {
    var config = {
      method: RequestMethods.PUT,
      url: signed,
      headers: {
        "Content-Type": CONTENT_TYPE,
        "Access-Control-Allow-Origin": true,
      },
      data: imageFile,
    };

    await axios(config)
      .then(function (response) {
        dispatch(successToast());
      })
      .catch(function (error) {});
  };

  const paymentHandler = async (
    amount: number,
    currency: string,
    order_id: string,
    promotionId: string
  ) => {
    let options = {
      key: process.env.REACT_APP_RAZORPAY_KEY_ID,
      amount,
      currency,
      name: Company.NAME,
      description: Company.DESCRIPTION,
      image: gbi_home_logo,
      order_id,
      callback_url: `${process.env.REACT_APP_BASE_API}/payments/validate`,
      prefill: {
        name: user?.user?.name,
        email: user?.user?.email,
      },
      notes: {
        promotionId,
      },
      theme: {
        color: "#3399cc",
      },
    };

    let rzp1 = new (window as any).Razorpay(options);

    rzp1.on("payment.failed", function (response: any) {
      alert("Payment Failed. Please Retry again.");
      navigate("/featured/failed");
    });

    rzp1.open();
  };

  const handleCreate = async (values: promotionsCreate) => {
    try {
      handleSuccessModal(true, "LOADING", "");

      if (!files.imageFile) {
        return setFiles({ ...files, image: true });
      }
      setFiles({ ...files, image: false });

      let promotionImages: string[] = [];

      for (const file of files.imageFile) {
        const signedLogoData: presignedData = {
          fileName: file.name ?? values.title,
          filePath: FILE_PATH.PROMOTIONS_IMAGE,
          fileType: FILE_TYPE.PNG,
        };

        let imageUrl = await getPresigned(signedLogoData);
        await postLogo(imageUrl, file);
        promotionImages.push(imageUrl.split("?")[0]);
      }

      const data: promotionsCreate = {
        title: values.title,
        description: values.description,
        images: promotionImages,
      };

      const { order_id, currency, amount, promotionId } = await createPromotion(
        data
      );

      handleSuccessModal(false, "LOADING", "");

      paymentHandler(amount, currency, order_id, promotionId);

      // handleSuccessModal(
      //   true,
      //   "SUCCESS",
      //   "Featured has been created successfully, it will be reviewed and approved by Admin."
      // );
      // changeModalState();
    } catch (err) {
      // handleSuccessModal(
      //   true,
      //   "ERROR",
      //   "There was an error while creating the Featured"
      // );
      notify("Failed to create Featured!", "error");
    }
  };

  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "auto";
    };
  }, []);

  const content = (
    <div className="z-10 pb-[200px] pt-4 fixed w-full h-screen bg-slate-700 bg-opacity-70 top-0 left-0 flex justify-center overflow-auto">
      <div className="product-modal-main relative">
        <div className="flex">
          <h4 className="text-lg font-roboto font-bold">Create Featured</h4>
          <button
            onClick={() => {
              changeModalState();
            }}
            className="absolute right-0 top-0 font-bold hover:text-red-500 border border-slate-100 px-3 py-1 rounded"
          >
            <AiOutlineClose />
          </button>
        </div>
        <Formik
          initialValues={initialValues}
          validationSchema={promotionsSchema}
          onSubmit={(values) => handleCreate(values)}
        >
          {({ handleChange, setFieldValue, handleSubmit, errors, values }) => {
            return (
              <>
                <Form className="flex flex-col w-full space-y-4 justify-center items-center">
                  <div className="flex flex-col w-full space-x-2 relative">
                    <div className="relative">
                      <input
                        onChange={(e) => setFieldValue("title", e.target.value)}
                        type="text"
                        id="floating_outlined"
                        className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                        placeholder=" "
                      />
                      <label
                        htmlFor="floating_outlined"
                        className="absolute text-sm text-gray-500  duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white  px-2 peer-focus:px-2 peer-focus:text-blue-600  peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1"
                      >
                        Featured Title
                      </label>
                    </div>
                  </div>

                  <div className="flex flex-col w-full space-x-2 relative">
                    <div className="relative">
                      <Editor
                        apiKey={process.env.REACT_APP_TINYMCE_API_KEY}
                        init={{
                          height: 200,
                          menubar: false,
                          plugins: [
                            "advlist autolink lists link image charmap print preview anchor",
                            "searchreplace visualblocks code fullscreen",
                            "insertdatetime media table paste code help wordcount",
                          ],
                          toolbar:
                            "undo redo | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | help",
                          placeholder:
                            "Write your featured description here...",
                        }}
                        onEditorChange={(e) => setFieldValue("description", e)}
                      />
                    </div>
                    {errors.description && (
                      <p
                        id="filled_error_help"
                        className="mt-2 text-xs text-red-600 dark:text-red-400"
                      >
                        {errors.description}
                      </p>
                    )}
                  </div>

                  <div className="flex flex-col w-full">
                    <label
                      className="block mb-2 text-sm font-medium text-gray-900"
                      htmlFor="logo"
                    >
                      Upload Featured Image&nbsp;
                      <span className="text-red-500 text-xs">(.png only)</span>
                    </label>
                    <input
                      onChange={handleImage}
                      accept=".png"
                      type="file"
                      id="logo"
                      aria-label="company-logo"
                      className="modal-input"
                      placeholder="Click to upload Company's Logo"
                      multiple
                      max={3}
                    />
                    <p
                      id="filled_error_help"
                      className={
                        "mt-2 text-xs text-red-600 dark:text-red-400 " +
                        (!files.image ? "hidden" : "")
                      }
                    >
                      {"Please upload product Image"}
                    </p>
                  </div>

                  <button
                    type="submit"
                    onClick={() => handleSubmit}
                    className="button active"
                  >
                    Create Featured
                  </button>
                </Form>
              </>
            );
          }}
        </Formik>
      </div>
    </div>
  );
  return ReactDOM.createPortal(content, document.body);
};

const PromotionModal = ({
  changeModalState,
  handleSuccessModal,
}: {
  changeModalState: () => void;
  handleSuccessModal: (isOpen: boolean, state: string, message: string) => void;
}) => {
  const user: USER = useSelector((state: STATE) => state.USER.USER);
  const navigate = useNavigate();
  const dispatch: Dispatch<any> = useDispatch();

  useEffect(() => {
    if (user.id && user.userType === PROFILE_TYPES.GENERAL_SUBSCRIBER) {
      notify("Unauthorized", "error");
      navigate("/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const [products, setProducts] = useState([]);
  const [opportunities, setOpportunities] = useState([]);

  const initialValues: promotionsProfileCreate = {
    id: "",
  };

  const [isOpen, setIsOpen] = useState(false);
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const [selectedOption, setSelectedOption]: any = useState();

  const selectOption = (option: any) => {
    setSelectedOption(option);
    setIsOpen(false);
  };

  const getYourProducts = () => {
    let url = `${process.env.REACT_APP_BASE_API}/products/user?productStatus=APPROVED`;
    const extoken: string =
      localStorage.getItem("GTI_data")?.split(" ")[0] ?? "";

    var config = {
      method: RequestMethods.GET,
      url: url,
      headers: {
        Authorization: `Bearer ${extoken}`,
        "Content-Type": "application/json",
      },
    };

    axios(config)
      .then(function (response) {
        setProducts(response?.data?.products);
        initialValues.id = response?.data?.products[0]._id;
      })
      .catch(function (error) {
        setProducts([]);
      });
  };

  const getYourOpportunities = () => {
    let url = `${process.env.REACT_APP_BASE_API}/opportunities/user?opportunityStatus=APPROVED`;
    const extoken: string =
      localStorage.getItem("GTI_data")?.split(" ")[0] ?? "";

    var config = {
      method: RequestMethods.GET,
      url: url,
      headers: {
        Authorization: `Bearer ${extoken}`,
        "Content-Type": "application/json",
      },
    };

    axios(config)
      .then(function (response) {
        setOpportunities(response?.data?.opportunities);
        initialValues.id = response?.data?.opportunities[0]._id;
      })
      .catch(function (error) {
        setOpportunities([]);
      });
  };

  const paymentHandler = async (
    amount: number,
    currency: string,
    order_id: string,
    promotionId: string
  ) => {
    let options = {
      key: process.env.REACT_APP_RAZORPAY_KEY_ID,
      amount,
      currency,
      name: Company.NAME,
      description: Company.DESCRIPTION,
      image: gbi_home_logo,
      order_id,
      callback_url: `${process.env.REACT_APP_BASE_API}/payments/validate`,
      prefill: {
        name: user?.user?.name,
        email: user?.user?.email,
      },
      notes: {
        promotionId,
      },
      theme: {
        color: "#3399cc",
      },
    };

    let rzp1 = new (window as any).Razorpay(options);

    rzp1.on("payment.failed", function (response: any) {
      alert("Payment Failed. Please Retry again.");
      navigate("/featured/failed");
    });

    rzp1.open();
  };

  const handleCreate = async (values: { id: string }) => {
    try {
      handleSuccessModal(true, "LOADING", "");

      const details: any[] =
        user?.userType === PROFILE_TYPES.DISPLAYER ? products : opportunities;

      const itemDetails = details.find((item) => item._id === values.id);

      const data: promotionsCreate = {
        title:
          user?.userType === PROFILE_TYPES.DISPLAYER
            ? itemDetails?.name
            : itemDetails?.company?.name,
        description: itemDetails.description,
        images: itemDetails?.images?.length
          ? itemDetails?.images
          : itemDetails?.image
          ? [itemDetails?.image]
          : [itemDetails?.company?.logo],
        productId:
          user?.userType === PROFILE_TYPES.DISPLAYER ? itemDetails?._id : null,
        opportunityId:
          user?.userType === PROFILE_TYPES.SCOUTER ? itemDetails?._id : null,
      };

      const { order_id, currency, amount, promotionId } = await createPromotion(
        data
      );

      handleSuccessModal(false, "LOADING", "");

      paymentHandler(amount, currency, order_id, promotionId);

      // handleSuccessModal(
      //   true,
      //   "SUCCESS",
      //   "Promotions has been created successfully, it will be reviewed and approved by Admin."
      // );
      // changeModalState();
    } catch (err) {
      // handleSuccessModal(
      //   true,
      //   "ERROR",
      //   "There was an error while creating the promotion"
      // );
      notify("Failed to create featured!", "error");
    }
  };

  useEffect(() => {
    if (user?.userType === PROFILE_TYPES.DISPLAYER) {
      getYourProducts();
    } else if (user?.userType === PROFILE_TYPES.SCOUTER) {
      getYourOpportunities();
    }
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "auto";
    };
  }, []);

  const getDefaultImage = (sectorName: string) => {
    if (sectorName === "Biotechnology") {
      return biotechnology;
    } else if (sectorName === "Clean Technology") {
      return cleantech;
    } else {
      return informationTechnology;
    }
  };

  const content = (
    <div className="z-10 pb-[200px] pt-4 fixed w-full h-screen bg-slate-700 bg-opacity-70 top-0 left-0 flex justify-center overflow-auto">
      <div className="product-modal-main relative">
        <div className="flex">
          <h4 className="text-lg font-roboto font-bold">Create Featured</h4>

          <button
            onClick={() => {
              changeModalState();
            }}
            className="absolute right-0 top-0 font-bold hover:text-red-500 border border-slate-100 px-3 py-1 rounded"
          >
            <AiOutlineClose />
          </button>
        </div>
        <Formik
          initialValues={initialValues}
          onSubmit={(values) => {
            console.log({ values });
            handleCreate(values);
          }}
        >
          {({ handleChange, setFieldValue, handleSubmit, errors, values }) => {
            return (
              <>
                <Form className="flex flex-col w-full space-y-4 justify-center items-center">
                  <div className="flex flex-col w-full">
                    <div className="flex flex-row w-full space-x-5 items-center">
                      {user?.userType === PROFILE_TYPES?.DISPLAYER ? (
                        products?.length ? (
                          <div className="w-full">
                            <button
                              id="dropdownUsersButton"
                              className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 w-full"
                              type="button"
                              onClick={toggleDropdown}
                            >
                              {selectedOption ? (
                                <div className="flex items-center px-4 py-2 hover:bg-gray-300 dark:hover:bg-gray-600 dark:hover:text-white">
                                  <img
                                    className="w-6 h-6 me-2 rounded-sm"
                                    src={
                                      selectedOption?.images?.length
                                        ? selectedOption?.images[0]
                                        : selectedOption?.image
                                        ? selectedOption?.image
                                        : selectedOption?.company?.logo
                                    }
                                    alt={selectedOption?.name}
                                  />
                                  &nbsp;
                                  {selectedOption?.name
                                    ? selectedOption?.name
                                    : selectedOption?.company?.name}
                                </div>
                              ) : (
                                "Select Technology"
                              )}
                              &nbsp;
                              {/* {selectedOption ? null : (
                                <svg
                                  className="w-2.5 h-2.5 text-right"
                                  aria-hidden="true"
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 10 6"
                                >
                                  <path
                                    stroke="currentColor"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="m1 1 4 4 4-4"
                                  />
                                </svg>
                              )} */}
                            </button>

                            {isOpen && (
                              <div
                                id="dropdownUsers"
                                className="z-10 bg-white rounded-lg shadow dark:bg-gray-700 w-full"
                              >
                                <ul className="h-48 py-2 overflow-y-auto text-gray-700 dark:text-gray-200 bg-gray-200">
                                  {products.map(
                                    (
                                      item:
                                        | productItemFullFetched
                                        | oppotunityItemFullFetched
                                        | any,
                                      id
                                    ) => {
                                      return (
                                        <li
                                          value={item?._id}
                                          className="cursor-pointer"
                                          onClick={() => {
                                            selectOption(item);
                                            setFieldValue("id", item._id);
                                          }}
                                        >
                                          <div className="flex items-center px-4 py-2 hover:bg-gray-300 dark:hover:bg-gray-600 dark:hover:text-white">
                                            <img
                                              className="w-6 h-6 me-2 rounded-sm"
                                              src={
                                                item?.images?.length
                                                  ? item?.images[0]
                                                  : item?.image
                                                  ? item?.image
                                                  : item?.company?.logo
                                              }
                                              alt={item?.name}
                                            />
                                            &nbsp;
                                            {item?.name}
                                          </div>
                                        </li>
                                      );
                                    }
                                  )}
                                </ul>
                              </div>
                            )}
                          </div>
                        ) : (
                          <p className="w-full text-center">
                            Plesae create Technology, to promote it
                          </p>
                        )
                      ) : opportunities?.length ? (
                        <ul className="h-48 py-2 overflow-y-auto text-gray-700 dark:text-gray-200 bg-gray-200">
                          {opportunities.map(
                            (
                              item:
                                | productItemFullFetched
                                | oppotunityItemFullFetched
                                | any,
                              id
                            ) => {
                              return (
                                <li
                                  value={item?._id}
                                  className="cursor-pointer"
                                  onClick={() => {
                                    selectOption(item);
                                    setFieldValue("id", item._id);
                                  }}
                                >
                                  <div className="flex items-center px-4 py-2 hover:bg-gray-300 dark:hover:bg-gray-600 dark:hover:text-white">
                                    <img
                                      className="w-6 h-6 me-2 rounded-sm"
                                      src={
                                        item?.images?.length
                                          ? item?.images[0]
                                          : item?.image
                                          ? item?.image
                                          : item?.company?.logo
                                      }
                                      alt={
                                        item?.name
                                          ? item?.name
                                          : item?.company?.name
                                      }
                                    />
                                    &nbsp;
                                    {item?.name
                                      ? item?.name
                                      : item?.company?.name}
                                  </div>
                                </li>
                              );
                            }
                          )}
                        </ul>
                      ) : (
                        <p>Plesae create Opportunity, to promote it</p>
                      )}
                    </div>
                  </div>
                  {products?.length || opportunities?.length ? (
                    <button
                      type="submit"
                      onClick={() => handleSubmit}
                      className="button active"
                    >
                      Create Featured
                    </button>
                  ) : null}
                </Form>
              </>
            );
          }}
        </Formik>
      </div>
    </div>
  );
  return ReactDOM.createPortal(content, document.body);
};

const Promotions = ({ handleLoginModal }: { handleLoginModal: () => void }) => {
  const navigate = useNavigate();
  const dispatch: Dispatch<any> = useDispatch();

  let [successModal, setSuccessModal] = useState<boolean>(false);
  const [state, setState] = useState("LOADING");
  const [message, setMessage] = useState("");

  const user: USER = useSelector((state: STATE) => state.USER.USER);

  const [socialMediaDetails, setSocialMediaDetails] = useState(false);
  const [socialMedia, setSocialMedia] = useState({
    logo: "",
    details: [
      {
        type: "",
        link: "",
        text: "",
      },
    ],
  });
  const [startPromotion, setStartPromomtion] = useState(false);
  const [promoteProfile, setPromoteProfile] = useState(false);
  const [personalisedPromote, setPersonalisedPromote] = useState(false);
  const promotions: PROMOTIONS_STATE = useSelector(
    (state: STATE) => state.PROMOTIONS
  );

  useEffect(() => {
    dispatch(getPromotions(SKIP, LIMIT));
  }, []);

  const handleSuccessModal = (
    isOpen: boolean,
    state: string,
    message: string
  ) => {
    setSuccessModal(isOpen);
    setState(state);
    setMessage(message);
  };

  const changePromoteProfile = async () => {
    setPromoteProfile(!promoteProfile);
  };

  const changePersonalisedPromote = async () => {
    setPersonalisedPromote(!personalisedPromote);
  };

  const handleYourFeaturedView = () => {
    navigate(`/your-featured`);
  };

  return (
    <div className="flex flex-col relative justify-between items-center w-full">
      <Helmet>
        <title>{title.PROMOTIONS}</title>
        <meta
          name="description"
          key="description"
          content={metaData.PROMOTIONS}
        />
        <meta name="title" key="title" content={title?.PROMOTIONS} />
        <meta property="og:title" content={title.PROMOTIONS} />
        <meta property="og:description" content={metaData.PROMOTIONS} />
        <meta property="og:image" content={globe} />
        <meta
          property="og:url"
          content={`${process.env.REACT_APP_BASE_URL}/technology`}
        />
        <meta property="og:type" content="website" />
        <meta name="twitter:title" content={title?.PROMOTIONS} />
        <meta name="twitter:description" content={metaData.PROMOTIONS} />
        <meta name="twitter:image" content={globe} />
        <meta name="twitter:card" content={title?.PROMOTIONS} />
      </Helmet>
      <div className="flex flex-col lg:flex-row w-full items-center justify-center">
        {promoteProfile && (
          <PromotionModal
            changeModalState={changePromoteProfile}
            handleSuccessModal={handleSuccessModal}
          />
        )}
        {personalisedPromote && (
          <ProfilePromotionModal
            changeModalState={changePersonalisedPromote}
            handleSuccessModal={handleSuccessModal}
          />
        )}
        <div className="flex flex-col items-center w-5/6 lg:w-3/6">
          <h1 className="text-GTI-BLUE-default font-semibold text-3xl lg:text-5xl mb-5 w-full text-left">
            FEATURED
          </h1>
          <img src={featured} alt="Featured" className="w-full lg:hidden" />
          <p className="text-sm lg:text-lg">{FEATURED}</p>
          {startPromotion ? (
            <div className="flex w-full justify-center items-center">
              {user?.userType !== PROFILE_TYPES.GENERAL_SUBSCRIBER && (
                <div
                  className="flex bg-contain justify-center items-center rounded-sm cursor-pointer w-full h-10 mt-5 bg-center"
                  style={{
                    backgroundImage: `url(${promote_profile})`,
                    backgroundRepeat: "no-repeat",
                  }}
                  onClick={() => {
                    if (user?.admin !== -1) {
                      setStartPromomtion(false);
                      setPromoteProfile(true);
                      setPersonalisedPromote(false);
                    }
                  }}
                ></div>
              )}
              <div
                className="flex bg-contain justify-center items-center rounded-sm cursor-pointer w-full h-10 mt-5 bg-center"
                style={{
                  backgroundImage: `url(${personalised_promotion})`,
                  backgroundRepeat: "no-repeat",
                }}
                onClick={() => {
                  if (user?.admin !== -1) {
                    setStartPromomtion(false);
                    setPromoteProfile(false);
                    setPersonalisedPromote(true);
                  }
                }}
              ></div>
            </div>
          ) : (
            <>
              {promotions?.promotionsCount ? (
                <div
                  className="flex bg-contain justify-center items-center rounded-sm cursor-pointer w-full text-left h-10 mt-5"
                  style={{
                    backgroundImage: `url(${your_featured})`,
                    backgroundRepeat: "no-repeat",
                  }}
                  onClick={() => {
                    if (user?.admin !== -1) {
                      handleYourFeaturedView();
                    } else {
                      handleLoginModal();
                    }
                  }}
                ></div>
              ) : null}
              <div
                className="flex bg-contain justify-center items-center rounded-sm cursor-pointer w-full text-left h-10 mt-5"
                style={{
                  backgroundImage: `url(${featured_button})`,
                  backgroundRepeat: "no-repeat",
                }}
                onClick={() => {
                  if (user?.admin !== -1) {
                    setStartPromomtion(true);
                    setPromoteProfile(false);
                    setPersonalisedPromote(false);
                  } else {
                    handleLoginModal();
                  }
                }}
              ></div>
            </>
          )}
        </div>
        <img src={featured} alt="Featured" className="w-2/6 hidden lg:block" />
      </div>

      <div className="mt-10 lg:mt-5 mb-10">
        <h2 className="text-GTI-BLUE-default font-semibold text-2xl lg:text-4xl w-full">
          Key Promotional Platforms Overview
        </h2>
      </div>

      <div className="ml-5 lg:ml-10 mr-5 lg:mr-10">
        <div className="lg:flex gap-10 mb-10">
          <div className="bg-blue-300 p-5 rounded-3xl h-fit">
            <img src={gti_homepage} className="rounded-3xl" />
          </div>

          <div className="flex flex-col justify-center items-center lg:w-2/3">
            <h3 className="text-GTI-BLUE-default font-semibold text-xl lg:text-2xl">
              Product showcase on GTI Homepage
            </h3>
            <p className="p-5 text-center text-sm lg:text-lg">
              We will be showcasing our product prominently on the homepage of
              our website. This feature aims to enhance visibility and
              engagement, providing visitors with detailed insights and
              highlights of our product's capabilities. By spotlighting your
              product, we intend to reach a wider audience and demonstrate its
              unique benefits and applications directly to our site visitors.
            </p>
          </div>
        </div>
        <div className="flex flex-col-reverse lg:flex-row gap-10 mb-10">
          <div className="flex flex-col justify-center items-center lg:w-2/3">
            <h3 className="text-GTI-BLUE-default font-semibold text-xl lg:text-2xl">
              Product showcase on GTI & GBI Social Media Handles
            </h3>
            <p className="p-5 text-center text-sm lg:text-lg">
              We will be promoting our technology on our social media platforms,
              significantly enhancing your online presence. This initiative aims
              to reach a broader audience, providing detailed insights and
              updates on latest innovations. Through this feature, we intend to
              engage more effectively with the target audience and showcase the
              practical benefits of our technology.
            </p>
          </div>
          <div className="flex bg-blue-300 p-5 rounded-3xl h-fit">
            <img
              src={linkedin}
              className="w-1/4 bg-white p-3 rounded-tl-3xl rounded-bl-3xl cursor-pointer"
              onClick={() => {
                setSocialMediaDetails(true);
                setSocialMedia(SocialMedium["linkedin"]);
              }}
            />
            <img
              src={facebook}
              className="w-1/4 bg-white p-3 cursor-pointer"
              onClick={() => {
                setSocialMediaDetails(true);
                setSocialMedia(SocialMedium["facebook"]);
              }}
            />
            <img
              src={instagram}
              className="w-1/4 bg-white p-3 cursor-pointer"
              onClick={() => {
                setSocialMediaDetails(true);
                setSocialMedia(SocialMedium["instagram"]);
              }}
            />
            <img
              src={twitter}
              className="w-1/4 bg-white p-3 rounded-tr-3xl rounded-br-3xl cursor-pointer"
              onClick={() => {
                setSocialMediaDetails(true);
                setSocialMedia(SocialMedium["twitter"]);
              }}
            />
          </div>
        </div>
        <div className="lg:flex gap-10 mb-10">
          <div className="bg-blue-300 p-5 rounded-3xl h-fit">
            <div className="flex justify-center bg-white p-5 rounded-tl-3xl rounded-tr-3xl gap-2 w-full">
              <a
                href="https://cafe-europe.info/en.html"
                target="_blank"
                className="w-1/3"
              >
                <img src={cafe_europe} className="w-full" />
              </a>
            </div>
            <div className="flex justify-evenly bg-white p-5 gap-2 w-full">
              <a
                href="https://economico.pro/"
                target="_blank"
                className="w-1/3"
              >
                <img src={economico_pro} className="w-full" />
              </a>
              <a href="https://punkt4.info/" target="_blank" className="w-1/3">
                <img src={punkt_info} className="w-full" />
              </a>
            </div>
            <div className="flex justify-evenly bg-white p-5 rounded-bl-3xl rounded-br-3xl gap-2 w-full">
              <a className="w-1/3" target="_blank">
                <img src={circular_times} className="w-full" />
              </a>
              <a
                href="https://swisstrade.com/"
                target="_blank"
                className="w-1/3"
              >
                <img src={swiss_trade} className="w-full" />
              </a>
            </div>
          </div>
          <div className="flex flex-col justify-center items-center lg:w-2/3">
            <h3 className="text-GTI-BLUE-default font-semibold text-xl lg:text-2xl">
              Product showcase on Global News channels
            </h3>
            <p className="p-5 text-center text-sm lg:text-lg">
              We will be showcasing your product on various news channels
              worldwide. This initiative aims to enhance our global presence and
              reach a wider audience, providing detailed insights and updates
              about your product. By featuring our product on international news
              platforms, we intend to demonstrate its unique benefits and
              applications to a diverse and expansive audience.
            </p>
          </div>
        </div>
      </div>

      <div className="flex flex-col mb-10 justify-center items-center ml-5 mr-5 lg:ml-0 lg:mr-0">
        <h2 className="text-GTI-BLUE-default font-semibold text-3xl lg:text-4xl w-full">
          Let’s take your technology global
        </h2>
        {startPromotion ? (
          <div className="flex w-full justify-center items-center">
            {user?.userType !== PROFILE_TYPES.GENERAL_SUBSCRIBER && (
              <div
                className="bg-contain rounded-sm cursor-pointer w-full h-10 mt-5 bg-center"
                style={{
                  backgroundImage: `url(${promote_profile})`,
                  backgroundRepeat: "no-repeat",
                }}
                onClick={() => {
                  if (user?.admin !== -1) {
                    setStartPromomtion(false);
                    setPromoteProfile(true);
                    setPersonalisedPromote(false);
                  }
                }}
              ></div>
            )}
            <div
              className="bg-contain rounded-sm cursor-pointer w-full h-10 mt-5 bg-center"
              style={{
                backgroundImage: `url(${personalised_promotion})`,
                backgroundRepeat: "no-repeat",
              }}
              onClick={() => {
                if (user?.admin !== -1) {
                  setStartPromomtion(false);
                  setPromoteProfile(false);
                  setPersonalisedPromote(true);
                }
              }}
            ></div>
          </div>
        ) : (
          <div
            className="bg-contain rounded-sm cursor-pointer w-full h-10 mt-5 m-auto bg-center"
            style={{
              backgroundImage: `url(${featured_button})`,
              backgroundRepeat: "no-repeat",
            }}
            onClick={() => {
              if (user?.admin !== -1) {
                setStartPromomtion(true);
                setPromoteProfile(false);
                setPersonalisedPromote(false);
              } else {
                handleLoginModal();
              }
            }}
          ></div>
        )}
      </div>

      {successModal && (
        <SuccessModal
          state={state}
          message={message}
          show={successModal}
          toggle={handleSuccessModal}
        />
      )}

      {socialMediaDetails && (
        <SocialMediaDetails
          setSocialMediaDetails={setSocialMediaDetails}
          logo={socialMedia["logo"]}
          socialMediaDetails={socialMedia["details"]}
        />
      )}
    </div>
  );
};

export default Promotions;
