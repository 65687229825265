import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import "./style.css";
import { useNavigate } from "react-router-dom";
import { ConnectionStatus } from "../constants";
import defaultPic from "../../assests/images/default-user.svg";
import { acceptConnection } from "../../api/user";
import { isSuccess, notify } from "../../utils";
import { useDispatch } from "react-redux";
import { getConnections } from "../../store/actioncreators/connectionactions";

const Card2 = ({
  Img,
  title,
  org,
  email,
  user,
  status,
}: {
  Img: string;
  title: string;
  org: string;
  email: string;
  user: any;
  status?: ConnectionStatus;
}) => {
  const currentUser: USER = useSelector((state: STATE) => state.USER.USER);
  const profile = false;
  const navigate = useNavigate();
  const dispatch = useDispatch<any>();

  const data = {
    ...(user.sender ? user.sender : user.receiver),
    ...(user.sender ? user.senderCompany : user.receiverCompany),
  };

  const [buttonText, setButtonText] = useState("View Profile");

  useEffect(() => {
    if (status) {
      switch (status) {
        case ConnectionStatus.CONNECTED:
          setButtonText("Chat");
          break;
        case ConnectionStatus.PENDING:
          setButtonText("Accept");
          break;
        default:
          setButtonText("View Profile");
          break;
      }
    }
  }, [status]);

  const handleAccept = async () => {
    const response = await acceptConnection(data._id);
    dispatch(getConnections(status));
    if (isSuccess(response)) {
      notify("Accepted request!", "success");
    } else {
      notify("Failed to accept!", "error");
    }
  };

  let handleChat = () => {
    navigate(`/chat/${data._id}`, { state: { user: data } });
  };
  // View Profile
  let handleView = () => {
    navigate(`/view-profile/${data._id}`);
  };
  const handleClick = () => {
    switch (status) {
      case ConnectionStatus.CONNECTED:
        handleChat();
        break;
      case ConnectionStatus.PENDING:
        handleAccept();
        break;
      default:
        handleView();
    }
  };

  return (
    <div className="w-full max-w-sm px-5 py-7 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
      <div className="flex flex-col items-center ">
        <img
          className="w-24 h-24 mb-3 rounded-full shadow-lg text-GTI-BLUE-default"
          src={data.logo ? data.logo : defaultPic}
          alt={data.name}
        />
        <h5 className="mb-1 text-xl font-medium text-gray-900 dark:text-white">
          {data.fullName}
        </h5>
        <span className="text-sm text-gray-500 dark:text-gray-400">
          {data.name}
        </span>
        <div className="flex mt-4 space-x-3 md:mt-6 w-full gap-2">
          <a
            onClick={handleClick}
            href="#"
            className="inline-flex w-full items-center justify-center px-4 py-2 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
          >
            {buttonText}
          </a>
          {status !== ConnectionStatus.REQUESTED ? (
            <a
              onClick={handleView}
              href="#"
              className="inline-flex w-full items-center justify-center px-4 py-2 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
            >
              View Profile
            </a>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default Card2;
