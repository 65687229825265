import * as Yup from "yup";

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

export const contactSchema = Yup.object().shape({
  fullName: Yup.string()
    .min(2, "Too Short!")
    .max(25, "Too Long!")
    .required("Full name Required"),
  phone: Yup.string(),
  email: Yup.string().email("Enter Valid email.").required(),
  message: Yup.string(),
});

export const helpdeskSchema = Yup.object().shape({
  firstName: Yup.string()
    .min(2, "Too Short!")
    .max(25, "Too Long!")
    .required("First name Required"),
  lastName: Yup.string()
    .min(2, "Too Short!")
    .max(25, "Too Long!")
    .required("Last name Required"),
  phone: Yup.string(),
  email: Yup.string()
    .email("Enter Valid email.")
    .required("Email is required!"),
  message: Yup.string(),
});
