import React, { Dispatch, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import productbanner from "../../assests/images/product_alt.png";
import {
  CONTENT_TYPE,
  CONTENT_TYPE_DOC,
  FILE_PATH,
  FILE_TYPE,
  LIMIT,
  NONE,
  presignedData,
  PRODUCT,
  productItem,
  productItemFullFetched,
  productItemPartialFetched,
  productUpdateItem,
  sectorItem,
  SKIP,
  subsectorItem,
  YOUR_PRODUCT,
} from "../constants";
import { useNavigate } from "react-router-dom";
import {
  getCompanyApprovedProducts,
  getCompanyPendingProducts,
  getCompanyRejectedProducts,
  getCompanyUnapprovedProducts,
  getYourProducts,
  updateProduct,
} from "../../store/actioncreators/productactions";
import { RiDeleteBin6Fill, RiEditBoxFill } from "react-icons/ri";
import { MdPreview } from "react-icons/md";
import axios from "axios";
import {
  failToast,
  successToast,
} from "../../store/actioncreators/toastactions";
import { Formik, Form } from "formik";
import { productSchema } from "../validations/productValidations";

// interface MyFormValues {
//   name: string;
//   description: string;
//   sectorId: string;
//   subSectorId: string;
// };

// type files = {
//   image: Boolean,
//   document: Boolean,
//   imageFile: File | null,
//   documentFiles: FileList | null,
// }

// type productModal = {
//   name: string;
//   description: string;
//   document: FileList | null;
//   image: File | null;
//   sectorId: string;
//   subSectorId: string;
// }

// const ProductModal = ({ currentProduct, handleModal }: { currentProduct: productItemPartialFetched, handleModal: () => void }) => {

//   const sectorlist: SECTOR = useSelector(
//     (state: STATE) => state.SECTOR.SECTOR
//   );
//   const subsectorlist: SUB_SECTOR = useSelector(
//     (state: STATE) => state.SUB_SECTOR.SUB_SECTOR
//   );

//   const initialValues: MyFormValues = {
//     name: currentProduct.name,
//     description: currentProduct.description,
//     sectorId: currentProduct.sectorId,
//     subSectorId: currentProduct.subSectorId,
//   };

//   const dispatch: Dispatch<any> = useDispatch();

//   const [files, setFiles] = useState<files>({
//     image: false,
//     document: false,
//     imageFile: null,
//     documentFiles: null,
//   })

//   const handleImage = function (e: React.ChangeEvent<HTMLInputElement>) {
//     const fileList = e.target.files;

//     if (!fileList) return;

//     setFiles({ ...files, imageFile: fileList[0], image: false });
//     // files.imageFile = fileList;
//   };

//   const handleDocuments = function (e: React.ChangeEvent<HTMLInputElement>) {
//     const fileList = e.target.files;

//     if (!fileList) return;
//     // initialValues.document = fileList
//     setFiles({ ...files, documentFiles: fileList, document: false });
//   };

//   const getPresigned = async (content: presignedData) => {
//     const data = JSON.stringify(content);
//     let result: string = "";
//     const config = {
//       method: "post",
//       url: `${process.env.REACT_APP_BASE_API}/users/getPresignedUrl`,
//       headers: {
//         "Content-Type": "application/json",
//       },
//       data: data,
//     };

//     await axios(config)
//       .then(function (response) {
//         console.log("url fetched", response);
//         result = response.data;
//       })
//       .catch(function (error) {
//         console.log("Error", error);
//         result = "error";
//         dispatch(failToast());
//       });

//     return result;
//   };

//   const postLogo = async (signed: string) => {
//     var config = {
//       method: "put",
//       url: signed,
//       headers: {
//         "Content-Type": CONTENT_TYPE,
//         "Access-Control-Allow-Origin": true,
//       },
//       data: files.imageFile,
//     };

//     await axios(config)
//       .then(function (response) {
//         console.log("logo uploaded");
//         dispatch(successToast());
//       })
//       .catch(function (error) {
//         console.log("error uploading logo");
//       });
//   };

//   const postDocument = async (signed: string, file: File) => {
//     var config = {
//       method: "put",
//       url: signed,
//       headers: {
//         "Content-Type": CONTENT_TYPE_DOC,
//         "Access-Control-Allow-Origin": true,
//       },
//       data: file,
//     };

//     await axios(config)
//       .then(function (response) {
//         console.log("document uploaded");
//         dispatch(successToast());
//       })
//       .catch(function (error) {
//         console.log("error uploading document");
//       });
//   };

//   const handleCreate = async (values: MyFormValues) => {

//     console.log('submit')

//     // if (!files.imageFile) {
//     //   return setFiles({ ...files, image: true });

//     // }
//     // if (!files.documentFiles) {
//     //   return setFiles({ ...files, document: true });

//     // }
//     // setFiles({ ...files, document: false, image: false });

//     let signedLogoURL: string = '';
//     let signedDocumentURLWhole: string = '';

//     // if (!productModal.image?.size && !productModal.document) return;

//     if (files.imageFile) {
//       const signedLogoData: presignedData = {
//         fileName: files.imageFile.name || values.name,
//         filePath: FILE_PATH.PRODUCTS_IMAGE,
//         fileType: FILE_TYPE.PNG,
//       };
//       signedLogoURL = await getPresigned(signedLogoData);
//       await postLogo(signedLogoURL);
//     }

//     if (files.documentFiles) {

//       Array.from(files.documentFiles).forEach(async (document, i) => {

//         let signedDocumentData: presignedData = {
//           fileName: document.name || values.name,
//           filePath: FILE_PATH.COMPANY_DOCS,
//           fileType: FILE_TYPE.PDF,
//         };
//         let tempurl = await getPresigned(signedDocumentData) + " ";
//         signedDocumentURLWhole = tempurl.split('?')[0] + " ";
//         postDocument(tempurl, document);
//         // signedDocumentURL.push(tempurl);
//       });
//     }
//     setTimeout(() => {
//       const data: productUpdateItem = {
//         productId: currentProduct._id,
//         name: values.name,
//         description: values.description,
//         document: files.documentFiles ? signedDocumentURLWhole : currentProduct.document,
//         image: files.imageFile ? signedLogoURL.split("?")[0] : currentProduct.image,
//         sectorId: values.sectorId,
//         subSectorId: values.subSectorId,
//       };
//       console.log('whole data', data);
//       dispatch(updateProduct(data));
//       handleModal();
//     }, 2000)

//   }
//   return (
//     <div className="product-modal-main">
//       <div className="flex">
//         <h4 className="text-lg font-roboto">Edit Product</h4>
//         <button
//           onClick={() => {
//             handleModal();
//           }}
//           className="absolute right-0 top-0 font-bold hover:text-red-500 duration-300 border border-slate-100 px-3 py-1 rounded"
//         >
//           X
//         </button>
//       </div>
//       <Formik
//         initialValues={initialValues}
//         validationSchema={productSchema}
//         onSubmit={(values) => handleCreate(values)}
//       >
//         {({ handleChange, setFieldValue, handleSubmit, errors, values }) => (
//           <>
//             <Form className="flex flex-col w-full space-y-4 justify-center items-center">
//               <div className="flex flex-col w-full space-x-2 relative">
//                 <div className="relative">
//                   <input defaultValue={currentProduct.name} onChange={(e) => setFieldValue('name', e.target.value)} type="text" id="floating_outlined" className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " />
//                   <label htmlFor="floating_outlined" className="absolute text-sm text-gray-500  duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white  px-2 peer-focus:px-2 peer-focus:text-blue-600  peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1">Product Name</label>
//                 </div>
//                 {errors.name && <p id="filled_error_help" className="mt-2 text-xs text-red-600 dark:text-red-400">{errors.name}</p>}
//               </div>

//               <div className="flex flex-col w-full space-x-2 relative">
//                 <div className="relative">
//                   {/* <label htmlFor="message" className="block mb-2 text-sm font-medium text-gray-500">Product Description</label> */}
//                   <textarea defaultValue={currentProduct.description} onChange={(e) => setFieldValue('description', e.target.value)} id="message" rows={4} className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500  " placeholder="Write your products description here..."></textarea>
//                 </div>
//                 {errors.description && <p id="filled_error_help" className="mt-2 text-xs text-red-600 dark:text-red-400">{errors.description}</p>}
//               </div>
//               <div className="flex flex-col w-full">
//                 <div className="flex flex-row w-full space-x-5 items-center">
//                   <h3 className="font-robot text-gray-800 text-sm whitespace-nowrap  ">Sector Type:</h3>
//                   <select onChange={(e) => setFieldValue('sectorId', e.target.value)} defaultValue={currentProduct.sectorId} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 ">
//                     {
//                       sectorlist.SECTOR_LIST.map((item: sectorItem, id) => {
//                         return (
//                           <option value={item._id}>{item.name}</option>
//                         )
//                       })
//                     }
//                   </select>
//                 </div>
//                 {errors.sectorId && <p id="filled_error_help" className="mt-2 text-xs text-red-600 dark:text-red-400">{errors.sectorId}</p>}
//               </div>
//               <div className="flex flex-col w-full">
//                 <div className="flex flex-row w-full space-x-5 items-center">
//                   <h3 className="font-robot text-gray-800 text-sm whitespace-nowrap  ">Sub Sector Type:</h3>
//                   <select onChange={(e) => setFieldValue('subsecId', e.target.value)} defaultValue={currentProduct.subSectorId} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 ">
//                     {
//                       subsectorlist.SUB_SECTOR_LIST.map((item: subsectorItem, id) => {
//                         return (
//                           <option value={item._id}>{item.name}</option>
//                         )
//                       })
//                     }
//                   </select>
//                 </div>
//                 {errors.subSectorId && <p id="filled_error_help" className="mt-2 text-xs text-red-600 dark:text-red-400">{errors.subSectorId}</p>}
//               </div>
//               <div className="flex flex-col w-full space-x-2 relative">
//                 <div className="relative">
//                   <input onChange={handleChange} type="text" id="floating_outlined" className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " />
//                   <label htmlFor="floating_outlined" className="absolute text-sm text-gray-500  duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white  px-2 peer-focus:px-2 peer-focus:text-blue-600  peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1">IPR Status</label>
//                 </div>
//                 {/* {errors. && <p id="filled_error_help" className="mt-2 text-xs text-red-600 dark:text-red-400">{errors.}</p>} */}
//               </div>
//               {/* <input onChange={(e) => { setModalData({ ...productModal, companyName: e.target.value }) }} type="text" aria-label="company-name" className="modal-input" placeholder="Company Name" /> */}
//               <div className="flex flex-col w-full">
//                 <label className="block mb-2 text-sm font-medium text-gray-900" htmlFor="logo">Upload Product Image <span className="text-red-500 text-xs">(.png only)</span></label>
//                 <input onChange={handleImage} accept='.png' type="file" id='logo' aria-label="company-logo" className="modal-input" placeholder="Click to upload Company's Logo" />
//                 <p id="filled_error_help" className={"mt-2 text-xs text-red-600 dark:text-red-400 " + (!files.image ? 'hidden' : '')}>{'Please upload product Image'}</p>
//               </div>
//               <div className="flex flex-col w-full">
//                 <label className="block mb-2 text-sm font-medium text-gray-900" htmlFor="documents">Upload Product Documents<span className="text-red-500 text-xs">(.pdf only)</span></label>
//                 <input onChange={handleDocuments} accept='.pdf' type="file" id='documents' multiple aria-label="company-documents" className="modal-input" placeholder="Click to upload Document" />
//                 <p id="filled_error_help" className={"mt-2 text-xs text-red-600 dark:text-red-400 " + (!files.document ? 'hidden' : '')}>{'Please upload product documents'}</p>
//               </div>
//               <button type="submit" onClick={() => handleSubmit} className="button active">Update</button>
//             </Form>
//           </>
//         )}

//       </Formik>

//     </div>
//   );
// }

const Card = ({
  item,
  handleSelect,
}: {
  item: productItemPartialFetched;
  handleSelect: (data: productItemPartialFetched) => void;
}) => {
  const dispatch: Dispatch<any> = useDispatch();
  const navigate = useNavigate();

  const handleView = () => {
    navigate(YOUR_PRODUCT, { state: { product: item } });
  };

  const DOP = new Date(item.createdAt);
  // console.log(item.image);

  const handleEdit = () => {
    handleSelect(item);
  };
  const handleDelete = async () => {};
  return (
    <div className="product-card-main">
      <div
        className="product-card-img"
        onClick={() => {
          handleView();
        }}
      >
        <img
          src={item.image === NONE ? productbanner : item.image}
          className="flex  w-full lg:h-56  rounded-t-lg hover:rounded-lg object-contain"
          alt={item.name}
          loading="lazy"
        />
      </div>
      <div className="product-card-title">
        <h4 className="font-roboto text-xs text-slate-500 ">
          {"Posted on:"}
          {DOP.toLocaleString("default", {
            month: "short",
            day: "2-digit",
            year: "numeric",
          })}
        </h4>
      </div>
      <div className="product-card-title">
        <h4 className="font-roboto text-lg text-GTI-BLUE-default ">
          {item.name.length > 25
            ? item.name.substring(0, 25) + "..."
            : item.name}
        </h4>
      </div>
      <div className="product-card-title">
        <h4 className="font-roboto  text-xs text-slate-500 ">
          {item.description.length > 150
            ? item.description.substring(0, 150) + "..."
            : item.description}
        </h4>
      </div>
      {/* <div className="flex flex-row justify-evenly">
        <div className="inline-flex items-center" onClick={() => { handleEdit() }}>
          <RiEditBoxFill className="w-5 h-5 mr-2" />Edit
        </div>
        <div className="inline-flex items-center" onClick={() => { handleView() }}>
          <MdPreview className="w-5 h-5 mr-2" />View
        </div>
        <div className="inline-flex items-center" onClick={() => { handleDelete() }}>
          <RiDeleteBin6Fill className="w-5 h-5" />Delete
        </div>
      </div> */}
    </div>
  );
};

const CompanyProductList = ({
  skip,
  limit,
  secId,
  subsecId,
  type,
}: {
  skip: string;
  limit: string;
  subsecId: string;
  secId: string;
  type: number;
}) => {
  const dispatch: Dispatch<any> = useDispatch();
  const products: PRODUCTS = useSelector(
    (state: STATE) => state.PRODUCTS.PRODUCTS
  );
  const user: USER = useSelector((state: STATE) => state.USER.USER);
  const navigate = useNavigate();
  useEffect(() => {
    switch (type) {
      case 0:
        dispatch(getCompanyApprovedProducts(skip, limit));
        break;
      case 1:
        dispatch(getCompanyUnapprovedProducts(skip, limit));
        break;
      case 2:
        dispatch(getCompanyPendingProducts(skip, limit));
        break;
      default:
        dispatch(getCompanyRejectedProducts(skip, limit));
    }
  }, [secId, subsecId, skip]);

  const [productEditModal, setModal] = useState(false);

  const [currentProduct, setProduct] = useState<productItemPartialFetched>({
    _id: "",
    name: "",
    description: "",
    image: "",
    displayOnHomePage: false,
    isApprovedBySubAdmin: false,
    isApprovedByAdmin: false,
    isRejected: false,
    document: "",
    sectorId: "",
    subSectorId: "",
    userId: "",
    createdAt: "",
    developmentStage: "",
    iprStatus: [],
    __v: -1,
    sectors: {
      _id: "",
      name: "",
      slug: "",
      image: "",
      createdAt: "",
      __v: -1,
    },
    subsectors: {
      _id: "",
      name: "",
      slug: "",
      sectorId: "",
      createdAt: "",
      __v: -1,
    },
  });

  const handleModel = () => {
    setModal(!productEditModal);
    switch (type) {
      case 0:
        dispatch(getCompanyApprovedProducts(skip, limit));
        break;
      case 1:
        dispatch(getCompanyUnapprovedProducts(skip, limit));
        break;
      case 2:
        dispatch(getCompanyPendingProducts(skip, limit));
        break;
      default:
        dispatch(getCompanyRejectedProducts(skip, limit));
    }
  };

  const handleSelect = (item: productItemPartialFetched) => {
    if (item.__v !== -1) {
      setProduct(item);
      handleModel();
    }
  };

  return (
    <div className="flex w-full justify-center items-center px-10 flex-col">
      <div className="product-list-main">
        {type == 0 &&
          products.COMPANY_APPROVED_PRODUCT_LIST &&
          products.COMPANY_APPROVED_PRODUCT_LIST.map(
            (item: productItemFullFetched, id: number) => {
              return <Card item={item} key={id} handleSelect={handleSelect} />;
            }
          )}
        {type == 1 &&
          products.COMPANY_UNAPPROVED_PRODUCT_LIST &&
          products.COMPANY_UNAPPROVED_PRODUCT_LIST.map(
            (item: productItemFullFetched, id: number) => {
              return <Card item={item} key={id} handleSelect={handleSelect} />;
            }
          )}
        {type == 2 &&
          products.COMPANY_PENDING_PRODUCT_LIST &&
          products.COMPANY_PENDING_PRODUCT_LIST.map(
            (item: productItemFullFetched, id: number) => {
              return <Card item={item} key={id} handleSelect={handleSelect} />;
            }
          )}
        {type == 3 &&
          products.COMPANY_REJECTED_PRODUCT_LIST &&
          products.COMPANY_REJECTED_PRODUCT_LIST.map(
            (item: productItemFullFetched, id: number) => {
              return <Card item={item} key={id} handleSelect={handleSelect} />;
            }
          )}
      </div>
    </div>
  );
};
export default CompanyProductList;
