import React, { Dispatch, useEffect } from "react";
import "./styles.css";
import { BsBell } from "react-icons/bs";
import { LIMIT, notificationItem, NOTIFICATIONS, SKIP } from "../constants";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { getAllNotification } from "../../store/actioncreators/notificationactions";
import { useNavigate } from "react-router-dom";

const Card = ({ item }: { item: notificationItem }) => {
  return (
    <div className="flex flex-row items-center justify-evenly p-2 w-full border-b border-b-slate-100 ">
      {item.image ? (
        <img
          src={item.image}
          className="rounded-full lg:w-16 lg:h-16 w-8 h-8 object-contain"
          alt="Notifications"
        />
      ) : (
        <BsBell />
      )}
      <p className="text-sm font-roboto text-center text-slate-600">
        {item.title ? item.title : "Error in displaying message"}
      </p>
    </div>
  );
};

const Notification = ({
  handleNotificationModal,
}: {
  handleNotificationModal: () => void;
}) => {
  const dispatch: Dispatch<any> = useDispatch();
  const notifications: NOTIFICATION = useSelector(
    (state: STATE) => state.NOTIFICATION.NOTIFICATION_LIST
  );
  const navigate = useNavigate();
  const handleView = () => {
    navigate(NOTIFICATIONS);
    handleNotificationModal();
  };

  useEffect(() => {
    dispatch(getAllNotification(SKIP, LIMIT));
  }, []);

  // console.log({ not: notifications.NOTIFICATION_NOT_VIEWED });

  return (
    <div className="z-10  absolute right-0  max-h-screen bg-white w-72   flex-col justify-between overflow-auto">
      <div className="flex flex-row px-5 py-5 relative border-b-gray-200 border-b">
        <h4 className="text-lg font-roboto text-GTI-BLUE-default">
          Notifications
        </h4>
        <button
          onClick={() => {
            handleNotificationModal();
          }}
          className="absolute right-0 top-5 font-bold hover:text-red-500 duration-300 border border-slate-100 px-3 py-1 rounded"
        >
          X
        </button>
      </div>
      <div className="flex flex-col h-full justify-start items-start">
        {notifications.NOTIFICATION_NOT_VIEWED.map(
          (item: notificationItem, id: number) => {
            return <Card item={item} key={id} />;
          }
        )}
      </div>
      <div className="flex flex-row">
        <button
          onClick={() => {
            handleView();
          }}
          className=" font-roboto bg-GTI-BLUE-default text-lg text-center text-white w-full p-5"
        >
          All Notifications
        </button>
      </div>
    </div>
  );
};

export default Notification;
