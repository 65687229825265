import React, { useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import ReactCountryFlag from "react-country-flag";
import { FaConnectdevelop } from "react-icons/fa";
import { useSelector } from "react-redux";
import "./style.css";

const UserProfile = () => {
  const currentUser: USER = useSelector((state: STATE) => state.USER.USER);

  let [emp_name, setEmpName] = useState(currentUser.user.name);
  let [org_name, setOrgName] = useState(currentUser?.company[0]?.name ?? "");
  let org_country = currentUser.user.country; //later fetch from user backend
  let navigate = useNavigate();
  const [tab, selectTab] = useState(1);
  const handletab = async (value: any) => {
    if (value === 1) {
      navigate("personal");
    } else if (value == 2 && currentUser.userType !== "GENERAL_SUBSCRIBER") {
      navigate("company");
    } else if (value === 3) {
      navigate("employeemgm");
    } else if (value === 4) {
      navigate("network");
    } else {
      navigate("password");
    }
    selectTab(value);
  };

  return (
    <div className="profile-main-1">
      <div className="profile-main-left">
        <div
          className="profile-top-cover mt-[100px]"
          // style={{ backgroundImage: `url(${test_bg})` }}
        ></div>
        <div className="profile-top-row ">
          <div className="inline-flex lg:flex-row space-x-5">
            <img
              src={
                currentUser?.company.length
                  ? currentUser?.company[0].logo
                    ? currentUser?.company[0].logo
                    : currentUser?.profileImage
                  : currentUser?.profileImage
              }
              className="profile-pic"
              alt={org_name}
            />
            <div className="flex flex-col translate-y-10">
              <h2 className="text-GTI-BLUE-default font-bold font-roboto text-2xl">
                {emp_name}
              </h2>
              <h4 className="text-GTI-BLUE-default">{org_name}</h4>

              <div className="w-full max-w-[600px] flex flex-col items-center md:flex-row md:gap-[100px]  justify-between">
                <h4 className="flex text-GTI-BLUE-default">
                  <span className="inline-flex items-center uppercase">
                    <ReactCountryFlag
                      countryCode={org_country ? org_country : "IN"}
                      svg
                      style={{ width: "2em", height: "2em" }}
                      className="mr-2"
                    />
                  </span>
                </h4>
                {/* <h4 className="flex items-center gap-2 text-GTI-BLUE-default">
                  {currentUser?.user?.email}{" "} */}
                {/* {currentUser?.user?.emailVerified ? (
                    <span>
                      <BsFillPatchCheckFill
                        className="text-GTI-BLUE-default"
                        size={18}
                      />
                    </span>
                  ) : null} */}
                {/* </h4> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="profile-main-right">
        <ol className="profile-main-right-content-1">
          <li
            className={
              "profile-main-right-content-title " +
              (tab === 1 ? "text-GTI-BLUE-default" : "text-slate-500")
            }
            onClick={() => {
              handletab(1);
            }}
          >
            <svg
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="w-5 h-5 mx-1"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M17.982 18.725A7.488 7.488 0 0012 15.75a7.488 7.488 0 00-5.982 2.975m11.963 0a9 9 0 10-11.963 0m11.963 0A8.966 8.966 0 0112 21a8.966 8.966 0 01-5.982-2.275M15 9.75a3 3 0 11-6 0 3 3 0 016 0z"
              />
            </svg>
            <button>Personal Details</button>
          </li>
          {currentUser.admin == 1 &&
            currentUser?.company &&
            currentUser?.userType !== "GENERAL_SUBSCRIBER" && (
              <li
                className={
                  "profile-main-right-content-title " +
                  (tab === 2 ? "text-GTI-BLUE-default" : "text-slate-500")
                }
                onClick={() => {
                  handletab(2);
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className="w-5 h-5 mx-1"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M12 21a9.004 9.004 0 008.716-6.747M12 21a9.004 9.004 0 01-8.716-6.747M12 21c2.485 0 4.5-4.03 4.5-9S14.485 3 12 3m0 18c-2.485 0-4.5-4.03-4.5-9S9.515 3 12 3m0 0a8.997 8.997 0 017.843 4.582M12 3a8.997 8.997 0 00-7.843 4.582m15.686 0A11.953 11.953 0 0112 10.5c-2.998 0-5.74-1.1-7.843-2.918m15.686 0A8.959 8.959 0 0121 12c0 .778-.099 1.533-.284 2.253m0 0A17.919 17.919 0 0112 16.5c-3.162 0-6.133-.815-8.716-2.247m0 0A9.015 9.015 0 013 12c0-1.605.42-3.113 1.157-4.418"
                  />
                </svg>
                <button>Company Details</button>
              </li>
            )}

          <li
            className={
              "profile-main-right-content-title " +
              (tab === 5 ? "text-GTI-BLUE-default" : "text-slate-500")
            }
            onClick={() => {
              handletab(5);
            }}
          >
            <svg
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="w-5 h-5 mx-1"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M15.75 5.25a3 3 0 013 3m3 0a6 6 0 01-7.029 5.912c-.563-.097-1.159.026-1.563.43L10.5 17.25H8.25v2.25H6v2.25H2.25v-2.818c0-.597.237-1.17.659-1.591l6.499-6.499c.404-.404.527-1 .43-1.563A6 6 0 1121.75 8.25z"
              />
            </svg>
            <button>Change Password</button>
          </li>
          <li
            className={
              "profile-main-right-content-title " +
              (tab === 4 ? "text-GTI-BLUE-default" : "text-slate-500")
            }
            onClick={() => {
              handletab(4);
            }}
          >
            <FaConnectdevelop className="w-5 h-5 mx-1" stroke="currentColor" />
            <button> Connections</button>
          </li>
        </ol>
        <Outlet />
      </div>
      <br />
    </div>
  );
};

export default UserProfile;
