import instagram from "../assests/images/featured/instagram.svg";
import facebook from "../assests/images/featured/facebook.svg";
import linkedin from "../assests/images/featured/linkedin.svg";
import twitter from "../assests/images/featured/twitter.svg";

type Country = {
  symbol: string;
  name: string;
};

export const countryList: Country[] = [
  { symbol: "AF", name: "Afghanistan" },
  { symbol: "AX", name: "Aland Islands" },
  { symbol: "AL", name: "Albania" },
  { symbol: "DZ", name: "Algeria" },
  { symbol: "AS", name: "American Samoa" },
  { symbol: "AD", name: "Andorra" },
  { symbol: "AO", name: "Angola" },
  { symbol: "AI", name: "Anguilla" },
  { symbol: "AQ", name: "Antarctica" },
  { symbol: "AG", name: "Antigua and Barbuda" },
  { symbol: "AR", name: "Argentina" },
  { symbol: "AM", name: "Armenia" },
  { symbol: "AW", name: "Aruba" },
  { symbol: "AU", name: "Australia" },
  { symbol: "AT", name: "Austria" },
  { symbol: "AZ", name: "Azerbaijan" },
  { symbol: "BS", name: "Bahamas" },
  { symbol: "BH", name: "Bahrain" },
  { symbol: "BD", name: "Bangladesh" },
  { symbol: "BB", name: "Barbados" },
  { symbol: "BY", name: "Belarus" },
  { symbol: "BE", name: "Belgium" },
  { symbol: "BZ", name: "Belize" },
  { symbol: "BJ", name: "Benin" },
  { symbol: "BM", name: "Bermuda" },
  { symbol: "BT", name: "Bhutan" },
  { symbol: "BO", name: "Bolivia" },
  { symbol: "BQ", name: "Bonaire, Sint Eustatius and Saba" },
  { symbol: "BA", name: "Bosnia and Herzegovina" },
  { symbol: "BW", name: "Botswana" },
  { symbol: "BV", name: "Bouvet Island" },
  { symbol: "BR", name: "Brazil" },
  { symbol: "IO", name: "British Indian Ocean Territory" },
  { symbol: "BN", name: "Brunei Darussalam" },
  { symbol: "BG", name: "Bulgaria" },
  { symbol: "BF", name: "Burkina Faso" },
  { symbol: "BI", name: "Burundi" },
  { symbol: "KH", name: "Cambodia" },
  { symbol: "CM", name: "Cameroon" },
  { symbol: "CA", name: "Canada" },
  { symbol: "CV", name: "Cape Verde" },
  { symbol: "KY", name: "Cayman Islands" },
  { symbol: "CF", name: "Central African Republic" },
  { symbol: "TD", name: "Chad" },
  { symbol: "CL", name: "Chile" },
  { symbol: "CN", name: "China" },
  { symbol: "CX", name: "Christmas Island" },
  { symbol: "CC", name: "Cocos (Keeling) Islands" },
  { symbol: "CO", name: "Colombia" },
  { symbol: "KM", name: "Comoros" },
  { symbol: "CG", name: "Congo" },
  { symbol: "CD", name: "Congo, Democratic Republic of the Congo" },
  { symbol: "CK", name: "Cook Islands" },
  { symbol: "CR", name: "Costa Rica" },
  { symbol: "CI", name: "Cote D'Ivoire" },
  { symbol: "HR", name: "Croatia" },
  { symbol: "CU", name: "Cuba" },
  { symbol: "CW", name: "Curacao" },
  { symbol: "CY", name: "Cyprus" },
  { symbol: "CZ", name: "Czech Republic" },
  { symbol: "DK", name: "Denmark" },
  { symbol: "DJ", name: "Djibouti" },
  { symbol: "DM", name: "Dominica" },
  { symbol: "DO", name: "Dominican Republic" },
  { symbol: "EC", name: "Ecuador" },
  { symbol: "EG", name: "Egypt" },
  { symbol: "SV", name: "El Salvador" },
  { symbol: "GQ", name: "Equatorial Guinea" },
  { symbol: "ER", name: "Eritrea" },
  { symbol: "EE", name: "Estonia" },
  { symbol: "ET", name: "Ethiopia" },
  { symbol: "FK", name: "Falkland Islands (Malvinas)" },
  { symbol: "FO", name: "Faroe Islands" },
  { symbol: "FJ", name: "Fiji" },
  { symbol: "FI", name: "Finland" },
  { symbol: "FR", name: "France" },
  { symbol: "GF", name: "French Guiana" },
  { symbol: "PF", name: "French Polynesia" },
  { symbol: "TF", name: "French Southern Territories" },
  { symbol: "GA", name: "Gabon" },
  { symbol: "GM", name: "Gambia" },
  { symbol: "GE", name: "Georgia" },
  { symbol: "DE", name: "Germany" },
  { symbol: "GH", name: "Ghana" },
  { symbol: "GI", name: "Gibraltar" },
  { symbol: "GR", name: "Greece" },
  { symbol: "GL", name: "Greenland" },
  { symbol: "GD", name: "Grenada" },
  { symbol: "GP", name: "Guadeloupe" },
  { symbol: "GU", name: "Guam" },
  { symbol: "GT", name: "Guatemala" },
  { symbol: "GG", name: "Guernsey" },
  { symbol: "GN", name: "Guinea" },
  { symbol: "GW", name: "Guinea-Bissau" },
  { symbol: "GY", name: "Guyana" },
  { symbol: "HT", name: "Haiti" },
  { symbol: "HM", name: "Heard Island and Mcdonald Islands" },
  { symbol: "VA", name: "Holy See (Vatican City State)" },
  { symbol: "HN", name: "Honduras" },
  { symbol: "HK", name: "Hong Kong" },
  { symbol: "HU", name: "Hungary" },
  { symbol: "IS", name: "Iceland" },
  { symbol: "IN", name: "India" },
  { symbol: "ID", name: "Indonesia" },
  { symbol: "IR", name: "Iran, Islamic Republic of" },
  { symbol: "IQ", name: "Iraq" },
  { symbol: "IE", name: "Ireland" },
  { symbol: "IM", name: "Isle of Man" },
  { symbol: "IL", name: "Israel" },
  { symbol: "IT", name: "Italy" },
  { symbol: "JM", name: "Jamaica" },
  { symbol: "JP", name: "Japan" },
  { symbol: "JE", name: "Jersey" },
  { symbol: "JO", name: "Jordan" },
  { symbol: "KZ", name: "Kazakhstan" },
  { symbol: "KE", name: "Kenya" },
  { symbol: "KI", name: "Kiribati" },
  { symbol: "KP", name: "Korea, Democratic People's Republic of" },
  { symbol: "KR", name: "Korea, Republic of" },
  { symbol: "XK", name: "Kosovo" },
  { symbol: "KW", name: "Kuwait" },
  { symbol: "KG", name: "Kyrgyzstan" },
  { symbol: "LA", name: "Lao People's Democratic Republic" },
  { symbol: "LV", name: "Latvia" },
  { symbol: "LB", name: "Lebanon" },
  { symbol: "LS", name: "Lesotho" },
  { symbol: "LR", name: "Liberia" },
  { symbol: "LY", name: "Libyan Arab Jamahiriya" },
  { symbol: "LI", name: "Liechtenstein" },
  { symbol: "LT", name: "Lithuania" },
  { symbol: "LU", name: "Luxembourg" },
  { symbol: "MO", name: "Macao" },
  { symbol: "MK", name: "Macedonia, the Former Yugoslav Republic of" },
  { symbol: "MG", name: "Madagascar" },
  { symbol: "MW", name: "Malawi" },
  { symbol: "MY", name: "Malaysia" },
  { symbol: "MV", name: "Maldives" },
  { symbol: "ML", name: "Mali" },
  { symbol: "MT", name: "Malta" },
  { symbol: "MH", name: "Marshall Islands" },
  { symbol: "MQ", name: "Martinique" },
  { symbol: "MR", name: "Mauritania" },
  { symbol: "MU", name: "Mauritius" },
  { symbol: "YT", name: "Mayotte" },
  { symbol: "MX", name: "Mexico" },
  { symbol: "FM", name: "Micronesia, Federated States of" },
  { symbol: "MD", name: "Moldova, Republic of" },
  { symbol: "MC", name: "Monaco" },
  { symbol: "MN", name: "Mongolia" },
  { symbol: "ME", name: "Montenegro" },
  { symbol: "MS", name: "Montserrat" },
  { symbol: "MA", name: "Morocco" },
  { symbol: "MZ", name: "Mozambique" },
  { symbol: "MM", name: "Myanmar" },
  { symbol: "NA", name: "Namibia" },
  { symbol: "NR", name: "Nauru" },
  { symbol: "NP", name: "Nepal" },
  { symbol: "NL", name: "Netherlands" },
  { symbol: "AN", name: "Netherlands Antilles" },
  { symbol: "NC", name: "New Caledonia" },
  { symbol: "NZ", name: "New Zealand" },
  { symbol: "NI", name: "Nicaragua" },
  { symbol: "NE", name: "Niger" },
  { symbol: "NG", name: "Nigeria" },
  { symbol: "NU", name: "Niue" },
  { symbol: "NF", name: "Norfolk Island" },
  { symbol: "MP", name: "Northern Mariana Islands" },
  { symbol: "NO", name: "Norway" },
  { symbol: "OM", name: "Oman" },
  { symbol: "PK", name: "Pakistan" },
  { symbol: "PW", name: "Palau" },
  { symbol: "PS", name: "Palestinian Territory, Occupied" },
  { symbol: "PA", name: "Panama" },
  { symbol: "PG", name: "Papua New Guinea" },
  { symbol: "PY", name: "Paraguay" },
  { symbol: "PE", name: "Peru" },
  { symbol: "PH", name: "Philippines" },
  { symbol: "PN", name: "Pitcairn" },
  { symbol: "PL", name: "Poland" },
  { symbol: "PT", name: "Portugal" },
  { symbol: "PR", name: "Puerto Rico" },
  { symbol: "QA", name: "Qatar" },
  { symbol: "RE", name: "Reunion" },
  { symbol: "RO", name: "Romania" },
  { symbol: "RU", name: "Russian Federation" },
  { symbol: "RW", name: "Rwanda" },
  { symbol: "BL", name: "Saint Barthelemy" },
  { symbol: "SH", name: "Saint Helena" },
  { symbol: "KN", name: "Saint Kitts and Nevis" },
  { symbol: "LC", name: "Saint Lucia" },
  { symbol: "MF", name: "Saint Martin" },
  { symbol: "PM", name: "Saint Pierre and Miquelon" },
  { symbol: "VC", name: "Saint Vincent and the Grenadines" },
  { symbol: "WS", name: "Samoa" },
  { symbol: "SM", name: "San Marino" },
  { symbol: "ST", name: "Sao Tome and Principe" },
  { symbol: "SA", name: "Saudi Arabia" },
  { symbol: "SN", name: "Senegal" },
  { symbol: "RS", name: "Serbia" },
  { symbol: "CS", name: "Serbia and Montenegro" },
  { symbol: "SC", name: "Seychelles" },
  { symbol: "SL", name: "Sierra Leone" },
  { symbol: "SG", name: "Singapore" },
  { symbol: "SX", name: "Sint Maarten" },
  { symbol: "SK", name: "Slovakia" },
  { symbol: "SI", name: "Slovenia" },
  { symbol: "SB", name: "Solomon Islands" },
  { symbol: "SO", name: "Somalia" },
  { symbol: "ZA", name: "South Africa" },
  { symbol: "GS", name: "South Georgia and the South Sandwich Islands" },
  { symbol: "SS", name: "South Sudan" },
  { symbol: "ES", name: "Spain" },
  { symbol: "LK", name: "Sri Lanka" },
  { symbol: "SD", name: "Sudan" },
  { symbol: "SR", name: "Suriname" },
  { symbol: "SJ", name: "Svalbard and Jan Mayen" },
  { symbol: "SZ", name: "Swaziland" },
  { symbol: "SE", name: "Sweden" },
  { symbol: "CH", name: "Switzerland" },
  { symbol: "SY", name: "Syrian Arab Republic" },
  { symbol: "TW", name: "Taiwan, Province of China" },
  { symbol: "TJ", name: "Tajikistan" },
  { symbol: "TZ", name: "Tanzania, United Republic of" },
  { symbol: "TH", name: "Thailand" },
  { symbol: "TL", name: "Timor-Leste" },
  { symbol: "TG", name: "Togo" },
  { symbol: "TK", name: "Tokelau" },
  { symbol: "TO", name: "Tonga" },
  { symbol: "TT", name: "Trinidad and Tobago" },
  { symbol: "TN", name: "Tunisia" },
  { symbol: "TR", name: "Turkey" },
  { symbol: "TM", name: "Turkmenistan" },
  { symbol: "TC", name: "Turks and Caicos Islands" },
  { symbol: "TV", name: "Tuvalu" },
  { symbol: "UG", name: "Uganda" },
  { symbol: "UA", name: "Ukraine" },
  { symbol: "AE", name: "United Arab Emirates" },
  { symbol: "GB", name: "United Kingdom" },
  { symbol: "US", name: "United States" },
  { symbol: "UM", name: "United States Minor Outlying Islands" },
  { symbol: "UY", name: "Uruguay" },
  { symbol: "UZ", name: "Uzbekistan" },
  { symbol: "VU", name: "Vanuatu" },
  { symbol: "VE", name: "Venezuela" },
  { symbol: "VN", name: "Viet Nam" },
  { symbol: "VG", name: "Virgin Islands, British" },
  { symbol: "VI", name: "Virgin Islands, U.s." },
  { symbol: "WF", name: "Wallis and Futuna" },
  { symbol: "EH", name: "Western Sahara" },
  { symbol: "YE", name: "Yemen" },
  { symbol: "ZM", name: "Zambia" },
  { symbol: "ZW", name: "Zimbabwe" },
];

export const displayerMessage =
  "If you are a technology company, incubator or accelerator looking to access new markets and opportunities to deploy your products and/or services, please sign up as a Technology Displayer";
export const scouterMessage =
  "If you are an institute, NGO, Government Institute or corporate searching for innovative solutions to solve business requirements, please sign up as a Technology Scouter";
export const generalSubscriberMessage =
  "If you are interested in technology, innovation and/or market opportunities and would like to explore partnerships and collaboration opportunities in the future, please sign up as a General Subscriber";

export const Company = {
  NAME: "Global Technology Interface",
  DESCRIPTION:
    "A platform to showcase innovative technologies and market opportunities.\n  Access our global network to make meaningful partnerships and explore significant collaboration opportunities, all in one place.",
};

export const SocialMedium = {
  linkedin: {
    logo: linkedin,
    details: [
      {
        type: "GTI",
        link: "https://www.linkedin.com/company/globaltech-interface/about/",
        text: "GTI Linkedin",
      },
      {
        type: "GBI",
        link: "https://www.linkedin.com/company/global-business-inroads/",
        text: "GBI Linkedin",
      },
    ],
  },
  facebook: {
    logo: facebook,
    details: [
      {
        type: "GTI",
        link: "https://www.facebook.com/people/Global-Tech-Interface/100063974445081/",
        text: "GTI Facebook",
      },
      {
        type: "GBI",
        link: "https://www.facebook.com/GlobalBusinessInroads",
        text: "GBI Facebook",
      },
    ],
  },
  instagram: {
    logo: instagram,
    details: [
      {
        type: "GBI",
        link: "https://www.instagram.com/globalbizin/",
        text: "GBI Instagram",
      },
    ],
  },
  twitter: {
    logo: twitter,
    details: [
      { type: "GTI", link: "https://x.com/GTI_Techglobal", text: "GTI X" },
      { type: "GBI", link: "https://x.com/GBI_Tech", text: "GBI X" },
    ],
  },
};
