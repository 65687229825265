import * as ACTION from "../actiontypes/actionTypes";

const initialState: CONNECTION_STATE = {
  CONNECTION: {
    CONNECTION_LIST: {
      connections: [],
      connectionsCount: 0,
    },
  },
};

export const connectionreducer = (
  state: CONNECTION_STATE = initialState,
  action: CONNECTION_ACTION
): CONNECTION_STATE => {
  switch (action.type) {
    case ACTION.CONNECTION_LIST:
      let connections: CONNECTION = action.CONNECTION.CONNECTION;
      // console.log("REDUX", connections);
      return { ...state, CONNECTION: connections };
    case ACTION.CONNECT_USER:
      return { ...state };
    case ACTION.UNCONNECT_USER:
      return { ...state };
    default:
      return { ...state };
  }
  // return state;
};
